import React, { useRef } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AddUser from './components/addUser';
import Buttons from './Buttons'
import { getUserListAction } from './Store/actions';
import { useNavigate } from "react-router-dom";
import { styles, StyledAgGridReact } from './styles'
import Loader from '../../../customWidgets/Loader';
import GridPagination from '../../../customWidgets/GridPagination';
import Utils from '../../../utils/Utils';
import AccessLevelContext from '../../../../accessLevel-context';
import SimpleSnackbar from '../../../customWidgets/Snackbar';
import * as XLSX from 'xlsx';
import logo from '../../../assets/excelicon.png';
import { apiUserList, apiDeleteUserandOrder } from '../../../services/Gateway/RestAPIHandler'

function UserList() {
  const accessLevel = React.useContext(AccessLevelContext)
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertType, setAlertType] = React.useState("success");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [openAddUser, SetOpenAddUser] = React.useState(false);
  const [labelRows, setLabelRows] = React.useState([]);
  const [labelIds, setLabelIds] = React.useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };

  const columnDef = [
    { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 20 },
    { field: 'userId', headerName: 'User ID', width: 250, resizable: true },
    { field: 'firstName', headerName: 'First Name', width: 200, resizable: true },
    { field: 'lastName', headerName: 'Last Name', width: 150, resizable: true },
    { field: 'description', headerName: 'Description', width: 180, resizable: true },
    { field: 'email', headerName: 'Email', width: 250, resizable: true },
    { field: 'telephone', headerName: 'Telephone', width: 150, resizable: true },
    { field: 'countryCode', headerName: 'Country Code', width: 180, resizable: true },
    { field: 'countryName', headerName: 'Country Name', width: 140, resizable: true },
    { field: 'liaisonOfficeCode', headerName: 'Liaison Office Code', width: 180, resizable: true },
    {
      field: 'groupName', headerName: 'User Type', width: 140, resizable: true,
      cellRenderer: params => {
        if (params.value === 'factory_group') {
          return <div>Factory User</div>
        } 
        return <div>Admin User</div>
      }
    },
    {
      field: 'emailNotify', headerName: 'Email Notify', width: 140, resizable: true,
      cellRenderer: params => {
        if (params.value === 'Y') {
          return <div>Yes</div>
        }
        return <div>No</div>
      }
    },
    {
      field: 'emailCode', headerName: 'Approval Notification for Division', width: 300, resizable: true,
      cellRenderer: params => {
        if (params.value === 'A') {
          return <div>BOTH</div>
        } else if (params.value === '2') {
          return <div>APPAREL</div>
        } else if (params.value === '1') {
          return <div>FOOTWEAR</div>
        } else if (params.value === 'F') {
          return <div>FACTORY</div>
        }
        return <div>NONE</div>
      }
    },
    { field: 'emailFactory', headerName: 'Approval Notification for Factory', width: 300, resizable: true }

  ]
  const GRID_FETCH_SIZE = 7;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const list = useSelector(state => state.userlist);
  const dataToExcel = (data) => {
    const newData = data.map(ele => {
      return {
        "User ID": ele?.userId,
        "First Name": ele?.firstName,
        "Last Name": ele?.lastName,
        "Description": ele?.description,
        "Email": ele?.email,
        "Telephone": ele?.telephone,
        "Country Code": ele?.countryCode,
        "Country Name": ele?.countryName,
        "Liaison Office Code": ele?.liaisonOfficeCode,
        "User Type": ele?.groupName==='factory_group'?'Factory User':'Admin User',
        "Email Notify": ele?.emailNotify==='Y'?'Yes':'No',
        "Approval Notification for Division": ele?.emailCode==='A'?'BOTH':(ele?.emailCode==='1'?'FOOTWEAR':(ele?.emailCode==='2'?'APPAREL':(ele?.emailCode==='F'?'FACTORY':'NONE'))),
        "Approval Notification for Factories": ele?.emailFactory
      }
    })

    const worksheet = XLSX.utils.json_to_sheet(newData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    XLSX.writeFile(workbook, "DownloadUserList.xlsx")
    setLoading(false)
  }
  const onSelectionChange = (event) => {
    const selectedRows = event.api.getSelectedRows();
    if (!Utils.isEmpty(selectedRows)) {
      setLabelRows(selectedRows)
      const labelIdslist = selectedRows.map(ele => ele?.userId).join(',')
      setLabelIds(labelIdslist)
    }
    else {
      setLabelRows([]);
      setLabelIds('')
    }
  }

  const handleGridDelete = (event) => {


    apiDeleteUserandOrder({ 'userIds': labelIds }).then(([response, json]) => {
      if (response.status === 200 && json.status === "SUCCESS") {
        setOpenAlert(true)
        setAlertType('success')
        setAlertMessage('Successfully Deleted')
        dispatch(getUserListAction({ fetchSize: GRID_FETCH_SIZE, page: 1 }))
        setLabelIds('');
        setLabelRows([]);
      }
      else {
        setOpenAlert(true)
        setAlertType('error')
        setAlertMessage('Unable to delete selected labels')
      }
    })
  }

  const exportToExcel = () => {
    setLoading(true)
    apiUserList(
      { fetchSize: list.order.data[0].totalElements, page: 1 }
    ).then(([response, json]) => {
      if (response.status === 200 && !Utils.isEmpty(json.results)) {
        const data = json.results[0].content ?? [];
        if (labelRows.length === 0) {
          dataToExcel(data)
        }
        else {
          dataToExcel(labelRows)
        }
      }
      else {
        setOpenAlert(true)
        setAlertType('error')
        setAlertMessage("No data to export")
        setLoading(false)
      }
    })
  }
  React.useEffect(() => {
    if (accessLevel?.accessLevel !== 'A01') {
      navigate('/')
    }
    else {
      dispatch(getUserListAction({ fetchSize: GRID_FETCH_SIZE, page: currentPage }));
    }
  }, [])//eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (!Utils.isEmpty(list.order) && !Utils.isEmpty(list.order.data)) {
      setLoading(false)
    }
  }, [list.order, currentPage])//eslint-disable-line react-hooks/exhaustive-deps
  const gridRef = useRef();
  const showGridLoader = () => {
    gridRef.current.api.showLoadingOverlay();
  }
  const getTotalPages = React.useMemo(() => {
    if (!Utils.isEmpty(list.order)) {
      return list.order.data.length === 0 ?
        {
          totalPages: 1,
          totalElements: 1
        } : {
          totalPages: list.order.data[0].totalPages,
          totalElements: list.order.data[0].totalElements
        };
    }
    else
      return {
        totalPages: 1,
        totalElements: 1
      };
  }, [list]);

  const handleGridAdd = () => {
    SetOpenAddUser(true);
  }

  const gotoKeyDown = (event, value) => {

    if (event.keyCode === 13 && parseInt(value) >= 1 && parseInt(value) <= getTotalPages.totalPages && parseInt(value) !== currentPage) {
      showGridLoader()
      setCurrentPage(parseInt(value));
      dispatch(getUserListAction({ fetchSize: GRID_FETCH_SIZE, page: parseInt(value) }));
    }

  }

  const handleGridPagination = (event, thisPage) => {
    if (currentPage !== thisPage) {
      setLabelRows([])
      showGridLoader()
      setCurrentPage(thisPage)
      dispatch(getUserListAction({ fetchSize: GRID_FETCH_SIZE, page: thisPage }));
    }
  }

  return (
    <Box style={styles.gridContainer}>
      <Loader open={loading} />
      <SimpleSnackbar setOpen={setOpenAlert} open={openAlert} message={alertMessage} messagetype={alertType} />
      <AddUser openModal={openAddUser} closeModal={SetOpenAddUser} currentPage={currentPage} />
      <img data-testid="excelicon" onClick={() => { exportToExcel() }} src={logo} style={styles.excelicon} alt={"Export Excel"} />
      <Box className={"ag-theme-alpine"} sx={{ height: '365px' }}>
        <StyledAgGridReact
          rowSelection='single'
          ref={gridRef}
          rowData={!Utils.isEmpty(list.order) && !Utils.isEmpty(list?.order?.data) ? list?.order?.data[0]?.content : []}
          columnDefs={columnDef}
          pagination={true}
          paginationPageSize={GRID_FETCH_SIZE}
          cacheBlockSize={GRID_FETCH_SIZE}
          suppressPaginationPanel={true}
          overlayLoadingTemplate={`<div class="loader"></div>`}
          onSelectionChanged={onSelectionChange}
        />

        <Box style={styles.alignBottom}>
          <GridPagination
            pageClick={handleGridPagination}
            gridTotal={getTotalPages}
            currentPage={currentPage}
            pageSize={GRID_FETCH_SIZE}
            gotoKeyDown={gotoKeyDown} />
          <Buttons labelRows={labelRows} handleGridAdd={handleGridAdd} handleGridDelete={handleGridDelete} />


        </Box>
      </Box>
    </Box>
  )
}
export default UserList