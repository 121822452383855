import { labelCustomerList, apisearchCustomer } from '../../../../services/Gateway/RestAPIHandler';
export const LABEL_CUSTOMER_LIST_GET = "Get Label Customer List";
export const LABEL_CUSTOMER_LIST_SUCCESS = "Label Customer List Sucess";
export const LABEL_CUSTOMER_LIST_FAILURE = "Label Customer List Failure";

const searchDefault = {
    "ascDesc": "",
    "fetchSize": 8,
    "filterSearch": "",
    "pageNumber": 1,
    "sortBy": ""
}
export function getlabelCustomerListAction(params) {
    return (dispatch) => {
        dispatch(getlabelCustomerList());
        return labelCustomerList(params)
            .then(([response, json]) => {
                if (response.status === 200) {
                    dispatch(getlabelCustomerListSuccess(json.results));
                }
                else {
                    dispatch(getlabelCustomerListFailure("Unable to fetch Customer list data; Error code - " + json.errorCode));
                }
            })
    }
}
export function searchCustomerAction(params = searchDefault) {
    return (dispatch) => {
        dispatch(getlabelCustomerList());
        return apisearchCustomer(params)
            .then(([response, json]) => {
                if (response.status === 200) {

                    dispatch(getlabelCustomerListSuccess(json.results, params));
                }
                else {
                    dispatch(getlabelCustomerListFailure("Unable to fetch Customer search data; Error code - " + json.errorCode, params));
                }
            })
    }
}

export function getlabelCustomerList() {
    return {
        type: LABEL_CUSTOMER_LIST_GET
    }
}

export function getlabelCustomerListSuccess(res,params) {
    return {
        type: LABEL_CUSTOMER_LIST_SUCCESS,
        payload: {res:res,request:params}

    }

}

export function getlabelCustomerListFailure(message,params) {
    return {
        type: LABEL_CUSTOMER_LIST_FAILURE,
        payload: {message:message,request:params}
    }
}