import { apiProductType } from '../../../../services/Gateway/RestAPIHandler';

 
 
 export const PRODUCT_TYPE_GET ="Get Product Type";
 export const PRODUCT_TYPE_SUCCESS = "Product Type Sucess";
 export const PRODUCT_TYPE_FAILURE = "Product Type Failure";
 export function getProductTypeAction(params) {
    return (dispatch) => {
        dispatch(getProductType());
        return apiProductType(params) 
            .then(([response, json]) => {
                if(response.status === 200){ 
                    dispatch(getProductTypeSuccess(json.results));
                }
                else {
                    dispatch(getProductTypeFailure("Unable to fetch Product Type data; Error code - " + json.errorCode));
                }
            })
    }
}

export function getProductType() {
    return {
        type: PRODUCT_TYPE_GET
    }
}

export function getProductTypeSuccess(data) {
    return {
        type: PRODUCT_TYPE_SUCCESS,
        payload: data

    }

}

export function getProductTypeFailure(message) {
    return {
        type: PRODUCT_TYPE_FAILURE,
        payload: message
    }
}