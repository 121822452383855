import envConfig from "../../../config/env/environment";
import Utils from "../../utils/Utils";

export async function UserLogin(params) {

  let raw = JSON.stringify({
    "password": params.password,
    "username": params.username
  });

  return await fetch(envConfig.gatewayPath + `/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: raw
  }).then((res) => Promise.all([res, res.json()]))
}

export const BeginAuth = async () => {
  // Static oidc params for a single provider
  const response_type = "id_token token";
  const scope = "openid profile";

  // Generate authentication URL
  let authUrl = `${envConfig.oneLogin.authority}/auth`;
  authUrl = Utils.formQueryString(authUrl, "client_id", envConfig.oneLogin.clientId);
  authUrl = Utils.formQueryString(authUrl, "redirect_uri", envConfig.oneLogin.redirectURI);
  authUrl = Utils.formQueryString(authUrl, "response_type", response_type);
  authUrl = Utils.formQueryString(authUrl, "scope", scope);
  authUrl = Utils.formQueryString(authUrl, "state", Utils.getUniqueKey() + "-" + Utils.getUniqueKey());
  authUrl = Utils.formQueryString(authUrl, "nonce", Utils.getUniqueKey() + "-" + Utils.getUniqueKey());
  //authUrl = Utils.formQueryString(authUrl, "state", null);
  //authUrl = Utils.formQueryString(authUrl, "nonce", null);

  window.location.assign(authUrl);
};
