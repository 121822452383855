import React from 'react'
import { Box } from '@mui/system';
import { styles, OrderButton } from './styles';
function Buttons(props) {
    const labelRows = props.labelRows;

    const handleGridAdd = () => {
        props.handleGridAdd();
    }

    const handleGridDelete = () => {
        props.handleGridDelete();
    }

    return (
        <Box style={styles.buttons}>
            <OrderButton onClick={handleGridAdd}>ADD</OrderButton>
            <OrderButton className={props.labelRows.length > 0 ? '' : 'disabled'} onClick={handleGridDelete}>DELETE</OrderButton> 
        </Box>
    )
}
export default Buttons
