import { apiOrderHistory} from '../../../../services/Gateway/RestAPIHandler';
import Utils from '../../../../utils/Utils';
 export const LABEL_LABEL_SIZE_GET ="Get Label Label Size List";
 export const LABEL_LABEL_SIZE_SUCCESS = "Label Label Size List Sucess";
 export const LABEL_LABEL_SIZE_FAILURE = "Label Label Size List Failure";



 export function getOrderHistoryAction(params) {
    return (dispatch) => {
        dispatch(getOrderHistory());
        return apiOrderHistory(params) 
            .then(([response, json]) => {
                if(response.status === 200){ 
                    dispatch(getOrderHistorySuccess(json.results));
                }
                else {
                    dispatch(getOrderHistoryFailure(`Unable to fetch Label Size list data; Error code - ${Utils.isEmpty(json.errorCode) ? 0 : json.errorCode}`));
                }
            })
    }
}

export function getOrderHistory() {
    return {
        type: LABEL_LABEL_SIZE_GET
    }
}

export function getOrderHistorySuccess(data) {
    return {
        type: LABEL_LABEL_SIZE_SUCCESS,
        payload: data

    }

}

export function getOrderHistoryFailure(message) {
    return {
        type: LABEL_LABEL_SIZE_FAILURE,
        payload: message
    }
}