import { takeEvery, call, put, all } from 'redux-saga/effects'
import { apisearchOrderList, labelArticleMaster,apisearchCountrySize, apisearchOrderLabel, apisearchApprovalOrderList} from '../../../services/Gateway/RestAPIHandler'
import { exportBulkDataSuccess } from './action'

function* exportDatatoExcel(action) {
    const query = [];
    const queryCall = [];
    const fetchSize = 500;
    let count = 1;
    let last = Math.floor(action.param.fetchSize/fetchSize) + ((action.param.fetchSize%fetchSize)>0 ? 1 : 0);
    //6 is set below to get 3000 records, fetch size is set to 500 vishal change 6 to 20
    last = (last > 20) ? 20 : last;

    while(count <= last){
        let param = {...action.param};
        Object.assign(param, {
            fetchSize: (action.param.fetchSize>fetchSize) ? fetchSize : action.param.fetchSize,
            pageNumber: (action.param.pageNumber * count)
        })
        query.push(param);
        ++count;
    }
    
    if(action.param.exportFor === 'OrderList'){
        query.map((value) => queryCall.push(call(apisearchOrderList, value)));
    }
    else if(action.param.exportFor === 'ArticleMaster') {
        query.map((value) => queryCall.push(call(labelArticleMaster, value)));
    }
    else if(action.param.exportFor === 'CountryLookup') {
        query.map((value) => queryCall.push(call(apisearchCountrySize, value)));
    }
    else if(action.param.exportFor === 'OrderLabelList') {
        query.map((value) => queryCall.push(call(apisearchOrderLabel, value)));
    }
    else if(action.param.exportFor === 'ApprovalList') {
        query.map((value) => queryCall.push(call(apisearchApprovalOrderList, value)));
    }

    const output = yield all(queryCall);
    let result = [];
    output.forEach((value) => {
        result = result.concat(value[1].results[0].content);
    })

    yield put(exportBulkDataSuccess(result, action.param.exportFor))
}

export default function* ExportSaga() {
    yield takeEvery('EXPORT_BULK_DATA_ASYNC', exportDatatoExcel);
}