export const orderListMapping = {
    "orderNumber": "PO No",
    "status": "Status",
    "orderQuantity": "Order Quantity",
    "articleNumber": "Article Number",
    "articleDescription": "Article Description",
    "countryOfOrigin": "Origin Country Code",
    "customerCode": "Customer Code",
    "customerOrderNum": "Customer Order No",
    "divisionCode": "Division Code",
    "factoryCode": "Factory Code",
    "factoryName": "Factory Name",
    "lastProductionDate": "Last Production Date",
    "shippingAddressingLine1": "Shipping Addressing Line 1",
    "shippingAddressingLine2": "Shipping Addressing Line 2",
    "shippingAddressingLine3": "Shipping Addressing Line 3",
    "shipToCountry": "Shipping Country Code",
    "currency": "Currency",
    "retailPrice": "Retail Price"
}

export const articleMasterMapping = {
    "articleNumber": "Article Number",
    "articleDescription": "Article Description",
    "oldArticleNumber": "Old Article Number",
    "divisionDescription": "Division",
    "brandCode": " Brand Code ",
    "sizeRange": "Size Range",
    "modelNum": "Model Number",
    "modelDescription": "Model Description",
    "ageGroup": "Age Group",
    "gender": "Gender",
    "saleslineCode": "Salesline",
    "upperMaterial": "Upper Material",
    "upperMaterialDesc": "Upper Material Description",
    "liningMaterial": "Lining Material",
    "liningMaterialDesc": "Lining Material Description",
    "insoleMaterial": "Insole Material",
    "insoleMaterialDesc": "Insole Material Description",
    "outsoleMaterial": "Outsole Material",
    "outsoleMaterialDesc": "Outsole Material Description",
    "productTypeDescription": "Product Type",
    "productGroupDescription": "Product Group",
    "sportsCategoryDescription": "Sports Category",
    "productFit": "Product Fit",
    "colourCombinationCode": "Colour Combination Code",
    "creationSeason": "Creation Season",
    "currentSeason": "Current Season",
    "colorCombinationShort": "Colour Combination Short",
    "colorCombinationLong": "Colour Combination Long",
    "workingNumber": "Working Number"
}

export const countryLookupMapping = {
        "division" : "Division Code" ,
        "genderAgeGroup" : "Gender Age Group",
        "technicalSize" : "Technical Size",
        "argentinaSize" : "Argentina Size",
        "brazilSize" : "Brazil Size",
        "britainSize" : "Britain Size",
        "canadaSize" : "Canada Size",
        "chileSize" : "Chile Size",
        "chinaSize" : "China Size",
        "europeSize" : "Europe Size",
        "frenchSize" : "France Size",
        "germanSize" : "Germany Size",
        "italySize" : "Italy Size",
        "japanSize" : "Japan Size",
        "mexicoSize" : "Mexico Size",
        "russiaSize" : "Russia Size",
        "spainSize" : "Spain Size",
        "taiwanSize" : "Taiwan Size",
        "ukSize" : "UK Size",
        "usaSize" : "USA Size"   
}

export const orderLabelListMapping = {
    "orderNum": "PO No",
    "orderLabelStatus": "Status",
    "orderQuantity": "Order Quantity",
    "labelQuantity": "Label Quantity",
    "labelCode": "Label Code",
    "labelSequence": "Label Sequence",
    "labelDescription": "Label Description",
    "isApproved": "Approval Status",
    "approvalComment": "Approval Comment",
    "createdDate": "Created Date",
    "modifiedDate": "Modified Date",
    "articleNumber": "Article Number",
    "customerCode": "Customer Code",
    "divisionCode": "Division Code",
    "factoryCode": "Factory Code",
    "factoryName": "Factory Name",
}

export const approvalListMapping = {
    "orderNumber": "PO No",
    "status": "Status",
    "orderQuantity": "Order Quantity",
    "articleNumber": "Article Number",
    "articleDescription": "Article Description",
    "countryOfOrigin": "Origin Country Code",
    "customerCode": "Customer Code",
    "customerOrderNum": "Customer Order No",
    "divisionCode": "Division Code",
    "factoryCode": "Factory Code",
    "factoryName": "Factory Name",
    "lastProductionDate": "Last Production Date",
    "shippingAddressingLine1": "Shipping Addressing Line 1",
    "shippingAddressingLine2": "Shipping Addressing Line 2",
    "shippingAddressingLine3": "Shipping Addressing Line 3",
    "shipToCountry": "Shipping Country Code",
    "currency": "Currency",
    "retailPrice": "Retail Price"
}