export const USER_ACCESS_GET = 'USER_ACCESS_GET';
export const USER_ACCESS_SUCCESS = 'USER_ACCESS_SUCCESS';
export const USER_ACCESS_FAILURE = 'USER_ACCESS_FAILURE';

export function getUserAccess() {
    return {
        type: USER_ACCESS_GET
    }
}

export function getUserAccessSuccess(res) {
    return {
        type: USER_ACCESS_SUCCESS,
        payload: res
    }
}

export function getUserAccessFailure(message) {
    return {
        type: USER_ACCESS_FAILURE,
        isError: true,
        payload: message
    }
}