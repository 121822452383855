import {LABEL_LIST_GET , LABEL_LIST_SUCCESS,LABEL_LIST_FAILURE,LABEL_LIST_ADD_FAILURE, LABEL_LIST_ADD_SUCCESS} from './actions';

const initialState = {
    order: {},
    request:{},
    searchColumns:[]
}

export function LabelListReducer(state = initialState, action) {
    
    if(action.type === LABEL_LIST_GET) {
        return state;
    }
    if(action.type === LABEL_LIST_SUCCESS || action.type === LABEL_LIST_ADD_SUCCESS) {
        return {
            ...state,
            order: {
                isLoaded: true,
                data: action.payload.res,
                message: 'Success'
            },
            request:action.payload.request,
            searchColumns:action.payload.searchColumns
            
        }
    }
    if(action.type === LABEL_LIST_FAILURE || action.type === LABEL_LIST_ADD_FAILURE) {
        return {
            ...state,
            order: {
                data: [],
                message: action.payload.message
            },
            request:action.payload.request,
            searchColumns:action.payload.searchColumns
        }
    }
    
    return state;
    
}



