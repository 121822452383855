import { apiProductGroup } from '../../../../services/Gateway/RestAPIHandler';

 
 
 export const PRODUCT_GROUP_GET ="Get Product Groups";
 export const PRODUCT_GROUP_SUCCESS = "Product Groups Sucess";
 export const PRODUCT_GROUP_FAILURE = "Product Groups Failure";
 export function getProductGroupAction(params) {
    return (dispatch) => {
        dispatch(getProductGroup());
        return apiProductGroup(params) 
            .then(([response, json]) => {
                if(response.status === 200){ 
                    dispatch(getProductGroupSuccess(json.results));
                }
                else {
                    dispatch(getProductGroupFailure("Unable to fetch User Groups data; Error code - " + json.errorCode));
                }
            })
    }
}

export function getProductGroup() {
    return {
        type: PRODUCT_GROUP_GET
    }
}

export function getProductGroupSuccess(data) {
    return {
        type: PRODUCT_GROUP_SUCCESS,
        payload: data

    }

}

export function getProductGroupFailure(message) {
    return {
        type: PRODUCT_GROUP_FAILURE,
        payload: message
    }
}