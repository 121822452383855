import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, IconButton, Stack, Modal, Select, MenuItem } from '@mui/material';
import { styles, OrderButton } from '../styles';
import Utils from '../../../../utils/Utils';
import {useSelector,useDispatch } from 'react-redux';
import SimpleSnackbar from '../../../../customWidgets/Snackbar';
import { addLabelListAdminAction } from '../Store/actions';

function AddLabel({ openModal, closeModal }) {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [alertType,setAlertType] = useState("success");
    const [alertMessage,setAlertMessage] = useState("");
    const [inputData,setInputData]=useState({labelUsage:[]})
    const [labelDetails,setLabelDetails] = useState({labelCode:'',name:'',labelUsageID:'',formula:'',overagePercentage:'',height:'',width:''})
    const list = useSelector(state => state.labelAdminList)
    
    useEffect(() => {
        if(!Utils.isEmpty(list.order.data)) {
            setInputData(list.order.data[0].referenceMap)
        }
        if(!Utils.isEmpty(list?.order?.message) && list?.order?.message !== 'Success') {
            setAlertType('error')
            setAlertMessage(list?.order?.message)
        }
    },[list.order])
    
    const handleGridSave = async() => {
        try {
            dispatch(addLabelListAdminAction(labelDetails));
            setAlertType('success')
            setAlertMessage("Label Added Successfully")
            setOpen(true)
            closeModal(false)
        }
        catch(ex) {
            setAlertType('error')
            setAlertMessage("Unable to Add Label ")
            setOpen(true)
        }
    }

    return (
        <>
           <SimpleSnackbar setOpen={setOpen} open={open}  message={alertMessage} messagetype={alertType} />
            <Modal
                open={openModal}
                onClose={() => closeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles.popupStyle}>
                    <Typography component='div' style={styles.popupContent.title}>
                        <Typography component='span' style={styles.popupContent.titleText}>Add Label</Typography>
                            <IconButton id="close-modal-popup" data-testid="close-modal-popup" onClick={() => closeModal(false)} style={styles.popupContent.closeButton}>
                                <CloseIcon />
                            </IconButton>
                        </Typography>

                    <Stack>
                        <Box sx={styles.popupContent.row}>
                            <Typography component='span'>Label Code</Typography>
                            <input type='text' value={labelDetails.labelCode}
                                    onChange={event => setLabelDetails({ ...labelDetails, labelCode: event.target.value })}
                                    style={styles.popupContent.dropdown}></input>
                            
                        </Box>
                        <Box sx={styles.popupContent.row}>
                            <Typography component='span'>Label Name</Typography>
                            <input type='text' value={labelDetails.name}
                                    onChange={event => setLabelDetails({ ...labelDetails, name: event.target.value })}
                                    style={styles.popupContent.dropdown}></input>
                        
                        </Box>
                        <Box sx={styles.popupContent.row}>
                            <Typography component='span'>Label Type</Typography>
                            <Select
                                displayEmpty sx={styles.popupContent.dropdown}
                                value={labelDetails.labelUsageId}
                                onChange={(event) => setLabelDetails({...labelDetails,labelUsageId:event.target.value})}
                            >
                                {inputData.labelUsage.map(ele => <MenuItem key={ele.id} value={ele.id}>{ele.labelUsageCode}</MenuItem> )}
                            </Select>
                        </Box>
                        <Box sx={styles.popupContent.row}>
                            <Typography component='span'>Formula</Typography>
                            <input type='text' value={labelDetails.formula}
                                    onChange={event => setLabelDetails({ ...labelDetails, formula: event.target.value })}
                                    style={styles.popupContent.dropdown}></input>
                        </Box>
                        <Box sx={styles.popupContent.row}>
                            <Typography component='span'>Overage Percentage </Typography>
                            <input type='text' value={labelDetails.overagePercentage}
                                    onChange={event => setLabelDetails({ ...labelDetails, overagePercentage: event.target.value })}
                                    style={styles.popupContent.dropdown}></input>
                        </Box>
                        <Box sx={styles.popupContent.row}>
                            <Typography component='span'>Label Height </Typography>
                            <input type='text' value={labelDetails.height}
                                    onChange={event => setLabelDetails({ ...labelDetails, height: event.target.value })}
                                    style={styles.popupContent.dropdown}></input>
                        </Box>
                        <Box sx={styles.popupContent.row}>
                            <Typography component='span'>Label Width </Typography>
                            <input type='text' value={labelDetails.width}
                                    onChange={event => setLabelDetails({ ...labelDetails, width: event.target.value })}
                                    style={styles.popupContent.dropdown}></input>
                        </Box>
                        
                        <Box sx={styles.popupContent.button}>
                            <OrderButton onClick={handleGridSave}>SAVE</OrderButton>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default AddLabel;