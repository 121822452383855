import { Box, Typography } from '@mui/material/';
import React from 'react'
import LabelLayoutCard from './LabelLayoutCard';
import LabelLayoutGrid from './LabelLayoutGrid';
import {  styles } from './style';

function LabelLayout() {
 
    return (

        <Box>
            <Typography component="div" style={styles.textFont} >Label Layout
            
            </Typography>
            <LabelLayoutCard/>
            <Typography component="div" style={styles.paddingTop}>
            <LabelLayoutGrid></LabelLayoutGrid>

            </Typography>
        </Box>
    );
}
export default LabelLayout