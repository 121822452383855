import { apiUserOrderFilter } from '../../../../services/Gateway/RestAPIHandler';

 
 
 export const USER_ORDER_FILTER_GET ="Get User Groups";
 export const USER_ORDER_FILTER_SUCCESS = "User Groups Sucess";
 export const USER_ORDER_FILTER_FAILURE = "User Groups Failure";
 export function getUserOrderFilterAction(params) {
    return (dispatch) => {
        dispatch(getUserOrderFilter());
        return apiUserOrderFilter(params) 
            .then(([response, json]) => {
                if(response.status === 200){ 
                    dispatch(getUserOrderFilterSuccess(json.results));
                }
                else {
                    dispatch(getUserOrderFilterFailure("Unable to fetch User Groups data; Error code - " + json.errorCode));
                }
            })
    }
}

export function getUserOrderFilter() {
    return {
        type: USER_ORDER_FILTER_GET
    }
}

export function getUserOrderFilterSuccess(data) {
    return {
        type: USER_ORDER_FILTER_SUCCESS,
        payload: data

    }

}

export function getUserOrderFilterFailure(message) {
    return {
        type: USER_ORDER_FILTER_FAILURE,
        payload: message
    }
}