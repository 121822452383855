import envConfig from "../../../config/env/environment";

const header = (httpMethod, value) => {
  return {
    method: httpMethod,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${window.localStorage.getItem("access_token")}`
    },
    body:JSON.stringify(value)
  }
}

const headerWithoutParam = (httpMethod) => {
  return {
    method: httpMethod,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${window.localStorage.getItem("access_token")}`
    },
  }
}
const headerForFile = (httpMethod, body) => {
  return {
    method: httpMethod,
    headers: {
      'Authorization': `Bearer ${window.localStorage.getItem("access_token")}`
    },
    body: body
  }
}

const fetchAPI = (url, headerFn) => {
  return fetch(url, headerFn).then((res) => Promise.all([res, res.json()]));
}


export async function apiAddLabel(params){

  return await fetchAPI(envConfig.gatewayPath + `/addlabels`, header('POST',params));

}
export async function apiSendLabels(params){
  return await fetchAPI(envConfig.gatewayPath + `/sendlabels`, header('POST', params));
}
export async function apiApproveLabels(params){
  return await fetchAPI(envConfig.gatewayPath + `/approvelabels`, header('POST', params));
}
export async function apiSendPoItem(params){
  return await fetchAPI(envConfig.gatewayPath + `/sendpoitem/${params}`, headerWithoutParam('POST'));
}
export async function apiCancelPoItem(params){
  return await fetchAPI(envConfig.gatewayPath + `/cancelpoitem/${params}`, headerWithoutParam('POST'));
}
export async function apiCancelLabel(params){
  return await fetchAPI(envConfig.gatewayPath + `/cancellabels`, header('POST', params));
}
export async function apiLockLabel(params){
  return await fetchAPI(envConfig.gatewayPath + `/locklabels`, header('POST', params));
}
export async function apiUnLockLabel(params){
  return await fetchAPI(envConfig.gatewayPath + `/unlocklabels`, header('POST', params));
}

export async function apiPrepareLabel(params){
  return await fetchAPI(envConfig.gatewayPath + `/preparelabel`,header('POST', params));
}
export async function apiPrepareSendLabel(params){
  return await fetchAPI(envConfig.gatewayPath + `/preparesendlabels/${params.poItemId}`, header('POST', {"labelIds":params.labelIds}));
}
export async function addLabelList(params) {
  return await fetch(envConfig.gatewayPath + `/uploadimage`,
    header('POST', params)).then((res) => Promise.all([res, res.json()]))
}
export async function addLabelRow(params) {

  return await fetch(envConfig.gatewayPath + `/createLabel`,

    header('POST', params)).then((res) => Promise.all([res, res.json()]))
}
export async function deleteLabelRow(path) {
  return await fetch(`/labelling/delLabel/${path}`,
  headerWithoutParam('DELETE')).then((res) => Promise.all([res, res.json()]))
}
// export async function apiBulkAction(params) {
//   return await fetchAPI(`/labelling/bulkPOUpload?action=${params.action}`, headerForFile('POST', params.body));
// }
export async function apiBulkAction(params) {
  return await fetchAPI(envConfig.gatewayPath + `/bulkpoupload?action=${params.action}`, headerForFile('POST', params.body));
}
export async function apisearchLabelAssignmentList(params) {
  return await fetchAPI(envConfig.gatewayPath + `/searchALA`, header('POST', params));
}
export async function apisearchCountrySize(params) {
  return await fetchAPI(envConfig.gatewayPath + `/searchCountrySizeLookup`, header('POST', params));
}

export async function apisearchCustomer(params) {
  return await fetchAPI(envConfig.gatewayPath + `/searchCustomer`, header('POST', params));
}
export async function apisearchFactory(params) {
  return await fetchAPI(envConfig.gatewayPath + `/searchFactory`, header('POST', params));
}

export async function apisearchOrderList(params) {
  return await fetchAPI(envConfig.gatewayPath + `/searchPoItem`, header('POST', params));
}
// export async function apiPOBulkAction(params) {
//   return await fetchAPI(`/labelling/bulkPOAction?action=${params.action}`, header('POST', params.body));
// }
export async function apiPOBulkAction(params) {
  return await fetchAPI(envConfig.gatewayPath + `/bulkpoaction?action=${params.action}`, header('POST', params.body));
}
// export async function apiDownloadBatchBulk(params) {
//   return await fetchAPI(`/labelling/downloadBulkOrder?filePath=${params}`, headerWithoutParam('GET'));
// }
export async function apiDownloadBatchBulk(params) {
  return await fetchAPI(envConfig.gatewayPath + `/downloadbulkorder?filePath=${params}`, headerWithoutParam('GET'));
}
// export async function apisearchOrderList(params) {
//   return await fetchAPI(`/labelling/searchPoItem`, header('POST', params));
// /labelling/downloadBulkOrder?filePath=BulkOrders%2F52_PREPARE.csv
// }
// export async function apiViewPOBulkAction(params) {
//   return await fetchAPI(`/labelling/searchPoItemBatch`, header('POST', params));
// }
export async function apiViewPOBulkAction(params) {
  return await fetchAPI(envConfig.gatewayPath + `/searchpoitembatch`, header('POST', params));
}
export async function apisearchLabelList(params) {
  return await fetchAPI(envConfig.gatewayPath + `/searchLabel`, header('POST', params));
}
export async function apiProductType(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchProductType/${params.fetchSize}/${params.page}`, headerWithoutParam('GET'));
}
export async function apiProductGroup(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchProductGroup/${params.fetchSize}/${params.page}`, headerWithoutParam('GET'));
}
export async function apiSportsCategory(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchSportsCategory/${params.fetchSize}/${params.page}`, headerWithoutParam('GET'));
}
export async function apilockPOItem(poNum){
  return await fetchAPI(envConfig.gatewayPath + `/lockPoItem/${poNum}`, headerWithoutParam('POST'));
}
export async function apiFetchAllHistory(poNum){
  return await fetchAPI(envConfig.gatewayPath + `/fetchTransactionLogs/${poNum}`, headerWithoutParam('GET'));
}

export async function apiDeleteLabel(params){
  return await fetchAPI(envConfig.gatewayPath + `/delorderlabel`,header('DELETE', params));
}
export async function apiDeleteLabelAssignment(params){
  return await fetchAPI(envConfig.gatewayPath +`/deleteALA`,header('DELETE', params));
}

export async function apiReadFile(path){
  return await fetchAPI(envConfig.gatewayPath + `/vieworderlabel?filePath=${path}`, headerWithoutParam('GET'));
}
export async function updateLabelSize(params) {
  return await fetchAPI(envConfig.gatewayPath + `/updateLabelQuantity/${params.POItem}`, header('POST', params.searchObjects));
}

export async function apiunlockPOItem(poNum){
  return await fetchAPI(envConfig.gatewayPath + `/unlockPoItem/${poNum}`, headerWithoutParam('POST'));
}
export async function apipreparePOItem(poNum){
  return await fetchAPI(envConfig.gatewayPath + `/preparePoItem/${poNum}`, headerWithoutParam('POST'));
}
export async function editLabelSize(params) {
  return await fetch(envConfig.gatewayPath + `/editlabel`,
    header('PUT',params)).then((res) => Promise.all([res, res.json()]))
}


export async function apisearchPOinformation(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchPOLabelSize/${params.POItem}`, headerWithoutParam('GET'));
}

export async function apiOrderList(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchPoItem/${params.fetchSize}/${params.page}`, headerWithoutParam('GET'));
}
export async function apiUserOrderFilter(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchalluserorderfilter/${params.fetchSize}/${params.page}`, headerWithoutParam('GET'));
}
export async function apiUserGroups(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchusergroups/${params.fetchSize}/${params.page}`, headerWithoutParam('GET'));
}
export async function apiUserList(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchusers/${params.fetchSize}/${params.page}`, headerWithoutParam('GET'));
}
export async function apiGetHeader(params) {
  return await fetchAPI( envConfig.gatewayPath + `/getallheaders`, header('POST', params));
}
// export async function apiGetHeader(params) {
//   return await fetchAPI( `/labelling/getAllHeaders`, header('POST', params));
// }
export async function apiAllOrderList() {

  return await fetchAPI(envConfig.gatewayPath + `/fetchallorders`, headerWithoutParam('GET'));

}
export async function apiDashBoard(params) {
  return await fetchAPI(envConfig.gatewayPath + `/dashboard`, headerWithoutParam('GET'));
}

export async function apiOrderHistory(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchTransactionLogs/${params.orderNumber}/${params.fetchSize}/${params.page}`, headerWithoutParam('GET'));
}

export async function apiLabelList(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchAll`, headerWithoutParam('GET'));
}

export async function referenceDataList(params) {
  return await fetch(`/referencedata?page=${params.page}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }).then((res) => Promise.all([res, res.json()]))
}
export async function labelAdminList(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchAll`, headerWithoutParam('GET'));
}
export async function labelLayoutGridList(params) {

  return await fetchAPI(envConfig.gatewayPath + `/fetchById/${params}`, headerWithoutParam('GET'));

}

export async function labelMainTypeList(params) {
  return await fetch(`/labelMainType?page=${params.page}`,{
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }).then((res) => Promise.all([res, res.json()]))
}
export async function labelUsageTypeList(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchLabelUsage?page=${params.page}`, headerWithoutParam('GET'));
}
export async function labelSizeInfoList(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchPOLabelSize/${params.orderLabelSize}`, headerWithoutParam('GET'));
}

export async function labelAssignmentList() {
  return await fetchAPI(envConfig.gatewayPath + `/fetcharticlelabelassignments`, headerWithoutParam('GET'));
}

export async function labelFactoryList(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchallfactories`, headerWithoutParam('GET'));
}
export async function OrderlabelList(param){
  return await fetchAPI(envConfig.gatewayPath + `/fetchOrderLabel`,headerWithoutParam('GET'));
}
export async function labelCustomerList(params) {
  return await fetchAPI(envConfig.gatewayPath + `/fetchallcustomers`, headerWithoutParam('GET'));
}

// export async function apiPrepareSendOrder(params) {
//   return await fetchAPI(envConfig.gatewayPath + `/preparesendpoitem/${params}`, headerWithoutParam('POST'));
// }

export async function apiPrepareSendOrder(params) {
  return await fetchAPI(envConfig.gatewayPath + `/bulkpreparesend`,header('POST', params));
}



export async function apiUploadFile(files) {
  return await fetch(envConfig.gatewayPath + `/uploadImage`,
    headerForFile('POST', files)).then((res) => Promise.all([res, res.json()]))
}
export async function apiUploadTextFile(files) {
  return await fetch(envConfig.gatewayPath + `/uploadlayout?imagePath=${files.imageName}&labelId=${files.Labelid}`,
    headerForFile('POST', files.data)).then((res) => Promise.all([res, res.json()]))
}

export async function labelArticleMaster(params) {
  return await fetchAPI( envConfig.gatewayPath + `/searchArticle`,header('POST', params));
}
export async function getUserAccessLevel() {
  return await fetchAPI(envConfig.gatewayPath + `/getuseraccesslevel`,headerWithoutParam('GET'));
}
export async function addLabelAssignmentRow(params) {

  return await fetch(envConfig.gatewayPath + `/addala`,
    header('POST', params)).then((res) => Promise.all([res, res.json()]))
}

export async function updateOveragePercent(params) {
  return await fetchAPI(envConfig.gatewayPath + `/updateoveragequantity/${params.orderNumber}`, header('POST', params));
}

export async function apiResetPoItem(params){
  return await fetchAPI(envConfig.gatewayPath + `/resetpoitem/${params}`, headerWithoutParam('POST'));
}

export async function apisearchOrderLabel(params) {
  return await fetchAPI(envConfig.gatewayPath + `/searchOrderLabel`, header('POST', params));
}

export async function editLabelAssignment(params) {
  return await fetch(envConfig.gatewayPath + `/editala`,
    header('POST', params)).then((res) => Promise.all([res, res.json()]))
}

export async function apisearchApprovalOrderList(params) {
  return await fetchAPI(envConfig.gatewayPath + `/searchPoItemApproval`, header('POST', params));
}

export async function addUser(params) {
  return await fetch(envConfig.gatewayPath + `/addUser`,
    header('POST', params)).then((res) => Promise.all([res, res.json()]))
}

export async function addUserOrder(params) {

  return await fetch(envConfig.gatewayPath + `/addUserOrderFilter`,
    header('POST', params)).then((res) => Promise.all([res, res.json()]))
}

export async function fetchAddUserDetails(){
  return await fetchAPI(envConfig.gatewayPath + `/fetchAddUserDetails`, headerWithoutParam('GET'));
}

export async function fetchFactoryUserDetails(){
  return await fetchAPI(envConfig.gatewayPath + `/fetchFactoryUserDetails`, headerWithoutParam('GET'));
}

export async function apiDeleteUserandOrder(params){
  return await fetchAPI(envConfig.gatewayPath +`/deleteUser`,header('DELETE', params));
}

export async function apiDeleteUserandOrder2(params){
  return await fetchAPI(envConfig.gatewayPath +`/deleteUserOrderFilter`,header('DELETE', params));
}

// export async function addLabelAssignmentRow(params) {

//   return await fetch(`/labelling/addALA`,
//     header('POST', params)).then((res) => Promise.all([res, res.json()]))
// }