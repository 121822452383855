import { apiViewPOBulkAction} from '../../../../services/Gateway/RestAPIHandler';
export const BULK_ACTION_LIST_GET = "Get Bulk Action List";
export const BULK_ACTION_LIST_SUCCESS = "Bulk Action List Sucess";
export const BULK_ACTION_LIST_FAILURE = "Bulk Action List Failure";

const searchDefault = {
    "ascDesc": "",
    "fetchSize": 7,
    "filterSearch": "",
    "pageNumber": 1,
    "sortBy": ""
}
export function getBulkActionListAction(params) {
    return (dispatch) => {
        dispatch(getBulkActionList());
        return apiViewPOBulkAction(params)
            .then(([response, json]) => {
                if (response.status === 200) {
                    dispatch(getBulkActionListSuccess(json.results));
                }
                else {
                    dispatch(getBulkActionListFailure("Unable to fetch Customer list data; Error code - " + json.errorCode));
                }
            })
    }
}
export function searchBulkActionAction(params = searchDefault) {
    return (dispatch) => {
        dispatch(getBulkActionList());
        return apiViewPOBulkAction(params)
            .then(([response, json]) => {
                if (response.status === 200) {

                    dispatch(getBulkActionListSuccess(json.results, params));
                }
                else {
                    dispatch(getBulkActionListFailure("Unable to fetch Customer search data; Error code - " + json.errorCode, params));
                }
            })
    }
}

export function getBulkActionList() {
    return {
        type: BULK_ACTION_LIST_GET
    }
}

export function getBulkActionListSuccess(res,params) {
    return {
        type: BULK_ACTION_LIST_SUCCESS,
        payload: {res:res,request:params}

    }

}

export function getBulkActionListFailure(message,params) {
    return {
        type: BULK_ACTION_LIST_FAILURE,
        payload: {message:message,request:params}
    }
}