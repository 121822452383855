import { styled} from '@mui/system';
export const styles={
  Orders:{
    display:'flex',
    flexDirection:'column',
    height:'100%',
    width:'100%'
  },
  TotalOrders_title:{
    margin:'26px 0 0 24px', 
    width: '91px',
    height: '17px',
    textAlign: 'left',
    font: 'normal normal bold 16px/18px Helvetica Neue',
    letterSpacing: '0px',
    color: '#293E53',
    opacity: 1,
    textDecoration:'none'
  },
  TotalOrders_number:{
    margin:'28px 0 0 24px', 
    width: '120px',
    height: '80px',
    textAlign: 'left',
    font:' normal normal bold 72px/82px Helvetica Neue',
    letterSpacing: '0px',
    color: '#000000',
    opacity: 1,
  },
  TotalOrders_link:{
    margin:'107px 0 0 24px', 
    width: '129px' ,
    height: '17px',
    textAlign: 'left',
    font: 'normal normal bold 16px/18px Helvetica Neue',
    letterSpacing: '0px',
    color: '#99702C',
    opacity: 1,
    cursor:'pointer'
  },
  CompletedOrders_title:{
    margin:'26px 0 0 24px', 
    width: '137px',
    height: '17px',
    textAlign: 'left',
    font: 'normal normal bold 16px/18px Helvetica Neue',
    letterSpacing: '0px',
    color: '#293E53',
    opacity: 1,
  },
  CompletedOrders_number:{
    margin:'36px 0 0 24px', 
    width: '120px',
    height: '80px',
    textAlign: 'left',
    font:' normal normal bold 72px/82px Helvetica Neue',
    letterSpacing: '0px',
    color: '#000000',
    opacity: 1,
  },
  CompletedOrders_link:{
    margin:'39px 0 0 24px', 
    width: '197px' ,
    height: '17px',
    textAlign: 'left',
    font: 'normal normal bold 16px/18px Helvetica Neue',
    letterSpacing: '0px',
    color: '#99702C',
    opacity: 1,
    cursor:'pointer'
  },
  ErrorOrders_title:{
    margin:'26px 0 0 24px', 
    width: '131px',
    height: '17px',
    textAlign: 'left',
    font: 'normal normal bold 16px/18px Helvetica Neue',
    letterSpacing: '0px',
    color: '#293E53',
    opacity: 1,
  },
  ErrorOrders_number:{
    margin:'28px 0 0 24px', 
    width: '120px',
    height: '80px',
    textAlign: 'left',
    font:' normal normal bold 72px/82px Helvetica Neue',
    letterSpacing: '0px',
    color: '#000000',
    opacity: 1,
  },
  ProgressOrders_title:{
    margin:'26px 0 0 24px',
    width: '141px',
    height: '17px',
    textAlign: 'left',
    font: 'normal normal bold 16px/18px Helvetica Neue',
    letterSpacing: '0px',
    color: '#293E53',
    opacity: 1,
  },
  ProgressOrders_number:{
    margin:'40px 0 0 236px',
    width: '120px',
    height: '80px',
    textAlign: 'center',
    font: 'normal normal bold 72px/82px Helvetica Neue',
    letterSpacing: '0px',
    color: '#000000',
    opacity: 1,
  },
  ProgressOrders_link:{
    margin:'49px 0 0 24px',
    width: '204px',
    height: '17px',
    textAlign: 'left',
    font:' normal normal bold 16px/18px Helvetica Neue',
    letterspacing: '0px',
    color: '#99702C',
    opacity: 1,
    cursor:'pointer',
  },
  arrow:{
    marginTop:'10px',
    cursor:'pointer',
    width: '15px',
    height: '14px',
    marginLeft:'10px',
  },
  ProgressOrders_status_title:{
    textAlign: 'left',
    //font: 'normal normal medium 12px/14px Helvetica Neue',
    letterSpacing: '0px',
    color: '#A7A7A7',
    opacity: 1,
    fontFamily: "Poppins, Helvetica, 'sans-serif'",
    fontSize: "0.8rem",
    height: '12px'
  },
  ProgressOrders_status_text:{
    width: '414px',
    height: '35px',
    textAlign: 'left',
    font: 'normal normal medium 16px/18px Helvetica Neue',
    letterSpacing: '0px',
    color: '#000000',
    opacity: 1,
  }
  ,
  ProgressOrders_status:{
    margin:'81px 0 0 24px',
    width: '347px',
    height: '17px',
    textAlign: 'left',
    font: 'normal normal bold 16px/18px Helvetica Neue',
    letterSpacing: '0px',
    color: '#293E53',
    opacity: 1,
  },
  ProgressOrders_status_number:{
    //width: '53px',
    height: '36px',
    textAlign: 'left',
    font: 'normal normal bold 32px/37px Helvetica Neue',
    letterSpacing: '0px',
    color: '#000000',
    opacity: 1,
    fontFamily: "Poppins, Helvetica, 'sans-serif'"
  },

  statusdiv:{
    display:'flex',
    margin:'50px 0 0 24px',
    width: '542px',
    height: '52px',
    opacity: 1,
  },
  ErrorOrders_link:{
    margin:'26px 0 0 24px', 
    width: '200px' ,
    height: '17px',
    textAlign: 'left',
    font: 'normal normal bold 16px/18px Helvetica Neue',
    letterSpacing: '0px',
    color: '#99702C',
    opacity: 1,
    cursor:'pointer'
  },
  ErrorOrders_disabledlink:{
    margin:'27px 0 0 24px', 
    width: '192px' ,
    height: '17px',
    textAlign: 'left',
    font: 'normal normal bold 16px/18px Helvetica Neue',
    letterSpacing: '0px',
    color: '#A3A3A3',
    opacity: 1,
    cursor:'pointer'
  },
  OrderStatus: {
    Count: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      width: "42px",
      marginRight: "50px"
    }
  }
}
export const DivContainer = styled('div')(
   {
    backgroundColor:'#fff',
    opacity: 1,
    margin: '0.25rem 0px',
    height:'853px',
    width:'521px',
    borderRadius: '5px',
    display:'flex',
    flexDirection: 'column',
    '&.RightDiv':{
      width:'592px',
      border:'1px solid #707070',
      height:'1080px'
    },
    '@media(max-width:1370px)':{
       margin: '0.05rem 0px',
        height: '40px',
        width:'400px',
        font: 'normal normal normal 13px/21px Poppins',
        '&.RightDiv':{
          marginLeft:'20px',
          width:'592px',
          border:'1px solid #707070',
          height:'1080px'
        },
    },
}
  );
  export const InnerDiv= styled('div')(
    {
    backgroundColor:'#fff',
    opacity: 1,
    height:'290px',
    border: '1px solid #707070',
    width:'100%',
    borderRadius: '5px',
    display:'flex',
    '@media(max-width:1370px)':{
      height:'350px',
    }
    }
  );