import { apiUserGroups } from '../../../../services/Gateway/RestAPIHandler';

 
 
 export const USER_GROUPS_GET ="Get User Groups";
 export const USER_GROUPS_SUCCESS = "User Groups Sucess";
 export const USER_GROUPS_FAILURE = "User Groups Failure";
 export function getUserGroupsAction(params) {
    return (dispatch) => {
        dispatch(getUserGroups());
        return apiUserGroups(params) 
            .then(([response, json]) => {
                if(response.status === 200){ 
                    dispatch(getUserGroupsSuccess(json.results));
                }
                else {
                    dispatch(getUserGroupsFailure("Unable to fetch User Groups data; Error code - " + json.errorCode));
                }
            })
    }
}

export function getUserGroups() {
    return {
        type: USER_GROUPS_GET
    }
}

export function getUserGroupsSuccess(data) {
    return {
        type: USER_GROUPS_SUCCESS,
        payload: data

    }

}

export function getUserGroupsFailure(message) {
    return {
        type: USER_GROUPS_FAILURE,
        payload: message
    }
}