import { orderListMapping, articleMasterMapping, countryLookupMapping, orderLabelListMapping,approvalListMapping} from '../constant';
import * as XLSX from 'xlsx';

export const EXPORT_DATA_GET = 'EXPORT_DATA_GET';
export const EXPORT_DATA_RESET = 'EXPORT_DATA_RESET';
export const EXPORT_BULK_DATA_SUCCESS = 'EXPORT_BULK_DATA_SUCCESS';
export const EXPORT_BULK_DATA_FAILURE = 'EXPORT_BULK_DATA_FAILURE';

export const exportBulkData = (param) => {
    return { type: 'EXPORT_BULK_DATA_ASYNC', param }
}

export const exportBulkDataSuccess = (result, exportFor) => {
    return {
        type: EXPORT_BULK_DATA_SUCCESS,
        payload: {
            result: result,
            exportFor: exportFor
        }
    }
}

export const resetExportData = () => {
    return { type: EXPORT_DATA_RESET }
}

export function dataToExcel(data, exportFor) {
    const newData = [];
    let listMapping = {};

    if(exportFor === 'OrderList') {
        listMapping = orderListMapping;
    }
    else if(exportFor === 'ArticleMaster') {
        listMapping = articleMasterMapping
    }
    else if(exportFor === 'CountryLookup'){
        listMapping = countryLookupMapping
    }
    else if(exportFor === 'OrderLabelList'){
        listMapping = orderLabelListMapping
    }
    else if(exportFor === 'ApprovalList') {
        listMapping = approvalListMapping;
    }

    data.map((val) => {
        const reMap = {}
        Object.keys(listMapping).forEach(key => {
            Object.assign(reMap, getObject(listMapping[key], val[key]));
        })
        newData.push(reMap)
    })
    
    const worksheet = XLSX.utils.json_to_sheet(newData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    XLSX.writeFile(workbook, `Download${exportFor}.xlsx`)
}

function getObject(key, value) {
    return { [key]: (key === 'divisionCode') ? ((value === '1') ? 'FOOTWEAR' : 'APPAREL') : value }
}