import { labelArticleMaster } from '../../../../services/Gateway/RestAPIHandler';

 
 
 export const LABEL_ARTICLE_LIST_GET ="Get Label Article List";
 export const LABEL_ARTICLE_LIST_SUCCESS = "Label Article List Sucess";
 export const LABEL_ARTICLE_LIST_FAILURE = "Label Article List Failure";
 const searchDefault = {
    "ascDesc": "",
    "fetchSize": 7,
    "filterSearch": "",
    "pageNumber": 1,
    "sortBy": ""
  }
 export function getLabelArticleListAction(params=searchDefault) {
    return (dispatch) => {
        dispatch(getLabelArticleList());
        return labelArticleMaster(params) 
            .then(([response, json]) => {
                if(response.status === 200){ 
                    dispatch(getLabelArticleListSuccess(json.results,params));
                }
                else {
                    dispatch(getLabelArticleListFailure("Unable to fetch article list data; Error code - " + json.errorCode,params));
                }
            })
    }
}

export function getLabelArticleList() {
    return {
        type: LABEL_ARTICLE_LIST_GET
    }
}

export function getLabelArticleListSuccess(res,params) {
    return {
        type: LABEL_ARTICLE_LIST_SUCCESS,
        payload: {res:res,request:params}

    }

}

export function getLabelArticleListFailure(message,params) {
    return {
        type: LABEL_ARTICLE_LIST_FAILURE,
        payload: {message:message,request:params}
    }
}