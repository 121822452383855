import {
    EXPORT_DATA_GET,
    EXPORT_DATA_RESET,
    EXPORT_BULK_DATA_SUCCESS,
    EXPORT_BULK_DATA_FAILURE,
    dataToExcel
} from './action';

const initialState = {
    export: {}
}

export function ExportExcelReducer(state = initialState, action) {
    if(action.type === EXPORT_DATA_GET) {
        return state;
    }
    if(action.type === EXPORT_BULK_DATA_SUCCESS) {
        dataToExcel(action.payload.result, action.payload.exportFor);
        return {
            ...state,
            export: {
                isLoaded: true,
                data: action.payload.result,
                message: 'Success'
            }
        }
    }
    if(action.type === EXPORT_BULK_DATA_FAILURE) {
        return {
            ...state,
            export: {
                data: [],
                isError: true,
                message: action.payload.message
            }
        }
    }
    if(action.type === EXPORT_DATA_RESET) {
        return {
            ...state,
            export: {}
        }
    }
    return state;
}