import React, { useState, useEffect, useMemo, useRef } from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Box } from '@mui/material';
import { styles, StyledAgGridReact } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {  searchCountrySizeAction } from './Store/actions';
import ExportExcel from '../../../customWidgets/ExportExcel'
import Utils from '../../../utils/Utils';
import {useNavigate,useLocation} from "react-router-dom";
import GridPagination from '../../../customWidgets/GridPagination/index';
import Loader from '../../../customWidgets/Loader'
import AccessLevelContext from '../../../../accessLevel-context';
import logo from '../../../assets/excelicon.png';
import * as XLSX from 'xlsx';
import { apisearchCountrySize } from '../../../services/Gateway/RestAPIHandler';
import SimpleSnackbar from '../../../customWidgets/Snackbar';

function CountrySizeLookup() {
  const location = useLocation();
  const accessLevel= React.useContext(AccessLevelContext)
  const navigate = useNavigate()
  const checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const columnDef = [
    { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 20 },
    // { field: 'customerId', headerName: 'Customer ', width: 150, headerClass: 'grid-header' },
    { field: 'division', headerName: 'Division',resizable:true , width: 150,valueFormatter: function (params) {
      if (params.value === '1') {
        return "FOOTWEAR";
      }
      else if (params.value === "2"){
       return "APPAREL";
      }
    } },
    { field: 'genderAgeGroup', headerName: 'Gender Age Group', width: 250, headerClass: 'grid-header',resizable:true },
    { field: 'technicalSize', headerName: 'Technical Size', width: 250, headerClass: 'grid-header',resizable:true },
    { field: 'usaSize', headerName: 'USA Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'argentinaSize', headerName: 'Argentina Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'brazilSize', headerName: 'Brazil Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'britainSize', headerName: 'Britain Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'canadaSize', headerName: 'Canada Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'chileSize', headerName: 'Chile Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'chinaSize', headerName: 'China Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'europeSize', headerName: 'Europe Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'frenchSize', headerName: 'France Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'germanSize', headerName: 'Germany Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'italySize', headerName: 'Italy Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'japanSize', headerName: 'Japan Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'mexicoSize', headerName: 'Mexico Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'russiaSize', headerName: 'Russia Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'spainSize', headerName: 'Spain Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'taiwanSize', headerName: 'Taiwan Size', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'ukSize', headerName: 'UK Size', width: 150, headerClass: 'grid-header',resizable:true },
    
  ];
  const [currentPage, setCurrentPage] = React.useState(1);
  const GRID_FETCH_SIZE = 50;
  const list = useSelector(state => state.countrySizeList);
  const gridRef = useRef();
  const [loading, setLoading] = useState(true)
  const [req, setReq] = useState(list.request)
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();
  const [alertType,setAlertType] = useState("success");
  const [alertMessage,setAlertMessage] = useState("");
  const [labelRows,setLabelRows] = useState([])

  useEffect(() => {
   
        if(location.state!==null){
          if(location.state.Status==='*'){
            dispatch(searchCountrySizeAction({
              "ascDesc": "",
              "fetchSize": GRID_FETCH_SIZE,
              "filterSearch": "",
              "pageNumber": 1,
              "sortBy": ""
            }))
          }
        }
        else {
      if(req.filterSearch!=='' && req.hasOwnProperty('filterSearch')){
      dispatch(searchCountrySizeAction(req))
    }
    else {
      dispatch(searchCountrySizeAction({
        "ascDesc": "",
        "fetchSize": GRID_FETCH_SIZE,
        "filterSearch": "",
        "pageNumber": currentPage,
        "sortBy": ""
      }))
    }
      
      }
    
  },[])//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!Utils.isEmpty(list.order)) {
      setLoading(false)
    }
  },[currentPage])//eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setCurrentPage(req.pageNumber)
  }, [req]);
  const onSelectionChange = (event) => {
    const selectedRows = event.api.getSelectedRows();
    if (!Utils.isEmpty(selectedRows)){
      setLabelRows(selectedRows)
    }
    else{
      setLabelRows([])
    }
  }
  const showGridLoader = () => {
    gridRef.current.api.showLoadingOverlay();
  }
  const getTotalPages = useMemo(() => {
    setReq(list.request)
    const orderData = !Utils.isEmpty(list.order) ? list.order?.data[0] : [];
    if (!Utils.isEmpty(list.order)) {
      return Utils.isEmpty(list.order?.data) ?
        {
          totalPages: 1,
          totalElements: 1
        } : {
          totalPages: orderData?.totalPages,
          totalElements: orderData?.totalElements
        };
    }
    else
      return {
        totalPages: 1,
        totalElements: 1
      };
  }, [list.order]);
  // const dataToExcel = (data) => {
  //   const newData = data.map(ele => {
  //     return {
  //       // "Customer" : ele?.customerId,
  //       "Division Code": ele?.division,
  //       "Gender Age Group": ele?.genderAgeGroup        ,
  //       "Technical Size":ele?.technicalSize,
  //       "Argentina Size":ele?.argentinaSize,
  //       "Brazil Size":ele?.brazilSize,
  //       "Britain Size":ele?.britainSize,
  //       "Canada Size":ele?.canadaSize,
  //       "Chile Size":ele?.chileSize,
  //       "China Size":ele?.chinaSize,
  //       "Europe Size":ele?.europeSize,
  //       "France Size":ele?.frenchSize,
  //       "Germany Size":ele?.germanSize,
  //       "Italy Size":ele?.italySize,
  //       "Japan Size":ele?.japanSize,
  //       "Mexico Size":ele?.mexicoSize,
  //       "Russia Size":ele?.russiaSize,
  //       "Spain Size":ele?.spainSize,
  //       "Taiwan Size":ele?.taiwanSize,
  //       "UK Size":ele?.ukSize,
  //       "USA Size":ele?.usaSize        

  //             }
  //   })
    
  //   const worksheet = XLSX.utils.json_to_sheet(newData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  //   XLSX.writeFile(workbook, "DownloadCountrySizeList.xlsx")
  //   setLoading(false)
  // }

  // const exportToExcel = () => {
  //   setLoading(true)
    
  //         if(labelRows.length===0){
  //           apisearchCountrySize(
  //             {...req,fetchSize:500,pageNumber: 1}
  //           ).then(([response, json])=> {
  //             if(response.status===200 && !Utils.isEmpty(json.results)){
  //             const data = json.results[0].content ?? []
  //             dataToExcel(data)
  //           }
  //           else{
  //             setOpenAlert(true)
  //             setAlertType('error')
  //             setAlertMessage("No data to export")
  //             setLoading(false)
  //           }
  //           })
  //         }
          
  //         else{
  //           dataToExcel(labelRows)
  //         }
          
  //       }

  const handleGridPagination = (event, thisPage) => {
    setLabelRows([])
    showGridLoader();
    setCurrentPage(thisPage);

    if (req.filterSearch !== '' && req.hasOwnProperty('filterSearch')) {
      dispatch(searchCountrySizeAction({ ...req, fetchSize: GRID_FETCH_SIZE, pageNumber: thisPage }));
    }
    else {
      dispatch(searchCountrySizeAction({
        "ascDesc": "",
        "fetchSize": GRID_FETCH_SIZE,
        "filterSearch": "",
        "pageNumber": thisPage,
        "sortBy": ""
      }))
    }
  }
  const gotoKeyDown = (event, value) => {
    if (event.keyCode === 13 && parseInt(value) >= 1  && parseInt(value) <= getTotalPages.totalPages && parseInt(value)!==currentPage) {
      showGridLoader();
      setLabelRows([]);
      setCurrentPage(parseInt(value));
      if (req.filterSearch !== '' && req.hasOwnProperty('filterSearch')) {
        dispatch(searchCountrySizeAction({ ...req, fetchSize: GRID_FETCH_SIZE, pageNumber: parseInt(value) }));
      }
      else {
        dispatch(searchCountrySizeAction({
          "ascDesc": "",
          "fetchSize": GRID_FETCH_SIZE,
          "filterSearch": "",
          "pageNumber": parseInt(value),
          "sortBy": ""
        }))
      }
    }
  }



  return (
    <Box style={styles.gridContainer}>
      <Loader open={loading} />
      <Box sx = {{marginLeft:'20px'}}>
        <ExportExcel exportFor="CountryLookup" request={req} orderRows={labelRows} totalCount={getTotalPages.totalElements} />
     </Box>
      
      {/* <img onClick={() => { exportToExcel() }} src={logo} style={styles.excelicon} alt={"Export Excel"} /> */}
      <SimpleSnackbar setOpen={setOpenAlert} open={openAlert}  message={alertMessage} messagetype={alertType} />

      <Box className={"ag-theme-alpine"}
        sx={{ height: '365px', width: '100%' }}>
        <StyledAgGridReact
          ref={gridRef}
          rowSelection='multiple'
          rowData={Object.keys(list.order).length > 0 ? list.order.data[0].content : []}
          columnDefs={columnDef}
          pagination={true}
          onSelectionChanged={onSelectionChange}
          suppressRowClickSelection={true}
          paginationPageSize={GRID_FETCH_SIZE}
          suppressPaginationPanel={true}
          overlayLoadingTemplate={`<div class="loader"></div>`}
        />
      </Box>
      <Box style={styles.alignBottom}>
        <GridPagination
          pageClick={handleGridPagination}
          gridTotal={getTotalPages}
          currentPage={currentPage}
          pageSize={GRID_FETCH_SIZE}
          onSelectionChanged={onSelectionChange}

          gotoKeyDown={gotoKeyDown} />
      </Box>

    </Box>
  )
}
export default CountrySizeLookup;