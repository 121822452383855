import React,{useRef,useMemo, useContext, useState}from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Box } from '@mui/material';
import SimpleSnackbar from '../../../customWidgets/Snackbar';
import { styles, StyledAgGridReact } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import GridPagination from '../../../customWidgets/GridPagination/index';
import { getLabelArticleListAction } from './Store/actions';
import {useLocation,useNavigate } from "react-router-dom";
import Loader from '../../../customWidgets/Loader'
import ExportExcel from '../../../customWidgets/ExportExcel'
import Utils from '../../../utils/Utils';
import AccessLevelContext from '../../../../accessLevel-context';

function ArticleMaster() {
  const accessLevel= useContext(AccessLevelContext)
  const navigate = useNavigate()
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType,setAlertType] = useState("success");
  const [alertMessage,setAlertMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading,setLoading] = useState(true)
  const [labelRows,setLabelRows] = useState([])
  const GRID_FETCH_SIZE = 7;
  const gridRef = useRef();
  const location =useLocation()
  
  const checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const columnDef = [
    { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 20 },
    { field: 'articleNumber', headerName: 'Article Number', width: 175, editable: true, headerClass: 'grid-header',resizable:true },
    { field: 'articleDescription', headerName: 'Article Description', width: 350, headerClass: 'grid-header',resizable:true },
    { field: 'oldArticleNumber', headerName: 'Old Article Number', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'divisionDescription', headerName: 'Division ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'brandCode', headerName: 'Brand Code ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'sizeRange', headerName: 'Size Range ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'modelNum', headerName: 'Model Number ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'modelDescription', headerName: 'Model Description ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'ageGroup', headerName: 'Age Group ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'gender', headerName: 'Gender', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'saleslineCode', headerName: 'Salesline', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'upperMaterial', headerName: 'Upper Material', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'upperMaterialDesc', headerName: 'Upper Material Description', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'liningMaterial', headerName: 'Lining Material', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'liningMaterialDesc', headerName: 'Lining Material Description', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'insoleMaterial', headerName: 'Insole Material', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'insoleMaterialDesc', headerName: 'Insole Material Description', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'outsoleMaterial', headerName: 'Outsole Material', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'outsoleMaterialDesc', headerName: 'Outsole Material Description', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'productTypeDescription', headerName: 'Product Type', width: 350, headerClass: 'grid-header',resizable:true },
    { field: 'productGroupDescription', headerName: 'Product Group', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'sportsCategoryDescription', headerName: 'Sports Category', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'productFit', headerName: 'Product Fit', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'colourCombinationCode', headerName: 'Colour Combination Code ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'creationSeason', headerName: 'Creation Season ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'currentSeason', headerName: 'Current Season ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'colorCombinationShort', headerName: 'Colour Combination Short ', width: 240, headerClass: 'grid-header',resizable:true },
    { field: 'colorCombinationLong', headerName: 'Colour Combination Long ', width: 240, headerClass: 'grid-header',resizable:true },
    { field: 'workingNumber', headerName: 'Working Num', width: 240, headerClass: 'grid-header',resizable:true },
    { field: 'specialUsageCode', headerName: 'Special Usage Code', width: 240, headerClass: 'grid-header',resizable:true },
    { field: 'specialUsageDesc', headerName: 'Special Usage Description', width: 240, headerClass: 'grid-header',resizable:true },
    { field: 'genderAgeMain', headerName: 'Gender Age Main', width: 240, headerClass: 'grid-header',resizable:true },
    { field: 'nrfColorCode', headerName: 'NRF Color Code', width: 240, headerClass: 'grid-header',resizable:true },
    { field: 'nrfColorDesc', headerName: 'NRF Color Desc', width: 240, headerClass: 'grid-header',resizable:true }

  ];
  const dispatch = useDispatch();
  const list = useSelector(state => state.labelArticleMaster);
  
  const [req,setReq]= React.useState(list.request)  
  React.useEffect(() => {
    if(!Utils.isEmpty(list.order) && !Utils.isEmpty(list.order.data)){
      setLoading(false)
    }
  },[list.order])
  React.useEffect(() => {
    if(accessLevel?.accessLevel!=='A01'){
      navigate('/')
    }
      else{
    if(location.state!==null){
      if(location.state.Status==='*'){
        dispatch(getLabelArticleListAction({
          "ascDesc": "",
          "fetchSize": GRID_FETCH_SIZE,
          "filterSearch": "",
          "pageNumber": 1,
          "sortBy": ""
        }))
        }
      }
     else{
    if(req.filterSearch!=='' && req.hasOwnProperty('filterSearch')){
      dispatch(getLabelArticleListAction(req))
    }
    else{
      dispatch(getLabelArticleListAction({
        "ascDesc": "",
        "fetchSize": GRID_FETCH_SIZE,
        "filterSearch": "",
        "pageNumber": currentPage,
        "sortBy": ""
      }))
    }}
  }

  },[])//eslint-disable-line react-hooks/exhaustive-deps
  const onSelectionChange = (event) => {
    const selectedRows = event.api.getSelectedRows();
    if (!Utils.isEmpty(selectedRows)){
      setLabelRows(selectedRows)
    }
    else{
      setLabelRows([])
    }
  }
  const gotoKeyDown = (event, value) => {
      
    if(event.keyCode === 13 && parseInt(value) >= 1  && parseInt(value) <= getTotalPages.totalPages && currentPage!==parseInt(value)) {
      setCurrentPage(parseInt(value));
      setLabelRows([]);
      showGridLoader()
      if(req.filterSearch!=='' && req.hasOwnProperty('filterSearch')){
        dispatch(getLabelArticleListAction({ ...req,fetchSize: GRID_FETCH_SIZE, pageNumber: parseInt(value) }));
      }
      else{
        dispatch(getLabelArticleListAction({
          "ascDesc": "",
          "fetchSize": GRID_FETCH_SIZE,
          "filterSearch": "",
          "pageNumber": parseInt(value),
          "sortBy": ""
        }))
      }
    }
  }

  const showGridLoader = () => {
    gridRef.current.api.showLoadingOverlay();
  }
  const getTotalPages = useMemo(() => {
    setReq(list.request)
    const orderData = !Utils.isEmpty(list.order) ? list.order?.data[0] : [];
    if(!Utils.isEmpty(list.order)) {
      return Utils.isEmpty(list.order?.data) ?
        {
          totalPages: 1,
          totalElements: 1
        } : {
          totalPages: orderData?.totalPages,
          totalElements: orderData?.totalElements
        };
    }
    else
      return {
        totalPages: 1,
        totalElements: 1
      };
  },[list.order]);//eslint-disable-line react-hooks/exhaustive-deps

  const handleGridPagination = (event, thisPage) => {

    if(currentPage!==thisPage){
      setLabelRows([])
      showGridLoader()
      setCurrentPage(thisPage) 
      if(req.filterSearch!=='' && req.hasOwnProperty('filterSearch')){
        dispatch(getLabelArticleListAction({ ...req,fetchSize: GRID_FETCH_SIZE, pageNumber: thisPage }));
      }
      else{
        dispatch(getLabelArticleListAction({
          "ascDesc": "",
          "fetchSize": GRID_FETCH_SIZE,
          "filterSearch": "",
          "pageNumber": thisPage,
          "sortBy": ""
        }))
      }
    }
  }


  return (
    
    <Box style={styles.gridContainer}>
      <SimpleSnackbar setOpen={setOpenAlert} open={openAlert}  message={alertMessage} messagetype={alertType} />
      {/*<img onClick={() => { exportToExcel() }} src={logo} style={styles.excelicon} alt={"Export Excel"} />*/}
      <ExportExcel exportFor="ArticleMaster" request={req} orderRows={labelRows} totalCount={getTotalPages.totalElements} />
      <Loader open={loading}/>
      <Box className={"ag-theme-alpine"} sx={{ height: '365px', width: '100%' }}>
        <StyledAgGridReact
          ref={gridRef}
          suppressRowClickSelection={true}
          onSelectionChanged={onSelectionChange}
          rowSelection='multiple'
          rowData={Object.keys(list.order).length > 0 ? list.order.data[0].content : []}
          columnDefs={columnDef}
          overlayLoadingTemplate={`<div class="loader"></div>`}
        />
      <Box style={styles.alignBottom}>
          <GridPagination
            pageClick={handleGridPagination}
            gridTotal={getTotalPages}
            currentPage={currentPage}
            pageSize={GRID_FETCH_SIZE}
            gotoKeyDown={gotoKeyDown} />
          </Box>
      </Box>
      {/* <Buttons buttonlist={buttonlist} /> */}

    </Box>
  )
}
export default ArticleMaster;