export const styles ={
    popupStyle1: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50px',
        height: '50px',
        background: 'none',
        p: 4,
        outline:'none'
    },
}