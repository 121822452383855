import React, { useState } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { Divider } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RangeInput from './RangeInput';
import {StyledPopperDiv2,styles} from './styles'
import {useLocation} from 'react-router-dom'
import Input from './Input';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Loader from '../Loader';
/* Grid variable declaration */
const labelColumnDef = [
  {field: 'labelCode', headerName: 'Label Code', width: 140, headerClass: 'grid-header',cellStyle:{color:'#044BD9',textDecoration:'underline',cursor:'pointer'},searchType:['Regular'] },
  {field: 'name', headerName: 'Label Name', width: 200, headerClass: 'grid-header',searchType:['Regular']},
  {field: 'labelType', headerName: 'Label Type ', width: 170, headerClass: 'grid-header',searchType:['Regular']},
  {field: 'formula', headerName: 'Formula ', width: 170, headerClass: 'grid-header',searchType:['Regular']},    
  {field: 'overagePercentage', headerName: 'Overage Percentage ', width: 200, headerClass: 'grid-header',searchType:['Regular']},
  {field: 'height', headerName: 'Label Height', width: 200, headerClass: 'grid-header',searchType:['Regular','Range'] },
  {field: 'width', headerName: 'Label Width', width: 200, headerClass: 'grid-header',searchType:['Regular','Range']},
  ];
const ArticleMasterColumnDef =[
  { field: 'articleNumber', headerName: 'Article Number', width: 175, editable: true, headerClass: 'grid-header',resizable:true,searchType:['Regular','Range'] },
    { field: 'articleDescription', headerName: 'Article Description', width: 350, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'oldArticleNumber', headerName: 'Old Article Number', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'divisionDescription', headerName: 'Division ', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'brandCode', headerName: 'Brand Code ', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'sizeRange', headerName: 'Size Range ', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'modelNum', headerName: 'Model Number ', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular','Range'] },
    { field: 'modelDescription', headerName: 'Model Description ', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'ageGroup', headerName: 'Age Group ', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'gender', headerName: 'Gender', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'saleslineCode', headerName: 'Salesline', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'upperMaterial', headerName: 'Upper Material', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'liningMaterial', headerName: 'Lining Material', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'insoleMaterial', headerName: 'Insole Material', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'outsoleMaterial', headerName: 'Outsole Material', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'productTypeDescription', headerName: 'Product Type', width: 350, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'productGroupDescription', headerName: 'Product Group', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'sportsCategoryDescription', headerName: 'Sports Category', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'productFit', headerName: 'Product Fit', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'colourCombinationCode', headerName: 'Colour Combination Code ', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular','Range'] },
    { field: 'creationSeason', headerName: 'Creation Season ', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'currentSeason', headerName: 'Current Season ', width: 140, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'colorCombinationShort', headerName: 'Colour Combination Short ', width: 240, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'colorCombinationLong', headerName: 'Colour Combination Long ', width: 240, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
    { field: 'workingNumber', headerName: 'Working Num', width: 240, headerClass: 'grid-header',resizable:true,searchType:['Regular','Range'] },

]
const orderColumnDef = [
  { field: 'orderNumber', headerName: 'PO No', width: 85,resizable:true,searchType:['Regular','Range'] },
  // { field: 'poItemId', headerName: 'PO Item No', width: 110,resizable:true, },
  { field: 'status', headerName: 'Status', width: 110,resizable:true,searchType:['Regular'] },
  { field: 'orderQuantity', headerName: 'Order Quantity', width: 110,resizable:true,searchType:['Regular','Range'] },
  { field: 'articleNumber', headerName: 'Article Number', width: 150,resizable:true,searchType:['Regular','Range'] },
  // { field: 'articleDescription', headerName: 'Article Description', width: 400 ,resizable:true,searchType:['Regular'] },
  { field: 'modelDescription', headerName: 'Model Description', width: 400 ,resizable:true,searchType:['Regular'] },
  { field: 'countryOfOrigin', headerName: 'Origin Country Code', width: 150,resizable:true,searchType:['Regular'] },
  { field: 'customerCode', headerName: 'Customer Code', width: 150,resizable:true,searchType:['Regular','Range'] },
  { field: 'customerOrderNum', headerName: 'Customer Order No', width: 150,resizable:true,searchType:['Regular','Range'] },
  { field: 'divisionCode', headerName: 'Division Code', width: 110,resizable:true,searchType:['Regular'] },
  { field: 'factoryCode', headerName: 'Factory Code', width: 110,resizable:true,searchType:['Regular'] },
  { field: 'factoryName', headerName: 'Factory Name', width: 110,resizable:true,searchType:['Regular'] },
  { field: 'lastProductionDate', headerName: 'Last Production Date', width: 110,resizable:true,type:'date',searchType:['Regular','Range'] },
  { field: 'shippingAddressingLine1', headerName: 'Shipping Addressing Line 1', width: 110,resizable:true,searchType:['Regular'] },
  { field: 'shippingAddressingLine2', headerName: 'Shipping Addressing Line 2', width: 110,resizable:true,searchType:['Regular'] },
  { field: 'shippingAddressingLine3', headerName: 'Shipping Addressing Line 3', width: 110,resizable:true,searchType:['Regular'] },
  { field: 'shipToCountry', headerName: 'Shipping Country Code', width: 110,resizable:true,searchType:['Regular'] },
  { field: 'currency', headerName: 'Currency' , width: 200,resizable:true,searchType:['Regular'] },
  { field: 'retailPrice', headerName: 'Retail Price' , width: 200,resizable:true,searchType:['Regular','Range'] },
  // { field: 'sportsCategoryCode', headerName: 'Sports Category Code', width: 110,resizable:true },
  // { field: 'workingNumber', headerName: 'Working Number', width: 110,resizable:true },
  // {field: 'liaisonOfficeCode', headerName: 'LO' , width: 200,resizable:true },  
];
const customerColumnDef = [
  { field: 'customerCode', headerName: 'Customer Code', width: 180, headerClass: 'grid-header',resizable:true,searchType:['Regular','Range'] },
  { field: 'customerType', headerName: 'Customer Type', width: 180, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },
  { field: 'name', headerName: 'Customer Short Name', width: 300, editable: true, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'shortDescription', headerName: 'Description', width: 300, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'shippingAddress1', headerName: 'Shipping Address 1', width: 240, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'shippingAddressCity', headerName: 'Shipping Address 2', width: 180, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'shippingAddressCountry', headerName: 'Shipping Address 3', width: 180, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'shippingAddressPostcode', headerName: 'Shipping Address Post code', width: 250, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'shippingCountryCode', headerName: 'Shipping Address Country Code', width: 280, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'greenPoint', headerName: 'Green Point', width: 280, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'pictogram', headerName: 'Pictogram', width: 280, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'brandCode', headerName: 'Barcode Type', width: 280, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
];

const LabelListAssignmentColumnDef = [
  { field: 'divisionDesc', headerName: 'Division', editable: true, width: 200, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },
  { field: 'ageGroup', headerName: 'Age Group', editable: true, width: 200, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },
  { field: 'countryDesc', headerName: 'Shipping Country', editable: true, width: 200, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },
  { field: 'productGroup', headerName: 'Product Group', width: 200, editable: true, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },
  { field: 'customerNumber', headerName: 'Customer Number', editable: true, width: 200, headerClass: 'grid-header',resizable:true,searchType:['Regular','Range']  },
  { field: 'labelCode', headerName: 'Label Code', width: 200, editable: true, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },
];

const factoryColumnDef = [
  { field: 'factoryCode', headerName: 'Factory Code', width: 125, headerClass: 'grid-header',searchType:['Regular','Range'] },
  { field: 'shortDescription', headerName: 'Factory Short Name', width: 175, editable: true, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'description', headerName: 'Description', width: 400, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'factoryCountryCode', headerName: 'Country Code', width: 160, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'manufacturerCode', headerName: 'Manufacturer Code', width: 180, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'liaisonOfficeCode', headerName: 'Liaison Office Code', width: 200, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },
  { field: 'factoryAddress1', headerName: 'Address 1', width: 400, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'factoryAddress2', headerName: 'Address 2', width: 300, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },
  { field: 'factoryAddress3', headerName: 'Address 3', width: 300, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },
  { field: 'factoryPostcode', headerName: 'Post Code', width: 150, headerClass: 'grid-header' ,resizable:true,searchType:['Regular','Range'] },
  { field: 'factoryTown', headerName: 'Town', width: 250, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },
  { field: 'factorySupplierCode', headerName: 'Supplier Code', width: 150, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },
  { field: 'factoryCurrencyCode', headerName: 'Currency Code', width: 150, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },
  { field: 'divisionCode', headerName: 'Division', width: 150, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'productionDepartmentCode', headerName: 'Production Department Code', width: 180, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'factoryLocked', headerName: 'Locked', width: 150, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },
  { field: 'factoryLockedReason', headerName: 'Locked Reason', width: 150, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },

 ];
 const countrySizeColumnDef = [
  { field: 'division', headerName: 'Division', width: 250, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'genderAgeGroup', headerName: 'Gender Age Group', width: 250, headerClass: 'grid-header',resizable:true,searchType:['Regular']},
  { field: 'technicalSize', headerName: 'Technical Size', width: 250, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
  { field: 'usaSize', headerName: 'USA Size', width: 150, headerClass: 'grid-header',resizable:true,searchType:['Regular'] },
   
]

const BulkActionColumnDef =[
  { field: 'poBatchId', headerName: 'Batch ID', width: 85,resizable:true,type:'text',searchType:['Regular','Range'] },
  {type:'text', field: 'status', headerName: 'Status', width: 110,resizable:true,searchType:['Regular'] },
  {type:'text', field: 'action', headerName: 'Action', width: 110,resizable:true,searchType:['Regular'] },
  {type:'text', field: 'createdBy', headerName: 'Created By', width: 110,resizable:true,searchType:['Regular'] },
  ];

  const inventoryColumnDef = [
    { field: 'orderNum', headerName: 'PO No', width: 85,resizable:true,type:'text',searchType:['Regular'] },
    {type:'text', field: 'orderLabelStatus', headerName: 'Status', width: 110,resizable:true,searchType:['Regular'] },
    {type:'text', field: 'orderQuantity', headerName: 'Order Quantity', width: 110,resizable:true,type:'number',searchType:['Regular'] },
    {type:'text', field: 'labelQuantity', headerName: 'Label Quantity', width: 110,resizable:true,type:'number',searchType:['Regular'] },
    {type:'text', field: 'labelCode', headerName: 'Label Code', width: 110,resizable:true,searchType:['Regular'] },
    { type:'text',field: 'articleNumber', headerName: 'Article Number', width: 150,resizable:true,searchType:['Regular'] },
    { type:'text',field: 'customerCode', headerName: 'Customer Code', width: 150,resizable:true,searchType:['Regular'] },
    {type:'text', field: 'divisionCode', headerName: 'Division Code', width: 110,resizable:true,searchType:['Regular'] },
    {type:'text', field: 'factoryCode', headerName: 'Factory Code', width: 110,resizable:true,searchType:['Regular'] },
    {type:'text', field: 'isApproved', headerName: 'Approval Status', width: 1,resizable:true,searchType:['Regular'] }
  ];

  const approvalColumnDef = [
    { field: 'orderNumber', headerName: 'PO No', width: 85,resizable:true,searchType:['Regular','Range'] },
    { field: 'status', headerName: 'Status', width: 110,resizable:true,searchType:['Regular'] },
    { field: 'orderQuantity', headerName: 'Order Quantity', width: 110,resizable:true,searchType:['Regular','Range'] },
    { field: 'articleNumber', headerName: 'Article Number', width: 150,resizable:true,searchType:['Regular','Range'] },
    { field: 'modelDescription', headerName: 'Model Description', width: 400 ,resizable:true,searchType:['Regular'] },
    { field: 'countryOfOrigin', headerName: 'Origin Country Code', width: 150,resizable:true,searchType:['Regular'] },
    { field: 'customerCode', headerName: 'Customer Code', width: 150,resizable:true,searchType:['Regular','Range'] },
    { field: 'customerOrderNum', headerName: 'Customer Order No', width: 150,resizable:true,searchType:['Regular','Range'] },
    { field: 'divisionCode', headerName: 'Division Code', width: 110,resizable:true,searchType:['Regular'] },
    { field: 'factoryCode', headerName: 'Factory Code', width: 110,resizable:true,searchType:['Regular'] },
    { field: 'factoryName', headerName: 'Factory Name', width: 110,resizable:true,searchType:['Regular'] },
    { field: 'lastProductionDate', headerName: 'Last Production Date', width: 110,resizable:true,type:'date',searchType:['Regular','Range'] },
    { field: 'shippingAddressingLine1', headerName: 'Shipping Addressing Line 1', width: 110,resizable:true,searchType:['Regular'] },
    { field: 'shippingAddressingLine2', headerName: 'Shipping Addressing Line 2', width: 110,resizable:true,searchType:['Regular'] },
    { field: 'shippingAddressingLine3', headerName: 'Shipping Addressing Line 3', width: 110,resizable:true,searchType:['Regular'] },
    { field: 'shipToCountry', headerName: 'Shipping Country Code', width: 110,resizable:true,searchType:['Regular'] },
    { field: 'currency', headerName: 'Currency' , width: 200,resizable:true,searchType:['Regular'] },
    { field: 'retailPrice', headerName: 'Retail Price' , width: 200,resizable:true,searchType:['Regular','Range'] },  
  ];

function SearchChild({setSearchChildren,setSearchList,searchlist,searchChildren,index,submitForm}){
  const location = useLocation();
  const [list,setList] = useState(orderColumnDef);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [category,setCategory] = React.useState('');
  const [searchType,setSearchType] = React.useState(['Regular','Range'])
  const [inputvalue,setInputvalue] = React.useState('')
  const open2 = Boolean(anchorEl2)
  const [loading,setLoading] = useState(true)
  React.useEffect(() => {
    setInputvalue(searchChildren[index].value)
  },[searchChildren[index].column])

  const updateVal = (val) => {
    // setTimeout(() => setInputvalue(val),0)
    setInputvalue(val)
    }

  React.useEffect(() => {
    setInputvalue(searchChildren[index].value)
    setLoading(false)
  },[])
    
  
  React.useEffect(() => {
    if(location.pathname.includes('Label_Assignment')){
      const newlist = LabelListAssignmentColumnDef
      setList(newlist)
    }
    if(location.pathname.includes('Customer_List')){
      const newlist = customerColumnDef
      setList(newlist)
    }
    if(location.pathname.includes('Factory_List')){
      const newlist = factoryColumnDef
      setList(newlist)
    }
    
    if(location.pathname.includes('Order_Label_Use')){
      const newlist = labelColumnDef
      setList(newlist)
    }
    if(location.pathname.includes('View_Bulk_Actions')){
      const newlist = BulkActionColumnDef
      setList(newlist)
    }
    if(location.pathname.includes('Order_List')){
      const newlist = orderColumnDef
      setList(newlist)
    }
    if(location.pathname.includes('Inventory_List')){
      const newlist = inventoryColumnDef
      setList(newlist)
    }
    if(location.pathname.includes('Label_List')){
      const newlist = labelColumnDef
      setList(newlist)
    }
    if(location.pathname.includes('Article_master')){
      const newlist = ArticleMasterColumnDef
      setList(newlist)
    }
    if(location.pathname.includes('Country_Size')){
      const newlist = countrySizeColumnDef
      setList(newlist)
    }
    if(location.pathname.includes('Approval_List')){
      const newlist = approvalColumnDef
      setList(newlist)
    }

  },[location.pathname])
    
  React.useEffect(() =>{
    let new_list=[]
    if(index!==0)
    {
      new_list = list.filter(function(o1){
        return !searchChildren.some(function(o2){
          return o1.headerName === o2.column
        })
      })
    }
    else {
      new_list = list
    }
    setSearchList(new_list)
  },[searchChildren,list])//eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const newchildren = searchChildren.map((ele,i) => {
      if(index===i) {
        return {...ele,value:inputvalue}
      }
      return ele
    })
    setSearchChildren(newchildren)       
  },[inputvalue,list])//eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() =>{
    const newchildren = searchChildren.map((ele,i) => {
      if(index===i&category!=='') {
        return {...ele,type:category}
      }
      return ele
    })
    setSearchChildren(newchildren)
  },[category,list])//eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchChange = (item) =>{
    const newchildren = searchChildren.map((ele,i) => {   
      if(index===i){
        return {...ele,column:item.headerName,searchterm:item.field,inputtype:item.type}
      }
      return ele
    })
    setInputvalue('')
    setSearchType(item.searchType)
    setSearchChildren(newchildren)
  }
  const handleKeyDown = (e) => {
    if(e.key==='Enter'){
      submitForm()
    }
  }
    const deleteRow= () => {
      if(searchChildren.length>1){
        const newchildren = searchChildren.filter((ele,i) => index !== i)
        setSearchChildren(newchildren)
      }
    }
    
    const handleClickSelect = (event) => {
      setAnchorEl2(anchorEl2 ? null : event.currentTarget);   
    };
    const id = open2 ? 'simple-popper' : undefined;
  return (
    <div data-testid="search-child" style={styles.Search_Select_div}>
      <Loader open={loading}/>
      <button data-testid="search-param-dropdown" style={styles.Search_Select_btn} onClick={handleClickSelect}>{searchChildren[index].column}<ArrowDropDownIcon/></button>
      <PopperUnstyled anchorEl={anchorEl2} id={id} open={open2}>
        <StyledPopperDiv2 style={styles.Search_Select}>
          <div style={{display:location.pathname.includes('Country_Size')? 'none' : 'block'}}>Search type
            <div sx={styles.Option_div}  >
              <FormControl>
                <RadioGroup
                  data-testid="chkBox-search-type"
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={searchChildren[index].type}
                  onChange={(e) => {setCategory(e.target.value);handleClickSelect()}}>
                  <FormControlLabel data-testid="ctrl-regular" value="Regular" control={<Radio />} label="Regular" />
                  <FormControlLabel style={{ display:searchType.length === 1 ? 'none' : 'flex' }} data-testid="ctrl-range" value="Range" control={<Radio />} label="Range" />
                </RadioGroup>
              </FormControl>
            </div>
            </div>
            <div style={{height:'100%'}}>
                {searchlist.map((item,i) => <div data-testid="select-items" sx={styles.dropdown} style={{color:item.headerName===searchChildren[index].column ?'#6498CC':'#171725',display:item.headerName===searchChildren[index].column || !item.searchType.includes(searchChildren[index].type)?'none':'flex'}} key={i} id={i} onClick={(e) => {handleSearchChange(item,i);handleClickSelect();}}><p style={{cursor:'pointer'}}>{item.headerName}</p> </div>)}
            </div>
          
        </StyledPopperDiv2>
      </PopperUnstyled>
      
      {searchChildren[index].type==='Regular' ? 
        <Input data-testid="regular-search-text" searchColumn={searchChildren[index].column} type={searchChildren[index].inputtype} style={styles.Search_row_input}  onChange={updateVal}  handleKeyDown={handleKeyDown}  value={searchChildren[index].value} maxLength={searchChildren[index].type==='Regular'?1250:20}/> :
        <RangeInput type={searchChildren[index].inputtype} searchChildren={searchChildren} setSearchChildren={setSearchChildren} index={index} handleKeyDown={handleKeyDown} />
      }
      { searchChildren.length!==1 ?
        <DeleteOutlinedIcon data-testid="DeleteOutlinedIcon" onClick={deleteRow} sx ={{marginRight:'10px',cursor:'pointer',color:'#FF4343', opacity: 0.7}}/> : ''
      }
      <Divider/>
    </div>
  )
}

export default SearchChild
