const devConfig = () => {
    return {
        environment: 'dev',
        domain: 'https://rblabeling.dev.aws.sparcgroup.cloud/',
        hostname: 'rblabeling.dev.aws.sparcgroup.cloud',
        albURL: 'http://reebok-labeling-alb-562534693.us-east-2.elb.amazonaws.com',
        gateway: 'https://dqtfj43b8j.execute-api.us-east-2.amazonaws.com',
        gatewayPath: '/dev/labelling',
        oneLogin: {
            authority: "https://sparcbrands.onelogin.com/oidc/2",
            loginURL: "https://sparcbrands.onelogin.com/",
            logout: "https://sparcbrands.onelogin.com/oidc/2/logout",
            clientId: "8a8fecb0-1b2a-013b-b855-0ae9238d1b17203226",
            redirectURI: "https://rblabeling.dev.aws.sparcgroup.cloud/redirect"
            // redirectURI: "http://localhost:3000/redirect"
        }
    }
}

export default devConfig
