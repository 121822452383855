import {USERLIST_GET , USERLIST_SUCCESS,USERLIST_FAILURE } from './actions';

const initialState = {
    order:{}
}

export function UserListReducer(state = initialState, action) {
    
    if(action.type === USERLIST_GET) {
        return state;
    }
    if(action.type === USERLIST_SUCCESS) {
        return {
            ...state,
            order: {
                isLoaded: true,
                data: action.payload,
                message: 'Success'
            }
        }
    }
    if(action.type === USERLIST_FAILURE) {
        return {
            ...state,
            order: {
                data: [],
                message: action.payload
            }
        }
    }
    return state;
}

