import React, { useState, useEffect, useMemo, useRef } from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Box } from '@mui/material';
import { styles, StyledAgGridReact } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {  searchCustomerAction } from './Store/actions';
import Utils from '../../../utils/Utils';
import {useNavigate } from "react-router-dom";
import GridPagination from '../../../customWidgets/GridPagination/index';
import Loader from '../../../customWidgets/Loader'
import AccessLevelContext from '../../../../accessLevel-context';
import logo from '../../../assets/excelicon.png';
import * as XLSX from 'xlsx';
import { apisearchCustomer } from '../../../services/Gateway/RestAPIHandler';
import SimpleSnackbar from '../../../customWidgets/Snackbar';

function LabelCustomer() {
  const accessLevel= React.useContext(AccessLevelContext)
  const navigate = useNavigate()
  const checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const columnDef = [
    { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 20 },
    // { field: 'customerId', headerName: 'Customer ', width: 150, headerClass: 'grid-header' },
    { field: 'customerCode', headerName: 'Customer Code', width: 180, headerClass: 'grid-header',resizable:true },
    { field: 'customerType', headerName: 'Customer Type', width: 180, headerClass: 'grid-header',resizable:true },
    { field: 'name', headerName: 'Customer Short Name', width: 300, editable: true, headerClass: 'grid-header',resizable:true },
    { field: 'shortDescription', headerName: 'Description', width: 300, headerClass: 'grid-header',resizable:true },
    { field: 'shippingAddress1', headerName: 'Shipping Address 1', width: 240, headerClass: 'grid-header',resizable:true },
    { field: 'shippingAddressCity', headerName: 'Shipping Address 2', width: 180, headerClass: 'grid-header',resizable:true },
    { field: 'shippingAddressCountry', headerName: 'Shipping Address 3', width: 180, headerClass: 'grid-header',resizable:true },
    { field: 'shippingAddressPostcode', headerName: 'Shipping Address Post code', width: 250, headerClass: 'grid-header',resizable:true },
    { field: 'shippingCountryCode', headerName: 'Shipping Address Country Code', width: 280, headerClass: 'grid-header',resizable:true },
    { field: 'greenPoint', headerName: 'Green Point', width: 280, headerClass: 'grid-header',resizable:true },
    { field: 'pictogram', headerName: 'Pictogram', width: 280, headerClass: 'grid-header',resizable:true },
    { field: 'articleBarcodeType', headerName: 'Barcode Type', width: 280, headerClass: 'grid-header',resizable:true },
  ];
  const [currentPage, setCurrentPage] = React.useState(1);
  const GRID_FETCH_SIZE = 7;
  const list = useSelector(state => state.labelCustomerList);
  const gridRef = useRef();
  const [loading, setLoading] = useState(true)
  const [req, setReq] = useState(list.request)
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();
  const [alertType,setAlertType] = useState("success");
  const [alertMessage,setAlertMessage] = useState("");
  const [labelRows,setLabelRows] = useState([])

  useEffect(() => {
      if(accessLevel?.accessLevel!=='A01'){
        navigate('/')
      }
      else{
        if(req.filterSearch!=='' && req.hasOwnProperty('filterSearch')){
      dispatch(searchCustomerAction(req))
    }
    else {
      dispatch(searchCustomerAction({
        "ascDesc": "",
        "fetchSize": GRID_FETCH_SIZE,
        "filterSearch": "",
        "pageNumber": currentPage,
        "sortBy": ""
      }))
    }
      }
    
    
  },[])//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!Utils.isEmpty(list.order)) {
      setLoading(false)
    }
  },[currentPage])//eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setCurrentPage(req.pageNumber)
  }, [req]);
  const onSelectionChange = (event) => {
    const selectedRows = event.api.getSelectedRows();
    if (!Utils.isEmpty(selectedRows)){
      setLabelRows(selectedRows)
    }
    else{
      setLabelRows([])
    }
  }
  const showGridLoader = () => {
    gridRef.current.api.showLoadingOverlay();
  }
  const getTotalPages = useMemo(() => {
    setReq(list.request)
    const orderData = !Utils.isEmpty(list.order) ? list.order?.data[0] : [];
    if (!Utils.isEmpty(list.order)) {
      return Utils.isEmpty(list.order?.data) ?
        {
          totalPages: 1,
          totalElements: 1
        } : {
          totalPages: orderData?.totalPages,
          totalElements: orderData?.totalElements
        };
    }
    else
      return {
        totalPages: 1,
        totalElements: 1
      };
  }, [list.order]);
  const dataToExcel = (data) => {
    const newData = data.map(ele => {
      return {
        // "Customer" : ele?.customerId,
        "Customer Code": ele?.customerCode,
        "Customer Type": ele?.customerType,
        "Customer Short Name":ele?.name,
        "Description": ele?.shortDescription,
        "Shipping Address 1": ele?.shippingAddress1,
        "Shipping Address 2": ele?.shippingAddressCity,
        "Shipping Address 3 ": ele?.shippingAddressCountry,
        "Shipping Address Post code": ele?.shippingAddressPostcode, 
        "Shipping Address Country Code": ele?.shippingCountryCode,
        "Green Point": ele?.greenPoint,
        "Pictogram": ele?.pictogram,
        "Barcode Type": ele?.brandCode,

              }
    })
    
    const worksheet = XLSX.utils.json_to_sheet(newData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    XLSX.writeFile(workbook, "DownloadCustomerList.xlsx")
    setLoading(false)
  }
  const exportToExcel = () => {
    setLoading(true)      
          if(labelRows.length===0){
            apisearchCustomer(
              {...req,fetchSize:500,pageNumber: 1}
            ).then(([response, json])=> {
            if(response.status===200 && !Utils.isEmpty(json.results)){
            const data = json.results[0].content;
            dataToExcel(data)
          } 
          else{
            setOpenAlert(true)
            setAlertType('error')
            setAlertMessage("No data to export")
            setLoading(false)
          }
        })
      }
          else{
            dataToExcel(labelRows)
          }  
        
  }

  const handleGridPagination = (event, thisPage) => {
    showGridLoader();
    setCurrentPage(thisPage);
    setLabelRows([])
    if (req.filterSearch !== '' && req.hasOwnProperty('filterSearch')) {
      dispatch(searchCustomerAction({ ...req, fetchSize: GRID_FETCH_SIZE, pageNumber: thisPage }));
    }
    else {
      dispatch(searchCustomerAction({
        "ascDesc": "",
        "fetchSize": GRID_FETCH_SIZE,
        "filterSearch": "",
        "pageNumber": thisPage,
        "sortBy": ""
      }))
    }
  }
  const gotoKeyDown = (event, value) => {
    if (event.keyCode === 13 && parseInt(value) >= 1  && parseInt(value) <= getTotalPages.totalPages && parseInt(value)!==currentPage) {
      showGridLoader();
      setLabelRows([])
      setCurrentPage(parseInt(value));
      if (req.filterSearch !== '' && req.hasOwnProperty('filterSearch')) {
        dispatch(searchCustomerAction({ ...req, fetchSize: GRID_FETCH_SIZE, pageNumber: parseInt(value) }));
      }
      else {
        dispatch(searchCustomerAction({
          "ascDesc": "",
          "fetchSize": GRID_FETCH_SIZE,
          "filterSearch": "",
          "pageNumber": parseInt(value),
          "sortBy": ""
        }))
      }
    }
  }



  return (
    <Box style={styles.gridContainer}>
      <Loader open={loading} />
      <img onClick={() => { exportToExcel() }} src={logo} style={styles.excelicon} alt={"Export Excel"} />
      <SimpleSnackbar setOpen={setOpenAlert} open={openAlert}  message={alertMessage} messagetype={alertType} />

      <Box className={"ag-theme-alpine"}
        sx={{ height: '365px', width: '100%' }}>
        <StyledAgGridReact
          ref={gridRef}
          rowSelection='multiple'
          rowData={Object.keys(list.order).length > 0 ? list.order.data[0].content : []}
          columnDefs={columnDef}
          pagination={true}
          onSelectionChanged={onSelectionChange}
          suppressRowClickSelection={true}
          paginationPageSize={GRID_FETCH_SIZE}
          suppressPaginationPanel={true}
          overlayLoadingTemplate={`<div class="loader"></div>`}
        />
      </Box>
      <Box style={styles.alignBottom}>
        <GridPagination
          pageClick={handleGridPagination}
          gridTotal={getTotalPages}
          currentPage={currentPage}
          pageSize={GRID_FETCH_SIZE}
          onSelectionChanged={onSelectionChange}

          gotoKeyDown={gotoKeyDown} />
      </Box>

    </Box>
  )
}
export default LabelCustomer;