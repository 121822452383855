import React,{useRef} from 'react';
import { Box,IconButton} from '@mui/material';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {apiBulkAction }from '../../services/Gateway/RestAPIHandler';
import {styles,OrderButton,DownloadButton,StyledLabel } from './styles';
import {useSelector } from 'react-redux';
import Utils from '../../utils/Utils';
import csvicon from '../../assets/csv_background.png';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SimpleSnackbar from '../../customWidgets/Snackbar';
import Loader from '../../customWidgets/Loader';


function BulUpload({close,selectedData,setSelectedData,formData,setFormData,fileName,setFileName}) {
   const list = useSelector(state => state.orderList);
  //  const [rowData,setRowData] = React.useState(location.state.rows)
   const [dragActive,setDragActive] = React.useState(false);
   const inputRef = useRef(null);
  //  const [fileName,setFileName] = React.useState('');
  //  const [rowData,setRowData] = React.useState([])
  //  const [selectedData,setSelectedData] = React.useState(false)
  //  const [formData,setFormData] = React.useState({})
   const [open,setOpen] = React.useState(false)
   const [alertMessage,setAlertMessage] = React.useState('')
   const [alertType,setAlertType] = React.useState('')
   const [loading,setLoading] = React.useState(false)
  //  const ref = useRef();
   const reset = () => {
    // ref.current.value = "";
    inputRef.current.value = "";
    setSelectedData(false)
    setFileName('')
   }

   const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if(e.type==="dragenter" || e.type==="dragover"){
      setDragActive(true);
    }
    else if(e.type==="dragleave"){
      setDragActive(false);
    }
   }
   const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if(e.dataTransfer.files && e.dataTransfer.files[0])
    {
      onFileChange(e.dataTransfer.files[0])
    }
   }
    const handleChange = (e) => {
      e.preventDefault();
      if(e.target.files && e.target.files[0])
        {
          onFileChange(e.target.files[0])
        }
    }
    const onButtonClick = () => {
      inputRef.current.click();
    }
  const convertJsonToCSV = async (e) => {
  
    const csvContent = [
      [
        "orderNumber",
        "status"
      ],
      // ...rowData.map(item => [
      //   item.orderNumber,
      //   item.status
      // ])
      [
        'Add OrderNumber here','Add Status here'
      ]
      ].map(e => e.join(","))
      .join("\n");

  
    let ele = document.createElement('a');
    const blob = new Blob([csvContent],{type: 'text/csv;charset=utf-8;'});
    const url = URL.createObjectURL(blob);
    ele.href = url;
    ele.setAttribute('download','export_template.csv');
    ele.click();
  }
  //  React.useEffect(() => {
  //   if(!Utils.isEmpty(list.order.data)){
  //     if(list.order.isLoaded){
  //       setRowData(list?.order?.data[0]?.content)
  //     }
  //   }
  //   if(list.order?.isError && !Utils.isEmpty(list.order.message)){
  //     setRowData([])
  //   }
  // },[list.order])//eslint-disable-line react-hooks/exhaustive-deps
  //  const handleDelete= (event) => {
  //   const newData = rowData.filter(function(o1){
  //     return selectedData.every(function(o2){
  //       return (o1.orderNumber !== o2.orderNumber)
  //     })
  //   })
  //   setRowData(newData)
  //  }
   const onBulkAction = (bulkaction) => {
    apiBulkAction({
      action:bulkaction,
      body:formData
    })
    .then(([response, json]) => {
      if(response.status===200 && json.status!=='ERROR')
      {
        setAlertMessage("Bulk Action Submitted  Successfully")
        setAlertType('success')
        setOpen(true)
        setLoading(false)
        reset()
      }
      else{
        setAlertMessage(json.responseMessage ?? json.errorMessage)
        setAlertType("error")
        setOpen(true)
        setLoading(false)
      }
    })

    // fetch(`/labelling/bulkPOUpload?action=${bulkaction}`,{
    //   method:"POST",
    //   body:formData,
    //   headers:{
    //     'Authorization': `Bearer ${window.localStorage.getItem("access_token")}`
    //   }
    // })

   }
   const onFileChange = (file) => {
    // textFile(e)
    
    
    
    if(file.name.includes('.csv') && file.type==="text/csv"){
    setFileName(file.name)
    const newFormData = new FormData();
    newFormData.append("file",file);
    setFormData(newFormData);
    setSelectedData(true)
    }
    else{
      setAlertMessage('Please choose only CSV file');
      setAlertType('error');
      setOpen(true);
      reset();
    }

   }
      const textFile = async (e) => {
       const reader = new FileReader();
       reader.onload = async (e) => {
        const text = (e.target.result);
        const lines = text.split('\n');
        const res = [];
        const header = lines[0].split(',')
        if(lines.length<=1000){
        for(let i=1;i<lines.length;i++){
          if(!lines[i])
          continue
          const obj = {}
          const currentLine = lines[i].split(',');
          for(let j=0;j<header.length;j++){
            if(currentLine[j]===''){
              alert('contains empty')
              break
            }
            obj[header[j]] = currentLine[j];
          }
          res.push(obj)
        }
      }
      else{
        setAlertMessage('Please upload files with maximun 1000 records');
        setAlertType('error');
        setOpen(true);
        reset();
      }
       };
       reader.readAsText(e.target.files[0])
    }
     return <Box style={styles.inputContainer} >
       <Loader open={loading}/>
       <SimpleSnackbar setOpen={setOpen} open={open}  message={alertMessage} messagetype={alertType} />
       <div><p style={{margin:'0 0 0 10px',fontWeight:'bolder'}}>BULK ACTION</p></div>
       {/* <DownloadButton onClick={convertJsonToCSV}>Download Template <FileDownloadOutlinedIcon sx ={{margin:'10px',fontSize: '15px',}}/> </DownloadButton> */}
        <IconButton id="close-modal-popup" data-testid="close-modal-popup" onClick={close} style={styles.closeButton}>
                <CloseIcon />
        </IconButton>
       <Box sx={{ position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)'}}>
       {/* <Box style={{display:'flex',justifyContent:'end',alignItems:'center',height:"100px",width:'350px',marginTop:'20px'}}>
          <input type={'file'} onChange={(e) => {onFileChange(e)}} accept={'.csv'} ref={ref}/>
          <DeleteOutlinedIcon sx={{color:'red',cursor:'pointer'}} onClick={reset}/>
        </Box>   */}
        <form style={styles.formUpload} onDragEnter={handleDrag} onSubmit={(e) => {e.preventDefault()}}>
          <input style={{display:'none'}} ref={inputRef} type={"file"} accept={'.csv'} onChange={handleChange}/>
          <StyledLabel
          className={dragActive?'dragActive':""}>
            <div style={{marginTop:'40px'}}>
              <img src={csvicon} />
              <p style={styles.divText}>
                Drag and drop your file here
              </p>
              <p style={{fontSize:'12px',color:'#878787',margin:'0px 0px 10px 0px'}}>Accepts only .csv files</p>
              </div>
          </StyledLabel>
         <div style={styles.divHorizontalLine}><hr style={styles.hortizontalLine}/><p style={styles.divText}>or</p> <hr style={styles.hortizontalLine}/></div>
              <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <p style={{marginRight:'5px',padding:'5px 5px',borderRadius:'5px',backgroundColor:'#bfd5f5',border:'1px solid rgba(135, 135, 135, 0.3)',display:selectedData?'block':'none',fontSize:fileName.length>20?'12px':'16px'}}>{fileName}</p> <DeleteOutlinedIcon sx={{marginLeft:'10px',color:'red',cursor:'pointer',display:selectedData?'block':'none'}} onClick={(e) => {e.stopPropagation();reset()}}/> 
                </div>
                <DownloadButton sx={{marginLeft:'20px'}} onClick={onButtonClick}>Upload a file</DownloadButton>
              </div>
         
          {
            dragActive && <div style={styles.dragdiv} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
            </div> 
          }
        </form>
        <Box sx={{marginTop:'20px'}}>
          <p style={{textAlign:'center'}}><span style={{color:'#044BD9',textDecoration:'underline',cursor:'pointer'}} onClick={convertJsonToCSV} >Click Here</span> to download template.</p>
        </Box>
        <Box sx={{marginTop:'50px',display:'flex',justifyContent:'space-evenly',alignItems:'center',width:'100%'}}>
            <OrderButton className={selectedData?'':'disabled'} onClick={() => onBulkAction('PREPARE')} >Prepare</OrderButton>
            <OrderButton className={selectedData?'':'disabled'} onClick={() => onBulkAction('SEND')}>Send</OrderButton>
            <OrderButton className={selectedData?'':'disabled'} onClick={() => onBulkAction('PREPARESEND')}>Prepare and Send</OrderButton>
        </Box>
       </Box>
    </Box>
}

export default BulUpload;