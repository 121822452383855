

export const styles = {
    textFont: {
        paddingTop: '10px',
        paddingBottom: '10px',
        textAlign: 'left',
        // font: 'normal normal medium 24px/35px Poppins',
        letterSpacing: '0px',
        fontSize:'20px',
        color: '#00000DE',
        fontWeight:'400px',
        opacity: '1',
        width: '153px',
        height: '33px',
    },
    paddingTop: {
        paddingTop: '10px',
    }
}