import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { Box,Typography,Tooltip } from '@mui/material';
//import * as XLSX from 'xlsx';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { searchOrderListAction} from './Store/actions';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector} from 'react-redux';
import { useNavigate,useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import BulkAction from '../../BulkUpload/index'
import Buttons from './Buttons';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { styles,StyledAgGridReact,OrderButton} from './styles';
import logo from '../../../assets/excelicon.png';
import GridPagination from '../../../customWidgets/GridPagination/index';
import * as ORDER from '../constant';
import {apipreparePOItem,apiunlockPOItem,apilockPOItem,apiCancelPoItem,apiSendPoItem,apiPrepareSendOrder,apiPOBulkAction,apisearchOrderList} from '../../../services/Gateway/RestAPIHandler'
import SimpleSnackbar from '../../../customWidgets/Snackbar';
import Utils from '../../../utils/Utils';
import Loader from '../../../customWidgets/Loader'
// import SimpleExportOrder from '../ExportOrder/index';
// import {store} from '../ExportOrder/Store/store';
// import { OrderButton } from '../../PoCardTable/styles';
import ArticleDetails from '../../ReferenceData/ArticleNonAdmin/index'
import ExportExcel from '../../../customWidgets/ExportExcel';

export default function OrderList(props) {
  /* Constant variables declaration */
  const location = useLocation();
  const [gridApi,setGridApi] = useState(null)
  const [selectedData,setSelectedData] = React.useState(false)
  const [formData,setFormData] = React.useState({})
  const [fileName,setFileName] = React.useState('');
  const GRID_FETCH_SIZE = 15;
  const gridRef = useRef();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [orderRows,setOrderRows] = useState([])
  /* Component state */

  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [poNum,setPONum] = useState(0);
  const [status,setStatus] = useState('NEW');
  const [alertType,setAlertType] = useState("success");
  const [alertMessage,setAlertMessage] = useState("");
  const [loading,setLoading] = useState(true)
  const [poItemIdsList,setPoItemIdsList] = useState([])
  let waitForRowSelect = false;

  const checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const list = useSelector(state => state.orderList);
  const [rowData,setRowData] =useState([]);
  const [req,setReq] = useState(list.request);
  const [openArticleDetails,setOpenArticleDetails] = useState(false)
  const handleOpen = () => setOpenArticleDetails(true);
  const handleClose = () => setOpenArticleDetails(false);
  const [openBulkAction,setOpenBulkAction] = useState(false)
  const handleOpenBulkAction = () => setOpenBulkAction(true)
  const handleCloseBulkAction = () => setOpenBulkAction(false)
  const [openDownloadBulk,setOpenDownloadBulk] = useState(false)
  const handleOpenDownloadBulk = () => setOpenDownloadBulk(true)
  const handleCloseDownloadBulk = () => {
    setOpenDownloadBulk(false);
    setDownloadType('Latest');
  }
  const [searchColumns,setSearchColumns]  = useState(list.searchColumns)
  const columnDef = [
    { field: ORDER.ORDERS.columnDef.checkbox.field, headerName: ORDER.ORDERS.columnDef.checkbox.headerName, resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 50 },
    { field: ORDER.ORDERS.columnDef.orderNumber.field, headerName: ORDER.ORDERS.columnDef.orderNumber.headerName, width: 130,resizable:true ,cellStyle:{color:'#044BD9',textDecoration:'underline',cursor:'pointer'} },
    { field: ORDER.ORDERS.columnDef.status.field, headerName: ORDER.ORDERS.columnDef.status.headerName, width: 150 ,resizable:true },
    { field: ORDER.ORDERS.columnDef.orderQuantity.field, headerName: ORDER.ORDERS.columnDef.orderQuantity.headerName, valueFormatter: params => { if(params.value===null){return 0} return params.value}, width: 140 ,resizable:true },
    { field: ORDER.ORDERS.columnDef.articleNumber.field, headerName: ORDER.ORDERS.columnDef.articleNumber.headerName, width: 150 ,resizable:true,cellStyle:{color:'#044BD9',textDecoration:'underline',cursor:'pointer'} },
    // { field: ORDER.ORDERS.columnDef.articleDescription.field, headerName: ORDER.ORDERS.columnDef.articleDescription.headerName, width: 400 ,resizable:true },
    { field: ORDER.ORDERS.columnDef.modelDescription.field, headerName: ORDER.ORDERS.columnDef.modelDescription.headerName, width: 400 ,resizable:true,},
    { field: ORDER.ORDERS.columnDef.countryOfOrigin.field, headerName: ORDER.ORDERS.columnDef.countryOfOrigin.headerName, width: 200 ,resizable:true },
    { field: ORDER.ORDERS.columnDef.customerCode.field, headerName: ORDER.ORDERS.columnDef.customerCode.headerName, width: 150 ,resizable:true },
    { field: ORDER.ORDERS.columnDef.customerOrderNum.field, headerName: ORDER.ORDERS.columnDef.customerOrderNum.headerName, width: 180 ,resizable:true },
    { field: ORDER.ORDERS.columnDef.divisionCode.field, headerName: ORDER.ORDERS.columnDef.divisionCode.headerName,resizable:true , width: 150,valueFormatter: function (params) {
      if (params.value === '1') {
        return "FOOTWEAR";
      }
      else if (params.value === "2"){
       return "APPAREL";
      }
    } },
    { field: ORDER.ORDERS.columnDef.factoryCode.field, headerName: ORDER.ORDERS.columnDef.factoryCode.headerName, width: 140 ,resizable:true },
    { field: ORDER.ORDERS.columnDef.factoryName.field, headerName: ORDER.ORDERS.columnDef.factoryName.headerName, width: 200 ,resizable:true },
    { field: ORDER.ORDERS.columnDef.lastProductionDate.field, headerName: ORDER.ORDERS.columnDef.lastProductionDate.headerName, width: 180 ,resizable:true },
    { field: ORDER.ORDERS.columnDef.shippingAddressingLine1.field, headerName: ORDER.ORDERS.columnDef.shippingAddressingLine1.headerName, width: 250,resizable:true },
    { field: ORDER.ORDERS.columnDef.shippingAddressingLine2.field, headerName: ORDER.ORDERS.columnDef.shippingAddressingLine2.headerName, width: 250,resizable:true },
    { field: ORDER.ORDERS.columnDef.shippingAddressingLine3.field, headerName: ORDER.ORDERS.columnDef.shippingAddressingLine3.headerName, width: 250,resizable:true },
    { field: ORDER.ORDERS.columnDef.shipToCountry.field, headerName: ORDER.ORDERS.columnDef.shipToCountry.headerName, width: 200,resizable:true },
    { field: ORDER.ORDERS.columnDef.currency.field, headerName: ORDER.ORDERS.columnDef.currency.headerName, width: 200,resizable:true },
    { field: ORDER.ORDERS.columnDef.retailPrice.field, headerName: ORDER.ORDERS.columnDef.retailPrice.headerName, width: 200,resizable:true }
  ];
const [downloadType,setDownloadType] = useState('Latest')
 
const [articleDetails,setArticleDetails] = React.useState({})
  useEffect(() => {
    if(req.hasOwnProperty('filterSearch')){
      setCurrentPage(req.pageNumber)
    }
  },[req]);

  const onGridReady= (params) => {
    setGridApi(params.api)
  }



  const convertJsonToCSV = async (e) => {
    const date = new Date().toJSON().replace(/[^\d*,]/g,'').slice(2,12).toString();
    setLoading(true)
    if(downloadType===ORDER.ORDERS.latest){
    apisearchOrderList(
      {...req,fetchSize:1000,pageNumber: 1}
    ).then(([response, json])=> {
      if(response.status===200 && !Utils.isEmpty(json.results)) {
        const data = json.results[0].content;
        const count = json.results[0].totalElements;
        if(count>1000){
          setAlertMessage("The Latest 1000 records are downloaded")
          setAlertType('warning')
          setOpen(true)
        }
        
        const csvContent = [
          [
            "orderNumber",
            "status"
          ],
          ...data.map(item => [
            item.orderNumber,
            item.status
          ])
        ].map(e => e.join(","))
        .join("\n");

  
        let ele = document.createElement('a');
        const blob = new Blob([csvContent],{type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        ele.href = url;
        ele.setAttribute('download',`Bulkexport_${date}.csv`);
        ele.click();
        setLoading(false)
      }
    })
    }
    else if (poItemIdsList.length===0 && downloadType==ORDER.ORDERS.selected){
      setAlertMessage("No Rows are selected to export")
      setAlertType('error')
      setOpen(true)
      setLoading(false)
    }
    else{
      const csvContent = [
        [
          "orderNumber",
          "status"
        ],
        ...poItemIdsList.map(item => [
          item.orderNumber,
          item.status
        ])
        ].map(e => e.join(","))
        .join("\n");
  
    
      let ele = document.createElement('a');
      const blob = new Blob([csvContent],{type: 'text/csv;charset=utf-8;'});
      const url = URL.createObjectURL(blob);
      ele.href = url;
      ele.setAttribute('download',`Bulkexport_${date}.csv`);
      ele.click();
      setLoading(false)

    }
}   
  /* Component method */
  const onSelectionChanged = (event) => {
    const selectedRow= event.api.getSelectedRows();
    setPONum(Utils.isEmpty(selectedRow) ? 0 : selectedRow[0]?.poItemId)
    setStatus(Utils.isEmpty(selectedRow) ? 'NEW' : selectedRow[0]?.status)

    if(Utils.isEmpty(poItemIdsList)){
      setPoItemIdsList(selectedRow)
    }
  }
  const handlePrepare = async() => {
    setLoading(true)
    apipreparePOItem(poNum)
    // apiPOBulkAction({
    //   action:'PREPARE',
    //   body:{
    //     "poItemIds": poItemIdsList
    //   }
    // })
    .then(([response, json]) => {
      if(response.status===200 && json.status!==ORDER.APIStatus.ERROR && json.results[0]!==ORDER.APIStatus.ERROR)
      {
        // handleDispatch()
        dispatch(searchOrderListAction({ ...req,fetchSize: GRID_FETCH_SIZE},searchColumns))
        setPONum(0)
        setAlertMessage("PO Prepared Successfully")
        setAlertType(ORDER.APIStatus.SUCCESS.toLowerCase())
        setOpen(true)
        setLoading(false)
      }
      else{

        json.errorMessage===undefined?setAlertMessage('Unable to prepare PO'):setAlertMessage(json.errorMessage)
        setAlertType(ORDER.APIStatus.ERROR.toLowerCase())
        setOpen(true)
        setLoading(false)
      }
      
  })
}

  const handleLock = async() => {
    setLoading(true)
    apilockPOItem(poNum)
    // apiPOBulkAction({
    //   action:'LOCK',
    //   body:{
    //     "poItemIds": poItemIdsList
    //   }
    // })
    .then(([response, json]) => {
      if(response.status===200 && json.status!==ORDER.APIStatus.ERROR)
      {   dispatch(searchOrderListAction({ ...req,fetchSize: GRID_FETCH_SIZE},searchColumns))
        // dispatch(searchOrderListAction(
        //   {
        //     "ascDesc":"desc" ,
        //     "fetchSize": 15,
        //     "filterSearch": '',
        //     "pageNumber": currentPage,
        //     "sortBy":"orderNumber"
        //   }))
        setAlertMessage("PO Locked Successfully")
        setAlertType(ORDER.APIStatus.SUCCESS.toLowerCase())
        setOpen(true)
        setPONum(0)
        setLoading(false)
      }
      else{
          setAlertMessage(json.responseMessage ?? json.errorMessage)
          setAlertType(ORDER.APIStatus.ERROR.toLowerCase())
          setOpen(true)
          setLoading(false)
      }
    })
  }
  const handleCancel = async() => {
    setLoading(true)
    apiCancelPoItem(poNum)
    // apiPOBulkAction({
    //   action:'CANCEL',
    //   body:{
    //     "poItemIds": poItemIdsList
    //   }
    // })
    .then(([response, json]) => {
      if(response.status===200 && json.status!==ORDER.APIStatus.ERROR)
      {
        dispatch(searchOrderListAction({ ...req,fetchSize: GRID_FETCH_SIZE},searchColumns))
        // dispatch(searchOrderListAction(
        //   {
        //     "ascDesc":"desc" ,
        //     "fetchSize": 15,
        //     "filterSearch": '',
        //     "pageNumber": currentPage,
        //     "sortBy":"orderNumber"
        //   }))
        setAlertMessage("PO Cancelled Successfully")
        setAlertType(ORDER.APIStatus.SUCCESS.toLowerCase())
        setOpen(true)
        setPONum(0)
        setLoading(false)
      }
      else{
        setAlertMessage(json.responseMessage ?? json.errorMessage)
        setAlertType(ORDER.APIStatus.ERROR.toLowerCase())
        setOpen(true)
        setLoading(false)
      }
    })
  }   
  const handleSent = async() => {
    setLoading(true)
    apiSendPoItem(poNum)
    // apiPOBulkAction({
    //   action:'SEND',
    //   body:{
    //     "poItemIds": poItemIdsList
    //   }
    // })
    .then(([response, json]) => {
      if(response.status===200 && json.status!==ORDER.APIStatus.ERROR)
      {
        dispatch(searchOrderListAction({ ...req,fetchSize: GRID_FETCH_SIZE},searchColumns))
        // dispatch(searchOrderListAction(
        //   {
        //     "ascDesc":"desc" ,
        //     "fetchSize": 15,
        //     "filterSearch": '',
        //     "pageNumber": currentPage,
        //     "sortBy":"orderNumber"
        //   }))
        setAlertMessage("PO Sent Successfully")
        setAlertType(ORDER.APIStatus.SUCCESS.toLowerCase())
        setOpen(true)
        setPONum(0)
        setLoading(false)
      }
      else{
        setAlertMessage(json.responseMessage ?? json.errorMessage)
        setAlertType(ORDER.APIStatus.ERROR.toLowerCase())
        setOpen(true)
        setLoading(false)
      }
    })
  }
  const handlePrepareSend = async() => {
    setLoading(true)
    apiPrepareSendOrder({
      "poItemIds": poItemIdsList
    })
    .then(([response, json]) => {
      if(response.status===200 && json.status!==ORDER.APIStatus.ERROR)
      {
        dispatch(searchOrderListAction({ ...req,fetchSize: GRID_FETCH_SIZE},searchColumns))
        // dispatch(searchOrderListAction(
        //   {
        //     "ascDesc":"desc" ,
        //     "fetchSize": 15,
        //     "filterSearch": '',
        //     "pageNumber": currentPage,
        //     "sortBy":"orderNumber"
        //   }))
        setAlertMessage(json.successMessage)
        setAlertType('success')
        setOpen(true)
        setPONum(0)
        setLoading(false)
      }
      else {
        setAlertMessage(json.responseMessage ?? json.errorMessage)
        setAlertType(ORDER.APIStatus.ERROR.toLowerCase())
        setOpen(true)
        setLoading(false)
      }
    })
  }
  const handleUnlock = async() => {
    setLoading(true)
    apiunlockPOItem(poNum)
    .then(([response, json]) => {
      if(response.status===200 && json.status!==ORDER.APIStatus.ERROR)
      {
        dispatch(searchOrderListAction({ ...req,fetchSize: GRID_FETCH_SIZE},searchColumns))
        // dispatch(searchOrderListAction(
        //   {
        //     "ascDesc":"desc" ,
        //     "fetchSize": 15,
        //     "filterSearch": '',
        //     "pageNumber": currentPage,
        //     "sortBy":"orderNumber"
        //   }))
        setAlertMessage("PO Unlocked Successfully")
        setAlertType('success')
        setOpen(true)
        setPONum(0)
        setLoading(false)
      }
      else {
        setAlertMessage(json.responseMessage ?? json.errorMessage)
        setAlertType(ORDER.APIStatus.ERROR.toLowerCase())
        setOpen(true)
        setLoading(false)
      }
    })
  }
  const handleCellClick = (event) => {
    if(event.colDef.headerName===ORDER.ORDERS.columnDef.articleNumber.headerName){
      setArticleDetails(event.data.articleDto)
      handleOpen(true)
    }
    else if(event.colDef.headerName===ORDER.ORDERS.columnDef.orderNumber.headerName){
      navigate(`/orders/Order_Label_Details?PONo=${event.data.orderNumber}`,{replace:true})
    }
    else {
      return
    }
  }
  useEffect(() => {
    if(!Utils.isEmpty(list.order.data)){
      if(list.order.isLoaded && gridApi){
        hideGridLoader()
        setRowData(list?.order?.data[0]?.content)
        setLoading(false)
      }
    }
    if(list.order?.isError && !Utils.isEmpty(list.order.message) && gridApi){
      setRowData([])
      hideGridLoader()
      setAlertMessage(list.order.message)
      setAlertType(ORDER.APIStatus.ERROR.toLowerCase())
      setOpen(true)
      setLoading(false)
    }
  },[list.order])//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true)
    
    if(location.state!==null){
      if(location.state.Status==='*'){
        dispatchSearch('')
      }
      else{
        dispatchSearch(`status:${location.state.Status}`)
      }
    }
    else {
      if(req.hasOwnProperty('filterSearch')){
        dispatch(searchOrderListAction({ ...req,fetchSize: GRID_FETCH_SIZE},searchColumns))
      }
      else{
        dispatch(searchOrderListAction({     
          "ascDesc":"desc" ,
          "fetchSize": 15,
          "filterSearch": '',
          "pageNumber": currentPage,
          "sortBy":"orderNumber"
        }));
      }
    }

    navigate(location.pathname,{replace:true});
  },[])//eslint-disable-line react-hooks/exhaustive-deps
 
  const getTotalPages = useMemo(() => {
    setReq(list.request)
    setSearchColumns(list.searchColumns)
    const [orderData] = !Utils.isEmpty(list.order) ? list?.order?.data ?? [] : [];
    if(!Utils.isEmpty(list.order)) {
      return Utils.isEmpty(list.order?.data) ?
        {
          totalPages: 1,
          totalElements: 1
        } : {
          totalPages: orderData?.totalPages,
          totalElements: orderData?.totalElements
        };
    }
    else
      return {
        totalPages: 1,
        totalElements: 1
      };
  },[list.order]);//eslint-disable-line react-hooks/exhaustive-deps
  
  const gotoKeyDown = (event, value) => {
    
    if(event.keyCode === 13 && parseInt(value) >= 1 && parseInt(value) <= getTotalPages.totalPages && currentPage!==parseInt(value)) {
      setCurrentPage(parseInt(value));
      showGridLoader();
      dispatch(searchOrderListAction({ ...req,fetchSize: GRID_FETCH_SIZE, pageNumber: parseInt(value) },searchColumns));
     
    }
  }
  const handleGridPagination = (event, thisPage) => {
    if(currentPage!==thisPage){
      showGridLoader()
      setCurrentPage(thisPage) 
      dispatch(searchOrderListAction({ ...req,fetchSize: GRID_FETCH_SIZE, pageNumber: thisPage },searchColumns));
    }
  }
  // const handleBulkPrepareSend = () => {navigate('/testpage',{state:{rows:orderRows}})}
  const handleBulkNavigate = () => {
    handleOpenBulkAction()
  }
  const showGridLoader = () => {
    gridRef.current.api.showLoadingOverlay();
  }
  const hideGridLoader = () => {
    gridRef.current.api.hideOverlay();
  }
  /* Grid pagination methods -End */

  const dispatchSearch = (searchString) => {
    if(location.state.Status==='*'){
      dispatch(searchOrderListAction(
        {
          "ascDesc":"desc" ,
          "fetchSize": 15,
          "filterSearch": searchString,
          "pageNumber": 1,
          "sortBy":"orderNumber"
        }))
    }
    else{
      dispatch(searchOrderListAction(
        {
          "ascDesc":"desc" ,
          "fetchSize": 15,
          "filterSearch": searchString,
          "pageNumber": 1,
          "sortBy":"orderNumber"
        },[{searchterm:'status',inputtype:'',type:'Regular',toValue:'',fromValue:'',column:'Status',value:location.state.Status.toUpperCase()}]))
    }
  }

  const onRowSelected = (event) => {
    //If id doesn't exists it would give undefined as result
    const itemIdExists = poItemIdsList.find((value) => value.poItemId === event?.data?.poItemId);
    if(!waitForRowSelect){
      const selectedRows = event.api.getSelectedRows();
      if(Utils.isEmpty(poItemIdsList) || Utils.isEmpty(itemIdExists)) {
         setPoItemIdsList(poItemIdsList => [...poItemIdsList,event?.data])
          // selectedRows.forEach(row => {
          //   if(row?.poItemId === event?.data?.poItemId) {
          //     // setOrderRows([...orderRows, row])
          //     // setPoItemIdsList(poItemIdsList => [...poItemIdsList,row])
          //   }
          // });
        }
      else {
        // const newList = poItemIdsList.filter(function(o1){
        //   return selectedRows.some(function(o2){
        //     return (o1.poItemId === o2.poItemId)
        //   })
        // })
        // const newList = poItemIdsList.filter((item) => item.poItemId !== event?.data?.poItemId)
        setPoItemIdsList((item) => item.filter((item) => item.poItemId !== event?.data?.poItemId)
        )
        
         // setOrderRows(row => (row.filter((item) => item.poItemId !== event?.data?.poItemId)))
      } 
    }
  }

  useEffect(() => {
    //Maintain the selected rows
    if(gridRef?.current?.api){
      waitForRowSelect = true;
      gridRef.current.api.deselectAll();

      gridRef.current.api.forEachNode((node) => {
        node.setSelected(!!node.data && poItemIdsList.map(item => item.poItemId).indexOf(node.data.poItemId)>-1)
      });
    }
  }, [rowData])

  return (
    <Box style={styles.gridContainer}>
      <Loader open={loading}/>
      {/* <OrderButton style={{fontSize:'10px'}}>Add to Selection</OrderButton>
      <OrderButton onClick={() =>{navigate('/testpage',{state:{rows:orderRows}})}} className={orderRows.length>0?'':'disabled'} style={{fontSize:'10px',marginLeft:'10px'}}> View Selection</OrderButton> */}
      {/* <img onClick={() => { }} src={logo} style={styles.excelicon} alt={"Export Excel"} /> */}
      {/* Commenting to quick fix export by saga - if count < 3000, selected records to be exported */}

      {/* <Provider store={store}><SimpleExportOrder request={req} orderRows={orderRows} totalCount={getTotalPages.totalElements} /></Provider> */}
      <ExportExcel exportFor="OrderList" request={req} orderRows={poItemIdsList.sort((a, b) => a.poItemId - b.poItemId)} totalCount={getTotalPages.totalElements} />
      <p onClick={handleOpenDownloadBulk} style={{position:'absolute',top:'90px',right:'20px',color:'#044BD9',textDecoration:'underline',cursor:'pointer',fontSize:'14px'}}>Download Bulk</p>
      <SimpleSnackbar setOpen={setOpen} open={open}  message={alertMessage} messagetype={alertType} />
      <Box className={"ag-theme-alpine"} sx={{height:'365px'}}>
        <StyledAgGridReact
          rowSelection='multiple'
          columnDefs={columnDef}
          rowData={rowData}
          onCellClicked={handleCellClick}
          onSelectionChanged={onSelectionChanged}
          enableCellTextSelection={true}
          ref={gridRef}
          pagination={true}
          paginationPageSize={GRID_FETCH_SIZE}
          onGridReady={onGridReady}
          cacheBlockSize={GRID_FETCH_SIZE}
          suppressRowClickSelection={true}
          suppressPaginationPanel={true}
          overlayLoadingTemplate={`<div class="loader"></div>`}
          onRowSelected={onRowSelected}
          
          // overlayNoRowsTemplate={`<span>No records to show</span>`}
        />
         <Modal
          open={openArticleDetails}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.popupStyle1}>
            <ArticleDetails close={handleClose} articleDetails={articleDetails}/>
          </Box>
        </Modal>
        <Modal
          open={openBulkAction}
          onClose={handleCloseBulkAction}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.popupStyle2}>
            <BulkAction close={handleCloseBulkAction} fileName={fileName} setFileName={setFileName} selectedData={selectedData} setSelectedData={setSelectedData} formData={formData} setFormData={setFormData} />
          </Box>
        </Modal>
        <Modal
          open={openDownloadBulk}
          onClose={handleCloseDownloadBulk}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={styles.popupStyle3}>
              <Box sx ={{display:'flex',alignItems:'center'}}>
                 <Typography sx={{marginTop:'20px',marginLeft:'10px'}} id="modal-modal-title" variant="h6" component="h2">
                  Download Bulk </Typography>
                  <Tooltip placement="right"sx={{marginTop:'20px',marginLeft:'10px'}} title="To Perform Bulk Action"><InfoIcon sx={{fontSize:'16px',marginTop:'20px',cursor:'pointer',marginLeft:'10px'}}/></Tooltip>
                  </Box>
              <Box sx={{margin:'5%',width:'90%',height:"60%",display:'flex',alignItems:'center',justifyContent:'center',border:'1px solid black',borderRadius:'5px',marginBottom:'0px'}}>
              <FormControl>
                <RadioGroup
                  data-testid="chkBox-search-type"
                  column="true"
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={downloadType}
                  onChange={(e) => {setDownloadType(e.target.value)}}
                 >
                  <FormControlLabel data-testid="ctrl-regular" value="Latest" control={<Radio />} label="Download Latest 1000 Records" />
                  <FormControlLabel data-testid="ctrl-range" value="Selected" control={<Radio />} label="Download Selected Purchase Order Data from Order List page " />
                </RadioGroup>
              </FormControl>
              </Box>
             
              <OrderButton onClick={handleCloseDownloadBulk} style={{marginLeft:'28%',marginTop:'10px'}}>
                Cancel
              </OrderButton>
              <OrderButton onClick={convertJsonToCSV} style={{marginTop:'10px'}} >
                Download
              </OrderButton> 
          </Box>
        </Modal>
        <Box style={styles.alignBottom}>
          <GridPagination
            pageClick={handleGridPagination}
            gridTotal={getTotalPages}
            totalSelection={poItemIdsList.length}
            currentPage={currentPage}
            pageSize={GRID_FETCH_SIZE}
            gotoKeyDown={gotoKeyDown} />
          <Buttons
            status={status}
            handleCancel={handleCancel}
            handleSent={handleSent}
            poNum={poNum}
            handleLock={handleLock}
            handleUnlock={handleUnlock}
            handlePrepare={handlePrepare}
            poItemIdsList={poItemIdsList}
            convertJsonToCSV={convertJsonToCSV}
            handlePrepareSend={handlePrepareSend}
            handleBulkNavigate={handleBulkNavigate}
            rowData={(!Utils.isEmpty(list.order) && !Utils.isEmpty(list.order?.data)) ? list.order?.data[0].content : []}/>
        </Box>
      </Box>
    </Box>
  );
}
