import {LABEL_USAGE_TYPE_GET , LABEL_USAGE_TYPE_SUCCESS,LABEL_USAGE_TYPE_FAILURE } from './actions';

const initialState = {
    order: {}
}

export function LabelUsageTypeReducer(state = initialState, action) {
    
    if(action.type === LABEL_USAGE_TYPE_GET) {
        return state;
    }
    if(action.type === LABEL_USAGE_TYPE_SUCCESS) {
        return {
            ...state,
            order: {
                isLoaded: true,
                data: action.payload,
                message: 'Success'
            }
        }
    }
    if(action.type === LABEL_USAGE_TYPE_FAILURE) {
        return {
            ...state,
            order: {
                data: [],
                message: action.payload
            }
        }
    }
    return state;
}

