import { ORDER_LIST_GET, ORDER_LIST_SUCCESS, ORDER_LIST_FAILURE,LABEL_LIST_GET, LABEL_LIST_SUCCESS, LABEL_LIST_FAILURE} from './actions';

const initialState = {
    order: {},
    request:{},
    searchColumns:[]
}

export function OrderReducer(state = initialState, action) {
 
    if(action.type === ORDER_LIST_GET) {
        return state;
    }
    if(action.type === ORDER_LIST_SUCCESS) {
        return {
            ...state,
            order: {
                isLoaded: true,
                data: action.payload.res,
                message: 'Success'
            },
            request:action.payload.request,
            searchColumns:action.payload.searchColumns
        }
    }
    if(action.type === ORDER_LIST_FAILURE) {
        return {
            ...state,
            order: {
                data: [],
                isError: true,
                message: action.payload.message
            },
            request:action.payload.request,
            searchColumns:action.payload.searchColumns
        }
    }
    return state;
}

export function LabelReducer(state = initialState, action) {
    if(action.type === LABEL_LIST_GET) {
        return state;
    }
    if(action.type === LABEL_LIST_SUCCESS) {
        return {
            ...state,
            order: {
                isLoaded: true,
                data: action.payload.res,
                message: 'Success'
            },
            
        }
    }
    if(action.type === LABEL_LIST_FAILURE) {
        return {
            ...state,
            order: {
                data: [],
                message: action.payload
            }
        }
    }
    return state;
}