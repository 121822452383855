import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useCallback,
  useRef,
  useState,
} from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Box, IconButton } from '@mui/material';
import { styles, StyledAgGridReact, RefreshButton } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import SimpleSnackbar from '../../../customWidgets/Snackbar';
import Loader from '../../../customWidgets/Loader';
import { useDispatch, useSelector } from 'react-redux';
//import { getlabelSizeInfoListAction } from './Store/actions';
import { OrderButton } from '../../PoCardTable/styles';
//import GridPagination from '../../../customWidgets/GridPagination/index';
import Utils from "../../../utils/Utils";
//import * as ORDER from '../constant';
import { Typography } from '@mui/material';
import { searchPOInformation } from '../Store/actions';
import { useSearchParams } from 'react-router-dom';
import { updateLabelSize } from '../../../services/Gateway/RestAPIHandler';
const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';
const NumericEditor = memo(
  forwardRef((props, ref) => {
    const createInitialState = () => {
      let startValue;
      let highlightAllOnFocus = true;
      if (props.eventKey === KEY_BACKSPACE || props.eventKey === KEY_DELETE) {
        startValue = '';
      } 
      else if (props.charPress) {
        startValue = props.charPress;
        highlightAllOnFocus = false;
      } else {
        startValue = props.value;
        if (props.eventKey === KEY_F2) {
          highlightAllOnFocus = false;
        }
      }

      return {
        value: startValue,
        highlightAllOnFocus,
      };
    };

    const initialState = createInitialState();
    const [value, setValue] = useState(initialState.value);
    const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(
      initialState.highlightAllOnFocus
    );
    const refInput = useRef(null);
    useEffect(() => {
      const eInput = refInput.current;
      eInput.focus();
      if (highlightAllOnFocus) {
        eInput.select();

        setHighlightAllOnFocus(false);
      } else {
        const length = eInput.value ? eInput.value.length : 0;
        if (length > 0 && length <=10) {
          eInput.setSelectionRange(length, length);
        }
       
      }
    }, []);

   
    const cancelBeforeStart =
      props.charPress && '1234567890'.indexOf(props.charPress) < 0;

    const isLeftOrRight = (event) => {
      return ['ArrowLeft', 'ArrowLeft'].indexOf(event.key) > -1;
    };

    const isCharNumeric = (charStr) => {
      return !!/\d/.test(charStr);
    };

    const isKeyPressedNumeric = (event) => {
      const charStr = event.key;
      return isCharNumeric(charStr);
    };

    const deleteOrBackspace = (event) => {
      return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
    };

    const finishedEditingPressed = (event) => {
      const key = event.key;
      return key === KEY_ENTER || key === KEY_TAB;
    };

    const onKeyDown = (event) => {

      if (isLeftOrRight(event) || deleteOrBackspace(event)) {
        event.stopPropagation();
        return;
      }
      if (!finishedEditingPressed(event) && !isKeyPressedNumeric(event)) {
        if (event.preventDefault) event.preventDefault();
      }
      if(event.ctrlKey && (event.key === 'c' || event.key === 'C' )){
        navigator.clipboard.writeText(value)
      }
      // if(event.ctrlKey && (event.key === 'v' || event.key === 'V' )){
      //   // setValue(navigator.clipboard.readText())
      // }
      if (finishedEditingPressed(event)) {
        props.stopEditing();
      }
    };
  
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },

        isCancelBeforeStart() {
          return cancelBeforeStart;
        },

        
        isCancelAfterEnd() {
          return value <0 || value>9999999999;
        },
      };
    });

    return (
      <input
        //onPaste={(event) => handlePaste(event.target.value)}
        ref={refInput}
        value={value}
        onChange={(event) => setValue(event.target.value.replace(/[^\d*,]/g,''))}
        onKeyDown={(event) => onKeyDown(event)}
        style={{ width: '100%',height:'100%' }}
        maxLength={10}
        />
    );
  })
);

function LabelSizeInfo({ request, poItem, status, close }) {
  /* Constant variables declaration */

  const requestLength = request.length;
  const [open, setOpen] = useState(false);
  const [alertType,setAlertType] = useState("success");
  const [alertMessage,setAlertMessage] = useState("");
  const [loading,setLoading] = useState(false)
  const [disabled,setDisabled] = useState(true)
  //const GRID_FETCH_SIZE = 7;
  //const totalGridPages = 1;
  const dispatch = useDispatch();
  const searchParams = useSearchParams()[0];
  // var LabelSizeData = [];
  const [labelSizeData,setLabelSizeData] = useState([])
  const checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  /* Grid declaration */
  const columnDef = [
    { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 20,hide:true },
    { field: 'poItemId', headerName: 'PO Item', width: 180,valueFormatter: function (params) { return searchParams.get("PONo")},resizable:true  },
    { field: 'labelSequence', headerName: 'Sequence', width: 160,resizable:true },
    { field: 'labelCode', headerName: 'Label Code', width: 165,resizable:true },
    { field: 'customerSize', headerName: 'Customer Size', width: 165,resizable:true },
    {
      field: 'labelPrintQuantity', headerName: 'Label Print Quantity', width: 180, editable: function (params) {
        return params.data.editable
      },resizable:true,cellStyle:function(params){if(params.data.editable){
        return {
          cursor:'text',
          border:'1px solid rgba(0,0,0,0.3)'
        }
      }},
      cellEditor: NumericEditor,
     
    },
    { field: 'technicalIndex', headerName: 'Technical Index', width: 180,resizable:true },
    { field: 'orderQuantity', headerName: 'Order Quantity', width: 180,resizable:true },
    { field: 'overagePercentage', headerName: 'Overage Percentage', width: 180,hide:true },
    { field: 'orderLabelId', headerName: 'Order Label ID', width: 180,hide:true },
  ];

  /* Component state */
  // const [currentPage, setCurrentPage] = React.useState(1);
  const [newData, setNewData] = React.useState([])

  /* Component method */

  const onCellEditingStopped = useCallback((event) => {
    const selectedRows = event.api.getSelectedRows();
    if(selectedRows[0].labelPrintQuantity<0){
      setAlertMessage("Label Print Quantity can't be negative")
      setAlertType('error')
      setOpen(true)
    }
    else{
      setNewData([...newData, selectedRows[0]])
      setDisabled(false)
    }
  }, [newData]);
  const saveFunction = (event) => {
    setLoading(true)
    const newArray = newData.map(({ id, labelPrintQuantity, labelSequence, labelCode, orderQuantity,overagePercentage,orderLabelId }) => ({ id, labelPrintQuantity, labelSequence, labelCode, orderQuantity,overagePercentage,orderLabelId}))
  //   const checkPrintQuality = (ele) => (parseInt(ele.labelPrintQuantity) === 0 && ) ;
  //   const validateLabelPrintQuality = newArray.every(checkPrintQuality);
  //  if(validateLabelPrintQuality){
  //   setAlertMessage("Order Label Quantity can't be changed to zero");
  //   setAlertType('error');
  //   setOpen(true);
  //   setLoading(false)
  //  }
  //  else 
  //  {
    updateLabelSize(
      {
        POItem: poItem, 
        searchObjects: {
          "orderLabelSizes": newArray
        }
      })
      .then(([response, json]) => {
        if(response.status===200 && json.status!=='ERROR')
        {
          setAlertMessage("Order Label Quantity Updated")
          setAlertType('success')
          setOpen(true)
          dispatch(searchPOInformation({
            "POItem": `${searchParams.get("PONo")}`,
          }))
          setLoading(false)
          close();
        }
        else{
          setAlertMessage(json.errorMessage)
          setAlertType('error')
          setOpen(true)
          setLoading(false)
        }
      }
      )}
  
  const refreshFunction = () => {
    setLoading(true)
     dispatch(searchPOInformation({
        "POItem": `${searchParams.get("PONo")}`,
      }))
      setLabelSizeData([])
      setDisabled(true)

  }
  const closeButtonFunction = (event) => {
    refreshFunction()
    close();
  }

  const list = useSelector(state => state.orderList);
  
 useEffect(() => {
  if (requestLength) {
    const new_list = !Utils.isEmpty(list.order) ? list.order.data[0].orderLabelSize : []
    const new_labels = new_list.filter(function(o1){
      return request.some(function(o2){
        return (o1.labelCode === o2.labelCode && o1.labelSequence === o2.labelSequence)
      })
    })
    setLabelSizeData(new_labels)
    setLoading(false)
  }
  else {
    setLabelSizeData(!Utils.isEmpty(list.order) ? list.order.data[0].orderLabelSize : [])
    setLoading(false)
  }
 
 },[list.order])//eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box style={styles.gridContainer}>
      <Loader open={loading}/>
      <SimpleSnackbar setOpen={setOpen} open={open}  message={alertMessage} messagetype={alertType} />
      <IconButton id="close-modal-popup" data-testid="close-modal-popup" onClick={closeButtonFunction} style={styles.closeButton}>
          <CloseIcon />
      </IconButton>
      <Typography component='div' style={styles.title} >
        <Typography component='span' style={styles.label1} >
          Label Size Information
        </Typography>
      </Typography>
      <Box className={"ag-theme-alpine"}
        sx={{ height: '400px', width: '100%' }}>
        <StyledAgGridReact
          rowSelection='multiple'
          rowData={labelSizeData}
          columnDefs={columnDef}
          stopEditingWhenCellsLoseFocus
          singleClickEdit={true}
          onCellEditingStopped={onCellEditingStopped}
        />
      </Box>

      <Box style={styles.alignBottom}>
        <RefreshButton onClick={refreshFunction}>REFRESH</RefreshButton>
        <OrderButton className={disabled?'disabled':''} onClick={saveFunction}>SAVE</OrderButton>
      </Box>

    </Box>
  )
}
export default LabelSizeInfo;
