import { labelMainTypeList } from '../../../../services/Gateway/RestAPIHandler';

 
 
 export const LABEL_MAIN_TYPE_GET ="Get Label Main Type";
 export const LABEL_MAIN_TYPE_SUCCESS = "Label Main Type Sucess";
 export const LABEL_MAIN_TYPE_FAILURE = "Label Main Type Failure";
 export function getLabelMainTypeAction(params) {
    return (dispatch) => {
        dispatch(getLabelMainType());
        return labelMainTypeList(params) 
            .then(([response, json]) => {
                if(response.status === 200){
                    dispatch(getLabelMainTypeSuccess(json.results));
                }
                else {
                    dispatch(getLabelMainTypeFailure("Unable to fetch label main type data; Error code - " + json.errorCode));
                }
            })
    }
}

export function getLabelMainType() {
    return {
        type: LABEL_MAIN_TYPE_GET
    }
}

export function getLabelMainTypeSuccess(data) {
    return {
        type: LABEL_MAIN_TYPE_SUCCESS,
        payload: data

    }

}

export function getLabelMainTypeFailure(message) {
    return {
        type: LABEL_MAIN_TYPE_FAILURE,
        payload: message
    }
}