import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import logo from '../../../assets/reebok_logo.png';
import { LoginStyle } from "../styles";

export default function RedirectPage(props) {
    const navigate = useNavigate();

    useEffect(() => {
        executeRedirect();
    },[])

    const executeRedirect = () => {
        const path = window.location.href;
        const values = new URLSearchParams(path);

        window.localStorage.setItem("access_token", values.get('access_token'));
        window.localStorage.setItem("expires_in", values.get('expires_in'));
        
        navigate('/');
        window.location.reload();
    }
    
    return (
        <Card sx={{ width: "345px",height:'400px',position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',borderRadius:'5px' }}>
          <CardMedia
            component="img"
            sx={LoginStyle.cardMedia}
            image= {logo}
            alt="Reebok_logo"
          />
          <CardContent sx={{height:'60%',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Typography component='div' className={'spinner'} />
          </CardContent>
        </Card>
    )
}