import React from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined'
import StarOutlinedIcon from '@mui/icons-material/StarOutlineOutlined'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const list = [{
  name: 'Dashboard',
  children: [],
  icon: <HomeOutlinedIcon />,
  link: '/',
  useraccess:['A01','A02','A03']
},
{
  name: 'Orders',
  children: [{
    name: 'Order List',
    children: [],
    link: '/Order_List',
    useraccess:['A01','A02','A03']
  },
  // {
  //   name: 'Order List Bulk ',
  //   children: [],
  //   link: '/orders/Bulk_Actions',
  //   useraccess:['A01','A02','A03']

  // },
  {
    name: 'View Bulk Actions ',
    children: [],
    link: '/orders/View_Bulk_Actions',
    useraccess:['A01','A02','A03']

  }
],
  icon: <RepeatOutlinedIcon />,
  link: '/orders',
  useraccess:['A01','A02','A03']
},
{
  name: 'Master Data',
  children: [
  //   {
  //   name: 'Label Main Type',
  //   children: [],
  //   link: '/Label/label_Main_Type'
  // }, 
  //   {
  //   name: 'Label Usage Type',
  //   children: [],
  //   link: '/label/Label_Usage_Type'
  // }, 
  {
    name: 'Label List',
    children: [],
    link: '/Label_List',
    useraccess:['A01','A02','A03']
  }, {
    name: 'Label Assignment',
    children: [],
    link: '/Label_Assignment',
    useraccess:['A01']
  },
   {
    name: 'Customer List ',
    children: [],
    link: '/Customer_List ',
    useraccess:['A01']
  }, 
  {
    name: 'Factory List',
    children: [],
    link: '/Factory_List',
    useraccess:['A01','A03']
  },
  {
    name: 'Article Master ',
    children: [],
    link: '/Article_master ',
    useraccess:['A01']
  },
  {
    name: 'Inventory Report',
    children: [],
    link: '/Inventory_List',
    useraccess:['A01','A02','A03']
  },
  {
    name: 'Approval List',
    children: [],
    link: '/Approval_List',
    useraccess:['A01']
  } ],
  icon: <StarOutlinedIcon />,
  link: '/',
  useraccess:['A01','A02','A03']
},
{
  name: 'Reference Data',
  children: [
     {
        name: 'Product Group',
        children: [],
        link: '/Product_Group',
        useraccess:['A01']
      }, {
        name: 'Product Type',
        children: [],
        link: '/Product_Type',
        useraccess:['A01']
      },{
        name: 'Sports Category',
        children: [],
        link: '/Sports_Category',
        useraccess:['A01']
      },{
        name: 'Country Size Lookup',
        children: [],
        link: '/Country_Size',
        useraccess:['A01','A02','A03']
      }],
  icon: <InsertDriveFileIcon />,
  link: '/',
  useraccess:['A01','A02','A03']
},
{
  name: 'Users Management',
  children: [{ name: 'User List', children: [], link: '/UserList',useraccess:['A01'] }, { name: 'User Groups', children: [], link: '/UserGroup',useraccess:['A01'] }, { name: 'User Order Filter', children: [], link: '/UserOrder',useraccess:['A01','A03'] }],
  link: '/',
  icon: <AccountCircleIcon />,
  useraccess:['A01','A03']
}
/*
{
  name: 'Test Page',
  children: [],
  link: '/testpage',
  icon: <AccountCircleIcon />,
  useraccess:['A01','A03']
}*/]

export default list