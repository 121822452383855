import React from 'react'
import * as XLSX from 'xlsx'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Box, Typography,IconButton } from '@mui/material';
import { styles, StyledAgGridReact } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import logo from '../../../assets/excelicon.png';
import GridPagination from '../../../customWidgets/GridPagination';
import { getOrderHistoryAction } from './Store/actions';
import {apiFetchAllHistory} from '../../../services/Gateway/RestAPIHandler'
import Loader from '../../../customWidgets/Loader';
import Utils from '../../../utils/Utils';

function ViewHistory({setAlertType,setAlertMessage,setOpen,close}) {
  const dispatch = useDispatch();
  const searchParams = useSearchParams()[0];
  const exportToExcel = () => {
    apiFetchAllHistory(
      searchParams.get("PONo")
    ).then(([response, json])=> {
      const data =json.results[0];
      if(data===null||response.status!==200){
        setAlertType('error')
        setAlertMessage('No data to download')
        setOpen(true)
      }
      else{
        const newData = data.map(ele => {
          return {
            "PO No" : ele.poNumber,
            "Label Code": ele.labelCode,
            "Action":ele.action,
            "Old Status": ele.oldStatus,
            "New Status":ele.newStatus,
            "Action Description":ele.actionDescription,
            "Time":ele.createdDate,
            "Error Description":ele.error,
            "User":ele.createdBy
          }
        })
        
        const worksheet = XLSX.utils.json_to_sheet(newData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
        XLSX.writeFile(workbook, `${searchParams.get("PONo")}_Order_History.xlsx`)
      }
    })
    
   
  }

  const columnDef = [
    { field: 'poNumber', headerName: 'PO No', width: 150,resizable:true },
    { field: 'labelCode', headerName: 'Label Code', width: 200,resizable:true },
    { field: 'action', headerName: 'Action', width: 200,resizable:true  },
    { field: 'oldStatus', headerName: 'Old State', width: 150,resizable:true  },
    { field: 'newStatus', headerName: 'New State', width: 150,resizable:true  },
    { field: 'actionDescription', headerName: 'Action Description', width: 300,resizable:true  },
    { field: 'createdDate', headerName: 'Time', width: 180,resizable:true },
    { field: 'error', headerName: 'Error Description', width: 500 ,resizable:true, wrapText: true, autoHeight: true },
    { field: 'createdBy', headerName: 'User', width: 250,resizable:true },
  ];
  
  const gridRef = React.useRef();
  const GRID_FETCH_SIZE = 7;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading,setLoading] = React.useState(true);
  const list = useSelector(state => state.OrderHistoryList);

  React.useEffect(() => {
    setLoading(true);
    dispatch(getOrderHistoryAction({ orderNumber:`${searchParams.get("PONo")}`,fetchSize: GRID_FETCH_SIZE, page: currentPage}));
    setLoading(false);
  },[])//eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if(list.order?.isError) {
      setAlertType('error')
      setAlertMessage(list.order?.message)
      setOpen(true)
    }
  }, [list.order])

 const getTotalPages = React.useMemo(() => {
  if(!Utils.isEmpty(list?.order?.data)) {
    return {
      totalPages: list?.order?.data[0]?.totalPages,
      totalElements: list?.order?.data[0]?.totalItems
    };
  }
  else
    return {
      totalPages: 1,
      totalElements: 1
    };
  },[list.order]);

  const gotoKeyDown = (event, value) => {
    if (event.keyCode === 13 && parseInt(value) <= getTotalPages.totalPages) {
      setCurrentPage(parseInt(value));
      dispatch(getOrderHistoryAction({ orderNumber:`${searchParams.get("PONo")}`,fetchSize: GRID_FETCH_SIZE, page: parseInt(value) }));
    }
  }

  const handleGridPagination = (event, thisPage) => {
    setLoading(true);
    setCurrentPage(thisPage);
    dispatch(getOrderHistoryAction({ orderNumber:`${searchParams.get("PONo")}`,fetchSize: GRID_FETCH_SIZE, page: thisPage }));
    setLoading(false);
  }


  return (
    <Box style={styles.gridContainer}>
      <Loader open ={loading}/>
      <Typography component='div' style={styles.title}>
        <Typography component='span' style={styles.label1}>Order History</Typography>
        <Box component="img" onClick={() => { exportToExcel() }} src={logo} sx={{ marginLeft: '18px', cursor: 'pointer', width: '19px', height: '18px', opacity: 1 }} alt={"Export Excel"} />
        <IconButton id="close-modal-popup" data-testid="close-modal-popup" onClick={close} style={styles.closeButton}>
          <CloseIcon />
        </IconButton>
      </Typography>
      
    
      <Box className={"ag-theme-alpine"} sx={{ height: '370px', width: '100%' }}>
        <StyledAgGridReact
          rowSelection='multiple'
          rowData={ !Utils.isEmpty(list?.order?.data) ? list.order.data[0].transactionStatusLogs === null ? [] : list.order.data[0].transactionStatusLogs : []}
          columnDefs={columnDef}
          ref={gridRef}
          pagination={true}
          paginationPageSize={GRID_FETCH_SIZE}
          cacheBlockSize={GRID_FETCH_SIZE}
          suppressPaginationPanel={true}
        />
      </Box>
      <Box style={styles.alignBottom}>
        <GridPagination
          pageClick={handleGridPagination}
          gridTotal={getTotalPages}
          currentPage={currentPage}
          pageSize={7}
          gotoKeyDown={gotoKeyDown} />
      </Box>
    </Box>
  )
}
export default ViewHistory;