class Utils {
  
  


  /*
    Function checks for empty or null or undefined for string and Array
    @return - value
    */
  static isEmpty(val) {
    return (
      val === null ||
      val === undefined ||
      val === "" ||
      (Array.isArray(val) && val.length === 0) ||
      (Object.keys(val).length === 0 && val.constructor === Object)
    )
  }

  /**
   * To get device width based on user media usage
   * @returns
   * @memberOf Utils
   */
  static getDeviceWidth() {
    return 0 // Need to Make calculations
  }

  static isObjectEmpty(val) {
    return Object.keys(val).length === 0 && val.constructor === Object // Need to add
  }

  /**
   * To convert single array to object. Basically to remove square brackets
   * @param {any} arr and key
   * @returns
   * @memberOf Utils
   */
  static arrayToObj(arr, key) {
    const obj = Object.assign({}, ...arr)
    return !Utils.isEmpty(obj) ? obj[key] : null
  }

  /**
   * To merge two arrays into single array
   * @param {any} arr1
   * @param {any} arr2
   * @param {any} _primaryId
   * @returns
   * @memberOf Utils
   */
  static async mergeTwoArrays(arr1, arr2) {
    return Array.prototype.push.apply(arr1, arr2)
  }

  /**
   * To remove duplicate entries in array with reference primarykey
   * @param {any} mergedArray
   * @param {any} primaryId
   * @returns
   * @memberOf Utils
   */
  static removeDuplicates(mergedArray, primaryId) {
    return Object.values(
      mergedArray.reduce(
        (acc, cur) => Object.assign(acc, { [primaryId.id]: cur }),
        {}
      )
    )
  }

  /**
   * To convert string to local
   * @param {any} str
   * @returns
   * @memberOf Utils
   */
  static toCamelCase(str) {
    return str
      .replace(/\s(.)/g, $1 => $1.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, $1 => $1.toLowerCase())
  }

  /**
   * To capitalizse first letter in string
   * @param {any} string
   * @returns
   * @memberOf Utils
   */
  static capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase()
  }

  /**
   * To capitalizse first letter in string and remove special characters
   * @param {any} string
   * @returns
   * @memberOf Utils
   */
  static toTitleCase(string) {
    return string.replace(/\b\w+/g ,(s) => {
      return (s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()).replace(/_+/g,' ')
    })
  }

  /**
   * To form query string for hitting api
   * @param {any} url
   * @param {any} key
   * @param {any} value
   * @returns
   * @memberOf Utils
   */
  static formQueryString(url, key, value) {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i")
    const uri = url?.toString()
    const separator = uri?.indexOf("?") !== -1 ? "&" : "?"
    if (uri?.match(re)) {
      return uri
    }
    return `${uri + separator + key}=${value}`
  }

  /**
   * Redirect the application to the new page using window open
   * @param {any} string
   * @returns
   * @memberOf Utils
   */
  static pageRedirection(url) {
    if (!this.isEmpty(url)) {
      window.open(url)
    }
  }

  static encodeUri(data) {
    if (!this.isEmpty(data)) {
      return encodeURIComponent(data)
    }
    return undefined
  }

  static formatLng(lng) {
    if (lng && lng.includes("-")) {
      const temp = lng.split("-")
      temp[1] = temp[1].toUpperCase()
      return temp.join("")
    }
    return lng
  }

  static getUniqueKey() {
    return Math.random().toString(36).substring(2,9);
  }

  static getStorageValue(key) {
    if(!this.isEmpty(window.localStorage.getItem(key))) {
      return window.localStorage.getItem(key);
    }
    return null;
  }

}

export default Utils