import { apiUserList } from '../../../../services/Gateway/RestAPIHandler';

 
 
 export const USERLIST_GET ="Get User Groups";
 export const USERLIST_SUCCESS = "User Groups Sucess";
 export const USERLIST_FAILURE = "User Groups Failure";
 export function getUserListAction(params) {
    return (dispatch) => {
        dispatch(getUserList());
        return apiUserList(params) 
            .then(([response, json]) => {
                if(response.status === 200){ 
                    dispatch(getUserListSuccess(json.results));
                }
                else {
                    dispatch(getUserListFailure("Unable to fetch User Groups data; Error code - " + json.errorCode));
                }
            })
    }
}

export function getUserList() {
    return {
        type: USERLIST_GET
    }
}

export function getUserListSuccess(data) {
    return {
        type: USERLIST_SUCCESS,
        payload: data

    }

}

export function getUserListFailure(message) {
    return {
        type: USERLIST_FAILURE,
        payload: message
    }
}