import React, { useEffect } from 'react';
import {Breadcrumbs as MUIBreadcrumbs, Typography}from '@mui/material/';
import {useLocation,Link as RouterLink} from 'react-router-dom';
import Utils from "../../utils/Utils";

export default function Breadcrumbs(){
  let location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x)
  
  const approve = new URLSearchParams(location.search).get("ApprovalList");
  if(approve==="Y"){
    pathnames.splice(0,pathnames.length);
    pathnames.push("Approvals");
    pathnames.push("Order_Label_Details")
  }
  const toHome = () => {
    if(pathnames[pathnames.length-2]==="Approvals"){
      return '/Approval_List'
    }
    if(pathnames[pathnames.length-1]==="Order_Label_Details" || pathnames[pathnames.length-1]==="View_Bulk_Actions"){
      return '/Order_List'
    }
    if(pathnames[pathnames.length-1]==="LabelAdmin"){
      return '/Label_List'
    }
    
    else{
      return '/'
    }
  }
  useEffect(() => {
    if(!Utils.isEmpty(pathnames)){
      let last = pathnames[pathnames.length - 1];
      document.title = "Labelling Suite | " + Utils.toTitleCase(last);
    }
    else {
      document.title = "Labelling Suite"
    }
  },[pathnames])
 
  return(
    <MUIBreadcrumbs data-testid="breadcrumb" aria-label='Breadcrumb'>
      {pathnames.map((value,index)=>{
        const last = index === pathnames.length-1;
        const to = `${pathnames.slice(0,index + 1).join('/')}`;
        //const changeValue = (value === 'Order_Label_Use') ? 'Order_Label_Details' : value;
        
        return last ? (
          <Typography data-testid="breadcrumb-url-last" color='textPrimary' key={to}>
            { Utils.toTitleCase(value) }
          </Typography>):(
          <Typography data-testid="breadcrumb-url" color='inherit' component={RouterLink} to={toHome()} key={to}>
            { Utils.toTitleCase(value) }
          </Typography>
        )
      })}
      

    </MUIBreadcrumbs>

  )
}

