import { styled, } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import { Button } from "@mui/material";

export const StyledAgGridReact = styled(AgGridReact)({
    '.ag-header-cell-text':{
        color: '#99702C',
        fontFamily: 'Helvetica Neue,Roboto,sans-serif',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '18px',
    },
    '.ag-paging-page-summary-panel':{
        margin:0,
    }
})

export const RefreshButton = styled(Button)({
    zIndex: '10',
    cursor: 'pointer',
    borderRadius: '4px',
    marginRight: '30px',
    width: '120px',
    height: '40px',
    color:'#FFFFFF',
    border: '1px solid #293E53',
    fontFamily: 'Poppins',
    fontSize: '14px', 
    background: '#293E53 0% 0% no-repeat padding-box',
    opacity: '1',
    padding:0,
    
    '&:hover':{
        border: '1px solid #293E53',
        borderRadius: '4px',
        color: '#293E53',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '5px 5px 5px rgba(0,0,0,0.3)'
    },
    '&:disabled':{
        background: '#878787 0% 0% no-repeat padding-box',
        border: '1px solid #0000',
        opacity:1,
    }


})


export const styles = {
    buttons:{
        display: 'flex',
        marginTop:'10px'
    }
    ,
    alignBottom: {
        width: '100%',
        display: 'flex',
        justifyContent: 'end'
    },
    paddingleft: {
        paddingLeft: '845px',
    },
    label1 :{
        textAlign: 'left',
        font: 'normal normal normal 13px/18px Poppins ',
        fontWeight: '600 ',
        letterSpacing: '0px',
        color: 'rgb(0,0,0)',
        opacity: 1,
        width: '100px',
        height: '27px',
    },
    label2 :{
        marginLeft:'30px',
        textAlign: 'left',
        font: 'normal normal normal 13px/18px Poppins ',
        fontWeight: '400 ',
        letterSpacing: '0px',
        color: 'rgb(0,0,0)',
        opacity: 1,
        width: '100px',
        height: '27px',
    },


    labeldata:{
        marginBottom:'20px',
        fontSize:'14px',
        border:'1px solid rgba(0,0,0,0.3)',
        borderRadius:'5px',
        float: 'left',
        width:'100%',
        height: '390px',
        overflow:'scroll',

    },
    closeButton: {
        position: "absolute",
        top: '0px',
        right: '0px'
    },
    gridContainer: {
        float: 'left',
        width: '100%',
        height: '390px',
    },
    gridPagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '5px 0',
        columnGap: '7px',
        buttons: {
            width: '35px',
            height: '35px',
            border: '1px solid var(--GridPageButtonBorder)',
            borderRadius: '4px',
            cursor: 'pointer',
            textAlign: 'center',

            color: 'var(--GridPageNumberColor)',
            fontWeight: 'bold',
            fontSize: '12px',
            lineHeight: '21px',
            fontFamily: 'Poppins',
            pageButton: {
                padding: '6px 0'
            },

            icons: {
                width: 'fit-content',
                height: 'fit-content',
                color: 'var(--GridPageIconColor)'
            }
        },
        selectedButton: {
            width: '35px',
            height: '35px',
            border: '1px solid var(--GridPageButtonBorder)',
            borderRadius: '4px',
            cursor: 'pointer',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '12px',
            lineHeight: '21px',
            fontFamily: 'Poppins',
            pageButton: {
                padding: '6px 0'
            },
            color: 'var(--ButtonBGColor)',
            backgroundColor: 'var(--ButtonBorderColor)'
        }
    },
}
export const DownloadButton = styled(Button)({
    zIndex: '10',
    paddingBottom : '20px',
    paddingLeft : '150px',
    cursor: 'pointer',
    borderRadius: '4px',
    marginRight: '30px',
    height: '49px',
    width:'130px',
    color: '#FFFFFF',
    border: '1px solid #293E53',
    fontFamily: 'Poppins',
    fontSize: '14px',
    background: '#293E53 0% 0% no-repeat padding-box',
    opacity: '1',
    padding: 0,

    '&:hover': {
        border: '1px solid #293E53',
        borderRadius: '4px',
        color: '#293E53',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '5px 5px 5px rgba(0,0,0,0.3)'
    },
    '&.disabled': {
        background: '#878787 0% 0% no-repeat padding-box',
        border: '1px solid #0000',
        opacity: 1,
    }


})