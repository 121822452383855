import React from 'react'
import { Box } from '@mui/system';
import {styles,OrderButton} from './styles';
function Buttons(props) {

  const handleGridDelete = () => {
    props.handleGridDelete();
  }

  const handleGridAdd = () => {
    props.handleGridAdd();
  }

  const handleGridEdit = () => {
    props.handleGridEdit();
  }

  return (
    <Box style={styles.buttons}>
      <OrderButton className={props.labelIds.length>0?'':'disabled'} onClick={handleGridDelete}>DELETE</OrderButton>
      <OrderButton  onClick={handleGridAdd}>ADD</OrderButton>
      <OrderButton className={props.labelIds.length>0?'':'disabled'} onClick={handleGridEdit}>EDIT</OrderButton>
    </Box>
  )
}
export default Buttons
