import React from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Box, IconButton } from '@mui/material';
import { styles, StyledAgGridReact } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import {useSelector } from 'react-redux';
import { Typography } from '@mui/material';
// import * as ORDER from '../constant';
// import { getlabelSizeInfoListAction } from './Store/actions';
// import Utils from '../../../utils/Utils';

function OrderSizeInfo({ request, close }) {
  /* Constant variables declaration */
  // const dispatch = useDispatch();
  // const GRID_FETCH_SIZE = 7;
  var orderSizeData = [];
  const list = useSelector(state => state.orderList);
  const requestLength = request.length;

  /* Grid declaration */
  const columnDef = [
    // { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 20 },
    { field: 'orderNumber', headerName: 'PO No', width: 200,resizable:true },
    { field: 'technicalIndex', headerName: 'Technical Index', width: 200,resizable:true },
    { field: 'customerSize', headerName: 'Customer Size', width: 200,resizable:true },
    { field: 'quantityPerSize', headerName: 'Order Quantity', width: 150,resizable:true },
    { field: 'eanBarCode', headerName: 'Ean Barcode', width: 150,resizable:true },
    { field: 'upcBarCode', headerName: 'Upc Barcode', width: 150,resizable:true },
  ];
  
  if (requestLength) {
    orderSizeData = request;
  }
  else {
    orderSizeData = Object.keys(list.order).length > 0 ? list.order.data[0].poItem.poItemSizes : []
  }
  const closeButtonFunction = (event) =>{
    close();
  }
  
  return (
    <Box style={styles.gridContainer}>
      <IconButton id="close-modal-popup" data-testid="close-modal-popup" onClick={closeButtonFunction} style={styles.closeButton}>
        <CloseIcon />
      </IconButton>
      <Typography component='div' sx={styles.title}>
        <Typography component='span' style={styles.label1}>Order Size Information</Typography>
      </Typography>
      <Box className={"ag-theme-alpine"}
        sx={{ height: '400px', width: '100%' }}>
        <StyledAgGridReact
          rowSelection='multiple'
          rowData={orderSizeData}
          columnDefs={columnDef}
        />
      </Box>
   
    </Box>
  )
}
export default OrderSizeInfo;
