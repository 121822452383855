import { styled, } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import { Button } from "@mui/material";

export const StyledLabel = styled('label')({
    height:'50%',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#ffffff',
    '&.dragActive':{
        backgroundColor:'#f8fafc'
    }
})
export const StyledAgGridReact = styled(AgGridReact)({
    '.ag-header-cell-text':{
        color: '#99702C',
        fontFamily: 'Helvetica Neue,Roboto,sans-serif',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '18px',
    },
    '.ag-paging-page-summary-panel':{
        margin:0,
    }
})
export const OrderButton = styled(Button)({

    cursor: 'pointer',
    borderRadius: '4px',
    marginRight: '5px',
    marginLeft:'5px',
    width: '130px',
    height: '49px',
    color:'#FFFFFF',
    border: '1px solid #293E53',
    fontFamily: 'Poppins',
    fontSize: '14px', 
    background: '#293E53 0% 0% no-repeat padding-box',
    opacity: '1',
    padding:0,
    ['@media(max-width:1370px)']:{
        borderRadius: '4px',
        marginRight: '10px',
        marginLeft:'10px',
        width: '135px',
        height: '40px',
        fontSize: '12px',
        
    },
    '&:hover':{
        border: '1px solid #293E53',
        borderRadius: '4px',
        color: '#293E53',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '5px 5px 5px rgba(0,0,0,0.3)'
    },
    '&.disabled':{
        background: '#878787 0% 0% no-repeat padding-box',
        border: '1px solid #FFFFFF',
        opacity:1,
        pointerEvents: 'none'
    }


})
export const DownloadButton = styled(Button)({

    cursor: 'pointer',
    borderRadius: '4px',
    marginRight: '10px',
    width: '150px',
    height: '40px',
    color:'#FFFFFF',
    border: '1px solid #293E53',
    fontFamily: 'Poppins',
    fontSize: '10px', 
    background: '#293E53 0% 0% no-repeat padding-box',
    opacity: '1',
    padding:0,
    ['@media(max-width:1370px)']:{
        borderRadius: '4px',
        marginRight: '20px',
        width: 'fit-content',
        height: '40px',
        fontSize: '10px',
        padding:'5px 10px'
        
    },
    '&:hover':{
        border: '1px solid #293E53',
        borderRadius: '4px',
        color: '#293E53',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '5px 5px 5px rgba(0,0,0,0.3)'
    },
    '&.disabled':{
        background: '#878787 0% 0% no-repeat padding-box',
        border: '1px solid #FFFFFF',
        opacity:1,
        pointerEvents: 'none'
    }


})
export const styles = {
    divText:{
        margin:'1em 5px',
        textAlign:'center'
    },
    hortizontalLine:{
        display:'block',
        height:'1px',
        border:'0',
        borderTop:'2px dashed #cbd5e1',
        margin:'1em 0',
        padding:'0',
        width:'45%',
        
    },
    divHorizontalLine:{
        display:'flex',
        width:'100%',
        alignItems:'center',
        justifyContent:'center'
    },
    dragdiv:{
        position:'absolute',
        width:'100%',
        height:'100%',
        borderRadius:'5px',
        top:'0px',
        right:'0px',
        left:'0px',
        bottom:'0px'
    },
    formUpload:{
        height:'250px',
        backgroundColor:'#ffffff',
        width:'100%',
        textAlign:'center',
        position:'relative',
        borderWidth:'2px',
        borderRadius:'5px',
        borderStyle:'dashed',
        borderColor:'#cbd5e1',
    },
    inputContainer:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center'
    },
    gridContainer: {
        float: 'left',
        width: '100%',
        height: '200px',
    },
    closeButton: {
        position: "absolute",
        top: '0',
        right: '0'
    },
    excelicon:
        {position:'absolute',top:'105px',marginLeft:'200px', cursor: 'pointer', width: '19px', height: '18px', opacity: 1 }
    
}

export const StyledPopperDiv = styled('div')(
   
    {    padding: '0.5rem',
        border: '1px solid',
        backgroundColor:'#fff',
        opacity: 1,
        margin: '0.25rem 0px',
        position:'absolute',
        top:'70px',
        left:'600px',
        '@media(max-width:1370px)':{
          position:'absolute',
          top:'70px',
          left:'400px',
          
      },
      }
      
      )
export const Searchbtn = styled('button')({
    
    marginRight:'20px',
    width: '29px',
    height: "29px",
    background:'transparent',
    border: 'none',
    cursor: 'pointer',
    opacity: 1,
    '&:hover':{
      boxShadow: 'none',
    }
    

  })

  export const SearchInput = styled('input')({
    border: 'none',
    outline: 'none',
    top: '25px',
    left: '1040px',
    width: '134px',
    height: '20px',
    textAlign: 'left',
    marginRight:'40px',
    font: 'normal normal normal 14px/21px Poppins',
    letterSpacing: '0.1px',
    color: '#878787',
    opacity: 1,
    '&:disabled':{
      backgroundColor: '#FFFFFF'
  }
  })