import {USER_GROUPS_GET , USER_GROUPS_SUCCESS,USER_GROUPS_FAILURE } from './actions';

const initialState = {
    order:{}
}

export function UserGroupsReducer(state = initialState, action) {
    
    if(action.type === USER_GROUPS_GET) {
        return state;
    }
    if(action.type === USER_GROUPS_SUCCESS) {
        return {
            ...state,
            order: {
                isLoaded: true,
                data: action.payload,
                message: 'Success'
            }
        }
    }
    if(action.type === USER_GROUPS_FAILURE) {
        return {
            ...state,
            order: {
                data: [],
                message: action.payload
            }
        }
    }
    return state;
}

