import React, {useState, useEffect,useContext} from 'react';
import {StyledList,styles} from './styles'
import Search from '../Search/';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MUIMenu from '@mui/material/Menu';
import MUIMenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import logo from '../../assets/reebok_logo.png';
import MenuItem from './MenuItem';
import list from './list';
import AccessLevelContext from '../../../accessLevel-context';
import {DrawerHeader,AppBar,Drawer} from './styles'
import {store as ViewBulkActionStore} from '../../modules/Orders/VIewBulkAction/Store/store'
import {store as BulkOrderListStore} from '../../modules/BulkUpload/Store/store'
import {store as OrderListStore} from '../../modules/Orders/OrderList/Store/store'
import {store as InventoryListStore} from '../../modules/Orders/InventoryList/Store/store'
import {store as LabelAssignmentStore} from '../../modules/Label/LabelAssignment/Store/store'
import {store as CustomerStore} from '../../modules/Label/LabelCustomerList/Store/store';
import {store as FactoryStore} from '../../modules/Label/LabelFactoryList/Store/store';
import {store as LabelListStore} from '../../modules/Label/LabelAdmin/Store/store'
import {store as ArticleMasterStore} from '../../modules/ReferenceData/ArticleMaster/Store/store'
import {store as countrySizeStore} from '../../modules/ReferenceData/CountrySizeLookup/Store/store'
import {store as ApprovalListStore} from '../../modules/Orders/ApprovalList/Store/store'
import { Provider } from 'react-redux';
import {useLocation} from 'react-router-dom';
import envConfig from "../../../config/env/environment";
export default function SideMenu() {
  let location = useLocation();
  const accessLevel= useContext(AccessLevelContext)
  const [store,setStore] = useState(OrderListStore)
  const [open, setOpen] = useState(false);
  const [storeList,setStoreList] = useState('')
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openUserMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  useEffect(() => {
    if(location.pathname==='/Label_Assignment'){
      setStore(LabelAssignmentStore)
      setStoreList('labelAssignmentList')
    }
     if (location.pathname==='/Customer_List'){
      setStore(CustomerStore)
      setStoreList('labelCustomerList')
     }
     if (location.pathname==='/orders/View_Bulk_Actions'){
      setStore(ViewBulkActionStore)
      setStoreList('orderList')
     }

     if (location.pathname==='/Factory_List'){
      setStore(FactoryStore)
      setStoreList('labelFactoryList')
     }
  
    if(location.pathname==='/Order_List'){
      setStore(OrderListStore)
      setStoreList('orderList')
    }
    if(location.pathname==='/Inventory_List'){
      setStore(InventoryListStore)
      setStoreList('InventoryListStore')
    }
    if(location.pathname==='/Label_List'){
      setStore(LabelListStore)
      setStoreList('labelAdminList')
    }
    if(location.pathname==='/Article_master'){
      setStore(ArticleMasterStore)
      setStoreList('labelArticleMaster')
    }
    if(location.pathname==='/Country_Size'){
      setStore(countrySizeStore)
      setStoreList('countrySizeList')
    }
    if(location.pathname==='/Approval_List'){
      setStore(ApprovalListStore)
      setStoreList('approvalList')
    }

  },[location.pathname])

  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
 
  const handleLogout = () => {
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("expires_in");
    window.location.assign(envConfig.oneLogin.logout);
  }

  return (
    <>
      <AppBar position="fixed" open={open} className={ open ? "sidebar-open" : "sidebar-close" }>
        <Toolbar>
          <IconButton
            data-testid="menu-icon-button"
            color="inherit"
            aria-label="open drawer"
            onClick={open?handleDrawerClose:handleDrawerOpen}
            edge="start"
            sx={{
              color:'black',
              marginRight: 5,
            }}
          >
            {open?<CloseIcon data-testid="menu-close-icon" />:<MenuIcon data-testid="menu-open-icon" />}
          </IconButton>
          <Box  sx={{height:'44px',width:'92px'}}><img style={{height:'44px',width:'92px'}}src={logo} alt="Reebok" /></Box>
          <Typography variant='h3' style ={styles.header_title}>International Labelling Suite</Typography>
          <Provider store={store}><Search storeList={storeList}/></Provider>
          <Box style={styles.logout} onClick={handleClick}>
            {/* <Link data-testid="app-logout" style={styles.logout.link} onClick={handleLogout} >Logout</Link> */}
            <Avatar sx={{ width: 32, height: 32 }}>{accessLevel?.userInitial}</Avatar>
            </Box>
      <MUIMenu
        sx={{minWidth:'300px'}}
        anchorEl={anchorEl}
        id="account-menu"
        open={openUserMenu}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MUIMenuItem>
        {accessLevel?.userName}
        </MUIMenuItem>
        <Divider/>
        <MUIMenuItem onClick={handleLogout}>
         Logout
        </MUIMenuItem>
      </MUIMenu>
          
          

        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} >
        <DrawerHeader/>
     
        <Divider />
        <StyledList>
          {list.map((text, index) => <MenuItem item={text} arrowopen={open} key={Math.random().toString(36).substring(2,9)} index={index} handleDrawerOpen={handleDrawerOpen}/>)}
        </StyledList>
      </Drawer>
     </>
  );
}
