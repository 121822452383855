import React from 'react';
import logo from '../../assets/excelicon.png';
import { useEffect, useState, useContext } from 'react';
import Utils from '../../utils/Utils';
import Loader from '../Loader';
import SimpleSnackbar from '../Snackbar';
import { OrderListStyles, ArticleMasterStyles, OrderLabelListStyles } from './styles';
import { exportBulkData, resetExportData, dataToExcel } from './Store/action';
import { gContext } from '../../Store/store';

export default function ExportExcel(props) {

    const [loading,setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [alertType,setAlertType] = useState("success");
    const [alertMessage,setAlertMessage] = useState("");
    const excelList = useContext(gContext);
    
    const exportToExcel = () => {
        if(props?.orderRows.length === 0) {
            excelList.store.dispatch(resetExportData());

            try{
                setLoading(true);
                if(props.totalCount > 10000) {
                    setOpen(true);
                    setAlertType('warning');
                    setAlertMessage('We are exporting only latest 10000 records');
                }

           
                let param = Object.assign(props.request, { fetchSize: props.totalCount, pageNumber: 1, exportFor: props?.exportFor })
                excelList.store.dispatch(exportBulkData(param));

                setLoading(false);
            }
            catch(ex) {
                setOpen(true);
                setAlertType('error');
                setAlertMessage('Unable to download excel');

                setLoading(false);
            }
        }
        else {
            dataToExcel(props?.orderRows, props?.exportFor)
        }
    }

    useEffect(() => {
        if(Utils.isEmpty(props.exportFor)){
            setOpen(true);
            setAlertType('error');
            setAlertMessage('Unable to export/Incorrect data exported');
        }
    }, [props?.totalCount, props?.exportFor])//eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Loader open={loading}/>
            <SimpleSnackbar setOpen={setOpen} open={open}  message={alertMessage} messagetype={alertType} />
            <img onClick={() => { exportToExcel() }} src={logo} style={ (props.exportFor === 'ArticleMaster') ? ArticleMasterStyles : (props.exportFor === 'OrderLabelList' ||  props.exportFor === 'ApprovalList') ? OrderLabelListStyles : OrderListStyles } alt={"Export Excel"} />
        </>
    )
}
