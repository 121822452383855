import {SPORTS_CATEGORY_GET , SPORTS_CATEGORY_SUCCESS,SPORTS_CATEGORY_FAILURE } from './actions';

const initialState = {
    order:{}
}

export function SportsCategoryReducer(state = initialState, action) {
    
    if(action.type === SPORTS_CATEGORY_GET) {
        return state;
    }
    if(action.type === SPORTS_CATEGORY_SUCCESS) {
        return {
            ...state,
            order: {
                isLoaded: true,
                data: action.payload,
                message: 'Success'
            }
        }
    }
    if(action.type === SPORTS_CATEGORY_FAILURE) {
        return {
            ...state,
            order: {
                data: [],
                message: action.payload
            }
        }
    }
    return state;
}

