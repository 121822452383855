import { Box, Stack, Typography } from '@mui/material'
import React, {useState,useEffect, useMemo} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import Legend from './Legend'
import {styles} from './styles'
import { Pie } from 'react-chartjs-2';
import {Chart, ArcElement, Tooltip} from 'chart.js'
import { Link } from 'react-router-dom';
import {dashboardAction} from './Store/actions';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Tiles from '../../customWidgets/Tiles/tiles';
import Utils from '../../utils/Utils';
import AccessLevelContext from '../../../accessLevel-context';
import Loader from '../../customWidgets/Loader'
import SimpleSnackbar from '../../customWidgets/Snackbar'



Chart.register(ArcElement, Tooltip)
function Home() {
  const dispatch = useDispatch()
  const [alertType,setAlertType] = useState("success");
  const [alertMessage,setAlertMessage] = useState("");
  const [open,setOpen] = useState(false)
  const [loading,setLoading] = useState(true)
  const accessLevel= React.useContext(AccessLevelContext)
  const list = useSelector(state => state.dashboardList)
  useEffect(() => {
    if(accessLevel?.accessLevel==='A01'||accessLevel?.accessLevel==='A02'||accessLevel?.accessLevel==='A03'){
      dispatch(dashboardAction())
    }
    
  },[accessLevel])//eslint-disable-line react-hooks/exhaustive-deps
  

  const getData = useMemo(() => {
    if(!Utils.isEmpty(list.order)) {
      return Utils.isEmpty(list.order?.data) ? [] : list.order?.data[0];
    }
    else{
      return [];
    }
  },[list.order]);

  const labelData = useMemo(() => {
    if(!Utils.isEmpty(list.order) && !Utils.isEmpty(list.order?.data)) {
      const [orderData] = list.order?.data;
      setLoading(false)
      return [
        Utils.isEmpty(orderData.complete) ? 0 : orderData.complete,
        Utils.isEmpty(orderData.new) ? 0 : orderData.new,
        Utils.isEmpty(orderData.inProgressOrders) ? 0 : orderData.inProgressOrders,
        Utils.isEmpty(orderData.error) ? 0 : orderData.error
      ];
    }

    if(list.order?.isError){
      setAlertMessage(list.order.message)
      setAlertType('error')
      setOpen(true)
      return [];
    }
    setLoading(false);
  },[list.order]);

  const data = {
    labels: ['Completed Orders', 'New Orders', 'In Progress', 'Orders with Error'],
    datasets: [
      {
        data:labelData,
        backgroundColor: [
          '#3D4144',
          '#0262F9',
          '#FCF75E',
          '#A52A2A'
        ],
        borderColor: [
          '#3D4144',
          '#0262F9',
          '#FCF75E',
          '#A52A2A'
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <main data-testid="home-page" style={{ padding: "0.5rem 55px", display:'flex', flexDirection: "column" }} className="mui-spacing">
     <Loader open={loading}/>
     <SimpleSnackbar setOpen={setOpen} open={open}  message={alertMessage} messagetype={alertType} />
     {getData.totalOrders===0?
     <Box sx ={{display:'flex',alignItems:'center',justifyContent:'center',height:'50vh',width:'100%'}}>
      <Box sx={{fontFamily: "inherit", fontWeight: "inherit", fontSize: "28px" }}>
        You have no orders to view
      </Box>
     </Box> 
     :
     <Box>
       <Box style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
        <Tiles title="Total Orders" value={getData.totalOrders ?? 0} status="*" bgColor="#FFA800" fontColor="white" />
        <Tiles title="Completed Orders" value={getData.complete ?? 0} status="SHIPPED" bgColor="#3D4144" fontColor="white" />
        <Tiles title="Inprogress Orders" value={getData.inProgressOrders ?? 0} status="INPROGRESS" bgColor="#FCF75E" fontColor="black" />
        <Tiles title="New Orders" value={getData.new ?? 0} status="NEW" bgColor="#0262F9" fontColor="white" />
        <Tiles title="Orders with Error" value={getData.error ?? 0} status="ERROR" bgColor="#A52A2A" fontColor="white" />
      </Box>
      <Stack style={{ backgroundColor: "#F3F6F9", width: "100%", minHeight: "300px", height: "100%", borderRadius: "15px", marginTop: "30px", padding: "20px", fontFamily: "Poppins, Helvetica, 'sans-serif'", fontWeight: 600 }}>
        <Typography style={{fontFamily: "inherit", fontWeight: "inherit", fontSize: "18px" }}>Order Status</Typography>
        <Box style={{display: "flex", justifyContent: "center", width: "100%", marginBottom: "30px"}}>
          <Box style={{ width: "300px", height: "300px" }}>
            <Pie data-testid="PieChart"  data={data} />
          </Box>
          <Box style={{display:"flex", flexDirection: "column", justifyContent: "center", marginLeft: "20px"}}>
            {getData.totalOrders===0 ? <div></div> : <Legend />}
          </Box>
        </Box>
        <Box>
          <Typography style={{fontFamily: "inherit", fontWeight: "inherit", fontSize: "18px" }}>Status wise distribution of orders</Typography>
          <Box style={{margin: "20px", display: "flex"}}>
            <Box style={styles.OrderStatus.Count}>
              <Typography style={styles.ProgressOrders_status_number}>{getData.prepared ?? 0}</Typography>
            </Box>
            <Box>
              <Typography style={styles.ProgressOrders_status_title}>Prepared</Typography>
              <Link style={styles.ProgressOrders_status_text} to='/Order_List' state={{Status:'PREPARED'}}>Orders that are ready with associated labels</Link>
              <ArrowForwardOutlinedIcon style={styles.arrow}/>
            </Box>
          </Box>
          <Box style={{margin: "20px", display: "flex"}}>
            <Box style={styles.OrderStatus.Count}>
              <Typography style={styles.ProgressOrders_status_number}>{getData.processing ?? 0}</Typography>
            </Box>
            <Box>
              <Typography style={styles.ProgressOrders_status_title}>Processing</Typography>
              <Link style={styles.ProgressOrders_status_text} to='/Order_List' state={{Status:'PROCESSING'}}>Orders prepared to be sent to printing partner's SFTP.</Link>
              <ArrowForwardOutlinedIcon style={styles.arrow}/>
            </Box>
          </Box>
          <Box style={{margin: "20px", display: "flex"}}>
            <Box style={styles.OrderStatus.Count}>
              <Typography style={styles.ProgressOrders_status_number}>{getData.sent ?? 0}</Typography>
            </Box>
            <Box>
              <Typography style={styles.ProgressOrders_status_title}>Sent</Typography>
              <Link style={styles.ProgressOrders_status_text} to='/Order_List' state={{Status:'SENT'}}>Orders sent to printing partner's SFTP </Link>
              <ArrowForwardOutlinedIcon style={styles.arrow}/>
            </Box>
          </Box>
          <Box style={{margin: "20px", display: "flex"}}>
            <Box style={styles.OrderStatus.Count}>
              <Typography style={styles.ProgressOrders_status_number}>{getData.printed ?? 0}</Typography>
            </Box>
            <Box>
              <Typography style={styles.ProgressOrders_status_title}>Printed</Typography>
              <Link style={styles.ProgressOrders_status_text} to='/Order_List' state={{Status:'PRINTED'}}>Orders acknowledged and printed by printing partner</Link>
              <ArrowForwardOutlinedIcon style={styles.arrow}/>
            </Box>
          </Box>
          <Box style={{margin: "20px", display: "flex"}}>
            <Box style={styles.OrderStatus.Count}>
              <Typography style={styles.ProgressOrders_status_number}>{getData.complete ?? 0}</Typography>
            </Box>
            <Box>
              <Typography style={styles.ProgressOrders_status_title}>Shipped</Typography>
              <Link style={styles.ProgressOrders_status_text} to='/Order_List' state={{Status:'SHIPPED'}}>Orders shipped to factories by printing partner</Link>
              <ArrowForwardOutlinedIcon style={styles.arrow}/>
            </Box>
          </Box>
          <Box style={{margin: "20px", display: "flex"}}>
            <Box style={styles.OrderStatus.Count}>
              <Typography style={styles.ProgressOrders_status_number}>{getData.lock ?? 0}</Typography>
            </Box>
            <Box>
              <Typography style={styles.ProgressOrders_status_title}>Locked</Typography>
              <Link style={styles.ProgressOrders_status_text} to='/Order_List' state={{Status:'LOCKED'}}>Locked Orders</Link>
              <ArrowForwardOutlinedIcon style={styles.arrow}/>
            </Box>
          </Box>
          <Box style={{margin: "20px", display: "flex"}}>
            <Box style={styles.OrderStatus.Count}>
              <Typography style={styles.ProgressOrders_status_number}>{getData.cancelled ?? 0}</Typography>
            </Box>
            <Box>
              <Typography style={styles.ProgressOrders_status_title}>Cancelled</Typography>
              <Link style={styles.ProgressOrders_status_text} to='/Order_List' state={{Status:'CANCELLED'}}>Cancelled Orders</Link>
              <ArrowForwardOutlinedIcon style={styles.arrow}/>
            </Box>
          </Box>
        </Box>
      </Stack>
     </Box>
}
    </main>
  )
}

export default Home