import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchPOInformation } from './Store/actions';
import Modal from "@mui/material/Modal";
import * as XLSX from 'xlsx';
import {apiUnLockLabel,apiLockLabel,apiPrepareSendLabel,apiCancelLabel,apiSendLabels,apiPrepareLabel,apiApproveLabels, apiDeleteLabel} from '../../services/Gateway/RestAPIHandler'
import LabelSizeInfo from './LabelSizeInfo';
import { Typography, Box } from '@mui/material/';
import LabelNonAdmin from '../Label/NonAdminAccess';
import Buttons from './Buttons';
import OrderAccordion from '../../customWidgets/OrderAccordion';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ORDERS, LabelStatus, APIStatus } from "./constant";
import { Button } from "react-bootstrap";
import { styles, StyledAgGridReact,OrderButton} from "./styles";
import logo from '../../assets/excelicon.png';
import { useSearchParams } from 'react-router-dom';
import OrderSizeInfo from './OrderSizeInfo';
import SimpleSnackbar from '../../customWidgets/Snackbar';
import ViewLabel from './ViewLabel/index'
import AddLabel from './AddLabel/index';
import Loader from  '../../customWidgets/Loader'
import Utils from '../../utils/Utils';

export default function Orders() {
  const [poItemId,setPoItemId] = useState(0)
  const [labelIds,setLabelIds] = useState("")
  const [hideAddLabel,setHideAddLabel] = useState(false)
  const [gridApi,setGridApi] = useState(null)
  const [preparedStatus,setPreparedStatus] = useState(false)
  const [lockStatus,setLockStatus] = useState(true)
  const [processingStatus,setProcessingStatus]= useState(false)
  const [unlockStatus,setUnLockStatus] = useState(false)
  const [sentStatus,setSentStatus] = useState(false)
  const [cancelStatus,setCancelStatus] = useState(false)
  const [shippedStatus,setShippedStatus] = useState(false)
  const [printedStatus,setPrintedStatus] = useState(false)
  const [isApprovedStatus,setIsApprovedStatus] = useState(false)
  const [isApprovalPending,setApprovalPendingStatus] = useState(false)
  const [approveSendStatus,setApproveSendStatus] = useState(false)
  const [req,setReq]= useState([])
  const [order,setOrderRequest] = useState([])
  const [filepath,setFilePath] = useState('');
  const searchParams = useSearchParams()[0];
  const [alertType,setAlertType] = useState("success")
  const [alertMessage,setAlertMessage] = useState("")
  const [label,setLabel] = useState("")
  const [status,setStatus] = useState("")
  const [labelRows,setLabelRows] = useState([])
  const [loading,setLoading] = useState(true);
  const [labelDetails,setLabelDetails] = useState('')
  const exportToData = (data) => {
    const newData = data.map(ele => {
      return {
        "Label Code": ele.labelCode,
        "Status":ele.orderLabelStatus,
        "Preparation Mode":ele.preparationMode,
        "Sequence":ele.labelSequence,
        "Label Order Quantity":ele.orderQuantity,
        "Label Print Quantity":ele.labelQuantity,
        "Receiver":ele.receiverCode,
        "Preparation Date":ele.preparationDate,
        "Send Date":ele.sendDate,
        "Print Date":ele.printDate,
        "Ship Date": ele.shipDate
      }
    })
    const worksheet = XLSX.utils.json_to_sheet(newData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    XLSX.writeFile(workbook, `${searchParams.get("PONo")}_Order_Labels.xlsx`)
    setLoading(false)
  }
  const exportToExcel = () => {
    const data = list.order.data[0].orderLabel;
    setLoading(true)
    if(data===null){
  
      setAlertType('error')
      setAlertMessage("No Data to export")
      setOpen1(true)
      setLoading(false)
    }
    else{
    if(labelRows.length===0){
      exportToData(data)
    }
    else{
      exportToData(labelRows)
    }
  }
  }
  const handleCellClick = (event) => {
    
    if((event.colDef.headerName!=='View / Download' && event.colDef.headerName!=='Label Code') ){
      return
    }
    else if(event.colDef.headerName==="Label Code" ){
      const [dtoLabel] = list.order?.data;
      const dto = dtoLabel?.orderLabel;
      const [newdto] = dto.filter(ele => ele.id === event.data.id);
      if(!Utils.isEmpty(newdto.labelDto)){
      setLabelDetails([newdto.labelDto])
      handleOpenNonAdmin()
      }
      else{
      setAlertType('error')
      setAlertMessage("Error in showing label details")
      setOpen1(true)
      }
    }
    else {
      const [dtoLabel] = list.order?.data;
      const dto = dtoLabel?.orderLabel;
      const [newdto] = dto.filter(ele => ele.id === event.data.id);
      setLabel(event.data.labelCode)
      setStatus(event.data.orderLabelStatus)
      setFilePath(newdto?.orderLabelFilePath)
      handleOpen3()
    } 
  }
  const handlePrepare = () => {
    setLoading(true)

    
    apiPrepareLabel(
      { "labelIds": labelIds }
    )
    .then(([response, json]) => {
     
      if(json.status!==APIStatus.ERROR)
      {
        dispatch(searchPOInformation({ 
          "POItem":`${searchParams.get("PONo")}`,
        }))
        setAlertType('success') 
        setLabelIds("")
        setAlertMessage(json.successMessage)
      }
      else{
        setAlertType('error')
        setAlertMessage(json.responseMessage ?? json.errorMessage)

      }
      setOpen1(true)
      setLoading(false)
    })
  }
  const handlePrepareSend = () => {
    setLoading(true)

    
    apiPrepareSendLabel({
      labelIds:labelIds,
      poItemId:poItemId
        }    )
    .then(([response, json]) => {
     
      if(json.status!==APIStatus.ERROR)
      {
        dispatch(searchPOInformation({ 
          "POItem":`${searchParams.get("PONo")}`,
        }))
        setAlertType('success') 
        setLabelIds("")
        setAlertMessage(json.responseMessage)
      }
      else{
        setAlertType('error')
        setAlertMessage(json.responseMessage ?? json.errorMessage)

      }
      setOpen1(true)
      setLoading(false)
    })
  }
  const handlelock = () => {
    setLoading(true)
    apiLockLabel(
       { "labelIds": labelIds }
      )
    .then(([response, json]) => {
      if(json.status===APIStatus.SUCCESS)
      {
        dispatch(searchPOInformation({ 

            "POItem":`${searchParams.get("PONo")}`,
           }))
           setAlertType('success') 
           setLabelIds("")
           setAlertMessage(json.responseMessage)
      }
      else{
        setAlertMessage(json.responseMessage ?? json.errorMessage)
        setAlertType('error')
      }
      
      setOpen1(true)
      setLoading(false)
  })

  }
  const handleunlock = () => {
    setLoading(true)
    apiUnLockLabel(
      { "labelIds": labelIds }
     )
   .then(([response, json]) => {
      if(json.status===APIStatus.SUCCESS)
      {
        dispatch(searchPOInformation({ 
          "POItem":`${searchParams.get("PONo")}`,
        }))
        setAlertType('success')
        setLabelIds("")
      }
      else{  
        setAlertType('error')
      }
      setAlertMessage(json.responseMessage ?? json.errorMessage)
      setOpen1(true)
      setLoading(false)
    })  }

  const handleCancel = () => {
    setLoading(true)
    apiCancelLabel(
      { "labelIds": labelIds }
     )
    .then(([response, json]) => {
      if(json.status===APIStatus.SUCCESS)
      {
        dispatch(searchPOInformation({ 
          "POItem":`${searchParams.get("PONo")}`,
        }))
        setAlertType('success')
        setLabelIds("")
      }
      else {
        setAlertType('error')
      }
      setAlertMessage(json.responseMessage)
      setOpen1(true)
      setLoading(false)
    })
  }

  const handleSent = () => {
    setLoading(true)
    apiSendLabels(
      { "labelIds": labelIds }
     )
   .then(([response, json]) => {
     if(json.status==="SUCCESS")
     {
       dispatch(searchPOInformation({ 
           "POItem":`${searchParams.get("PONo")}`,
          }))
          setAlertType('success')
          setLabelIds("")
          setAlertMessage("Label Sent successfully")
     } else{
      setAlertMessage(json.responseMessage ?? json.errorMessage)
      setAlertType('error')
      
    }
    
    setOpen1(true)
    setLoading(false)
 })
  }
  const handleApprove = () => {
    setLoading(true)
    apiApproveLabels(
      { "labelIds": labelIds }
     )
   .then(([response, json]) => {
     if(json.status==="SUCCESS")
     {
       dispatch(searchPOInformation({ 
           "POItem":`${searchParams.get("PONo")}`,
          }))
          setAlertType('success')
          setLabelIds("")
          setAlertMessage("Label Sent successfully")
     } else{
      dispatch(searchPOInformation({ 
        "POItem":`${searchParams.get("PONo")}`,
       }))
      setAlertMessage(json.responseMessage ?? json.errorMessage)
      setAlertType('error')
      
    }
    
    setOpen1(true)
    setLoading(false)
 })
  }
  const handleDelete = () => {
    setLoading(true)
    apiDeleteLabel(
      { "labelIds": labelIds })
   .then(([response, json]) => {
     if(json.status===APIStatus.SUCCESS)
     {
       dispatch(searchPOInformation({ 
           "POItem":`${searchParams.get("PONo")}`,
          }))
          setAlertType('success')
          setLabelIds("")
          setAlertMessage("Label deleted successfully")
     }
     else{
      setAlertMessage(json.responseMessage ?? json.errorMessage)
      setAlertType('error')
      
    }
    
    setOpen1(true)
    handleClose4()
    setLoading(false)
 })
  }

   const handleSendApproval = () => {
    setLoading(true)
    apiSendLabels(
      { "labelIds": labelIds }
     )
   .then(([response, json]) => {
     if(json.status==="SUCCESS")
     {
       dispatch(searchPOInformation({ 
           "POItem":`${searchParams.get("PONo")}`,
          }))
          setAlertType('success')
          setLabelIds("")
          setAlertMessage("Label Sent successfully")
     } else{
      setAlertMessage(json.responseMessage ?? json.errorMessage)
      json.errorMessage===undefined?setAlertType('success'):setAlertType('error')
    }
    
    setOpen1(true)
    setLoading(false)
 })
  }

  const onGridReady= (params) => {
    setGridApi(params.api)
  }
  const rowClassRules = React.useMemo(() => {
    return {

      'unapproved': (params) => {
      //   var numSickDays = params.data.sickDays;
      //   return numSickDays > 5 && numSickDays <= 7;
      const approved = params.data.isApproved
      return !approved.includes("Y")
      },
      
    };
  }, []);
  const onSelectionChange = (event) => {
    const selectedRows = event.api.getSelectedRows();
    if (selectedRows.length > 0){
      setLabelRows(selectedRows)
      const orderData = list.order.data[0];
      const labelSizeList = orderData?.orderLabelSize
      const orderList = orderData?.poItem.poItemSizes
      
      const orderReq = orderList.filter(function(o1){
        return selectedRows.some(function(o2){
          return (o1.poItemId === o2.poItemId)
        })
      })
      const new_list = labelSizeList.filter(function(o1){
        return selectedRows.some(function(o2){
          return (o1.labelCode === o2.labelCode && o1.labelSequence === o2.labelSequence)
        })
      })
      setOrderRequest(orderReq)
      setReq(new_list)
      const Ids = selectedRows.map((ele) => ele.id)
      const PoItemId = selectedRows[0].poItemId
      setPoItemId(PoItemId)
      setLabelIds(Ids.join(','))
      const checkLockStatus = (ele) => ele.orderLabelStatus===LabelStatus.LOCKED
      const checkSentStatus = (ele) => ele.orderLabelStatus===LabelStatus.SENT
      const checkUnLockStatus = (ele) => ele.orderLabelStatus===LabelStatus.LOCKED
      const checkCancelStatus = (ele) => ele.orderLabelStatus===LabelStatus.CANCELLED
      const checkProcessIngStatus = (ele) => ele.orderLabelStatus===LabelStatus.PROCESSING
      const checkPreparedStatus = (ele) => ele.orderLabelStatus===LabelStatus.PREPARED
      const checkPrintedStatus = (ele) => ele.orderLabelStatus===LabelStatus.PRINTED
      const checkShippedStatus = (ele) => ele.orderLabelStatus===LabelStatus.SHIPPED
      const checkApproved = (ele) => (ele.isApproved.includes('Y'))
      const checkPending = (ele) => (ele.isApproved.includes('N'))
      let labelApprovedStatus = selectedRows.every(checkApproved)
      setIsApprovedStatus(labelApprovedStatus)
      let LabelPrintedStatus = selectedRows.every(checkPrintedStatus)
      setPrintedStatus(LabelPrintedStatus)
      let LabelShippedStatus = selectedRows.every(checkShippedStatus)
      setShippedStatus(LabelShippedStatus)
      let LabelPreparedStatus = selectedRows.every(checkPreparedStatus)
      setPreparedStatus(LabelPreparedStatus)
      let LabelLockStatus = selectedRows.every(checkLockStatus)
      setLockStatus(LabelLockStatus)
      let LabelUnLockStatus = selectedRows.every(checkUnLockStatus)
      setUnLockStatus(LabelUnLockStatus)
      let LabelSentStatus = selectedRows.some(checkSentStatus)
      setSentStatus(LabelSentStatus)
      let LabelCancelStatus = selectedRows.some(checkCancelStatus)
      setCancelStatus(LabelCancelStatus)
      let LabelProcessingStatus = selectedRows.some(checkProcessIngStatus)
      setProcessingStatus(LabelProcessingStatus)
      let labelNotApprovedStatus = selectedRows.every(checkPending)
      setApprovalPendingStatus(labelNotApprovedStatus)
    }
    else if (selectedRows.length === 0) {
      setLabelRows([])
      const [orderData] = list.order?.data
      const lblcd = orderData?.orderLabelSize;
      const orderSizeInfo = orderData?.poItem?.poItemSizes
      setOrderRequest(orderSizeInfo)
      setLockStatus(true)
      setUnLockStatus(false)
      setReq(lblcd)
      setLabelIds("")
    }

  }
  
  const [open1, setOpen1] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  //const [currentPage, setCurrentPage] = React.useState(1);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false)
  
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);
  const [openAddLabel, setOpenAddLabel] = React.useState(false);
  const handleOpenAddLabel = () => setOpenAddLabel(true);
  const handleCloseAddLabel = () => setOpenAddLabel(false);
  const [openLabelNonadmin,setOpenNonAdmin]  = React.useState(false);
  const handleOpenNonAdmin = () => setOpenNonAdmin(true);
  const handleCloseNonAdmin = () => setOpenNonAdmin(false)
  const checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const gridRef = useRef();
  const columnDef = [
    { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 50 },
    { field: 'id', headerName: 'Label ID', width: 100, resizable: true,hide:true },
    { field: 'labelCode', headerName: 'Label Code', width: 180, resizable: true,cellStyle:{color:'#044BD9',textDecoration:'underline',cursor:'pointer'} },
    { field: 'orderLabelStatus', headerName: 'Status', width: 125, resizable: true },
    { field: 'preparationMode', headerName: 'Preparation Mode', width: 170, resizable: true },
    { field: 'labelSequence', headerName: 'Sequence', width: 110, resizable: true },
    { field: 'orderQuantity', headerName: 'Label Order Quantity', width: 180, resizable: true },
    { field: 'labelQuantity', headerName: 'Label Print Quantity', width: 180, resizable: true },
    { field: 'receiverCode', headerName: 'Receiver', width: 110, resizable: true },
    {field:'view', width: 150,headerName:'View / Download',valueFormatter: params => {return "View / Download"},cellStyle:{color:'#044BD9',textDecoration:'underline',cursor:'pointer'}, resizable: true},
    { field: 'preparationDate', headerName: 'Preparation Date', width: 170, resizable: true },
    { field: 'sendDate', headerName: 'Send Date', width: 170, resizable: true },
    { field: 'printDate', headerName: 'Print Date', width: 140, resizable: true },
    { field: 'shipDate', headerName: 'Ship Date', width: 140, resizable: true }
  ];
const rowData =[
  {id:'123',approved:false,labelCode:'abc',orderLabelStatus:'xyz',preparationMode:'batch',labelSequence:2,orderQuantity:'500',labelQuantity:'200',receiverCode:'abc'},
  {id:'123',approved:true,labelCode:'def',orderLabelStatus:'xyz',preparationMode:'batch',labelSequence:2,orderQuantity:'500',labelQuantity:'200',receiverCode:'abc'}

]

  const dispatch = useDispatch();
  const list = useSelector(state => state.orderList);
  const [accordionOpen, setAccordionOpen] = React.useState(true)
  const [poStatus,setPoStatus] = useState('')

  React.useEffect(() => {
   if(gridApi){
    showGridLoader();
    dispatch(searchPOInformation({
      "POItem": `${searchParams.get("PONo")}`,
    }))
   }
  }, [dispatch, searchParams,gridApi])
  
  React.useEffect(() => {
    if( gridApi && !Utils.isEmpty(list.order.data)){
      setLoading(false)
      setPoStatus(list.order.data[0].poItem.status)
      hideGridLoader();
      // if(list.order?.data[0]?.orderLabel === null) {
      //   showNoRecordOverlay();
      // }
    }
    
    if(!Utils.isEmpty(list?.order?.isError)) {
      setAlertType('error')
      setAlertMessage(list?.order?.message)
      setOpen1(true)
      setLoading(false)
      hideGridLoader();
      // showNoRecordOverlay();
    }
  },[list.order])//eslint-disable-line react-hooks/exhaustive-deps
  
  const showGridLoader = () => {
    gridRef.current.api.showLoadingOverlay();
  }
  const hideGridLoader = () => {
    gridRef.current.api.hideOverlay();
  }
  // const showNoRecordOverlay = () => {
  //   gridRef.current.api.showNoRowsOverlay();
  // }

  return (

    <Box style={styles.gridContainer}>
      <Loader open ={loading}/>
      <SimpleSnackbar setOpen={setOpen1} open={open1}  message={alertMessage} messagetype={alertType} />
      <OrderAccordion setOpen={setOpen1} accordionOpen={accordionOpen} setAccordionOpen={setAccordionOpen} setAlertMessage={setAlertMessage} setAlertType={setAlertType} />
      <Typography component='div' style={styles.labelInfo}>
        <Typography style={styles.labelTitle} variant="h6" component="span">{ORDERS.labelInfo} </Typography>
        <img onClick={() => { exportToExcel() }} src={logo} style={{ marginLeft: '18px', cursor: 'pointer', width: '19px', height: '18px', opacity: 1 }} alt={"Export Excel"} />
        <Typography style={styles.sizeInfo} component="span">
          <Button style={styles.btnSizeInfo} onClick={handleOpen}>{ORDERS.orderSizeInfo}</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styles.popupStyle}>
              <OrderSizeInfo request={order} close={handleClose}></OrderSizeInfo>
            </Box>
          </Modal>
        </Typography>

        <Typography style={styles.lblSizeInfo} component="span">
          <Button style={styles.btnSizeInfo} onClick={handleOpen2}>{ORDERS.labelSizeInfo}</Button>
          <Modal
            open={open2}
            onClose={handleClose2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styles.popupStyle}>
              <LabelSizeInfo  poItem={searchParams.get("PONo")} status={poStatus} request={req} close={handleClose2}/>
            </Box>
          </Modal>
        </Typography>
      </Typography>
      <Typography component='div' style={styles.approvalInfo}>
        <Typography style={styles.approvalTitle} component="span">{ORDERS.approvalNote}</Typography>
      </Typography>
      <Modal
            open={open3}
            onClose={handleClose3}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styles.popupStyle2}>
              <ViewLabel setAlertMessage={setAlertMessage} setAlertType={setAlertType} setOpen1={setOpen1} filepath={filepath} poNum={searchParams.get("PONo")} status={status} label={label} close={handleClose3}/>
            </Box>
          </Modal>
          <Modal
          open={openLabelNonadmin}
          onClose={handleCloseNonAdmin}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.popupStyle}>
            <LabelNonAdmin label={labelDetails}  close={handleCloseNonAdmin} ></LabelNonAdmin>
          </Box>
        </Modal>
        <Modal
          open={open4}
          onClose={handleClose4}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={styles.style1}>
              <Typography sx={{marginTop:'20px',marginLeft:'10px'}} id="modal-modal-title" variant="h6" component="h2">
                  Delete Labels?</Typography>
              <Typography id="modal-modal-description" sx={{ margin:'10px' }}>
                You are about to delete selected labels. Do you want to proceed?
              </Typography>
              <OrderButton onClick={handleClose4} style={{marginLeft:'25%',marginTop:'50px'}}>
                Cancel
              </OrderButton>
              <OrderButton style={{marginTop:'50px'}} onClick={handleDelete}>
                Delete
              </OrderButton>
          </Box>
        </Modal>

        <Modal
          open={openAddLabel}
          onClose={handleCloseAddLabel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.popupStyle1} style={{display:hideAddLabel?'none':'block'}}>
            <AddLabel setOpen={setOpen1} setHideAddLabel={setHideAddLabel} setAlertMessage={setAlertMessage} setAlertType={setAlertType} close={handleCloseAddLabel}/>
          </Box>
        </Modal>
      <Box
        className={"ag-theme-alpine"}
        sx={{ height: accordionOpen ? '250px' : '400px' }}>
        <StyledAgGridReact
          ref={gridRef}
          rowData={!Utils.isEmpty(list?.order?.data) ? list.order?.data[0]?.orderLabel === null ? [] : list.order.data[0].orderLabel : []}
          columnDefs={columnDef}
          pagination={true}
          onGridReady={onGridReady}
          suppressRowClickSelection={true}
          // paginationPageSize={GRID_FETCH_SIZE}
          rowClassRules={rowClassRules}
          onSelectionChanged={onSelectionChange}
          rowSelection={'multiple'}
          onCellClicked={handleCellClick}
          suppressPaginationPanel={true}
          overlayLoadingTemplate={`<div class="loader"></div>`}
          // overlayNoRowsTemplate={`<span>No records to show</span>`}
        />
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Buttons 
          labelIds={labelIds}
          ApprovedStatus={isApprovedStatus}
          ApprovalPendingStatus={isApprovalPending}
          preparedStatus={preparedStatus}
          cancelStatus={cancelStatus}
          printedStatus={printedStatus}
          shippedStatus={shippedStatus}
          approveSendStatus={approveSendStatus}
          handleOpen4={handleOpen4}
          sentStatus={sentStatus}
          handlePrepare={handlePrepare}
          unlockStatus={unlockStatus}
          processingStatus={processingStatus}
          lockStatus={lockStatus}
          handleSent={handleSent}
          handleCancel={handleCancel}
          handlelock={handlelock}
          handleApprove={handleApprove}
          handleunlock={handleunlock}
          handleOpenAddLabel={handleOpenAddLabel}
          handlePrepareSend={handlePrepareSend}
          handleSendApproval={handleSendApproval}
          poStatus={poStatus} />
      </Box>
    </Box>
  );
}
