import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, IconButton, Stack, Modal, MenuItem, Card, CardContent, Autocomplete, TextField, Select } from '@mui/material';
import { styles, OrderButton, SearchInput } from '../styles';
import Utils from '../../../../utils/Utils';
import { ORDERS } from '../../constant';
import { useSelector, useDispatch } from 'react-redux';
import { addLabelAssignmentRow, fetchFactoryUserDetails, addUserOrder } from '../../../../services/Gateway/RestAPIHandler'
import SimpleSnackbar from '../../../../customWidgets/Snackbar';
import { getUserOrderFilterAction } from '../Store/actions';



function AddUserOrder({ openModal, closeModal, currentPage }) {
    const [open, setOpen] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [disablefactory, setDisablefactory] = useState(false);
    const list = useSelector(state => state.userorderfilter);
    const [factoryCodes, setFactoryCodes] = useState('');
    const dispatch = useDispatch();
    const [userID, setUserID] = useState([]);
    const [user, setUser] = useState('');
    const [factoryList, setFactoryList] = useState([]);
    const GRID_FETCH_SIZE = 7;
    const presentPage = 1;

    React.useEffect(() => {
        return (dispatch) => {
            fetchFactoryUserDetails()
                .then(([response, json]) => {
                    if (response.status === 200) {
                        setUserID(json.results[0].user);
                        setFactoryList(json.results[0].factoryCode);
                    }
                    else {
                        //  dispatch(getUserListFailure("Unable to fetch User Groups data; Error code - " + json.errorCode));
                    }
                })
        }

    }, [list.order])



    const handleUserIdChange = (event, newValue, reason) => {
        if (reason === 'clear')
            setUser('');
        else
            userID.filter(ele => ele.userId.includes(newValue)).map(ele =>
        { 
            setUser(ele.userId);
            if(ele.groupId === 'G1')
                setDisablefactory(true);
            else
                setDisablefactory(false);

        });
    }

    const callbackFromMultiSelect = (labels) => {
        setFactoryCodes(labels);
    };

    const clearValues = () => {
        setFactoryCodes([]);
        setUser('');
    }


    const handleGridSave = async () => {
        let vlabels = Object.keys(factoryCodes).map(function (k) { return factoryCodes[k] }).join(",");
        if (user === '' || user === "") {
            setAlertType('error')
            setAlertMessage("Select UserID")
            setOpen(true)
        } else {
            handleSave();
        }
    }

    const handleSave = async () => {

        let vlabels = Object.keys(factoryCodes).map(function (k) { return factoryCodes[k] }).join(",");
        addUserOrder({

            "factory": vlabels,
            "liasonOffice": "01",
            "userId": user
        })
            .then(([response, json]) => {
                if (response.status === 200) {
                    if (json.results === undefined) {
                        setAlertType('error')
                        setAlertMessage("Same ALA combination already exists")
                        setOpen(true)
                    }
                    else if (json.results[0] === 'SUCCESS') {
                        setFactoryCodes([]);
                        setUser('');
                        setAlertType('success')
                        setAlertMessage("Factory Mapping To User Done Successfully")
                        setOpen(true)
                        closeModal(false)
                        dispatch(getUserOrderFilterAction({ fetchSize: GRID_FETCH_SIZE, page: presentPage }))
                    } else {
                        setAlertType('error')
                        setAlertMessage(json.results[0])
                        setOpen(true)
                    }
                }
                else {
                    setAlertType('error')
                    setAlertMessage("Unable to Add User")
                    setOpen(true)
                }
            })
    }

    return (
        <>
            <SimpleSnackbar setOpen={setOpen} open={open} message={alertMessage} messagetype={alertType} />
            <Modal
                open={openModal}
                onClose={() => closeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={styles.popupStyle} style={{ overflowY: 'scroll' }}>
                    <Typography component='div' style={styles.popupContent.title}>
                        <Typography component='span' style={styles.popupContent.titleText}>Add User Order Filter</Typography>
                        <IconButton id="close-modal-popup" data-testid="close-modal-popup" onClick={() => { closeModal(false); clearValues() }} style={styles.popupContent.closeButton}>
                            <CloseIcon />
                        </IconButton>
                    </Typography>

                    <Stack>

                        <Box sx={styles.popupContent.row}>
                            <Autocomplete
                                id="product-group"
                                options={userID.map(ele => ele.userId)}
                                onChange={(e1, newValue1, reason1) => handleUserIdChange(e1, newValue1, reason1)}
                                style={{ width: 480 }}
                                renderInput={(params1) => (
                                    <TextField {...params1} label="User ID" variant="outlined" />
                                )}
                            />


                        </Box>
                        <Box sx={styles.popupContent.row}>
                            <Autocomplete
                                multiple
                                disabled={disablefactory}
                                limitTags={2}
                                id="multiple-limit-tags"
                                options={factoryList.map(ele => ele.factoryCode)}
                                onChange={(event, newValues6) => {
                                    callbackFromMultiSelect(newValues6);
                                }}
                                renderInput={(params6) => (
                                    <TextField {...params6} label="Factory" />
                                )}
                                sx={{ width: '480px' }}
                            />
                        </Box>
                        <Typography component='div' style={styles.approvalInfo}>
                            <Typography style={styles.approvalTitle} component="span">{ORDERS.UserOrderFilterNote}</Typography>
                        </Typography>
                        <Box sx={styles.popupContent.button}>
                            <OrderButton onClick={handleGridSave}>SAVE</OrderButton>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default AddUserOrder;