import React from 'react'
import { StyledInput,styles } from './styles'

const  Input = ({value,onChange,type,handleKeyDown,maxLength,searchColumn}) => {
  const textColumns = [
    'Shipping Country','Currency','Label Type','Division','Age Group','Customer Short Name','Description','Barcode Type','Green Point','Shipping Address Country Code','Shipping Address 3',
    'Country Code',
    'Product Department Code',
    'Locked',
    'Locked Reason',
    'Brand Code',
    'Gender',
    'Colour Combination Long',
    'Colour Combination Short',
    'Product Fit','Customer Type','Gender Age Group'
  ]
  const numericColumns = ['Order Quantity','Overage Percentage','Batch ID','Label Height','Label Width','Article Number','Technical Size']
  const currencyColumns = ['Retail Price']  
    const [val,setVal] = React.useState(value)
  
    const updateVal = (v) => {
      let newvalue;
      if(numericColumns.includes(searchColumn)){
        newvalue= v.replace(/[^\d*,]/g,'')
      }
      else if(textColumns.includes(searchColumn)){
        newvalue= v.replace(/[^a-zA-Z*,&_( )-/]/g,'')
      }
      else if(currencyColumns.includes(searchColumn)){
        newvalue= v.replace(/[^\d*,. ]/g,'')
      }
      else if(searchColumn.includes('date')|| searchColumn.includes('Date') || searchColumn.includes('Time') || searchColumn.includes('time')){
        newvalue = v
      }
      else{
        newvalue= v.replace(/[^0-9a-zA-Z*,&_( )-/]/g,'')
      }
        setVal(newvalue);
        onChange(newvalue);
    }
    React.useEffect(() => {
      if(value===''){
        setVal('')
      }
      else{
        setVal(value)
      }
    },[value])
  return (
    <StyledInput data-testid="search-input" value={val} type={type} onChange={(e)=> updateVal(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} style={styles.Search_row_input} maxLength={maxLength} />
  )
}

export default Input