import React, { useRef, useState, useMemo, useEffect } from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import * as XLSX from 'xlsx';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import AccessLevelContext from '../../../../accessLevel-context';
import logo from '../../../assets/excelicon.png';
import { Box } from '@mui/material';
import { getLabelListAdminAction } from './Store/actions';
import { styles, StyledAgGridReact } from './styles';
import AddLabel from './components/AddLabel';
import { useDispatch, useSelector } from 'react-redux';
import GridPagination from '../../../customWidgets/GridPagination/index';
import { apisearchLabelList } from '../../../services/Gateway/RestAPIHandler';
import {useLocation } from "react-router-dom";
import LabelNonAdmin from '../NonAdminAccess';
import Utils from '../../../utils/Utils';
import Loader from '../../../customWidgets/Loader'
import SimpleSnackbar from '../../../customWidgets/Snackbar';


function LabelAdmin() {
  const location = useLocation();
  const gridRef = useRef();
  const [labelRows,setLabelRows] = React.useState([])
  const accessLevel= React.useContext(AccessLevelContext)
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType,setAlertType] = useState("success");
  const [alertMessage,setAlertMessage] = useState("");
  const [rowData,setRowData] = useState([])
  //const createdBy = window.localStorage.getItem('username')


  const checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const columnDef = [
    { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 50 },

    {
      field: 'id', headerName: 'Label ID', width: 120, headerClass: 'grid-header', hide:true
    },
    {
      field: 'labelCode', headerName: 'Label Code', width: 140, headerClass: 'grid-header',cellStyle:{color:'#044BD9',textDecoration:'underline',cursor:'pointer'},resizable:true
    },
    {
      field: 'name', headerName: 'Label Name', width: 350, headerClass: 'grid-header',resizable:true
    },
    {
      field: 'labelType', headerName: 'Label Type ', width: 170, headerClass: 'grid-header',resizable:true
    }
    ,
    {
      field: 'formula', headerName: 'Formula ', width: 170, headerClass: 'grid-header',resizable:true
    },    {
      field: 'overagePercentage', headerName: 'Overage Percentage ', width: 200, headerClass: 'grid-header',resizable:true
    },
    
    {
      field: 'height', headerName: 'Label Height', width: 200, headerClass: 'grid-header',resizable:true
    },
    {
      field: 'width', headerName: 'Label Width', width: 200, headerClass: 'grid-header',resizable:true
    }

  ];

  const [lblId, setLblId] = useState('');
  const [loading,setLoading] = useState(true)
  const [openAddLabel, setOpenAddLabel] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let navigate = useNavigate()
  const list = useSelector(state => state.labelAdminList);
  const GRID_FETCH_SIZE = 7;
  const [req,setReq]= useState(list.request)   
  const [searchColumns,setSearchColumns] = useState(list.searchColumns)
  React.useEffect(() => {
    setLoading(true)
    if(location.state!==null){
      if(location.state.Status==='*'){
        dispatch(getLabelListAdminAction({
          "ascDesc": "",
          "fetchSize": GRID_FETCH_SIZE,
          "filterSearch": "",
          "pageNumber": 1,
          "sortBy": ""
        }))
      }
    }
    else {
      if(req.filterSearch!=='' && req.hasOwnProperty('filterSearch')) {
        dispatch(getLabelListAdminAction(req,searchColumns))
      }
      else {
        dispatch(getLabelListAdminAction({
          "ascDesc": "",
          "fetchSize": GRID_FETCH_SIZE,
          "filterSearch": "",
          "pageNumber": 1,
          "sortBy": ""
        }))
      }
    }
  }, [])//eslint-disable-line react-hooks/exhaustive-deps
  
  const dataToExcel = (data) => {
    const newData = data.map(ele => {
      return {
        "Label Code" : ele?.labelCode,
        "Label Name": ele?.name,
        "Label Type":ele?.labelUsageType,
        "Formula": ele?.formula,
        "Overage Percentage": ele?.overagePercentage,
        "Label Height": ele?.height,
        "Label Width": ele?.width,

              }
    })
    
    const worksheet = XLSX.utils.json_to_sheet(newData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    XLSX.writeFile(workbook, "DownloadLabelList.xlsx")
    setLoading(false)
  }
  const showErrorMessage = () => {
    setOpenAlert(true)
    setAlertType('error')
    setAlertMessage("No data to export")
    setLoading(false)
  }
  const exportToExcel = () => {
    setLoading(true)
          if(labelRows.length===0){
            apisearchLabelList(
              {...req,fetchSize:500,pageNumber: 1}
            ).then(([response, json])=> {
              if(response.status===200 && !Utils.isEmpty(json.results)){
              const data = json.results[0].data.content;
              if(data.length>0){
                dataToExcel(data)
              }
              else{
                showErrorMessage()
              }
              }
            else{
              showErrorMessage()
            }
          })
          }      
          else{
            dataToExcel(labelRows)
          }  
       
  }

  
  React.useEffect(() => {
    if(!Utils.isEmpty(list.order) && !Utils.isEmpty(list.order.data)){
      setLoading(false)
    }
    if(!Utils.isEmpty(list?.order?.message) && list?.order?.message !== 'Success') {
      setAlertType('error')
      setAlertMessage(list?.order?.message)
    }
  },[list.order])
  
  useEffect(() => {
    setCurrentPage(req.pageNumber)
  },[req]);
  const onSelectionChange = (event) => {
    const selectedRows = event.api.getSelectedRows();
    if (!Utils.isEmpty(selectedRows)){
      setLabelRows(selectedRows)
    }
    else{
      setLabelRows([])
    }
  }
  const handleCellClick = (event) => {
    const selectedRow = gridRef.current.api.getSelectedRows();
    setLblId(selectedRow)
   
    if (event.colDef.field !== 'labelCode') {
      return
    }
    else if (Utils.isEmpty(event.data.id)) {
      return
    }
    else if (event.data.id > 0) {
      if (accessLevel?.accessLevel!=='A01'){
        handleOpen();
    }
    else{
      navigate(`/Label/LabelAdmin?id=${event.data.id}`)
    }
  }
  }



  const gotoKeyDown = (event, value) => {
      
    if(event.keyCode === 13 && parseInt(value) >= 1  && parseInt(value) <= getTotalPages.totalPages && currentPage!==parseInt(value)) {
      setCurrentPage(parseInt(value));
      showGridLoader()
      setLabelRows([])
      if(req.filterSearch!=='' && req.hasOwnProperty('filterSearch')){
        dispatch(getLabelListAdminAction({ ...req,fetchSize: GRID_FETCH_SIZE, pageNumber: parseInt(value) },searchColumns));
      }
      else{
        dispatch(getLabelListAdminAction({
          "ascDesc": "",
          "fetchSize": GRID_FETCH_SIZE,
          "filterSearch": "",
          "pageNumber": parseInt(value),
          "sortBy": ""
        }))
      }
    }
  }

  const showGridLoader = () => {
    gridRef.current.api.showLoadingOverlay();
  }

  const getTotalPages = useMemo(() => {
    setReq(list.request)
    setSearchColumns(list.searchColumns)
    const orderData = (!Utils.isEmpty(list.order) && !Utils.isEmpty(list.order?.data)) ? list.order?.data[0]?.data : [];
    if(!Utils.isEmpty(list.order)) {
      return Utils.isEmpty(list.order?.data) ?
        {
          totalPages: 1,
          totalElements: 1
        } : {
          totalPages: orderData?.totalPages,
          totalElements: orderData?.totalElements
        };
    }
    else
      return {
        totalPages: 1,
        totalElements: 1
      };
  },[list.order]);//eslint-disable-line react-hooks/exhaustive-deps

  const handleGridPagination = (event, thisPage) => {

    if(currentPage!==thisPage){
      setLabelRows([])
      showGridLoader()
      setCurrentPage(thisPage)

      if(req.filterSearch!=='' && req.hasOwnProperty('filterSearch')){
        dispatch(getLabelListAdminAction({ ...req,fetchSize: GRID_FETCH_SIZE, pageNumber: thisPage },searchColumns));
      }
      else{
        dispatch(getLabelListAdminAction({
          "ascDesc": "",
          "fetchSize": GRID_FETCH_SIZE,
          "filterSearch": "",
          "pageNumber": thisPage,
          "sortBy": ""
        }))
      }
    }
  }
  
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if(!Utils.isEmpty(list.order?.data)){
      if(list.order?.isLoaded ){
         setRowData(list?.order?.data[0]?.data?.content)
         setLoading(false)
      }
    }
    if(list.order?.message!=='Success' && !Utils.isEmpty(list.order?.message) ){
      setRowData([])
      setAlertMessage(list.order?.message)
      setAlertType('error')
      setOpenAlert(true)
      setLoading(false)
    }
  },[list.order])//eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box style={styles.gridContainer}>
      <Loader open ={loading}/>
      <img onClick={() => { exportToExcel() }} src={logo} style={styles.excelicon} alt={"Export Excel"} />
      <SimpleSnackbar setOpen={setOpenAlert} open={openAlert}  message={alertMessage} messagetype={alertType} />
      <AddLabel openModal={openAddLabel} closeModal={setOpenAddLabel} />
      <Box className={"ag-theme-alpine"}
        sx={{ height: '365px', width: '100%' }}>
        <StyledAgGridReact
          ref={gridRef}
          rowSelection='multiple'
          // rowData={!Utils.isEmpty(list.order) ? list.order?.data[0]?.data.content : []}
          rowData={rowData}
          columnDefs={columnDef}
          onCellClicked={handleCellClick}
          pagination={false}
          enableCellTextSelection={true}
          suppressRowClickSelection={true}
          paginationPageSize={0}
          onSelectionChanged={onSelectionChange}
          suppressPaginationPanel={true}
          overlayLoadingTemplate={`<div class="loader"></div>`}
        />
      </Box>
      <Box style={styles.alignBottom}>
         <GridPagination
            pageClick={handleGridPagination}
            gridTotal={getTotalPages}
            currentPage={currentPage}
            pageSize={GRID_FETCH_SIZE}
            gotoKeyDown={gotoKeyDown} />
        {/* <Buttons labelIds={labelIds} handleGridAdd={handleGridAdd} handleGridSave={handleGridSave} handleGridDelete={handleGridDelete} /> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.popupStyle1}>
            <LabelNonAdmin label={lblId}  close={handleClose} ></LabelNonAdmin>
          </Box>
        </Modal>

        {/* <GridPagination
          pageClick={handleGridPagination}
          totalPages={getTotalPages}
          currentPage={currentPage}
          gotoKeyDown={gotoKeyDown} /> */}
      </Box>
    </Box>

  );
}
export default LabelAdmin
