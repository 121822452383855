export const POCard = {
    POInfo: "PO Information",
    PONumber: "PO Number",
    CustCode: "Customer Code",
    CustOrderNo: "Customer Order No",
    OrderQty: "Order Quantity",
    CountryOrigin: "Country of Origin",
    SkipCountry: "Ship to Country",
    Division: "Division",
    prodGroup: "Product Group"
}