import { apiOrderList,apiLabelList,apisearchPOinformation } from '../../../services/Gateway/RestAPIHandler';
import Utils from '../../../utils/Utils';

export const ORDER_LIST_GET_ASYNC = 'Get Order List Async';
export const ORDER_LIST_GET = 'Get Order List';
export const ORDER_LIST_SUCCESS = 'Order List Success';
export const ORDER_LIST_FAILURE = 'Order List Failure';
export const LABEL_LIST_GET = 'Get Label List';
export const LABEL_LIST_SUCCESS = 'Label List Success';
export const LABEL_LIST_FAILURE = 'Label List Failure';

export function searchPOInformation(params) {
    
        return (dispatch) => {
            try {
                dispatch(getOrderList());
                return apisearchPOinformation(params)
                    .then(([response, json]) => {
                        if(response.status === 200) {
                            dispatch(getOrderListSuccess(json.results));
                        }
                        else {
                            dispatch(getOrderListFailure(`Unable to fetch order data; Error code - ${Utils.isEmpty(json.errorCode) ? 0 : json.errorCode}`));
                        }
                    })
            }
            catch(ex) {
            }
        }
    }
    


export function getOrderListAction(params) {
    return (dispatch) => {
        dispatch(getOrderList());
        return apiOrderList(params)
            .then(([response, json]) => {
                if(response.status === 200){
                    dispatch(getOrderListSuccess(json.results));
                }
                else {
                    dispatch(getOrderListFailure("Unable to fetch order data; Error code - " + json.errorCode));
                }
            })
    }
}

export function getOrderList() {
    return {
        type: ORDER_LIST_GET
    }
}

export function getOrderListSuccess(data) {
    return {
        type: ORDER_LIST_SUCCESS,
        payload: data
    }
}

export function getOrderListFailure(message) {
    return {
        type: ORDER_LIST_FAILURE,
        payload: message
    }
}

export function getLabelListAction(params) {
    return (dispatch) => {
        dispatch(getLabelList());
        return apiLabelList(params)
            .then(([response, json]) => {
                if(response.status === 200){
                    dispatch(getLabelListSuccess(json.results));
                }
                else {
                    dispatch(getLabelListFailure("Unable to fetch order data; Error code - " + json.errorCode));
                }
            })
    }
}

export function getLabelList() {
    return {
        type: LABEL_LIST_GET
    }
}

export function getLabelListSuccess(data) {
    return {
        type: LABEL_LIST_SUCCESS,
        payload: data
    }
}

export function getLabelListFailure(message) {
    return {
        type: LABEL_LIST_FAILURE,
        payload: message
    }
}