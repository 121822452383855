import React from 'react'
import {Modal,Typography,Box} from '@mui/material/';
import {styles} from './styles'
function Loader({open}) {
    
  return (
    <Modal
      data-testid="loader"
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx ={styles.popupStyle1}><Typography component={'div'} className={'spinner'}></Typography></Box>
    </Modal>
  )
}

export default Loader