import React, { useContext } from 'react';
import {styles} from './styles'
import CustomMenuItem from './MenuItem';
import {StyledListItem,StyledListItemButton,StyledMenu} from './styles'
import {Tooltip,ListItemIcon,ListItemText,Box} from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import {useNavigate } from "react-router-dom";
//import {useSelector} from 'react-redux';
import Utils from '../../utils/Utils';
import AccessLevelContext from '../../../accessLevel-context';

export default function PositionedMenu({item,index,arrowopen,handleDrawerOpen}) {
  const text = item;
  let navigate = useNavigate();
  //const list = useSelector(state => state.UserAccess)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const accessLevel= useContext(AccessLevelContext)
  
  if(Utils.isEmpty(arrowopen)) {
    arrowopen=true
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div data-testid="menu-list-access" style={{display:text.useraccess.includes(accessLevel?.accessLevel)?'block':'none'}}>
      <StyledListItem key={index} atyle={styles.menu_item} disablePadding>
        <Tooltip title={arrowopen?'':text.name} placement='right'>
          <StyledListItemButton data-testid="menu-list"
            onClick = { arrowopen ? !Utils.isEmpty(item?.children) ? handleClick : () => { navigate(item.link,{state:{Status:'*'}});} : handleDrawerOpen }> 
            { Utils.isEmpty(text.icon) ? '' :
              <Box sx={styles.PositionedMenu.listButton}>
                <ListItemIcon sx={styles.PositionedMenu.listIconButton} style={{mr: open ? 3 : 'auto'}}>
                  {text.icon}
                </ListItemIcon>
              </Box>
            }
            
            <ListItemText primary={text.name} sx={{ opacity: arrowopen? 1 : 0 , paddingLeft:'28px', width:'186px'}} />
            {text.children.length>0 ? <ArrowRightIcon sx={{ opacity: arrowopen? 1 : 0,padding:'0',}}/>:''}
          </StyledListItemButton>
        </Tooltip>
      </StyledListItem>
      <StyledMenu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {item.children.map((elem) => <CustomMenuItem key={Math.random().toString(36).substring(2,9)}  item={elem}/>)}
      </StyledMenu>
    </div>
  );
}