export const ORDERS = {
    labelInfo: "Label Information",
    orderSizeInfo: "Order Size Information",
    labelSizeInfo: "Label Size Information",
    button: {
        prepare: "Prepare",
        send: "Send"
    },
    EmailCodenote: 'Select BOTH to send approval request emails for footwear and apparel, APPAREL - to send approval request emails for apparel, FOOTWEAR - to send approval request emails for footwear, NONE - does not send any approval request emails.',
    EmailFactorynote: 'OPTIONAL. Select from list of factories to send approval requests email to the factory user.',
    UserOrderFilterNote:'OPTIONAL. Select only for Factory users to view the orders from list of factories.'
}

export const LabelStatus = {
    LOCKED: "LOCKED",
    CANCELLED: "CANCELLED",
    SENT: "SENT",
    PREPARED: "PREPARED"
}

export const APIStatus = {
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    ERROR: "ERROR"
}

export const GridPage = {
    Previous: 'grid-previous-page',
    Next: 'grid-next-page',
    Current: 'grid-current-page'
}