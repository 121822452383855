import { styled } from "@mui/system";
import { Box,Button, Select,MenuItem } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';

export const StyledAgGridReact = styled(AgGridReact)({
    '[class*="unapproved"]': {
        backgroundColor: "rgba(0,0,0,0.3) !important",
      },
    '.ag-header-cell-text':{
        color: '#99702C',
        fontFamily: 'Helvetica Neue,Roboto,sans-serif',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '18px'
    },
    '.ag-cell':{
        border:'none',
        outline:'none'
    },
    '.ag-paging-page-summary-panel':{
        margin:0,
    }
})
export const BoxContainer = styled(Box)({
    float: 'left',
    width: '100%',
    height: '390px',
    paddingLeft: '40px'
});

export const BoxSection = styled('div')({
    paddingTop: '20px',
    paddingBottom: '20px'
});

export const SingleRow = styled(Box)({
    display: "inline-flex",
    flexDirection: "row",
    gap: "15px",
    paddingTop: "20px",
    paddingBottom: "20px"
})

export const StyledMenuItem = styled(MenuItem)(
    {
        fontSize:'14px',
        '@media(max-width:1370px)':{
            fontSize:'12px'
        }
    }
)
export const StyledSearch = styled(Select)(
    {
   
    height:'49px',
    width:'150px',
    fontSize:'14px',
    '@media(max-width:1370px)':{
        borderRadius: '4px',
        width: '115px',
        height: '40px',
        fontSize: '12px',
    },

}
)
export const AddLabelButton = styled(Button)({
    zIndex: '10',
    cursor: 'pointer',
    borderRadius: '4px',
    marginRight: '10px',
    width: '130px',
    height: '49px',
    color:'#FFFFFF',
    border: '1px solid #293E53',
    fontFamily: 'Poppins',
    fontSize: '14px', 
    background: '#293E53 0% 0% no-repeat padding-box',
    opacity: '1',
    padding:0,
    marginLeft:'10px',
    marginTop:'46px',
    '@media(max-width:1370px)':{
        borderRadius: '4px',
        marginRight: '20px',
        width: '115px',
        height: '40px',
        fontSize: '12px',
        marginLeft:'40px'
    },
    '&:hover':{
        border: '1px solid #293E53',
        borderRadius: '4px',
        color: '#293E53',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '5px 5px 5px rgba(0,0,0,0.3)'
    },
    '&.disabled':{
        background: '#878787 0% 0% no-repeat padding-box',
        border: '1px solid #0000',
        opacity:1,
    }
})
export const OrderButton = styled(Button)({
    zIndex: '10',
    cursor: 'pointer',
    borderRadius: '4px',
    marginBottom:'20px',
    marginRight: '30px',
    width: '130px',
    height: '49px',
    color:'#FFFFFF',
    border: '1px solid #293E53',
    fontFamily: 'Poppins',
    fontSize: '14px', 
    background: '#293E53 0% 0% no-repeat padding-box',
    opacity: '1',
    padding:0,
    
    '@media(max-width:1370px)':{
        borderRadius: '4px',
        marginRight: '15px',
        width: '115px',
        height: '40px',
        fontSize: '12px',
    },
    '&:hover':{
        border: '1px solid #293E53',
        borderRadius: '4px',
        color: '#293E53',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '5px 5px 5px rgba(0,0,0,0.3)'
    },
    '&.disabled':{
        background: '#878787 0% 0% no-repeat padding-box',
        border: '1px solid #FFFFFF',
        opacity:1,
        pointerEvents: 'none'
    },
    '&.hidden':{
        display:'none'
    }

})

export const styles = {
    buttons:{
        display: 'flex',
        marginTop:'46px',
        marginBottom:'20px',
        flexWrap:'wrap'
        }
    ,
    labelInfo: {
        marginBottom: '16px'
    },
    labelTitle: {
        paddingLeft: '14px'
    },
    sizeInfo: {
        paddingLeft: '35%'
    },
    btnSizeInfo: {
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        textDecoration: 'underline',
        color:'#044BD9',
        width:'fit-content'
    },
    style1:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '605px',
        height: '250px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #707070',
        boxShadow: 24,
        p: 4,
    },
    lblSizeInfo: {
        paddingLeft: '3%',
    },
    gridContainer: {
        float: 'left',
        width: '100%',
        height: '390px',
    },
    popupStyle2: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '1169px',
        height: '600px',
      
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #707070',
        boxShadow: 24,
        p: 4,
    },
    popupStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '1169px',
        height: '550px',
        overflowY:'scroll',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #707070',
        boxShadow: 24,
        p: 4,
    },

    popupStyle1: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '535px',
        height: '535px',
      
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #707070',
        boxShadow: 24,
        p: 4,
    },

    marginTitle: {
        marginTop:'20px',
        marginLeft:'10px'
    },
    margin10: {
        margin: '10px'
    },
    cancelMargin: {
        marginLeft: '25%',
        marginTop: '50px'
    },
    deleteMargin: {
        marginTop:'50px'
    },
    approvalInfo: {
        paddingLeft: '64%'
    },
    approvalTitle: {
        color: '#808080',
        fontFamily: 'Helvetica Neue,Roboto,sans-serif',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '18px'
    }
}