import { labelUsageTypeList } from '../../../../services/Gateway/RestAPIHandler';

 
 
 export const LABEL_USAGE_TYPE_GET ="Get Label Usage Type";
 export const LABEL_USAGE_TYPE_SUCCESS = "Label Usage Type Sucess";
 export const LABEL_USAGE_TYPE_FAILURE = "Label Usage Type Failure";
 export function getLabelUsageTypeAction(params) {
    return (dispatch) => {
        dispatch(getLabelUsageType());
        return labelUsageTypeList(params) 
            .then(([response, json]) => {
                if(response.status === 200){
                    dispatch(getLabelUsageTypeSuccess(json.results));
                }
                else {
                    dispatch(getLabelUsageTypeFailure("Unable to fetch label main type data; Error code - " + json.errorCode));
                }
            })
    }
}

export function getLabelUsageType() {
    return {
        type: LABEL_USAGE_TYPE_GET
    }
}

export function getLabelUsageTypeSuccess(data) {
    return {
        type: LABEL_USAGE_TYPE_SUCCESS,
        payload: data

    }

}

export function getLabelUsageTypeFailure(message) {
    return {
        type: LABEL_USAGE_TYPE_FAILURE,
        payload: message
    }
}