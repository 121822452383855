import {PRODUCT_GROUP_GET , PRODUCT_GROUP_SUCCESS,PRODUCT_GROUP_FAILURE } from './actions';

const initialState = {
    order:{}
}

export function ProductGroupReducer(state = initialState, action) {
    
    if(action.type === PRODUCT_GROUP_GET) {
        return state;
    }
    if(action.type === PRODUCT_GROUP_SUCCESS) {
        return {
            ...state,
            order: {
                isLoaded: true,
                data: action.payload,
                message: 'Success'
            }
        }
    }
    if(action.type === PRODUCT_GROUP_FAILURE) {
        return {
            ...state,
            order: {
                data: [],
                message: action.payload
            }
        }
    }
    return state;
}

