import {
    applyMiddleware,
    combineReducers,
    createStore,
    compose
} from 'redux';
import thunk from 'redux-thunk';
import { DashboardReducer } from './reducer';

const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    dashboardList: DashboardReducer

});

export const store = createStore(reducers, composeEnhancers(middleware));