import { labelLayoutGridList } from '../../../../../services/Gateway/RestAPIHandler';
export const LABEL_LIST_Grid_GET = 'Get Label List Grid';
export const LABEL_LIST_Grid_SUCCESS = 'Label List Success Grid';
export const LABEL_LIST_Grid_FAILURE = 'Label List Failure Grid';

export function getLabelListGridAction(params) {
    return (dispatch) => {
        dispatch(getLabelListGrid()); 
        return labelLayoutGridList(params)
            .then(([response, json]) => {              

                if(response.status === 200){
                    dispatch(getLabelListGridSuccess(json));
                } 
                else {
                    dispatch(getLabelListGridFailure("Unable to fetch order data; Error code - " + json.errorCode));
                }
            })
    }
}

export function getLabelListGrid() {
    return {
        type: LABEL_LIST_Grid_GET
    }
}

export function getLabelListGridSuccess(data) {
    return {
        type: LABEL_LIST_Grid_SUCCESS,
        payload: data

    }

}

export function getLabelListGridFailure(message) {
    return {
        type: LABEL_LIST_Grid_FAILURE,
        payload: message
    }
}