import React from 'react'
import Buttons from '../../Orders/OrderList/Buttons';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Box } from '@mui/material';
import { styles,StyledAgGridReact } from './styles';
import { getLabelMainTypeAction } from './Store/actions';
import {useDispatch, useSelector  } from 'react-redux';
import GridPagination from '../../../customWidgets/GridPagination/index';

function LabelMainType(){

    const buttonlist =['Add','Save','Delete'];
    const [currentPage, setCurrentPage] = React.useState(1);
    const GRID_FETCH_SIZE = 8;

    const checkboxSelection = function (params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    };
    const headerCheckboxSelection = function (params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    };
    const columnDef = [
      { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 20 },
    
        { field: 'lblTypeId', headerName: 'Label Main Type ID',editable: true, width: 385, headerClass: 'grid-header' },
        { field: 'lblMain', headerName: 'Name', width: 415,editable: true, headerClass: 'grid-header' },
        { field: 'lblDescription', headerName: 'Description',editable: true, width: 400, headerClass: 'grid-header' },
 
    ];
    // const gridPage = [];
   
  
    const dispatch = useDispatch();
    React.useEffect(() =>{
      dispatch(getLabelMainTypeAction({page: currentPage}))
    },[dispatch, currentPage])
    const list = useSelector(state => state.labelMainTypeList);

    const handleGridPagination = (event, thisPage) => {
      setCurrentPage(thisPage);
    }

    const getTotalPages = React.useMemo(() => {
      if(Object.keys(list.order).length > 0) {
        return list.order.data.length === 0 ?
          {
            totalPages: 1,
            totalElements: 1
          } : {
            totalPages: list.order.data[0].totalPages,
            totalElements: list.order.data[0].totalElements
          };
      }
      else
        return {
          totalPages: 1,
          totalElements: 1
        };
    },[list.order]);

    const gotoKeyDown = (event, value) => {
      if(event.keyCode === 13 && parseInt(value) <= getTotalPages.totalPages) {
        setCurrentPage(parseInt(value));
      }
    }

  return(
    <Box style={styles.gridContainer}>
      <Box className={"ag-theme-alpine"} sx={{height:'400px',width: '100%'}}>
        <StyledAgGridReact
          rowSelection='single'
          rowData={Object.keys(list.order).length > 0 ? list.order.data[0].content : []}
          columnDefs={columnDef}
          pagination={true}
          paginationPageSize={GRID_FETCH_SIZE}
          suppressPaginationPanel={true}
        />
        <Box style={styles.alignBottom}>
          <GridPagination
              pageClick={handleGridPagination}
              gridTotal={getTotalPages}
              currentPage={currentPage}
              pageSize={GRID_FETCH_SIZE}
              gotoKeyDown={gotoKeyDown} />
          <Buttons buttonlist={buttonlist}/>
        </Box>
      </Box>
    </Box>
)
}
export default LabelMainType;