import React,{useState} from 'react';
import { Box, Pagination } from '@mui/material';
import { styles } from "./styles";
import Utils from '../../utils/Utils';

export default function GridPagination(props) {

  const [inputvalue,setInputValue] = useState('')
 
    const handlePageClick = (event, value) => {
      props.pageClick(event, value);
    }

    const gotoKeyDown = (e) => {
      const regexNumber = new RegExp('([0-9])');
      if(regexNumber.test(e.key) || e.keyCode === 13 || e.keyCode === 8
        || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 46) {
          if(e.keyCode === 13){
            //Trigger action when enter key is down
            props.gotoKeyDown(e, e.target.value);
            setInputValue('')
            e.target.blur()
          }
        return true;
      }
      else {
        e.preventDefault();
        return false;
      }
    }

    const showPageInfo = () => {
      return (<span data-testid="grid-pageInfo" style={styles.gridPagination.pageInfo}>
        {(((props.currentPage - 1) * props.pageSize) + 1)} - {props.currentPage===props.gridTotal.totalPages?props.gridTotal.totalElements:(props.currentPage * props.pageSize)} of total {props.gridTotal.totalElements} records {recordSelected()}
      </span>)
    }

    const recordSelected = () => {
      //return (props.totalSelection > 0) ? (props.totalSelection ` rows selected`) : ''
      return (props?.totalSelection === 1) ? (`(` + props?.totalSelection + ` row selected)`)
        : (props?.totalSelection>0) ? (`(` + props?.totalSelection + ` rows selected)`) : ''
    }

    const showAllPageInfo = () => {
      return (<span data-testid="grid-pageInfo" style={styles.gridPagination.pageInfo}>
        1 - {props.gridTotal.totalElements} of total {props.gridTotal.totalElements} records
      </span>)
    }

    return (
      <>
        <Box id="grid-pagination" data-testid="grid-pagination" style={styles.gridPagination}>
          { (props.pageSize > 0 && !Utils.isEmpty(props.gridTotal) && !Utils.isEmpty(props.currentPage) ) ? showPageInfo() : showAllPageInfo() }
          <Box style={styles.gridPagination.pageButton}>
            <Pagination
              sx={styles.gridPagination.muiButton}
              variant="outlined"
              shape="rounded"
              boundaryCount={1}
              siblingCount={0}
              page={props.currentPage ?? 0}
              count={props.gridTotal.totalPages ?? 0}
              defaultPage={1}
              onChange={handlePageClick} />
            <Box id="GotoField" style={styles.Goto}>
              <span>Goto</span>
              <input type='text' value={inputvalue} onChange ={(e)=>{setInputValue(e.target.value)}} className="textbox" data-testid="grid-txt-goto" style={styles.Goto.text} maxLength={4} onKeyDown={gotoKeyDown} ></input>
            </Box>
          </Box>
        </Box>
      </>
    )
}
