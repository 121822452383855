import React, { useState } from 'react'
import {styles} from './styles'
import Input from './Input';

function RangeInput({type, searchChildren,setSearchChildren,index,handleKeyDown}) {
  const [fromValue,setFromValue] = useState(searchChildren[index].fromValue)
  const [toValue,setToValue] = useState(searchChildren[index].toValue)
  
  const updateFromVal = (val) => {
    setTimeout(() => setFromValue(val),500)
  }
  const updateToVal = (val) => {
    setTimeout(() => setToValue(val),500)
  }
  
  React.useEffect(() =>{
    const newchildren = searchChildren.map((ele,i) =>
    {  if(index===i){
        return {...ele,toValue:toValue,fromValue:fromValue,value:''}
      }
      return ele
    })
    
    setSearchChildren(newchildren)       
},[toValue,fromValue])//eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div data-testid="range-search-text" style={styles.Search_Range_Input}>
      <div style={{marginBottom:'5px'}}>
        <label style={styles.rangeInputFrom}>From</label>
        <Input 
          data-testid="range-from-input"
          type={type}
          value={searchChildren[index].fromValue}
          onChange={updateFromVal}
          maxLength={200}
          searchColumn={searchChildren[index].column}
          handleKeyDown={handleKeyDown}
          />
      </div>
      <div>
        <label style={styles.rangeInputTo}>To</label>
        <Input 
          data-testid="range-to-input"
          type={type}
          maxLength={200}
          value={searchChildren[index].toValue}
          onChange={updateToVal}
          handleKeyDown={handleKeyDown}
          searchColumn={searchChildren[index].column}
          />
          
      </div>
    </div>
  )
}

export default RangeInput
