export const ORDERS = {
    labelInfo: "Label Information",
    orderSizeInfo: "Order Size Information",
    labelSizeInfo: "Label Size Information",
    approvalNote: "*Click on SEND FOR APPROVAL for unapproved labels",
    latest:'Latest',
    selected:'Selected',
    button: {
        prepare: "Prepare",
        send: "Send"
    },
    columnDef: {
        checkbox: {field: 'chkBox', headerName: ''},
        orderNumber: {field: 'orderNumber', headerName: 'P.O No.'},
        status: {field: 'status', headerName: 'Status'},
        orderQuantity: {field: 'orderQuantity', headerName: 'Order Quantity'},
        articleNumber: {field: 'articleNumber', headerName: 'Article Number'},
        articleDescription: {field: 'articleDescription', headerName: 'Article Description'},
        modelDescription:{field: 'articleDto.modelDescription', headerName: 'Model Description'},
        countryOfOrigin: {field: 'countryOfOrigin', headerName: 'Origin Country Code'},
        customerCode: {field: 'customerCode', headerName: 'Customer Code'},
        customerOrderNum: {field: 'customerOrderNum', headerName: 'Customer Order No'},
        divisionCode: {field: 'divisionCode', headerName: 'Division'},
        factoryCode: {field: 'factoryDto.factoryCode', headerName: 'Factory Code'},
        factoryName: {field: 'factoryDto.name',headerName: 'Factory Name'},
        lastProductionDate: {field: 'lastProductionDate', headerName: 'Last Production Date'},
        shippingAddressingLine1: {field: 'shippingAddressingLine1', headerName: 'Shipping Addressing Line 1'},
        shippingAddressingLine2: {field: 'shippingAddressingLine2', headerName: 'Shipping Addressing Line 2'},
        shippingAddressingLine3: {field: 'shippingAddressingLine3', headerName: 'Shipping Addressing Line 3'},
        shipToCountry: {field: 'shipToCountry', headerName: 'Shipping Country Code'},
        currency: {field: 'currency', headerName: 'Currency'},
        retailPrice: {field: 'retailPrice', headerName: 'Retail Price'}
    }
}

export const LabelStatus = {
    LOCKED: "LOCKED",
    CANCELLED: "CANCELLED",
    SENT: "SENT",
    PREPARED: "PREPARED",
    PROCESSING: "PROCESSING",
    SHIPPED:"SHIPPED",
    PRINTED:"PRINTED"
}

export const APIStatus = {
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    ERROR: "ERROR"
}

export const GridPage = {
    Previous: 'grid-previous-page',
    Next: 'grid-next-page',
    Current: 'grid-current-page'
}