import React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

function Tiles(props) {
    return (
        <Stack style={{ width: "200px", cursor: "pointer" }}>
            <Link to='/Order_List' state={{Status:props.status}} style={{textDecoration: "none"}}>
                <Paper style={{ backgroundColor: props.bgColor, color: props.fontColor, fontFamily: "Poppins, Helvetica, 'sans-serif'", fontWeight: 600, width: "100%", height: "120px", borderRadius: "15px", padding: "12px" }}>
                    <Typography style={{textAlign: "center", fontFamily: "inherit", fontWeight: "inherit"}}>{props.title}</Typography>
                    <Typography style={{textAlign: "center", fontSize: "52px", fontFamily: "inherit", fontWeight: "inherit"}}>{props.value}</Typography>
                </Paper>
            </Link>
        </Stack>
    )
}

export default Tiles