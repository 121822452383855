import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, IconButton, Stack, Modal, MenuItem, Card, CardContent, Autocomplete, TextField, Select } from '@mui/material';
import { styles, OrderButton, SearchInput } from '../styles';
import { ORDERS } from '../../constant';
import Utils from '../../../../utils/Utils';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAddUserDetails, addUser } from '../../../../services/Gateway/RestAPIHandler'
import SimpleSnackbar from '../../../../customWidgets/Snackbar';
import { getUserListAction } from '../Store/actions';



function AddLabelUser({ openModal, closeModal, currentPage }) {
    const [open, setOpen] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [factoryList, setfactoryList] = useState([]);
    const [group, setGroup] = useState([]);
    const [telephone, setTelephone] = useState('');
    const list = useSelector(state => state.userlist)
    const [emailFactory, setEmailFactory] = useState([]);
    const [groupCode, setgroupCode] = useState('');
    const [age, setAge] = useState('');
    const [country, setCountry] = useState('')
    const dispatch = useDispatch();
    const GRID_FETCH_SIZE = 7;
    const presentPage = 1;
    // const [productgrouplist,setProductgruoplist] = useState(inputData.product);
    const emailNotifyList = ["Y", "N"];
    const emailCodeList = [
        {
            id: "A",
            name: "BOTH"
        },
        {
            id: "1",
            name: "FOOTWEAR"
        },
        {
            id: "2",
            name: "APPAREL"
        },
        {
            id: "0",
            name: "NONE"
        }

    ];


    const [emailCode, setEmailCode] = React.useState('');
    const [emailNotify, setEmailNotify] = React.useState('');

    /* Values are hardcoded temporarily till get data from API */
    React.useEffect(() => {
        return (dispatch) => {
            fetchAddUserDetails()
                .then(([response, json]) => {
                    if (response.status === 200) {
                        setCountryList(json.results[0].country);
                        setfactoryList(json.results[0].factory);
                        setGroup(json.results[0].userGroup);

                    }
                    else {
                        //  dispatch(getUserListFailure("Unable to fetch User Groups data; Error code - " + json.errorCode));
                    }
                })
        }
    }, [list.order])




    const callbackFromMultiSelect = (labels) => {
        setEmailFactory(labels);
    };

    const clearValues = () => {
        setEmailFactory([]);
        setEmailCode("");
        setEmailNotify("");
        setCountry("");
    }


    const handleCountryChange = (event, newValue, reason) => {
        if (reason === 'clear')
            setCountry('');
        else
            countryList.filter(ele => ele.countryName.includes(newValue)).map(ele => setCountry(ele.countryName));
    }

    const handleGroupChange = (event, newValue, reason) => {
        newValue =(newValue === 'Factory User' ? 'factory_group':'admin_group');
        if (reason === 'clear')
            setgroupCode('');
        else
            group.filter(ele => ele.name.includes(newValue)).map(ele => setgroupCode(ele.groupId));
        if (newValue === 'admin_group') {
            document.getElementById("emailCodes").style.display = 'block';
            document.getElementById("multiple-limit-tags").style.display = 'none';
        } else {
            document.getElementById("emailCodes").style.display = 'none';
            document.getElementById("multiple-limit-tags").style.display = 'block';
        }

    }

    const handleEmailCodeChange = (event, newValue, reason) => {
        if (reason === 'clear')
            setEmailCode('');
        else
            emailCodeList.filter(ele => ele.name.includes(newValue)).map(ele => setEmailCode(ele.id));
    }

    const handleEmailNotifyChange = (event, newValue, reason) => {
        if (reason === 'clear')
            setEmailNotify('');
        else
            emailNotifyList.filter(ele => ele.includes(newValue)).map(ele => setEmailNotify(ele));
    }
    const handleGridSave = async () => {
        let vlabels = Object.keys(emailFactory).map(function (k) { return emailFactory[k] }).join(",");
        if (document.getElementsByClassName("userID")[0].value === '' || document.getElementsByClassName("userID")[0].value === "") {
            setAlertType('error')
            setAlertMessage("Please enter UserID")
            setOpen(true)
        } else if (document.getElementsByClassName("firstName")[0].value === '' || document.getElementsByClassName("firstName")[0].value === "") {
            setAlertType('error')
            setAlertMessage("Please enter First Name")
            setOpen(true)
        } else if (document.getElementsByClassName("lastName")[0].value === '' || document.getElementsByClassName("lastName")[0].value === "") {
            setAlertType('error')
            setAlertMessage("Please enter Last Name")
            setOpen(true)
        } else if (country === '' || country === "") {
            setAlertType('error')
            setAlertMessage("Select Country")
            setOpen(true)
        } else if (groupCode === '' || groupCode === "") {
            setAlertType('error')
            setAlertMessage("Select Group")
            setOpen(true)
        } else if (emailNotify === '' || emailNotify === "") {
            setAlertType('error')
            setAlertMessage("Select Email Notify")
            setOpen(true)
        } else if (document.getElementsByClassName("email")[0].value === '' || document.getElementsByClassName("email")[0].value === "") {
            setAlertType('error')
            setAlertMessage("Please enter email ID")
            setOpen(true)
        } else {
            handleSave();
        }
    }

    const handleSave = async () => {
        let vlabels = Object.keys(emailFactory).map(function (k) { return emailFactory[k] }).join(",");
        addUser({

            "countryName": country,
            "email": document.getElementsByClassName("email")[0].value,
            "emailCode": emailCode,
            "emailFactory": vlabels,
            "emailNotify": emailNotify,
            "firstName": document.getElementsByClassName("firstName")[0].value,
            "groupCode": groupCode,
            "lastName": document.getElementsByClassName("lastName")[0].value,
            "liaisonOfficeCode": "01",
            "telephone": document.getElementsByClassName("telephone")[0].value,
            "userId": document.getElementsByClassName("userID")[0].value
        })
            .then(([response, json]) => {
                if (response.status === 200) {
                    if (json.results === undefined) {
                        setAlertType('error')
                        setAlertMessage("Please enter the details correctly")
                        setOpen(true)
                    }
                    else if (json.results[0] === 'SUCCESS') {
                        setAge('')
                        setCountry('')
                        setEmailFactory([])
                        setAlertType('success')
                        setAlertMessage("User Added Successfully")
                        setOpen(true)
                        closeModal(false)
                        dispatch(getUserListAction({ fetchSize: GRID_FETCH_SIZE, page: presentPage }))
                    } else {
                        setAlertType('error')
                        setAlertMessage(json.results[0])
                        setOpen(true)
                    }
                }
                else {
                    setAlertType('error')
                    setAlertMessage("Unable to Add User")
                    setOpen(true)
                }
            })
    }

    return (
        <>
            <SimpleSnackbar setOpen={setOpen} open={open} message={alertMessage} messagetype={alertType} />
            <Modal
                open={openModal}
                onClose={() => closeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={styles.popupStyle} style={{ overflowY: 'scroll' }}>
                    <Typography component='div' style={styles.popupContent.title}>
                        <Typography component='span' style={styles.popupContent.titleText}>Add User</Typography>
                        <IconButton id="close-modal-popup" data-testid="close-modal-popup" onClick={() => { closeModal(false); clearValues() }} style={styles.popupContent.closeButton}>
                            <CloseIcon />
                        </IconButton>
                    </Typography>

                    <Stack>
                        <Box sx={styles.popupContent.row}>

                            <SearchInput type={'text'} style={{ width: 230 }} placeholder='User ID' className='userID' />

                            <SearchInput type={'text'} style={{ width: 230 }} placeholder='First Name' className='firstName' />

                        </Box>





                        <Box sx={styles.popupContent.row}>
                            <SearchInput type={'text'} style={{ width: 230 }} placeholder='Last Name' className='lastName' />

                            <Autocomplete
                                id="customer"
                                options={group.map(ele => ele.name==='factory_group'?'Factory User':'Admin User')}
                                onChange={(e25, newValue25, reason25) => handleGroupChange(e25, newValue25, reason25)}
                                style={{ width: 230 }}
                                renderInput={(params25) => (
                                    <TextField {...params25} label="User Type" variant="outlined" />
                                )}
                            />
                        </Box>


                        <Box sx={styles.popupContent.row}>
                            <SearchInput type={'text'} style={{ width: "230px" }} placeholder='Email' className='email' />
                            <Autocomplete
                                id="product-group"
                                options={emailNotifyList.map(ele => ele)}
                                onChange={(e21, newValue21, reason21) => handleEmailNotifyChange(e21, newValue21, reason21)}
                                style={{ width: 230 }}
                                renderInput={(params21) => (
                                    <TextField {...params21} label="Email Notify" variant="outlined" />
                                )}
                            />

                        </Box>

                        <Box sx={styles.popupContent.row}>
                            <SearchInput type={'text'} style={{ width: "230px" }} placeholder='Telephone Number' className='telephone' />

                            <Autocomplete
                                id="specialdescription"
                                options={countryList.map(ele => ele.countryName)}
                                onChange={(e24, newValue24, reason24) => handleCountryChange(e24, newValue24, reason24)}
                                style={{ width: 230 }}
                                renderInput={(params24) => (
                                    <TextField {...params24} label="Country" variant="outlined" />
                                )}
                            />

                        </Box>

                        <div id="emailCodes">
                            <Box sx={styles.popupContent.row} >

                                <Autocomplete

                                    options={emailCodeList.map(ele => ele.name)}
                                    onChange={(e22, newValue22, reason22) => handleEmailCodeChange(e22, newValue22, reason22)}
                                    style={{ width: 480 }}
                                    renderInput={(params22) => (
                                        <TextField {...params22} label="Approval Notification for Division" variant="outlined" />
                                    )}
                                />

                            </Box>
                            <Typography component='div' style={styles.approvalInfo}>
                                <Typography style={styles.approvalTitle} component="span">{ORDERS.EmailCodenote}</Typography>
                            </Typography>
                        </div>
                        <div id="multiple-limit-tags">
                            <Box sx={styles.popupContent.row}>
                                <Autocomplete
                                    multiple
                                    limitTags={2}
                                    options={factoryList.map(ele => ele.factoryCode)}
                                    onChange={(event, newValues23) => {
                                        callbackFromMultiSelect(newValues23);
                                    }}
                                    renderInput={(params23) => (
                                        <TextField {...params23} label="Approval Notification for Factories" />
                                    )}
                                    sx={{ width: '480px' }}
                                />
                            </Box>

                            <Typography component='div' style={styles.approvalInfo}>
                                <Typography style={styles.approvalTitle} component="span">{ORDERS.EmailFactorynote}</Typography>
                            </Typography>
                        </div>

                        <Box sx={styles.popupContent.button}>
                            <OrderButton onClick={handleGridSave}>SAVE</OrderButton>
                        </Box>

                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default AddLabelUser;