import {apisearchLabelList, addLabelRow} from '../../../../services/Gateway/RestAPIHandler';
import Utils from '../../../../utils/Utils';

export const LABEL_LIST_GET = 'Get Label List Admin';
export const LABEL_LIST_SUCCESS = 'Label List Success Admin';
export const LABEL_LIST_FAILURE = 'Label List Failure Admin';

export const LABEL_LIST_ADD = 'Add Label List Admin';
export const LABEL_LIST_ADD_SUCCESS = 'Add Label List Success Admin';
export const LABEL_LIST_ADD_FAILURE = 'Add Label List Failure Admin';

const searchObjectDefault = {
    "ascDesc": "",
    "fetchSize": 7,
    "filterSearch": "",
    "pageNumber": 1,
    "sortBy": ""
}
const searchColumnsDefault = [{column:'All columns',searchterm:'',inputtype:'',value:'',type:'Regular',toValue:'',fromValue:''}]


export function getLabelListAdminAction(params=searchObjectDefault,searchColumns=searchColumnsDefault) {
    return (dispatch) => {
        dispatch(getLabelListAdmin());
        return apisearchLabelList(params) 
            .then(([response, json]) => {
                if(response.status === 200 && json.status!=="ERROR"){
                    dispatch(getLabelListAdminSuccess(json.results,params,searchColumns));
                }
                else {
                    dispatch(getLabelListAdminFailure(`Unable to fetch label list data; Error code - ${Utils.isEmpty(json.errorCode) ? 0 : json.errorCode}`,params,searchColumns));
                }
            })
    }
}

export function addLabelListAdminAction(params) {
    return (dispatch) => {
        dispatch(addLabelListAdmin());
        return addLabelRow(params) 
            .then(([response, json]) => {
                if(response.status === 200){
                    dispatch(addLabelListAdminSuccess(json.results,params));
                    dispatch(getLabelListAdminAction(searchObjectDefault));
                }
                else {
                    dispatch(addLabelListAdminFailure("Unable to fetch label list data; Error code - " + json.errorCode,params));
                }
            })
    }
}

export function getLabelListAdmin() {
    return {
        type: LABEL_LIST_GET
    }
}

export function getLabelListAdminSuccess(res,params,searchColumns) {
    return {
        type: LABEL_LIST_SUCCESS,
        payload: {res:res,request:params,searchColumns:searchColumns}

    }

}

export function getLabelListAdminFailure(message,params,searchColumns) {
    return {
        type: LABEL_LIST_FAILURE,
        payload: {message:message,request:params,searchColumns:searchColumns}
    }
}

export function addLabelListAdmin() {
    return {
        type: LABEL_LIST_ADD
    }
}

export function addLabelListAdminSuccess(res,params) {
    return {
        type: LABEL_LIST_ADD_SUCCESS,
        payload: {res:res,request:params}
    }
}

export function addLabelListAdminFailure(message,params) {
    return {
        type: LABEL_LIST_ADD_FAILURE,
        payload: {message:message,request:params}
    }
}