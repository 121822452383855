import { styled } from "@mui/system";
import { Box } from '@mui/material'
import logo from '../../../../assets/upload.JPG'
import { Button } from "@mui/material";

export const Div = styled('div')({
    paddingTop: '13px',
    paddingLeft: '20px'
});
export const StyledCard = styled(Box)({
    paddingBottom: '0px',
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    padding: '5px 14px ',

});

export const OrderButton = styled(Button)({
    zIndex: '10',
    cursor: 'pointer',
    borderRadius: '4px',
    marginRight: '30px',
    width: '120px',
    height: '40px',
    color:'#FFFFFF',
    border: '1px solid #293E53',
    fontFamily: 'Poppins',
    fontSize: '14px', 
    background: '#293E53 0% 0% no-repeat padding-box',
    opacity: '1',
    padding:0,
    
    '&:hover':{
        border: '1px solid #293E53',
        borderRadius: '4px',
        color: '#293E53',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '5px 5px 5px rgba(0,0,0,0.3)'
    },
    '&.disabled':{
        background: '#878787 0% 0% no-repeat padding-box',
        border: '1px solid #0000',
        opacity:1,
    }


})

export const styles = {

col1field :{
    width: '30%',
},
uploadBtnWrapper : {
    position: 'relative',
    overflow: 'hidden',
    display: 'inline-block',
    width: '16.7%',

   
},
textbox : {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #707070',
    borderRadius:'4px',
    opacity: '1',
    width: '137px',
    height: '28px',
},
padding50 :{
    paddingLeft: '35px',
    marginBottom: '0px',
},
padding20 :{
    paddingLeft: '30px',

},
btn : {
    background: `url(${logo}) #FFFFFF 0% 0% no-repeat padding-box`,
    border: '1px solid #293E53',
    backgroundPosition:  'right',
    backgroundSize: '18px 18px',
    borderRadius: '4px',
    textAlign: 'left',
    opacity: '1',
    width: '137px',
    height: '28px',
    font: 'normal normal normal 14px/20px Poppins',
    letterSpacing: '0px',
    color: '#293E53',
},
uploadBtnWrapperInput  : {
    pointerEvents: 'none',
    fontSize: '100px',
    position: 'absolute',
    left: '0',
    top: '0',
    opacity: '0',
},
col2field :{
    width: '16.7%',
},
col3field :{
    width: '21.7%',
    
},
}



