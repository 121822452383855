import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, IconButton, Stack, Modal, MenuItem, Card, CardContent, Autocomplete, TextField, Select } from '@mui/material';
import { styles, OrderButton, SearchInput } from '../styles';
import Utils from '../../../../utils/Utils';
import { useSelector, useDispatch } from 'react-redux';
import { addLabelAssignmentRow } from '../../../../services/Gateway/RestAPIHandler'
import SimpleSnackbar from '../../../../customWidgets/Snackbar';
import { searchLabelAssignmentAction } from '../Store/actions';



function AddLabelAssignment({ openModal, closeModal, currentPage }) {
    const [open, setOpen] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [inputData, setInputData] = useState({ division: [], ageGroup: [], product: [], country: [], customerLis: [], labelCode: [], specialUsages: [], label: [] })
    const list = useSelector(state => state.labelAssignmentList)
    const [labelCode, setLabelCode] = useState([]);
    const [division, setDivision] = useState('');
    const [age, setAge] = useState('');
    const [specialdesc, setSpecialdesc] = useState('');
    const [isspecialusage, setIsspecialusage] = useState('');
    const [product, setProduct] = useState('')
    const [country, setCountry] = useState('')
    const [customer, setCustomer] = useState('')
    const [label, setLabel] = useState('')
    const dispatch = useDispatch();
    const GRID_FETCH_SIZE = 7;
    // const [productgrouplist,setProductgruoplist] = useState(inputData.product);
    const specialOptionList = ["Y", "N"];
    const [productOptions, setProductOptions] = React.useState([]);
    const [labelOptions, setLabelOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [inputSpecialValue, setInputSpecialValue] = React.useState('');

    /* Values are hardcoded temporarily till get data from API */
    React.useEffect(() => {
        if (Object.keys(list.order).length > 0 && !Utils.isEmpty(list.order.data[0])) {

            setInputData(list.order.data[0].referenceMap)

            if (list.order.data[0].referenceMap.product !== undefined) {
                setProductOptions(list.order.data[0].referenceMap.product.map(ele => ele.value));
            }
            if (list.order.data[0].referenceMap.labelCode !== undefined) {
                setLabelOptions(list.order.data[0].referenceMap.labelCode.map(ele => ele));
            }
            setIsspecialusage('N')
        }
    }, [list.order])


    const handleChange = (event) => {
        const { target: { value } } = event;
        setLabelCode(
            // On autofill we get a stringifie
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSpecialUsageChange = (event, value, reason) => {
        if (reason === 'clear') {
            setInputSpecialValue('');
            setIsspecialusage('N');
        }
        else {
            inputData.specialUsages.filter(ele => ele.description.includes(value)).map(ele => setInputSpecialValue(ele.description));
            const specialUsageVal = isspecialusage;
            if (specialUsageVal === 'N') {
                //document.getElementById("special").innerHTML = "";
                setInputSpecialValue('');
                setAlertType('error')
                setAlertMessage("Is Special Usage should be Yes for selecting Special Usage Description")
                setOpen(true)
            }
        }

    };

    const callbackFromMultiSelect = (labels) => {
        setLabelCode(labels);
    };

    const clearValues = () => {
        setLabelCode([]);
        setProduct('');
        setIsspecialusage('N');
        setSpecialdesc('');
        setInputValue('');
        setInputSpecialValue('');
    }
    // const handlesearch = () => {
    //     let searchString =''
    //     if(age!==''){
    //         searchString+=`ageGroup:${age},`
    //     }
    //     if(country!==''){
    //         searchString+=`shippingCountry:${country},`
    //     }
    //     if(division!==''){
    //         searchString+=`division:${division},`
    //     }
    //     if(customer!==''){
    //         searchString+=`customerNumber:${customer},`
    //     }
    //     if(product!==''){
    //         searchString+=`productGroup:${product},`
    //     }
    // }

    const handleproductChange = (event, newValue, reason) => {
        if (reason === 'clear')
            setInputValue('')
        else
            inputData.product.filter(ele => ele.value.includes(newValue)).map(ele => setInputValue(ele.value));
    }

    const handleCountryChange = (event, newValue, reason) => {
        if (reason === 'clear')
            setCountry('');
        else
            inputData.country.filter(ele => ele.countryName.includes(newValue)).map(ele => setCountry(ele.countryName));
    }

    const handleCustomerChange = (event, newValue, reason) => {
        if (reason === 'clear')
            setCustomer('');
        else
            inputData.customerLis.filter(ele => ele.customerCode.includes(newValue)).map(ele => setCustomer(ele.customerCode));
    }

    const handleAgeChange = (event, newValue, reason) => {
        if (reason === 'clear')
            setAge('');
        else
            inputData.ageGroup.filter(ele => ele.value.includes(newValue)).map(ele => setAge(ele.value));
    }

    const handledivisionChange = (newValue, reason) => {
        if (reason === 'clear') {
            setDivision('');
            setInputValue('');
            setLabelCode([]);
        }
        else {
            inputData.division.filter(ele => ele.value.includes(newValue)).map(ele => setDivision(ele.id));
            setInputValue('');
            setLabelCode([]);
            const code = (newValue === 'FOOTWEAR') ? '1' : '2';
            setProductOptions(inputData.product.filter(ele => ele.division.includes(code)).map(ele => ele.value));
            setLabelOptions(inputData.label.filter(ele => ele.division.includes(code)).map(ele => ele.labelCode));
        }
    }

    const handleSpecialOptionChange = (event, newValue, reason) => {
        if (reason === 'clear') {
            setIsspecialusage('');
            setInputSpecialValue('');
        }
        else {
            setIsspecialusage(newValue);
            setInputSpecialValue('');
        }

    }

    const handleGridSave = async () => {
        let vlabels = Object.keys(labelCode).map(function (k) { return labelCode[k] }).join(",");
        if (division === '' || division === "") {
            setAlertType('error')
            setAlertMessage("Select Division")
            setOpen(true)
        } else if (age === '' || age === "") {
            setAlertType('error')
            setAlertMessage("Select Age Group")
            setOpen(true)
        } else if (country === '' || country === "") {
            setAlertType('error')
            setAlertMessage("Select Country")
            setOpen(true)
        } else if (customer === '' || customer === "") {
            setAlertType('error')
            setAlertMessage("Select Customer")
            setOpen(true)
        } else if (inputValue === '' || inputValue === "") {
            setAlertType('error')
            setAlertMessage("Select Product Group")
            setOpen(true)
        } else if (isspecialusage === '' || isspecialusage === "") {
            setAlertType('error')
            setAlertMessage("Select Speical Usage")
            setOpen(true)
        } else if (isspecialusage === 'Y' && (inputSpecialValue === '' || inputSpecialValue === "")) {
            setAlertType('error')
            setAlertMessage("Select special usage description")
            setOpen(true)
        } else if (vlabels === '' || vlabels === "") {
            setAlertType('error')
            setAlertMessage("Label Code Required")
            setOpen(true)
        } else {
            handleSave();
        }
    }

    const handleSave = async () => {
        setIsspecialusage(
            specialdesc === '' ? "N" : "Y",
        )
        let vlabels = Object.keys(labelCode).map(function (k) { return labelCode[k] }).join(",");
        addLabelAssignmentRow({

            "ageGroup": age,
            "customerNumber": customer,
            "division": {
                "code": division
            },
            "labelCode": vlabels,
            "productGroup": inputValue,
            "shippingCountry": { "countryName": country },
            "specialUsageField": inputSpecialValue,
            "isSpecialUsage": isspecialusage

        })
            .then(([response, json]) => {
                if (response.status === 200) {
                    if (json.results === undefined) {
                        setAlertType('error')
                        setAlertMessage("Same ALA combination already exists")
                        setOpen(true)
                    }
                    else if (json.results[0] === 'SUCCESS') {
                        setAge('')
                        setCountry('')
                        setCustomer('')
                        setDivision('')
                        setLabelCode([])
                        setProduct('')
                        setIsspecialusage('N')
                        setSpecialdesc('')
                        setInputValue('');
                        setInputSpecialValue('');
                        setAlertType('success')
                        setAlertMessage("Label Assignment Added Successfully")
                        setOpen(true)
                        closeModal(false)
                        dispatch(searchLabelAssignmentAction({
                            "ascDesc": "",
                            "fetchSize": GRID_FETCH_SIZE,
                            "filterSearch": "",
                            "pageNumber": currentPage,
                            "sortBy": ""
                        }))
                    } else {
                        setAlertType('error')
                        setAlertMessage("Add Label Assignment Error ")
                        setOpen(true)
                    }
                }
                else {
                    setAlertType('error')
                    setAlertMessage("Unable to Add Label Assignment")
                    setOpen(true)
                }
            })
    }

    return (
        <>
            <SimpleSnackbar setOpen={setOpen} open={open} message={alertMessage} messagetype={alertType} />
            <Modal
                open={openModal}
                onClose={() => closeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={styles.popupStyle} style={{ overflowY: 'scroll' }}>
                    <Typography component='div' style={styles.popupContent.title}>
                        <Typography component='span' style={styles.popupContent.titleText}>Add Label Assignment</Typography>
                        <IconButton id="close-modal-popup" data-testid="close-modal-popup" onClick={() => { closeModal(false); clearValues() }} style={styles.popupContent.closeButton}>
                            <CloseIcon />
                        </IconButton>
                    </Typography>

                    <Stack>
                        <Box sx={styles.popupContent.row}>

                            <Autocomplete
                                id="division"
                                options={inputData.division.map(ele => ele.value)}
                                onChange={(e8, newValue8, reason8) => handledivisionChange(newValue8, reason8)}
                                style={{ width: 230 }}
                                renderInput={(params8) => (
                                    <TextField {...params8} label="Division" variant="outlined" />
                                )}
                            />
                            <Autocomplete
                                id="age"
                                options={inputData.ageGroup.map(ele => ele.value)}
                                onChange={(e7, newValue7, reason7) => handleAgeChange(e7, newValue7, reason7)}
                                style={{ width: 230 }}

                                renderInput={(params7) => (
                                    <TextField {...params7} label="Age Group" variant="outlined" />
                                )}
                            />
                        </Box>





                        <Box sx={styles.popupContent.row}>
                            <Autocomplete
                                id="country"
                                options={inputData.country.map(ele => ele.countryName)}
                                onChange={(e2, newValue2, reason2) => handleCountryChange(e2, newValue2, reason2)}
                                style={{ width: 230 }}
                                renderInput={(params2) => (
                                    <TextField {...params2} label="Country" variant="outlined" />
                                )}
                            />
                            <Autocomplete
                                id="customer"
                                options={inputData.customerLis.map(ele => ele.customerCode)}
                                onChange={(e3, newValue3, reason3) => handleCustomerChange(e3, newValue3, reason3)}
                                style={{ width: 230 }}
                                renderInput={(params3) => (
                                    <TextField {...params3} label="Customer" variant="outlined" />
                                )}
                            />
                        </Box>


                        <Box sx={styles.popupContent.row}>
                            <Autocomplete
                                id="specialoption"
                                options={specialOptionList.map(ele => ele)}
                                value={isspecialusage}
                               // defaultValue="N"
                                onChange={(e4, newValue4, reason4) => handleSpecialOptionChange(e4, newValue4, reason4)}
                                style={{ width: 230 }}
                                renderInput={(params4) => (
                                    <TextField {...params4} label="Is Special Usage" variant="outlined" />
                                )}
                            />

                            <Autocomplete
                                id="specialdescription"
                                value={inputSpecialValue}
                                options={inputData.specialUsages.map(ele => ele.description)}
                                onChange={(e5, newValue5, reason5) => handleSpecialUsageChange(e5, newValue5, reason5)}
                                style={{ width: 230 }}
                                renderInput={(params5) => (
                                    <TextField {...params5} label="Special Usage Description" variant="outlined" />
                                )}
                            />

                        </Box>

                        <Box sx={styles.popupContent.row}>
                            <Autocomplete
                                id="product-group"
                                options={productOptions}
                                value={inputValue}
                                onChange={(e1, newValue1, reason1) => handleproductChange(e1, newValue1, reason1)}
                                style={{ width: 480 }}
                                renderInput={(params1) => (
                                    <TextField {...params1} label="Product Group Description" variant="outlined" />
                                )}
                            />

                        </Box>


                        {/* <Box sx={styles.popupContent.button}>
                        <OrderButton className={age===''&&customer===''&&country===''&&product===''&&division===''?'disabled':''} onClick={handlesearch}>GO</OrderButton>
                    </Box> */}
                        {/* <Box sx={styles.popupContent.row}>
                            <Typography component='span'>Is Special Usage</Typography>
                            <Select id="specialUsage"
                                displayEmpty sx={styles.popupContent.dropdown}
                                value={isspecialusage}
                                onChange={handleSpecialUsageChange}
                                >
                                {specialOptionList.map(ele => <MenuItem key={ele} value={ele}>{ele}</MenuItem>)}    
                            </Select>
                </Box> 
                        <Box sx={styles.popupContent.row}>
                            <Typography component='span'>Is Special Usage</Typography>

                        </Box>
                         <Box sx={styles.popupContent.row}>
                            <Typography component='span'>Is Special Usage</Typography>
                            <SearchInput type={'text'} list="isu" onChange={handleSpecialUsageChange} />

                            <datalist
                                displayEmpty sx={styles.popupContent.dropdown}
                                value={isspecialusage} id="isu" >
                                {specialOptionList.map(ele => <option key={ele} value={ele}>{ele}</option>)}
                            </datalist>
                        </Box>
                                */}


                        <Box sx={styles.popupContent.row}>
                            <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-limit-tags"
                                options={labelOptions}
                                value={labelCode}
                                onChange={(event, newValues6) => {
                                    callbackFromMultiSelect(newValues6);
                                }}
                                renderInput={(params6) => (
                                    <TextField {...params6} label="Label Codes" />
                                )}
                                sx={{ width: '480px' }}
                            />
                        </Box>

                        <Box sx={styles.popupContent.button}>
                            <OrderButton onClick={handleGridSave}>SAVE</OrderButton>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default AddLabelAssignment;