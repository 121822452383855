import { styled} from '@mui/system';

export const StyledPopperDiv = styled('div')(
   
{    padding: '0.5rem',
    border: '1px solid',
    backgroundColor:'#fff',
    opacity: 1,
    margin: '0.25rem 0px',
    position:'absolute',
    top:'70px',
    left:'600px',
    '@media(max-width:1370px)':{
      position:'absolute',
      top:'70px',
      left:'400px',
      
  },
  }
  
  )
  export const StyledPopperDiv2 = styled('div')(
   
    {    padding: '0.5rem',
        border: '1px solid',
        backgroundColor:'#fff',
        opacity: 1,
        margin: '0.25rem 0px',
      }
      
      )
  export const SearchInput = styled('input')({
    border: 'none',
    outline: 'none',
    top: '25px',
    left: '1040px',
    width: '134px',
    height: '20px',
    textAlign: 'left',
    marginRight:'40px',
    font: 'normal normal normal 14px/21px Poppins',
    letterSpacing: '0.1px',
    color: '#878787',
    opacity: 1,
    '&:disabled':{
      backgroundColor: '#FFFFFF'
  }
  })

  export const StyledInput = styled('input')({
      paddingLeft:'10px',
      fontSize:'14px',
      fontFamily:'Poppins',
      userSelect:'none'
    
  })
  export const Searchbtn = styled('button')({
    
    marginRight:'20px',
    width: '29px',
    height: "29px",
    background:'transparent',
    border: 'none',
    cursor: 'pointer',
    opacity: 1,
    '&:hover':{
      boxShadow: 'none',
    }
    

  })

export const styles = {
  Search_row_input:{
    height: '60%',
    width: '58%',
    border: '1px solid rgba(0,0,0,0.3)',
    marginLeft: '20px',
    marginRight:'10px',
    borderRadius:'5px'
},
Search_Select_btn:{
  paddingLeft:'10px',
  maxWidth:'170px',
  minWidth: '170px',
  height: '70%',
  maxHeight:'90%',
  background: '#6498CC 0% 0% no-repeat padding-box',
  borderRadius: '4px',
  opacity: 1,
  textAlign: 'left',
  font: 'normal normal normal 14px/21px Poppins',
  letterSpacing: '0.1px',
  color: '#FFFFFF',
  padding: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent:'space-between',
  marginLeft:'6px',
  border:'none'
}
,
Search_Select:{
  width: '266px',
  height: 'fit-content',
  maxHeight:'314px',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 3px 20px #00000029',
  border: '1px solid #F2F2F2',
  borderRadius: '5px',
  opacity: 1,
  overflowY: 'auto',
  zIndex:'20'
},
Addquery_btn:{
  width: '112px',
  height: '38px',
  background:' #FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #293E53',
  borderRadius: '4px',
  opacity: 1,
  margin: '50px 20px 0 5%',

},
ClearAll_btn1:{
  border:'none',
  outline:'none',
  width: 'fit-content',
  maxHeight: '35px',
  padding:'0 5px 0 5px',
  marginRight:'30px',
  font: 'normal normal normal 14px/21px Poppins',
  letterSpacing: '0.1px',
  color: '#FF4343',
  background: '#FF434310 0% 0% no-repeat padding-box',
  borderRadius: '4px',
  opacity: 1,
},
popupStyle: {
  position: 'absolute',
  paddingTop:'20px',
  top: '70px',
  left: '50%',
  transform: 'translate(-50%,0%)',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  userSelect: 'none',
  outline: 'none',
  width: '584px',
  minHeight: '311px',
  border: '1px solid #DEDEDE',
  borderRadius: '4px',
  opacity: 1,
  boxShadow: 1,
},
ClearAll_btn:{
  border:'none',
  outline:'none',
  width: '81px',
  height: '38px',
  font: 'normal normal normal 14px/21px Poppins',
  letterSpacing: '0.1px',
  color: '#FF4343',
  background: '#FF434310 0% 0% no-repeat padding-box',
  borderRadius: '4px',
  opacity: 1,
},Search_drawer_btn2:{
  // marginLeft:'60%',
  width: '130px',
  height: '49px',
  cursor: 'pointer',
  background: '#FF434310 0% 0% no-repeat padding-box',
  border: '1px solid #293E53',
  borderRadius: '4px',
  opacity: 1,
  textAlign: 'center',
  font: 'normal normal normal 14px/21px Poppins',
  letterSpacing: '0.1px',
  color: '#FF4343',
  marginRight:'20px'
},
Search_drawer_btn:{
  // marginLeft:'75%',
 
  width: '130px',
  height: '49px',
  cursor: 'pointer',
  background: '#293E53 0% 0% no-repeat padding-box',
  border: '1px solid #293E53',
  borderRadius: '4px',
  opacity: 1,
  textAlign: 'center',
  font: 'normal normal normal 14px/21px Poppins',
  letterSpacing: '0.1px',
  color: '#FFFFFF',
  marginRight:'20px'
},
Search_drawer_btn_disabled:{
  // marginLeft:'75%',
 
  width: '130px',
  height: '49px',
  cursor: 'pointer',
  border: '1px solid #293E53',
  borderRadius: '4px',
  textAlign: 'center',
  font: 'normal normal normal 14px/21px Poppins',
  letterSpacing: '0.1px',
  color: '#FFFFFF',
  marginRight:'20px',
  background: '#878787 0% 0% no-repeat padding-box',
  opacity:1,
  pointerEvents: 'none'
},
Search_sort:{
  display:'flex',
  marginBottom:'20px'
},
Sort_Text:{
  color:'#FF4343',
  marginTop:'20px',
},
Search_Select_div:{
  display:'flex',
  alignItems:'center',
  width: '570px',
  height: '65px',
  marginBottom:'4px',
  background:'#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow:'0px 3px 6px #00000029',
},
Search_div:{
  fontSize: '14px',
  userSelect: 'none',
  outline: 'none',
  width: '584px',
  minHeight: '270px',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #DEDEDE',
  borderRadius: '4px',
  opacity: 1,
  },
Option_div:{
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  marginTop:'20px',
  marginBottom:'20px'
},
  Search_Range_Input:{
    display:'flex',
    flexDirection:'column',
    width: '70%',
    marginLeft: '30px'
  },
  RangeInput:{
    height:'100%',
    width:'200px'
},
Search:{
  border:'1px solid rgba(0,0,0,0.3)',
  userSelect: 'none',
  position:'absolute',
  top: '8px',
  display:'flex',
  alignItems: 'center',
  justifyContent: 'end',
  left: '800px',
  width: '240px',
  height: '50px',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxshadow: '0px 0px 6px #00000029',
  borderRadius: '8px',
  opacity: 1,
  cursor: 'pointer',
},
dropdown: {
  fontSize:'14px',
  justifyContent:'space-between',
  margin:'10px',
  userSelect:'none',
  cursor:'pointer',
},
rangeInputFrom: {
  marginRight:'20px',
  fontFamily:'Poppins'
},
rangeInputTo: {
  marginRight:'40px',
  fontFamily:'Poppins'
}
}