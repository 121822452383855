import { apiOrderList,apiLabelList,apisearchApprovalOrderList} from '../../../../services/Gateway/RestAPIHandler';
import Utils from '../../../../utils/Utils';
export const ORDER_LIST_GET = 'Get Order List';
export const ORDER_LIST_SUCCESS = 'Order List Success';
export const ORDER_LIST_FAILURE = 'Order List Failure';
export const LABEL_LIST_GET = 'Get Label List';
export const LABEL_LIST_SUCCESS = 'Label List Success';
export const LABEL_LIST_FAILURE = 'Label List Failure';
const searchObjectDefault = {
    "ascDesc": "",
    "fetchSize": 15,
    "filterSearch": "",
    "pageNumber": 1,
    "sortBy": ""
}
const searchColumnsDefault = [{column:'All columns',searchterm:'status',inputtype:'',value:'',type:'Regular',toValue:'',fromValue:''}]

export function searchApprovalOrderListAction(params=searchObjectDefault,searchColumns=searchColumnsDefault) {
    return (dispatch) => {
        return apisearchApprovalOrderList(params)
            .then(([response, json]) => {
                if(response.status === 200 && json.status!=="ERROR"){
                    let res = json.results
                    dispatch(getOrderListSuccess(res,params,searchColumns));
                }
                else {
                    dispatch(getOrderListFailure(`Unable to fetch order data; Error code - ${Utils.isEmpty(json.errorCode) ? 0 : json.errorCode}`,params,searchColumns));
                }
            })
    }
}

export function getOrderListAction(params=searchObjectDefault) {
    return (dispatch) => {
        dispatch(getOrderList());
        return apiOrderList(params)
            .then(([response, json]) => {
                if(response.status === 200){
                    dispatch(getOrderListSuccess(json.results,params))
                }
                else {
                    dispatch(getOrderListFailure("Unable to fetch order data; Error code - " + json.errorCode,params));
                }
            })
    }
}

export function getOrderList() {
    return {
        type: ORDER_LIST_GET
    }
}

export function getOrderListSuccess(res,params,searchColumns) {
    
    return {
        type: ORDER_LIST_SUCCESS,
        payload: {res:res,request:params,searchColumns:searchColumns}
    }
}

export function getOrderListFailure(message,params,searchColumns) {
    return {
        type: ORDER_LIST_FAILURE,
        payload: {message:message,request:params,searchColumns:searchColumns}
    }
}

export function getLabelListAction(params) {
    return (dispatch) => {
        dispatch(getLabelList());
        return apiLabelList(params)
            .then(([response, json]) => {
                if(response.status === 200){
                    dispatch(getLabelListSuccess(json.results));
                }
                else {
                    dispatch(getLabelListFailure("Unable to fetch order data; Error code - " + json.errorCode));
                }
            })
    }
}

export function getLabelList() {
    return {
        type: LABEL_LIST_GET
    }
}

export function getLabelListSuccess(data) {
    return {
        type: LABEL_LIST_SUCCESS,
        payload: data
    }
}

export function getLabelListFailure(message) {
    return {
        type: LABEL_LIST_FAILURE,
        payload: message
    }
}
