import { Box, Card, CardContent, Typography } from '@mui/material/';
import React, { useState, useEffect } from 'react'
import { styles, StyledCard, OrderButton } from './style';
import { useSearchParams } from 'react-router-dom';
import SimpleSnackbar from '../../../../customWidgets/Snackbar';
import { getLabelListGridAction } from '../LabelLayoutGrid/Store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { editLabelSize } from "../../../../services/Gateway/RestAPIHandler";
import Utils from '../../../../utils/Utils';
import Loader from '../../../../customWidgets/Loader';

const LabelLayoutCard = () => {
    const [open1,setOpen1] = React.useState(false)
    const [loading,setLoading] = React.useState(true)
    const [alertType,setAlertType] = React.useState("success")
    const [alertMessage,setAlertMessage] = React.useState("")
    const [labelDetails, setLabelDetails] = useState({id:'',height:'',width:'',labelCode:'',labelUsageType:'',formula:'',overagePercentage:'',name:''})
    const searchParams = useSearchParams()[0];
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getLabelListGridAction(searchParams.get('id')))
    }, [])//eslint-disable-line react-hooks/exhaustive-deps
    
    const list = useSelector(state => state.labelLayoutGridList);
    useEffect(() => {
        if(!Utils.isEmpty(list.order) && list.order.data.length > 0){
            setLabelDetails(list.order.data[0]) 
            setLoading(false)
        }
    }, [list])

    const imageDet = '';
    const textFileDet = ''
    /*
    const [textFileDet, setTextFileDet] = useState('')

    const textFile = async (event) => {
        const textFileDetails = event.target.files[0];
        setTextFileDet(textFileDetails.name)
    }
    */
    
    const editLayout = () => {
        setLoading(true)
        const newArray = {
            id: labelDetails.id,
            createdBy: labelDetails.createdBy,
            labelCode: labelDetails.labelCode,
            labelUsageId: labelDetails.labelUsageId, 
            name: labelDetails.name,
            height: labelDetails.height,
            width: labelDetails.width,
            formula:labelDetails.formula,
            overagePercentage:labelDetails.overagePercentage
        }
        editLabelSize(newArray)
        .then(([response, json]) => {
            if(response.status===200 && json.status!=="ERROR")
            {
              dispatch(getLabelListGridAction(searchParams.get('id')))
              setAlertMessage("Label Successfully Updated")
              setAlertType('success')
              setOpen1(true)
              setLoading(false)
            }
            else{
                setAlertMessage(json.errorMessage)
                setAlertType('error')
                setOpen1(true)
                setLoading(false)
            }
          })
    }

    return (

        <Box>
        <Loader open={loading}/>
        <SimpleSnackbar setOpen={setOpen1} open={open1}  message={alertMessage} messagetype={alertType} />
            <Card style={{
                width: '100%'
            }}> <h3 style={styles.padding50}> Fill in details for the label</h3>
                <CardContent>
                    <StyledCard >
                        <Typography component='span' style={styles.col1field}>Label</Typography>
                        <Typography component='span' style={styles.col1field}>
                        {labelDetails.labelCode}
                            {/* <input type='text' value={labelDetails.labelCode}
                                onChange={event => setLabelDetails({ ...labelDetails, labelCode: event.target.value })}
                                style={styles.textbox}></input> */}
                        </Typography>

                        <Typography component='span' style={styles.col1field}>Formula</Typography>
                            <Typography component='span' style={styles.col1field}>
                            {/* <input type='text' value={labelDetails.formula}
                                onChange={event => setLabelDetails({ ...labelDetails, formula: event.target.value })}
                                style={styles.textbox}></input> */}
                            <select
                             value={labelDetails.formula}
                             onChange={event => setLabelDetails({ ...labelDetails, formula: event.target.value })}>
                                <option value="SIZE">SIZE</option>
                                <option value="SIZE*2">SIZE*2</option>
                            </select>
                        </Typography>
                      


                        <Typography component='span' style={styles.col1field}>Label Length</Typography>
                        <Typography component='span' style={styles.col1field} >          
                        {labelDetails.height}              
                        {/* <input type='text' value={labelDetails.height}
                            onChange={event => setLabelDetails({ ...labelDetails, height: event.target.value })} style={styles.textbox}></input> */}
                        </Typography>

                    </StyledCard>
                    <StyledCard >
                        <Typography component='span' style={styles.col1field}>Label Description</Typography>
                        <Typography component='span' style={styles.col1field} >                
                            <input type='text' value={labelDetails.name??""}
                            onChange={event => setLabelDetails({ ...labelDetails, name: event.target.value })} style={styles.textbox}></input>
                        </Typography>

                        <Typography component='span' style={styles.col1field}>Usage</Typography>
                        <Typography component='span' style={styles.col1field}>           
                        {labelDetails.labelUsageType}   
                                  {/* <input type='text' value={labelDetails.labelUsageType}
                            onChange={event => setLabelDetails({ ...labelDetails, labelUsageType: event.target.value })} style={styles.textbox}></input> */}
                        </Typography>


                        <Typography component='span' style={styles.col1field}>Label Width</Typography>
                        <Typography component='span' style={styles.col1field}>     
                        {labelDetails.width}                 
                          {/* <input type='text' value={labelDetails.width}
                            onChange={event => setLabelDetails({ ...labelDetails, width: event.target.value })} style={styles.textbox}></input> */}
                        </Typography>

                    </StyledCard>
                    <StyledCard >
                        <Typography component='span' style={styles.col1field}>Type</Typography>
                        <Typography component='span' style={styles.col1field}>               
                        {labelDetails.labelType}      
                           {/* <input type='text' value={labelDetails.labelType}
                            onChange={event => setLabelDetails({ ...labelDetails, labelType: event.target.value })} style={styles.textbox}></input> */}
                        </Typography>

                        <Typography component='span' style={styles.col1field}>Version</Typography>
                        <Typography component='span' style={styles.col1field}>     
                        {1}           
                                {/* <input type='text' value={1}
                            onChange={event => setLabelDetails({ ...labelDetails, version: event.target.value })} style={styles.textbox}></input> */}
                        </Typography>


                        <Typography component='span' style={styles.col1field}>Overage %</Typography>
                        <Typography component='span' style={styles.col1field}>                        <input type='text' value={labelDetails.overagePercentage ?? ''}
                            onChange={event => setLabelDetails({ ...labelDetails, overagePercentage: event.target.value })} style={styles.textbox}></input>
                        </Typography>

                    </StyledCard>
                    <StyledCard >
                        <Typography component='span' style={styles.col2field}>Reference Image</Typography>

                        <Typography component='span' style={styles.uploadBtnWrapper}  >
                            {/* <input  type="file" onChange={fileChangedHandler} style={styles.uploadBtnWrapperInput} /> */}
                            <input  type="file" style={styles.uploadBtnWrapperInput} />
                            <button style={styles.btn} >Browse  </button>
                            <label>{imageDet}</label>
                        </Typography>
                        <Typography component='span' style={styles.col2field}  >Label Codesoft File</Typography>
                        <Typography component='span' style={styles.uploadBtnWrapper}>


                            {/* <input  type="file" style={styles.uploadBtnWrapperInput} onChange={textFile} /> */}
                            <input  type="file" style={styles.uploadBtnWrapperInput}  />


                            <button style={styles.btn} >Browse  </button>

                            <label>{textFileDet}</label>
                        </Typography>
                        {/* <Typography component='span' onClick={editLayout} style={styles.padding20}>
                            <OrderButton>Edit</OrderButton>

                        </Typography> */}
                        <Typography component='span' onClick={editLayout} style={styles.padding20}>
                            <OrderButton>SAVE</OrderButton>
                        </Typography>
                    </StyledCard>
                </CardContent>
            </Card>
        </Box>
    );
}
export default LabelLayoutCard