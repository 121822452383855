export const OrderListStyles = {
    position: 'absolute',
    top:'105px',
    marginLeft:'110px',
    cursor: 'pointer',
    width: '19px',
    height: '18px',
    opacity: 1
}

export const ArticleMasterStyles = {
    position: 'absolute',
    top:'105px',
    marginLeft:'132px',
    cursor: 'pointer',
    width: '19px',
    height: '18px',
    opacity: 1
}

export const OrderLabelListStyles = {
    position: 'absolute',
    top:'105px',
    marginLeft:'130px',
    cursor: 'pointer',
    width: '19px',
    height: '18px',
    opacity: 1
}

export const Styles = {
    NotVisible: {
        display: 'none'
    }
}