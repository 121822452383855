export const ORDERS = {
    labelInfo: "Label Information",
    orderSizeInfo: "Order Size Information",
    labelSizeInfo: "Label Size Information",
    latest:'Latest',
    selected:'Selected',
    button: {
        prepare: "Prepare",
        send: "Send"
    },
    columnDef: {
        checkbox: {field: 'chkBox', headerName: ''},
        orderNumber: {field: 'orderNum', headerName: 'P.O No.'},
        status: {field: 'orderLabelStatus', headerName: 'Status'},
        orderQuantity: {field: 'orderQuantity', headerName: 'Order Quantity'},
        labelQuantity: {field: 'labelQuantity', headerName: 'Label Quantity'},
        labelCode: {field: 'labelCode', headerName: 'Label Code'},
        labelSequence: {field: 'labelSequence', headerName: 'Label Sequence'},
        isApproved: {field: 'isApproved', headerName: 'Approval Status'},
        approvalComment: {field: 'approvalComment', headerName: 'Approval Comment'},
        createdDate: {field: 'createdDate', headerName: 'Created Date'},
        modifiedDate: {field: 'modifiedDate', headerName: 'Modified Date'},
        articleNumber: {field: 'articleNumber', headerName: 'Article Number'},
        shipDate: {field: 'shipDate', headerName: 'Ship Date'},
        sentDate: {field: 'sendDate', headerName: 'Sent Date'},
        printDate: {field: 'printDate', headerName: 'Print Date'},
        // articleDescription: {field: 'articleDescription', headerName: 'Article Description'},
        // modelDescription:{field: 'articleDto.modelDescription', headerName: 'Model Description'},
        // countryOfOrigin: {field: 'countryOfOrigin', headerName: 'Origin Country Code'},
        customerCode: {field: 'customerCode', headerName: 'Customer Code'},
        customerName: {field: 'customerDTO.name', headerName: 'Customer Name'},
        divisionCode: {field: 'divisionCode', headerName: 'Division'},
        factoryCode: {field: 'factoryCode', headerName: 'Factory Code'},
        factoryName: {field: 'factoryDTO.name',headerName: 'Factory Name'},
        // lastProductionDate: {field: 'lastProductionDate', headerName: 'Last Production Date'},
        // shippingAddressingLine1: {field: 'shippingAddressingLine1', headerName: 'Shipping Addressing Line 1'},
        // shippingAddressingLine2: {field: 'shippingAddressingLine2', headerName: 'Shipping Addressing Line 2'},
        // shippingAddressingLine3: {field: 'shippingAddressingLine3', headerName: 'Shipping Addressing Line 3'},
        // shipToCountry: {field: 'shipToCountry', headerName: 'Shipping Country Code'},
        // currency: {field: 'currency', headerName: 'Currency'},
        retailPrice: {field: 'retailPrice', headerName: 'Retail Price'},
        labelDescription: {field: 'labelDto.name', headerName: 'Label Description'}
    }
}

export const LabelStatus = {
    LOCKED: "LOCKED",
    CANCELLED: "CANCELLED",
    SENT: "SENT",
    PREPARED: "PREPARED",
    PROCESSING: "PROCESSING",
    SHIPPED:"SHIPPED",
    PRINTED:"PRINTED"
}

export const APIStatus = {
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    ERROR: "ERROR"
}

export const GridPage = {
    Previous: 'grid-previous-page',
    Next: 'grid-next-page',
    Current: 'grid-current-page'
}