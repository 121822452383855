import React from 'react'
import { Box,Typography } from '@mui/material/';
import { styles,StyledAgGridReact } from './styles';
import {useDispatch, useSelector  } from 'react-redux';
import SimpleSnackbar from '../../../../customWidgets/Snackbar';
import { getLabelListGridAction} from './Store/actions';
import * as XLSX from 'xlsx'
import { useSearchParams } from 'react-router-dom';
import Utils from '../../../../utils/Utils';
import logo from '../../../../assets/excelicon.png';


function LabelLayoutGrid() {
  const [open1,setOpen1] = React.useState(false)
  const [gridApi,setGridApi] = React.useState(null)
  const [alertType,setAlertType] = React.useState("success")
  const [alertMessage,setAlertMessage] = React.useState("")
  const searchParams = useSearchParams()[0];
    const onGridReady= (params) => {
      setGridApi(params.api)
    }
  
    const columnDef = [
      // { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 50 },
      { field: 'fieldCode', headerName: 'Field Name', width: 230,resizable:true  },
      { field: 'fieldType', headerName: 'Field Type', width: 235,resizable:true  },
      { field: 'fieldLength', headerName: 'Field Length', width: 235,resizable:true  },
      { field: 'mandatory', headerName: 'Mandatory', width: 235,resizable:true  },    
      { field: 'fieldFormat', headerName: 'Field Format ', width: 225,resizable:true  },
      { field: 'fieldSequence', headerName: 'Field Sequence', width: 280,resizable:true  },
  
    ]; 
   

    const exportToData = (data) => {
      const newData = data.map(ele => {
        return {
          "Field Name": ele?.fieldCode,
          "Field Type":ele?.fieldType,
          "Field Length":ele?.fieldLength,
          "Mandatory":ele?.mandatory,
          "Field Format":ele?.fieldFormat,
          "Field Sequence":ele?.fieldSequence,
        }
      })
      const worksheet = XLSX.utils.json_to_sheet(newData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
      XLSX.writeFile(workbook, `Label_Layout.xlsx`)
     
    }

    const exportToExcel = () => {
      // const data = list.order.data[0].labelLayoutList;
      const data = list.order.data[0].labelLayoutDTOs;
      if(data===null){
        setAlertType('error')
        setAlertMessage("No Data to export")
        setOpen1(true)
      }
      else{
        exportToData(data)
    }
    }
    const dispatch = useDispatch();
    React.useEffect(() => {

        if(gridApi){
          gridApi.showLoadingOverlay()
          dispatch(getLabelListGridAction(searchParams.get('id')))
        }
     

    }, [gridApi])//eslint-disable-line react-hooks/exhaustive-deps
    const list = useSelector(state => state.labelLayoutGridList);
    React.useEffect(() => {
      if(!Utils.isEmpty(list.order.data)){
        if(list.order.isLoaded && gridApi){
           gridApi.hideOverlay()
        }
        
      }
    },[list.order,gridApi])//eslint-disable-line react-hooks/exhaustive-deps
    return (
      <Box style={styles.gridContainer}>
      <SimpleSnackbar setOpen={setOpen1} open={open1}  message={alertMessage} messagetype={alertType} /> 
        <Typography style={styles.labelTitle} variant="h6" component="span">Layout</Typography>
        <img onClick={() => { exportToExcel() }} src={logo} style={{ marginLeft: '18px', cursor: 'pointer', width: '19px', height: '18px', opacity: 1 }} alt={"Export Excel"} />
       
        <Box className={"ag-theme-alpine"} sx={{height:'400px',width: '100%',marginTop:'20px'}}>
          <StyledAgGridReact
            rowSelection='multiple'
            onGridReady={onGridReady}
            rowData={!Utils.isEmpty(list.order) && !Utils.isEmpty(list?.order?.data) ? list?.order?.data[0]?.labelLayoutDTOs : []}
            columnDefs={columnDef}
            pagination={false}
            suppressRowClickSelection={true}
            suppressPaginationPanel={true}
            overlayLoadingTemplate={`<div class="loader"></div>`}
          />
    
        </Box>
      </Box>


    )


}
export default LabelLayoutGrid;
