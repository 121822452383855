import React,{useRef} from 'react';
import { Box} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSportsCategoryAction } from './Store/actions';
import {styles,StyledAgGridReact} from './styles'
import Loader from '../../../customWidgets/Loader';
import {useNavigate } from "react-router-dom";
import GridPagination from '../../../customWidgets/GridPagination';
import Utils from '../../../utils/Utils';
import AccessLevelContext from '../../../../accessLevel-context';
import SimpleSnackbar from '../../../customWidgets/Snackbar';
import * as XLSX from 'xlsx';
import logo from '../../../assets/excelicon.png';
import {apiSportsCategory} from '../../../services/Gateway/RestAPIHandler'
function UserOrder() {
  const accessLevel= React.useContext(AccessLevelContext)
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertType,setAlertType] = React.useState("success");
  const [alertMessage,setAlertMessage] = React.useState("");
  const [labelRows,setLabelRows] = React.useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const columnDef = [
        { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 20 },
        { field: 'code', headerName: 'Sports Category Code', width:230,resizable:true},
        { field: 'value', headerName: 'Sports Category Description', width: 755 ,resizable:true }
       
    ]
   
    const GRID_FETCH_SIZE = 50;
    const [currentPage, setCurrentPage] = React.useState(1);
    const [loading,setLoading] = React.useState(true);
    const list = useSelector(state => state.sportscategorylist);
    const dataToExcel = (data) => {
      const newData = data.map(ele => {
        return {
          "Sports Category Code":ele?.code,
          "Sports Category Description":ele?.value,
                }
      })
      
      const worksheet = XLSX.utils.json_to_sheet(newData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
      XLSX.writeFile(workbook, "DownloadSportsCategory.xlsx")
      setLoading(false)
    }
    const onSelectionChange = (event) => {
      const selectedRows = event.api.getSelectedRows();
      if (!Utils.isEmpty(selectedRows)){
        setLabelRows(selectedRows)
      }
      else{
        setLabelRows([])
      }
    }
    const exportToExcel = () => {
      setLoading(true)
      
            if(labelRows.length===0){
              apiSportsCategory(
                {fetchSize:list.order.data[0].totalElements,page: 1}
              ).then(([response, json])=> {
                if(response.status===200 && !Utils.isEmpty(json.results)){
                const data = json.results[0].content ?? []
                dataToExcel(data)
              }
              else{
                setOpenAlert(true)
                setAlertType('error')
                setAlertMessage("No data to export")
                setLoading(false)
              }
              })
            }
            
            else{
              dataToExcel(labelRows)
            }
            
          }
        
    React.useEffect(() => {
      if(accessLevel?.accessLevel!=='A01' && accessLevel?.accessLevel!=='A03'){
        navigate('/')
      }
        else{
      dispatch(getSportsCategoryAction({fetchSize: GRID_FETCH_SIZE, page: currentPage}));
        }
      
     },[])//eslint-disable-line react-hooks/exhaustive-deps
     React.useEffect(() => {
      if(!Utils.isEmpty(list.order) && !Utils.isEmpty(list.order.data)){
        setLoading(false)
      }
    },[list.order,currentPage])//eslint-disable-line react-hooks/exhaustive-deps
     const gridRef = useRef();
  const showGridLoader = () => {
    gridRef.current.api.showLoadingOverlay();
  }
   const getTotalPages = React.useMemo(() => {
    if(!Utils.isEmpty(list.order)) {
      return list.order.data.length === 0 ?
        {
          totalPages: 1,
          totalElements: 1
        } : {
          totalPages: list.order.data[0].totalPages,
          totalElements: list.order.data[0].totalElements
        };
    }
    else
      return {
        totalPages: 1,
        totalElements: 1
      };
  },[list]);
  
    const gotoKeyDown = (event, value) => {
      
      if (event.keyCode === 13 && parseInt(value) >= 1  && parseInt(value) <= getTotalPages.totalPages && parseInt(value)!==currentPage) {
        showGridLoader()
        setLabelRows([]);
        setCurrentPage(parseInt(value));
        dispatch(getSportsCategoryAction({fetchSize: GRID_FETCH_SIZE, page: parseInt(value) }));
      }
  
    }
  
    const handleGridPagination = (event, thisPage) => {
      if(currentPage!==thisPage){
        setLabelRows([])
        showGridLoader()
        setCurrentPage(thisPage) 
        dispatch(getSportsCategoryAction({fetchSize: GRID_FETCH_SIZE, page: thisPage }));
      }
    }
  
    return (
      <Box style={styles.gridContainer}>
        <Loader open={loading}/>
        <SimpleSnackbar setOpen={setOpenAlert} open={openAlert}  message={alertMessage} messagetype={alertType} />
      <img data-testid="excelicon"  onClick={() => { exportToExcel() }} src={logo} style={styles.excelicon} alt={"Export Excel"} />
          <Box className={"ag-theme-alpine"} sx={{height:'365px'}}>
          <StyledAgGridReact
          rowSelection='multiple'
          suppressRowClickSelection={true}
          ref={gridRef}
          rowData={!Utils.isEmpty(list.order) && !Utils.isEmpty(list?.order?.data) ? list?.order?.data[0]?.content : []}
          columnDefs={columnDef}
          pagination={true}
          paginationPageSize={GRID_FETCH_SIZE}
          onSelectionChanged={onSelectionChange}
          cacheBlockSize={GRID_FETCH_SIZE}
          suppressPaginationPanel={true}
          overlayLoadingTemplate={`<div class="loader"></div>`}
          />
          
          <Box style={styles.alignBottom}>
            <GridPagination
              pageClick={handleGridPagination}
              gridTotal={getTotalPages}
              currentPage={currentPage}
              pageSize={GRID_FETCH_SIZE}
              gotoKeyDown={gotoKeyDown} />
          
          </Box>
        </Box>
      </Box>
    )
  }

export default UserOrder