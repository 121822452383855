import './App.css';
import { Box, CssBaseline, Modal, Card, Typography } from '@mui/material';
//import {store as useraccessstore} from './app/customWidgets/SideMenu/Store/store'
import { gStore, gContext } from './app/Store/store';
import SideMenu from './app/customWidgets/SideMenu'
import { useRoutes } from "react-router-dom";
import routeConfig from './app/router/Router';
import Breadcrumbs from './app/baseWidgets/Breadcrumbs/';
import React, { useState, useEffect } from 'react';
import {apiGetHeader} from './app/services/Gateway/RestAPIHandler'
import UnauthorizedLayout from './app/modules/Login'; 
import * as API from './app/services/Gateway/LoginAPI';
import Utils from './app/utils/Utils';
import {getUserAccess} from './app/Store/actions'
import AccessLevelContext from './accessLevel-context';
import SimpleSnackbar from './app/customWidgets/Snackbar';
import { Provider,useDispatch,useSelector } from 'react-redux';
import Loader from './app/customWidgets/Loader';
import envConfig from './config/env/environment';

function App() {

  const [loggedIn,setLoggedIn] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertBar, setAlertBar] = useState({
      alertMessage: "",
      alertType: "success"
  });
  const [sessionAlert, setSessionAlert] = useState(false);
  
  const handleLogout = () => {
    setLoggedIn(false);
    setSessionAlert(false);
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("expires_in");
    window.location.assign(envConfig.oneLogin.logout);
  }

  const handleUserLogin = (username, password) => {
    API.UserLogin({
      username: username,
      password: password
    }).then(user => {
        if(user[0].status === 200 && Utils.isEmpty(user[1].error)) {
            window.localStorage.setItem("access_token", user[1].access_token);
            window.localStorage.setItem("expires_in", user[1].expires_in);
            setLoggedIn(true);
            executeLogoutTimer();
        }
        else {
            showSnackBar("Incorrect credentials", "error");
        }
    }).catch(err => {
      showSnackBar("Unable to fetch resource, please try later", "error");
    })
  }

  const executeLogoutTimer = () => {
    const expire_in = Utils.getStorageValue("expires_in");
    setTimeout(() => {
      setSessionAlert(true);

      setTimeout(() => {
        handleLogout();
      }, 10000)
    }, (expire_in * 1000))
  }

  const showSnackBar = (message, type) => {
    setOpenAlert(true);
    setAlertBar({
        alertMessage: message,
        alertType: type
    })
  }

  const checkAuthorized = () => {
    if(!loggedIn) {
      const access_token = Utils.getStorageValue("access_token");
      if(access_token) {
        setLoggedIn(true);
        executeLogoutTimer();
      }
    }
  }
  checkAuthorized();

  const handleSessionClose = () => {
    setSessionAlert(false);
  }
  
  
  return (
    <>
     
      <SimpleSnackbar setOpen={setOpenAlert} open={openAlert}  message={alertBar["alertMessage"]} messagetype={alertBar["alertType"]} />
      <Modal open={sessionAlert}
        onClose={handleSessionClose}
        sx={{ display: 'inline-flex', alignItems: 'center', width: '280px', height: 'fit-content', left: '40%', top: '36%' }}>
        <Card sx={{ width: '100%', padding: '12%', marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}>
          <Typography component='span'>Your session has expired</Typography>
        </Card>
      </Modal>
      {/*<ErrorBoundary>*/}
      
      {loggedIn ?<AppLayout data-testid="app-ui" showSnackBar={showSnackBar} handleLogout={handleLogout}/> : <UnauthorizedLayout data-testid="app-login-ui" handleLogin={handleUserLogin} /> }

      {/*</ErrorBoundary>*/}
    </>
  );
}

export function AppLayout(props) {
  let routes = useRoutes(routeConfig);
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(true)
  const [accessLevel,setAccessLevel] = useState(null)
  const list = useSelector(state => state.UserAccess)
  
  useEffect(() => {
    if(!Utils.isEmpty(list.order)){
      if(!Utils.isEmpty(list.order.data) && list.order.message==='Success'){
        setLoading(false)
        setAccessLevel(list.order.data)
        if(accessLevel!=='A01'&&accessLevel!=='A02'&&accessLevel!=='A03'&&accessLevel!==null){
          props.handleLogout();
        }
      }
    }
  },[list.order])

  useEffect(() => {  
    const token =window.localStorage.getItem("access_token")
    apiGetHeader(
      {
        "access_token":token
      }
    )
    .then(([response,json]) => {
      if(response.status===200 && json.status==='SUCCESS')
      {
        dispatch(getUserAccess())
      }
      else{
        props.showSnackBar(json.errorMessage,'error')
        props.handleLogout()
      }
    })
  },[])//eslint-disable-line react-hooks/exhaustive-deps
  
  return (
  <Box sx={{display:'flex'}}>
    <Loader open={loading}/>
    <CssBaseline />
    <AccessLevelContext.Provider value ={accessLevel}>
      <Provider store={gStore} context={gContext}>
        <SideMenu/>
        <Box component="main" sx={{ marginTop:'68px', flexGrow: 1, p: 3, minWidth: '750px' }}>
          <Box sx={{ padding: "10px 24px" }}><Breadcrumbs/></Box>
          {routes}
        </Box>
      </Provider>
    </AccessLevelContext.Provider>
  </Box>)
}


export default App;

