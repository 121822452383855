import { ORDER_LIST_GET, ORDER_LIST_SUCCESS, ORDER_LIST_FAILURE} from './actions';

const initialState = {
    order: {}
}

export function DashboardReducer(state = initialState, action) {
 
    if(action.type === ORDER_LIST_GET) {
        return state;
    }
    if(action.type === ORDER_LIST_SUCCESS) {
        return {
            ...state,
            order: {
                isLoaded: true,
                data: action.payload.res,
                message: 'Success'
            }
           
        }
    }
    if(action.type === ORDER_LIST_FAILURE) {
        return {
            ...state,
            order: {
                data: [],
                isError: true,
                message: action.payload
            }
        }
    }
    return state;
}

