import React, { useState, useEffect } from 'react'
import { styles,StyledAgGridReact } from './styles';
import { Box} from '@mui/material';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Buttons from '../Orders/OrderList/Buttons';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';



function LabelReceiver() {
    const [tableData, setTableData] = useState([])
    const checkboxSelection = function (params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    };
    const headerCheckboxSelection = function (params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    };
    const totalGridPages = 1;
    // let navigate = useNavigate()
    const columnDef = [
      { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 20 },
      { field: 'lblId', headerName: 'Label receiver ID', width: 185 },
      { field: 'lblName', headerName: 'Name', width: 110 },
      { field: 'lblServer', headerName: 'FTP Server', width: 215 },
      { field: 'lblEmail', headerName: 'Email', width: 225 },
      { field: 'lblIn', headerName: 'FTP IN ', width: 125 },
      { field: 'lblOut', headerName: 'FTP OUT', width: 110 },
      { field: 'lblStatus', headerName: 'FTP Status', width: 180 },
      { field: 'lblUsername', headerName: 'FTP Username', width: 180 },
      { field: 'lblPassword', headerName: 'FTP Password', width: 180 },
      { field: 'lblParam', headerName: 'FTP Param', width: 180 },
      { field: 'lblReceiver', headerName: 'Receiver Type', width: 180 },
      { field: 'lblValid', headerName: 'Is Valid', width: 180 }
    ];
    
    const buttonlist =['Add','Save','Delete']
    
    
    const gridRef = React.useRef();
    const gridPage = [];
    for(let pageCnt=0; pageCnt < totalGridPages && pageCnt < 4; pageCnt++ ){
      gridPage.push(
      <Box style={(pageCnt === 0) ? styles.gridPagination.selectedButton : styles.gridPagination.buttons}>
        <Box style={styles.gridPagination.buttons.pageButton}>{(pageCnt + 1)}</Box>
      </Box>)
    }

    useEffect(() => {
      fetch("http://localhost:8090/referencedata")
        .then((data) => data.json())
        .then((data) => setTableData(data))
  
    }, [])
  

   
    return (
     
      <Box style={styles.gridContainer}>
      <Box className={"ag-theme-alpine"}
      sx={{height:'400px',width: '100%'}}>
      <StyledAgGridReact
        rowSelection='single'
        // rowData={Object.keys(list.order).length > 0 ? list.order.data[0].content : []}
        rowData={tableData}
        columnDefs={columnDef}
        ref={gridRef}
        pagination={true}
        paginationPageSize={8}
        suppressPaginationPanel={true}
      />
        <Box id="grid-pagination" style={styles.gridPagination}>
        <Box id="grid-first-page" style={styles.gridPagination.buttons}>
          <ArrowLeftIcon style={styles.gridPagination.buttons.icons} />
        </Box>
        {gridPage}
        <Box id="grid-last-page" style={styles.gridPagination.buttons}>
          <ArrowRightIcon style={styles.gridPagination.buttons.icons} />
        </Box>
      </Box>
    </Box>
       <Buttons buttonlist={buttonlist}/>
      </Box>
    
    )
}
export default LabelReceiver;