import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { styled} from '@mui/material/styles';
import List from '@mui/material/List';
import {ListItem,ListItemButton,Menu} from '@mui/material'

const drawerWidth = 278;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });
  
 export  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  
 export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    height:'68px',
    background:'#FCFCFC 0% 0% no-repeat padding-box',
    boxShadow:'0px 1px 4px #15223214',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      // width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  
  export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );
export const styles ={
  header_title: {
    marginLeft: '40px',
    color: '#293E53',
    fontFamily: "Poppins, Helvetica, 'sans-serif'",
    fontWeight: 'bold' ,
    fontSize: '24px'
  },
  menu_item: {
    fontSize: '18px' ,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    background:'#293E53 0% 0% no-repeat padding-box',
    color:'#FFF',
    padding:0,
    fontFamily:'Poppins'
  },
  logout: {
    color: 'black',
    marginLeft: 'auto',
    marginTop:'10px',
    link: {
      cursor: 'pointer'
    },
    cursor: 'pointer'

  },
  PositionedMenu: {
    listButton: {
      borderRadius:'50%',
      height:'25px',
      width:'25px',
      background:'white',
      display:'flex'
    },
    listIconButton: {
      color:'#000000',
      minWidth: 0,
      height:'24px',
      justifyContent: 'center',
    }
  }
}

export const StyledListItem = styled(ListItem)({
  fontSize: '18px' ,
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  background:'#293E53 0% 0% no-repeat padding-box',
  color:'#FFF',
  padding:0,
  fontFamily:'Poppins'
})
export const StyledMenu = styled(Menu)({
  '.MuiList-root':{
    padding:0,
  },
  '.MuiTypography-root':{
    fontSize:'16px',
    fontFamily:'Poppins'
  }

})
export const StyledList = styled(List)(
  {
  background:'#293E53 0% 0% no-repeat padding-box', 
  height: '916px',
  color:'white',
  padding:'0',
  '.MuiTypography-root':{
    fontSize:'16px',
    fontFamily:'Poppins'
  }}
  

)
export const StyledListItemButton =styled(ListItemButton)({
  display:'flex',
  padding: '0 20px 0 20px',
  alignItems:'center',
  color:'white',
  minHeight: 50,
  justifyContent:'intial',
  width: '250px',
  '&:hover':{
    backgroundColor: '#6498CC',
  }
})