import {apisearchLabelAssignmentList} from '../../../../services/Gateway/RestAPIHandler';
 export const LABEL_ASSIGNMENT_GET_ASYNC = 'Get Label Assignment List Async';
 export const LABEL_ASSIGNMENT_GET ="Get Label Assignment Type";
 export const LABEL_ASSIGNMENT_SUCCESS = "Label Assignment Type Sucess";
 export const LABEL_ASSIGNMENT_FAILURE = "Label Assignment Type Failure";
 const searchDefault = {
    "ascDesc": "",
    "fetchSize": 7,
    "filterSearch": "",
    "pageNumber": 1,
    "sortBy": ""
  }
 export function getLabelAssignmentAction(params) {
    return (dispatch) => {
        dispatch(getLabelAssignment()); 
        return apisearchLabelAssignmentList(params)
            .then(([response, json]) => {
                if(response.status === 200){
                   
                    dispatch(getLabelAssignmentSuccess(json.results,params));
                }
                else {
                    dispatch(getLabelAssignmentFailure("Unable to fetch label assignment data; Error code - " + json.errorCode,params));
                }
            })
    }
}
export function searchLabelAssignmentAction(params=searchDefault) {
    return (dispatch) => {
        dispatch(getLabelAssignment());
        return apisearchLabelAssignmentList(params) 
            .then(([response, json]) => {
                if(response.status === 200){
                    
                    dispatch(getLabelAssignmentSuccess(json.results,params));
                }
                else {
                    dispatch(getLabelAssignmentFailure("Unable to fetch label assignment data; Error code - " + json.errorCode,params));
                }
            })
    }
}


export function getLabelAssignment() {
    return {
        type: LABEL_ASSIGNMENT_GET
    }
}

export function getLabelAssignmentSuccess(res,params) {
    return {
        type: LABEL_ASSIGNMENT_SUCCESS,
        payload: {res:res,request:params}

    }

}

export function getLabelAssignmentFailure(message,params) {
    return {
        type: LABEL_ASSIGNMENT_FAILURE,
        payload: {message:message,request:params}
    }
}
