import * as React from 'react';
import './Label.css';
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx'
import { getLabelListAction } from './Store/actions';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/excelicon.png';
import Utils from '../../utils/Utils';

export default function Label() {

  const currentPage = 1;

  const columnDef = [
    { field: 'id', headerName: 'Label ID', width: 85 },
    { field: 'labelCode', headerName: 'Label Code', width: 110 },
    { field: 'name', headerName: 'Label Name', width: 115 },
    { field: 'lblMainTypeId', headerName: 'Label Main Type ID', width: 125 },
    { field: 'lblUsageTypeId', headerName: 'Label Usage Main Type ID', width: 125 },
    { field: 'lblFormula', headerName: 'Formula', width: 110 },
    { field: 'lblPercentage1', headerName: 'Percentage', width: 110 },
    { field: 'lblPercentage2', headerName: 'Percentage', width: 110 },
    { field: 'lblPercentage3', headerName: 'Percentage', width: 110 },
    { field: 'lblPercentage4', headerName: 'Percentage', width: 110 },
    { field: 'lblPercentage5', headerName: 'Percentage', width: 110 },
    { field: 'lblPercentage6', headerName: 'Percentage', width: 110 },
    { field: 'lblPercentage7', headerName: 'Percentage', width: 110 }
  ];
  const list = useSelector(state => state.Labellist);
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(list.order.data[0].content);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook,worksheet,"Sheet1")
    XLSX.writeFile(workbook,"Download.xlsx")
    }


    const dispatch = useDispatch();
    React.useEffect(() =>{
      dispatch(getLabelListAction({page: currentPage}))
    },[dispatch, currentPage])
  
  return (
    <main style={{ padding: "2rem 0", paddingBottom: 0 }}>
      <div className='grid-container'>
        <div className='header'>
          <h4>{"Label List Admin"}</h4>
            <img  onClick={() => {exportToExcel()}} src={logo} style ={{ marginLeft:'18px',cursor:'pointer',width:'19px', height: '18px',opacity: 1}} alt={"Download"} />
         
        </div>
        <DataGrid
          rows={!Utils.isEmpty(list.order) ? list.order?.data[0]?.content : []}
          rowCount={!Utils.isEmpty(list.order) ? list.order?.data[0]?.numberOfElements : 1}
          columns={columnDef}
          getRowId={(row) => row.id}
          pageSize={8}
          rowsPerPageOptions={[8]}
          checkboxSelection
          disableSelectionOnClick
          page={parseInt(currentPage) - 1}
          paginationMode="server"
        />
      </div>
    </main>
  );
}