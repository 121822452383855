import React, { useState, useEffect, useMemo, useRef } from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Box } from '@mui/material';
import { styles, StyledAgGridReact } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {  searchBulkActionAction } from './Store/actions';
import Utils from '../../../utils/Utils';
import {useLocation} from "react-router-dom";
import GridPagination from '../../../customWidgets/GridPagination/index';
import Loader from '../../../customWidgets/Loader'
import AccessLevelContext from '../../../../accessLevel-context';
import { apiViewPOBulkAction,apiDownloadBatchBulk } from '../../../services/Gateway/RestAPIHandler';
import SimpleSnackbar from '../../../customWidgets/Snackbar';

function ViewBulkAction() {
  
  const [gridApi,setGridApi] = useState(null)
  const location = useLocation();
  const accessLevel= React.useContext(AccessLevelContext)
  const onGridReady= (params) => {
    setGridApi(params.api)
  }

  // const checkboxSelection = function (params) {
  //   return params.columnApi.getRowGroupColumns().length === 0;
  // };
  // const headerCheckboxSelection = function (params) {
  //   return params.columnApi.getRowGroupColumns().length === 0;
  // };
  const columnDef = [
    // { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 20 },
    // { field: 'customerId', headerName: 'Customer ', width: 150, headerClass: 'grid-header' },
    { field: 'poBatchId', headerName: 'Batch ID', width: 180, headerClass: 'grid-header',resizable:true },
    { field: 'status', headerName: 'Status', width: 250, headerClass: 'grid-header',resizable:true },
    { field: 'action', headerName: 'Action', width: 250, headerClass: 'grid-header',resizable:true },
    { field: 'createdBy', headerName: 'Created By', width: 250, headerClass: 'grid-header',resizable:true },
    { field: 'createdDate', headerName: 'Created Date', width: 250, headerClass: 'grid-header',resizable:true },
    { field: 'batchFilePath', headerName: 'Download', width: 250, headerClass: 'grid-header',resizable:true,valueFormatter: params => {return "Download"},cellStyle:{color:'#044BD9',textDecoration:'underline',cursor:'pointer'} },
  ];
  const [currentPage, setCurrentPage] = React.useState(1);
  const GRID_FETCH_SIZE = 7;
  const list = useSelector(state => state.orderList);
  const gridRef = useRef();
  const [loading, setLoading] = useState(true)
  const [req, setReq] = useState(list.request)
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();
  const [alertType,setAlertType] = useState("success");
  const [alertMessage,setAlertMessage] = useState("");
  const [labelRows,setLabelRows] = useState([])

  useEffect(() => {
    if(location.state!==null){
      if(location.state.Status==='*'){
        dispatch(searchBulkActionAction({
          "ascDesc": "",
          "fetchSize": GRID_FETCH_SIZE,
          "filterSearch": "",
          "pageNumber": 1,
          "sortBy": ""
        }))
      }
    }
    else {
    if(req.filterSearch!=='' && req.hasOwnProperty('filterSearch')){
      dispatch(searchBulkActionAction(req))
    }
    else {
      dispatch(searchBulkActionAction({
        "ascDesc": "",
        "fetchSize": GRID_FETCH_SIZE,
        "filterSearch": "",
        "pageNumber": currentPage,
        "sortBy": ""
      }))
    }
  }

    
    
  },[])//eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if(!Utils.isEmpty(list.order.data)){
      if(list.order.isLoaded && gridApi){
        hideGridLoader()
        setLabelRows(list?.order?.data[0]?.content)
        setLoading(false)
      }
    }
    if(list.order?.isError && !Utils.isEmpty(list.order.message) && gridApi){
      setLabelRows([])
      hideGridLoader()
      setAlertMessage(list.order.message)
      setAlertType("error")
      setOpenAlert(true)
      setLoading(false)
    }
  },[list.order])//eslint-disable-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   if (!Utils.isEmpty(list.order)) {
  //     setLoading(false)
  //   }
  // },[currentPage])//eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setCurrentPage(req.pageNumber)
  }, [req]);
  // const onSelectionChange = (event) => {
  //   const selectedRows = event.api.getSelectedRows();
  //   if (!Utils.isEmpty(selectedRows)){
  //     setLabelRows(selectedRows)
  //   }
  //   else{
  //     setLabelRows([])
  //   }
  // }
  const handleCellClick = (event) => {
    if(event.colDef.headerName==='Download'){
      apiDownloadBatchBulk(event.value)
      .then(([response, json])=> {
        if(response.status===200 && !Utils.isEmpty(json.results)){
        const data = json.results[0];
        // console.log(Object.keys(data[0]))c]
        const headers = Object.keys(data[0])
        const csvContent = [
          [headers[0]],
          ...data.map(item => [
            item[headers[0]]
          ])
          ].map(e => e.join(","))
          .join("\n");
    
      
        let ele = document.createElement('a');
        const blob = new Blob([csvContent],{type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        ele.href = url;
        ele.setAttribute('download',`${event.value}`);
        ele.click();
        setLoading(false)
      } 
      else{
        setOpenAlert(true)
        setAlertType('error')
        setAlertMessage("No data to export")
        setLoading(false)
      }
    })
    }
    else {
      return
    }
  }
  const showGridLoader = () => {
    gridRef.current.api.showLoadingOverlay();
  }
  const hideGridLoader = () => {
    gridRef.current.api.hideOverlay();
  }
  const getTotalPages = useMemo(() => {
    setReq(list.request)
    const [orderData] = !Utils.isEmpty(list.order) ? list?.order?.data ?? [] : [];
    if (!Utils.isEmpty(list.order)) {
      return Utils.isEmpty(list.order?.data) ?
        {
          totalPages: 1,
          totalElements: 1
        } : {
          totalPages: orderData?.totalPages,
          totalElements: orderData?.totalElements
        };
    }
    else
      return {
        totalPages: 1,
        totalElements: 1
      };
  }, [list.order]);
  // const dataToExcel = (data) => {
  //   const newData = data.map(ele => {
  //     return {
  //       // "Customer" : ele?.customerId,
  //       "Customer Code": ele?.customerCode,
  //       "Customer Type": ele?.customerType,
  //       "Customer Short Name":ele?.name,
  //       "Description": ele?.shortDescription,
  //       "Shipping Address 1": ele?.shippingAddress1,
  //       "Shipping Address 2": ele?.shippingAddressCity,
  //       "Shipping Address 3 ": ele?.shippingAddressCountry,
  //       "Shipping Address Post code": ele?.shippingAddressPostcode, 
  //       "Shipping Address Country Code": ele?.shippingCountryCode,
  //       "Green Point": ele?.greenPoint,
  //       "Pictogram": ele?.pictogram,
  //       "Barcode Type": ele?.brandCode,

  //             }
  //   })
    
  //   const worksheet = XLSX.utils.json_to_sheet(newData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  //   XLSX.writeFile(workbook, "DownloadCountrySizeList.xlsx")
  //   setLoading(false)
  // // }
  // const exportToExcel = () => {
  //   setLoading(true)      
  //         if(labelRows.length===0){
  //           apiViewPOBulkAction(
  //             {...req,fetchSize:500,pageNumber: 1}
  //           ).then(([response, json])=> {
  //           if(response.status===200 && !Utils.isEmpty(json.results)){
  //           const data = json.results[0].content;
  //           dataToExcel(data)
  //         } 
  //         else{
  //           setOpenAlert(true)
  //           setAlertType('error')
  //           setAlertMessage("No data to export")
  //           setLoading(false)
  //         }
  //       })
  //     }
  //         else{
  //           dataToExcel(labelRows)
  //         }  
        
  // }

  const handleGridPagination = (event, thisPage) => {
    showGridLoader();
    setCurrentPage(thisPage);

    if (req.filterSearch !== '' && req.hasOwnProperty('filterSearch')) {
      dispatch(searchBulkActionAction({ ...req, fetchSize: GRID_FETCH_SIZE, pageNumber: thisPage }));
    }
    else {
      dispatch(searchBulkActionAction({
        "ascDesc": "",
        "fetchSize": GRID_FETCH_SIZE,
        "filterSearch": "",
        "pageNumber": thisPage,
        "sortBy": ""
      }))
    }
  }
  const gotoKeyDown = (event, value) => {
    if (event.keyCode === 13 && parseInt(value) >= 1  && parseInt(value) <= getTotalPages.totalPages && parseInt(value)!==currentPage) {
      showGridLoader();
      setCurrentPage(parseInt(value));
      if (req.filterSearch !== '' && req.hasOwnProperty('filterSearch')) {
        dispatch(searchBulkActionAction({ ...req, fetchSize: GRID_FETCH_SIZE, pageNumber: parseInt(value) }));
      }
      else {
        dispatch(searchBulkActionAction({
          "ascDesc": "",
          "fetchSize": GRID_FETCH_SIZE,
          "filterSearch": "",
          "pageNumber": parseInt(value),
          "sortBy": ""
        }))
      }
    }
  }



  return (
    <Box style={styles.gridContainer}>
      <Loader open={loading} />
      {/* <img onClick={() => { exportToExcel() }} src={logo} style={styles.excelicon} alt={"Export Excel"} /> */}
      <SimpleSnackbar setOpen={setOpenAlert} open={openAlert}  message={alertMessage} messagetype={alertType} />

      <Box className={"ag-theme-alpine"}
        sx={{ height: '365px', width: '100%' }}>
        <StyledAgGridReact
          ref={gridRef}
          rowSelection='single'
          onGridReady={onGridReady}
          // rowData={Object.keys(list.order).length > 0 ? list.order.data[0].content : []}
          rowData={labelRows}
          columnDefs={columnDef}
          onCellClicked={handleCellClick}
          pagination={true}
          suppressRowClickSelection={true}
          // onSelectionChanged={onSelectionChange}
          paginationPageSize={GRID_FETCH_SIZE}
          suppressPaginationPanel={true}
          overlayLoadingTemplate={`<div class="loader"></div>`}
        />
      </Box>
      <Box style={styles.alignBottom}>
        <GridPagination
          pageClick={handleGridPagination}
          gridTotal={getTotalPages}
          currentPage={currentPage}
          pageSize={GRID_FETCH_SIZE}
          // onSelectionChanged={onSelectionChange}

          gotoKeyDown={gotoKeyDown} />
      </Box>

    </Box>
  )
}
export default ViewBulkAction;