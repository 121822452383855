
import {
    applyMiddleware,
    combineReducers,
    createStore
} from 'redux';
//import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga'
import { UserAccessReducer } from './reducer';
import { ExportExcelReducer } from '../customWidgets/ExportExcel/Store/reducer';
import rootSaga from './rootSaga';
import { createContext } from 'react';

//const middleware = applyMiddleware(thunk);
const sagaMiddleware = createSagaMiddleware()

const reducers = combineReducers({
    UserAccess: UserAccessReducer,
    ExportData: ExportExcelReducer
});

export const gContext = createContext();
export const gStore = createStore(reducers, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga)