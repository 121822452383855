import { call, put, takeEvery } from 'redux-saga/effects';
import { getUserAccessLevel } from '../services/Gateway/RestAPIHandler';
import Utils from '../utils/Utils';
import { getUserAccessSuccess, getUserAccessFailure } from './actions';

function* getUserAccessAction() {
    const accessResponse = yield call(apiUserAccessLevel);
    yield put(accessResponse);
}

async function apiUserAccessLevel() {
    return getUserAccessLevel()
        .then(([response, json]) => {
            if(response.status === 200) {
                return getUserAccessSuccess(json)
            }
            else {
                return getUserAccessFailure(`Unable to fetch User Access data; Error code - ${Utils.isEmpty(json?.errorCode) ? '0' : json?.errorCode}`)
            }
        })
}

export default function* UserAccessSaga() {
    yield takeEvery('USER_ACCESS_GET', getUserAccessAction)
}