import { apiSportsCategory } from '../../../../services/Gateway/RestAPIHandler';

 
 
 export const SPORTS_CATEGORY_GET ="Get User Groups";
 export const SPORTS_CATEGORY_SUCCESS = "User Groups Sucess";
 export const SPORTS_CATEGORY_FAILURE = "User Groups Failure";
 export function getSportsCategoryAction(params) {
    return (dispatch) => {
        dispatch(getSportsCategory());
        return apiSportsCategory(params) 
            .then(([response, json]) => {
                if(response.status === 200){ 
                    dispatch(getSportsCategorySuccess(json.results));
                }
                else {
                    dispatch(getSportsCategoryFailure("Unable to fetch User Groups data; Error code - " + json.errorCode));
                }
            })
    }
}

export function getSportsCategory() {
    return {
        type: SPORTS_CATEGORY_GET
    }
}

export function getSportsCategorySuccess(data) {
    return {
        type: SPORTS_CATEGORY_SUCCESS,
        payload: data

    }

}

export function getSportsCategoryFailure(message) {
    return {
        type: SPORTS_CATEGORY_FAILURE,
        payload: message
    }
}