import React, { useEffect } from "react";
import { BeginAuth } from "../../../services/Gateway/LoginAPI";
import Card from '@mui/material/Card';
import logo from '../../../assets/reebok_logo.png'
import { LoginStyle } from "../styles";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Typography } from '@mui/material';

export default function AuthPage(props) {

    const getAuthentication = () => {
        BeginAuth();
    }

    useEffect(() => {
        getAuthentication();
    }, [])

    return (
      <Card sx={{ width: "345px",height:'400px',position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',borderRadius:'5px' }}>
        <CardMedia
          component="img"
          sx={LoginStyle.cardMedia}
          image= {logo}
          alt="Reebok_logo"
        />
        <CardContent sx={{height:'60%',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Typography component='div' className={'spinner'} />
        </CardContent>
      </Card>
    )
}