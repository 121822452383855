import React from "react";
import { AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PoCardTable from "../../modules/PoCardTable/PoCardTable";
import { POAccordion, POAccordionSummary, POTypography } from "./styles"; 

export default function OrderAccordion({accordionOpen,setAccordionOpen,setAlertMessage,setOpen,setAlertType}) {

    const [expanded, setExpanded] = React.useState('POPanel');
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
      setAccordionOpen(!accordionOpen)
    };
    
    return (
      <Box sx={{marginBottom: 2}}> 
        <POAccordion data-testid="accordion" expanded={expanded === 'POPanel'} onChange={handleChange('POPanel')}>
          <POAccordionSummary id="po-summary-header"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="po-summary-content"
          >
            <POTypography>PO Information</POTypography>
          </POAccordionSummary>
          <AccordionDetails sx ={{padding:'0', marginBottom: '8px'}}>
            <PoCardTable setAlertMessage={setAlertMessage} openPOAlert={setOpen} setAlertType={setAlertType}/>
          </AccordionDetails>
        </POAccordion>
      </Box>
    );
}
