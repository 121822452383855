import React from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Box,IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styles,DownloadButton} from './styles';
import { Typography } from '@mui/material';
import {apiReadFile} from '../../../services/Gateway/RestAPIHandler'

function ViewLabel({poNum,label,close,filepath,status,setOpen1,setAlertMessage,setAlertType}) {
  const exportToTxt = () =>{
    const data = JSON.stringify(labeldata);
    const newData = data.replace(/\\n/g,"\n").replace(/"/g,"").replace(/\\t/g,"\t");
    const blob = new Blob([newData],{type:"text/plain"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `${label}.txt`
    link.href=url;
    link.click()

  }
  const [labeldata,setLabelData] = React.useState("")
  const [loading,setLoading] = React.useState(true)
  React.useEffect(() => {
    apiReadFile(filepath)
    .then(([response, json]) => {
      if(response.status===200 && json.status!=='ERROR')
      
      {
        const data = JSON.stringify(json.results[0]).replace(/\\n/g,"\n").replace(/"/g,"").replace(/\\t/g,"&nbsp");
      setLabelData(data)
    }
    else{
      setOpen1(true)
      setAlertType('error')
      setAlertMessage("No data to show")
      close();
    }
      setLoading(false)
    })
   
   
   
  },[])//eslint-disable-line react-hooks/exhaustive-deps
  const closeButtonFunction = (event) =>{
    close();

  }
  return ( <Box style={styles.gridContainer}>
        <IconButton id="close-modal-popup" data-testid="close-modal-popup" onClick={closeButtonFunction} style={styles.closeButton}>
    <CloseIcon />
</IconButton>
<Typography component='div' style={{marginBottom:'20px'}}>
  <Typography component='span' style={styles.label1}>PO Number:</Typography>
  <Typography component='span' style={styles.label2}>{poNum}</Typography>
</Typography>
<Typography component='div' style={{marginBottom:'20px'}}>
  <Typography component='span' style={styles.label1}>Label ID:</Typography>
  <Typography component='span' style={styles.label2}>{label}</Typography>
</Typography>
<Typography component='div' style={styles.labeldata}>
  {loading?<Typography component='div' sx={{        position: 'absolute',
        top: '50%',
        left: '50%',
       transform: 'translate(-50%, -50%)',}} className={'spinner'}></Typography>:labeldata.split('\n').map(ele => <Typography component='div' key={Math.random().toString(36).substring(2,9)}  style={{marginTop:'20px',marginLeft:'20px',marginRight:'20px',width:'100%',whiteSpace:'pre',fontSize:'14px'}}>{ele.replace(/\\t/g,'h')}</Typography>)}
</Typography>
<DownloadButton className={loading||status==='ERROR'?'disabled':''} onClick={exportToTxt}>Download</DownloadButton>
</Box>
  )
}
export default ViewLabel;