import {styled} from '@mui/system'
import { Box } from '@mui/material'

export const OrderButton = styled('button')({
    cursor:'pointer',
    border: '1px solid #293E53',
    background: '#293E53 0% 0% no-repeat padding-box',
    color:'#FFFFFF',
    width: '130px',
    borderRadius: '4px',
    height: '49px',
    font: 'normal normal normal 14px/21px Poppins',
    '@media(max-width:1370px)':{
        width: '115px',
        height: '40px',
        font: 'normal normal normal 13px/21px Poppins',
    },
    '&:hover':{
        color:'#293E53',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #293E53',
        borderRadius: '4px',
        boxShadow: '5px 5px 5px rgba(0,0,0,0.3)'
    },
    '&.disabled':{
        background: '#878787 0% 0% no-repeat padding-box',
        border: '1px solid #FFFFFF',
        opacity:1,
        pointerEvents: 'none'
    },
})

export const StyledCard = styled(Box)({
    paddingBottom: '0px',
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    padding: '5px 14px '
})

export const styles ={
    prepareButton :{
        border: '1px solid #293E53',
        borderRadius: '4px',
        opacity: 1,
        width: '130px',
        height: '49px',
        font: 'normal normal normal 14px/21px Poppins',
    },
    prepareModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '605px',
        height: '250px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #707070',
        boxShadow: 24,
        p: 4
    },
    
    buttonOutlinedPrepare :{
        border: '1px solid #293E53',
        borderRadius: '4px',
        opacity: 1,
        width: '130px',
        height: '49px',
        font: 'normal normal normal 14px/21px Poppins',
    },
    
    lockButton:{
        border: '1px solid #293E53',
        borderRadius: '4px',
        opacity: 1,
        width: '130px',
        height: '49px',
        font: 'normal normal normal 14px/21px Poppins',
    },
    saveButton :{
        border: '1px solid #293E53',
        borderRadius: '4px',
        opacity: 1,
        width: '130px',
        height: '49px',
        color: '#FFFFFF',
        backgroundColor: '#293E53' ,
        font: 'normal normal normal 14px/21px Poppins',
    },
    
    poNumber :{
        width: '80px',
        height: '20px',
        textAlign: 'left',
        font: 'normal normal normal 12px/18px Poppins',
        fontWeight: '600',
        fontSize: '12px' ,
        letterSpacing: '0px',
        color: '#010101',
        opacity: 1
    },
    
    poNum:{
        font: 'normal normal normal 14px/20px Poppins',
        letterSpacing: '0px',
        color: '#293E53',
        opacity: 1,
        width: '101px',
        height: '20px',
    },
    
    poNum3 :{
        paddingLeft: '35px',
        font: 'normal normal normal 14px/20px Poppins',
        letterSpacing: '0px',
        color: '#293E53',
        opacity: 1,
        width: '101px',
        height: '20px',
    },
    
    coNum2 :{
        paddingLeft: '15px',
    
        font: 'normal normal normal 14px/20px Poppins',
        letterSpacing: '0px',
        color: '#293E53',
        opacity: 1,
        width: '101px',
        height: '20px',
    },
    
    coNum3 :{
        paddingLeft: '57px',
        font: 'normal normal normal 14px/20px Poppins',
        letterSpacing: '0px',
        color: '#293E53',
        opacity: 1,
        width: '101px',
        height: '20px',
    },
    customerCode:{
        textAlign: 'left',
        font: 'normal normal medium 14px/21px Poppins',
        letterSpacing: '0px',
        color: '#010101',
        opacity: 1
    },
    paddingSave:{
        paddingLeft: '47px',
    
    },
    paddingRefresh:{
        padding: '40px 0 38px 795px'
    
    },
    
    label1 :{
        textAlign: 'left',
        font: 'normal normal medium 24px/28px Helvetica Neue',
        letterSpacing: '0px',
        color: '#293E53',
        opacity: 1,
        paddingBottom: '40px',
        width: '239px',
        height: '27px',
    },
    
    country :{
        paddingLeft: '130px',
        width: '80px',
        height: '20px',
        textAlign: 'left',
        font: 'normal normal medium 14px/21px Poppins',
        letterSpacing: '0px',
        color: '#010101',
        opacity: 1
    },
    
    coNum :{
        font: 'normal normal normal 14px/20px Poppins',
        letterSpacing: '0px',
        color: '#293E53',
        opacity: 1,
        width: '101px',
        height: '20px',
    },
    
    prepare :{
        paddingLeft: '54px',
    
    },
    
    lock :{
        paddingLeft: '40px',
    },
    
    send :{
        paddingLeft: '79px',
    },
    
    send2 :{
        paddingLeft: '99px',
    },
    
    card1 :{
        paddingBottom: '0px',
        display: 'inline-flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexGrow: 1,
        padding: '5px 14px ',
    
    },
    
    manual :{
        paddingLeft: '25px',
    },
    
    card2 :{
        paddingTop: '5px',
        paddingBottom: '0px',
        display: 'inline-flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexGrow: 1,
        padding: '5px 14px ',
    
    
    },
    
    card3 :{
        paddingTop: '5px',
        display: 'inline-flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexGrow: 1,
        padding: '5px 14px ',
    
    },
    card4 :{
        paddingTop: '10px',
        display: 'inline-flex',
        width: '100%',
        alignItems: 'center',
        justifyContent:'flex-start',
        flexGrow: 1,
       
    },
    
    cardContent :{
        display: 'inline-flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexGrow: 1,
        padding: '5px 14px ',
    },
    
    col1title:{
        width: '15%',
        font: 'normal normal normal 12px/18px Poppins ',
        fontWeight: '600 ',
        fontSize: '12px ',
        color: '#010101',
    },
    col2title :{
        width: '15%',
        font: 'normal normal normal 12px/18px Poppins ',
        fontWeight: '600 ',
        fontSize: '12px ',
        color: '#010101',
    },
    
    col1field :{
        font: 'normal normal normal 12px/20px Poppins',
        width: '16%',
    },
    col2field :{
        font: 'normal normal normal 12px/20px Poppins ',
        width: '14%',
        color: '#293E53',
    },
    
    btn_prepare:{
        minWidth: '12%',
        margin: '0 15px',
    },
    btn_lock:{
        minWidth: '12%',
        margin: '0 15px',
    },
    btn_send :{
        minWidth: '12%',
        margin: '0 15px',
    },
    btn_history :{
        width: '3%',
    },
    popupStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '1169px',
        height: '570px',
      
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #707070',
        boxShadow: 24,
        p: 4,
    },
    overageStyle: {
        fontSize: '13px',
        clear: 'both',
        padding: '4px 0',
        width: '350px'
    },
    showOverage : {
        display: 'block'
    },
    hideOverage : {
        display: 'none'
    },
    overageStatus : {
        error: {
            color: 'red',
            display: 'block'
        },
        success: {
            color: 'green',
            display: 'block'
        }
    },

    cardreset :{
        paddingTop: '5px',
        width: '90%',
        alignItems: 'right',
        display: 'inline-flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
        padding: '5px 14px ',
    }
}