import {USER_ACCESS_GET , USER_ACCESS_SUCCESS,USER_ACCESS_FAILURE,} from './actions';

const initialState = {
    order: {}
}

export function UserAccessReducer(state = initialState, action) {
    
    if(action.type === USER_ACCESS_GET) {
        return state;
    }
    if(action.type === USER_ACCESS_SUCCESS) {
        return {
            ...state,
            order: {
                isLoaded: true,
                data: action.payload,
                message: 'Success'
            }
            
        }
    }
    if(action.type === USER_ACCESS_FAILURE) {
        return {
            ...state,
            order: {
                data: [],
                message: action.payload
            }
        }
    }
    
    return state;
    
}



