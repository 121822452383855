import React from 'react';
import Home from "../modules/Home/index";
import NoMatch from "../modules/Error/NoMatch";
import Orders from "../modules/Orders/";
import Label from '../modules/Label/';
import { Provider } from 'react-redux';
import OrderList from "../modules/Orders/OrderList/";
import ApprovalList from "../modules/Orders/ApprovalList/";
import ViewBulkAction from "../modules/Orders/VIewBulkAction"
import InventoryList from "../modules/Orders/InventoryList"
import UserList from "../modules/UserManagement/UserList/index"
import UserGroup from "../modules/UserManagement/UserGroup/index"
import ProductType from "../modules/ReferenceData/ProductType";
import ProductGroup from "../modules/ReferenceData/ProductGroup";
import SportsCategory from "../modules/ReferenceData/SportsCategory";
import CountrySizeLookup from "../modules/ReferenceData/CountrySizeLookup";
import {store as userliststore} from "../modules/UserManagement/UserList/Store/store"
import {store as usergroupstore} from "../modules/UserManagement/UserGroup/Store/store"
import { store as producttypestore } from "../modules/ReferenceData/ProductType/Store/store";
import { store as productgroupstore } from "../modules/ReferenceData/ProductGroup/Store/store";
import { store as sportscategorystore } from "../modules/ReferenceData/SportsCategory/Store/store";
import {store as userorderstore} from "../modules/UserManagement/UserOrder/Store/store"
import {store as countrysizestore} from "../modules/ReferenceData/CountrySizeLookup/Store/store"
import UserOrder from "../modules/UserManagement/UserOrder/index"
import { store as dashboardstore } from '../modules/Home/Store/store'
import { store as orderliststore } from '../modules/Orders/OrderList/Store/store'
import { store as Inventoryliststore } from '../modules/Orders/InventoryList/Store/store'
import {store as viewBulkActionstore} from '../modules/Orders/VIewBulkAction/Store/store'
import { store as labelstore } from '../modules/Label/Store/store'
import LabelReceiver from "../modules/ReferenceData";
import LabelAdmin from "../modules/Label/LabelAdmin";
import { store as labeladminstore } from '../modules/Label/LabelAdmin/Store/store';
import LabelMainType from "../modules/Label/LabelMainType";
import { store as orderstore } from "../modules/Orders/Store/store"
import { store as labelmaintypestore } from "../modules/Label/LabelMainType/Store/store";
import { store as labelusagetypestore } from "../modules/Label/LabelUsage/Store/store";
import { store as labelassignment } from "../modules/Label/LabelAssignment/Store/store";
import { store as labelfactory } from "../modules/Label/LabelFactoryList/Store/store";
import LabelUsageType from "../modules/Label/LabelUsage";
import LabelAssignment from "../modules/Label/LabelAssignment";
import LabelFactory from "../modules/Label/LabelFactoryList";
import LabelCustomer from "../modules/Label/LabelCustomerList";
import { store as labelcustomer } from "../modules/Label/LabelCustomerList/Store/store";
import LabelLayout from "../modules/Label/LabelLayout";
import { store as labelgridstore } from "../modules/Label/LabelLayout/LabelLayoutGrid/Store/store";
import {store as articlegridstore} from "../modules/ReferenceData/ArticleMaster/Store/store";
import {store as approvalListStore} from "../modules/Orders/ApprovalList/Store/store";
import ArticleMaster from "../modules/ReferenceData/ArticleMaster";
import { Navigate } from "react-router-dom";

const routeConfig = [
  {
    path: "/",
    element: <Provider store={dashboardstore}><Home /></Provider>,
    breadcrumb: 'Home'
  },
  {
    path: "/Order_List",
    element: <Provider store={orderliststore}><OrderList /></Provider>,
    breadcrumb: 'Orders'
  },
  {
    path: "/Label/LabelAdmin",
    element: <Provider store={labelgridstore}><LabelLayout /></Provider>,
    breadcrumb: 'Master Data'
  },
  {
    path: "/Article_master",
    element: <Provider store={articlegridstore}><ArticleMaster /></Provider>,
    breadcrumb: 'Master Data'
  },
  {
    path: "/orders/Order_Label_Details",
    element: <Provider store={orderstore}><Orders /></Provider>,
    breadcrumb: 'Orders'
  },
  {
    path: "referenceData/Label_Receiver",
    element: <LabelReceiver />,
    breadcrumb: 'Reference Data'
  },
  {
    path: "/referenceData/productReference/articleMaster",
    element: <ArticleMaster/>,
    breadcrumb: 'Reference Data'
  },
  {
    path: "/Label_List",
    element: <Provider store={labeladminstore}><LabelAdmin/></Provider>,
    breadcrumb: 'Master Data'
  },
  {
    path: "/label_Main_Type",
    element: <Provider store={labelmaintypestore}><LabelMainType/></Provider>,
    breadcrumb: 'Master Data'
  },
  {
    path: "/Customer_List",
    element: <Provider store={labelcustomer}><LabelCustomer/></Provider>,
    breadcrumb: 'Master Data'
  },
  {
    path: "/Label_Assignment",
    element: <Provider store={labelassignment}><LabelAssignment/></Provider>,
    breadcrumb: 'Master Data'
  },
  {
    path: "/label/Label_Usage_Type",
    element: <Provider store={labelusagetypestore}><LabelUsageType/></Provider>,
    breadcrumb: 'Master Data'
  },
  {
    path: "/Factory_List",
    element: <Provider store={labelfactory}><LabelFactory/></Provider>,
    breadcrumb: 'Master Data'
  },
  {
    path: "Label/*",
    element: <Provider store={labelstore}><Label/></Provider>,
    breadcrumb: 'Master Data'
  },
  {
    path: "/UserList",
    element: <Provider store={userliststore}><UserList/></Provider>,
    breadcrumb: 'User List'
  },
  {
    path: "/UserGroup",
    element: <Provider store={usergroupstore}><UserGroup/></Provider>,
    breadcrumb: 'User Group'
  },
  {
    path: "/UserOrder",
    element: <Provider store={userorderstore}><UserOrder/></Provider>,
    breadcrumb: 'User Order'
  },
  {
    path: "/Country_Size",
    element: <Provider store={countrysizestore}><CountrySizeLookup/></Provider>,
    breadcrumb: 'Country Size'
  },
  {
    path: "/Product_Type",
    element: <Provider store={producttypestore}><ProductType/></Provider>,
    breadcrumb: 'Product Type'
  },
  {
    path: "/Product_Group",
    element: <Provider store={productgroupstore}><ProductGroup/></Provider>,
    breadcrumb: 'Product Group'
  },
  {
    path: "/Sports_Category",
    element: <Provider store={sportscategorystore}><SportsCategory/></Provider>,
    breadcrumb: 'Sports Category'
  },
  
  // {
  //   path: "/orders/Bulk_Actions",
  //   element: <Provider store={testStore}><TestPage /></Provider>,
  //   breadcrumb: 'Test Page'
  // },
  {
    path: "/orders/View_Bulk_Actions",
    element: <Provider store={viewBulkActionstore}><ViewBulkAction /></Provider>,
    breadcrumb: 'Test Page'
  },
  {
    path: "/Inventory_List",
    element: <Provider store={Inventoryliststore}><InventoryList /></Provider>,
    breadcrumb: 'Test Page'
  },
  {
    path: "/auth",
    element: <Navigate to ='/'/>,
    breadcrumb: 'User Order'
  },
  {
    path: "/*",
    element: <NoMatch />,
    breadcrumb: 'Home'
  },
  {
    path: "/Approval_List",
    element: <Provider store={approvalListStore}><ApprovalList /></Provider>,
    breadcrumb: 'Orders'
  }
];

export default routeConfig;