import { labelFactoryList,apisearchFactory } from '../../../../services/Gateway/RestAPIHandler';

 
 
 export const LABEL_FACTORY_LIST_GET ="Get Label Factory List";
 export const LABEL_FACTORY_LIST_SUCCESS = "Label Factory List Sucess";
 export const LABEL_FACTORY_LIST_FAILURE = "Label Factory List Failure";
 const searchDefault = {
    "ascDesc": "",
    "fetchSize": 8,
    "filterSearch": "",
    "pageNumber": 1,
    "sortBy": ""
}
 export function getLabelFactoryListAction(params) {
    return (dispatch) => {
        dispatch(getLabelFactoryList());
        return labelFactoryList(params) 
            .then(([response, json]) => {
                if(response.status === 200){ 
                    dispatch(getLabelFactoryListSuccess(json.results));
                }
                else {
                    dispatch(getLabelFactoryListFailure("Unable to fetch factory list data; Error code - " + json.errorCode));
                }
            })
    }
}
export function searchFactoryAction(params = searchDefault) {
    return (dispatch) => {
        dispatch(getLabelFactoryList());
        return apisearchFactory(params)
            .then(([response, json]) => {
                if (response.status === 200) {

                    dispatch(getLabelFactoryListSuccess(json.results, params));
                }
                else {
                    dispatch(getLabelFactoryListFailure("Unable to fetch Factory search data; Error code - " + json.errorCode, params));
                }
            })
    }
}

export function getLabelFactoryList() {
    return {
        type: LABEL_FACTORY_LIST_GET
    }
}

export function getLabelFactoryListSuccess(res,params) {
    return {
        type: LABEL_FACTORY_LIST_SUCCESS,
        payload: {res:res,request:params}

    }

}

export function getLabelFactoryListFailure(message,params) {
    return {
        type: LABEL_FACTORY_LIST_FAILURE,
        payload: {message:message,request:params}
    }
}