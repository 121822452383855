import { styled, } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import { Button } from "@mui/material";

export const StyledAgGridReact = styled(AgGridReact)({
    '.ag-header-cell-text': {
        color: '#99702C',
        fontFamily: 'Helvetica Neue,Roboto,sans-serif',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '18px',
    },
    '.ag-paging-page-summary-panel': {
        margin: 0,
    }
})

export const OrderButton = styled(Button)({
    zIndex: '10',
    cursor: 'pointer',
    borderRadius: '4px',
    marginRight: '30px',
    width: '120px',
    height: '40px',
    color: '#FFFFFF',
    border: '1px solid #293E53',
    fontFamily: 'Poppins',
    fontSize: '14px',
    background: '#293E53 0% 0% no-repeat padding-box',
    opacity: '1',
    padding: 0,

    '&:hover': {
        border: '1px solid #293E53',
        borderRadius: '4px',
        color: '#293E53',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '5px 5px 5px rgba(0,0,0,0.3)'
    },
    '&:disabled': {
        background: '#878787 0% 0% no-repeat padding-box',
        border: '1px solid #0000',
        opacity: 1,
    }


})

export const Lbl = styled('div')({
        display: "flex",
        alignItems: "center",
        textAlign: "center",

        '&:after' : {
            content: '""',
            flex: "1",
            borderBottom: "1px solid #000",
            marginLeft: "1.5em",
        }
})


export const styles = {
    buttons: {
        display: 'flex',
        marginTop: '10px'
    }
    ,
    textFont: {
        textAlign: 'left',
        fontFamily: 'normal normal medium 24px/28px Helvetica Neue',
        color: '#293E53',
        opacity: 1,
    },
    card2 :{ 
        paddingTop: '10px',
        paddingBottom: '0px',
        display: 'inline-flex',
        width: '100%',
        height:'fit-content',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexGrow: 1,
    },

    card3 :{ 
        paddingTop: '10px',
        paddingBottom: '20px',
        display: 'inline-flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexGrow: 1,
    },
    closeButton: {
        position: "absolute",
        top: '0',
        right: '0'
    },
    labelFont: {
        color: '#FF4343',
        fontFamily: '"Helvetica Neue", Roboto, sans-serif',
        paddingLeft: '3%',

    },
    gridContainer: {
        float: 'left',
        width: '100%',
        height: '300px',
    },
    imageBox:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        margin:'30px 0 30px 0',
    },
    gridPagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '5px 0',
        columnGap: '7px',
        buttons: {
            width: '35px',
            height: '35px',
            border: '1px solid var(--GridPageButtonBorder)',
            borderRadius: '4px',
            cursor: 'pointer',
            textAlign: 'center',

            color: 'var(--GridPageNumberColor)',
            fontWeight: 'bold',
            fontSize: '12px',
            lineHeight: '21px',
            fontFamily: 'Poppins',
            pageButton: {
                padding: '6px 0'
            },

            icons: {
                width: 'fit-content',
                height: 'fit-content',
                color: 'var(--GridPageIconColor)'
            }
        },
        selectedButton: {
            width: '35px',
            height: '35px',
            border: '1px solid var(--GridPageButtonBorder)',
            borderRadius: '4px',
            cursor: 'pointer',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '12px',
            lineHeight: '21px',
            fontFamily: 'Poppins',
            pageButton: {
                padding: '6px 0'
            },
            color: 'var(--ButtonBGColor)',
            backgroundColor: 'var(--ButtonBorderColor)'
        },
        
        text: {
            textAlign: ''
        }
    },
}
export const DivContainer = styled('div')(
    ({ theme }) => `
    background-color: ${theme.palette.mode === 'dark' ? '#121212' : '#fff'};
    opacity: 1;
    margin: 0.25rem 0px;
    height:853px;
    width:521px;
    border-radius: 5px;
    display:flex;
    flex-direction: column;
    
  `,
);
export const LabelContainer = styled('span')(
    ({ theme }) => `
   

    color: #FF4343;
    text-align: left;
    font: normal normal medium 14px/21px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    width: 300px;
    
  `,
);
export const H6Label = styled('span')(
    ({ theme }) => `
    height: 33px;
    font: normal normal medium 24px/35px Poppins;
    letter-spacing: 0px;
    color: #010101;
    opacity: 1;
    display: inline; 

    
  `,
);
export const HR = styled('span')(
    ({ theme }) => `
    position: relative;
    clear: none;
    padding-top : 3px;
    padding-left : 180px
    display: inline; 

    
  `,
);
export const LabelFont = styled('span')(
    ({ theme }) => `
    text-align: left;
    fontFamily: 'Poppins',
    letter-spacing: 0px;
    color: #010101;
    opacity: 1;
    height: 20px;
    width: 300px;
    paddingLeft:20px;
    
  `,
);
