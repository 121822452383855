import { styled } from "@mui/system";
import { Button } from "@mui/material";

export const LoginButton = styled(Button)({
    cursor: 'pointer',
    borderRadius: '4px',
    marginRight: '10px',
    width: '130px',
    height: '49px',
    color:'#FFFFFF',
    border: '1px solid #293E53',
    fontFamily: 'Poppins',
    fontSize: '14px', 
    background: '#293E53 0% 0% no-repeat padding-box',
    opacity: '1',
    padding:0,
    '@media(max-width:1370px)':{
        borderRadius: '4px',
        marginRight: '20px',
        width: '115px',
        height: '40px',
        fontSize: '12px',
        
    },
    '&:hover':{
        border: '1px solid #293E53',
        borderRadius: '4px',
        color:'#FFFFFF',
        background: '#293E53 0% 0% no-repeat padding-box',
        boxShadow: '5px 5px 5px rgba(0,0,0,0.3)'
    },
    '&.disabled':{
        background: '#878787 0% 0% no-repeat padding-box',
        border: '1px solid #FFFFFF',
        opacity:1,
        pointerEvents: 'none'
    }
})

export const LoginStyle = {
    cardMedia:
    {paddingTop:'20px',paddingLeft:'30%',width:'150px',objectFit:'cover',paddingBottom:'20px',paddingRight:'30%',backgroundColor:'rgba(0,0,0,0.8)'}
,
    container: {
        display: 'flex',
        width: '100%',

        divider: {
            marginTop: '5%',
            marginBottom: '5%'
        },
        leftPanel: {
            width: '60%',
            height: '100vh',
            maxHeight: '700px',
            overflow: 'hidden',

            img: {
                position: 'absolute',
                left: '120px',
                top: 'calc(30% - 45px)',
                height:'44px',
                width:'92px',
                marginBottom: '30px'
            },
            title: {
                position: 'absolute',
                left: '120px',
                top: '30%',
                width: '50px',
                color: '#000',

                fontFamily: 'Poppins',
                fontSize: '56px',
                fontWeight: 'bold',
                justifyContent: 'center'
            }
        },
        rightPanel: {
            width: '40%',
            height: '100vh',
            maxHeight: '700px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            
            signin:{
                fontSize: '28px',
                fontWeight: '800',
                fontFamily: 'Poppins',
                marginBottom: '16px'
            },
            loginField: {
                display: 'inline-flex',
                flexDirection: 'column',
                alignItems: 'center',
                textBox: {
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    border: '1px solid #707070',
                    borderRadius: '4px',
                    opacity: 1,
                    width: '160px',
                    height: '28px',
                    marginLeft: '25px',
                    outlineWidth: '0'
                },
                password: {
                    display: 'inline-flex',
                    border: '1px solid #707070',
                    borderRadius: '4px',
                    opacity: 1,
                    width: '165px',
                    height: '29px',
                    marginLeft: '25px',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    textbox: {
                        width: '132px',
                        height: '21px',
                        marginLeft: '1px',
                        border: 'none',
                        outlineWidth: '0'
                    },
                    icon: {
                        opacity: '0.8'
                    }
                }
            },
            align: {
                marginBottom: '20px'
            },
           

            button: {
                width: '125px',
                height: '38px',
                textAlign: 'center',
                backgroundColor: 'var(--LoginButton)',
                borderColor: 'var(--LoginButton)',
                color: 'var(--ButtonBGColor)'
            }
        }
    }
}