// import {
//     applyMiddleware,
//     combineReducers,
//     compose
// } from 'redux';
// import createSagaMiddleware from 'redux-saga';
// import { LabelAdminReducer } from './reducer';
// import { watchLabelAdminList } from './saga';
// import { configureStore } from '@reduxjs/toolkit';

// const sagaMiddleware = createSagaMiddleware();
// const middleware = applyMiddleware(sagaMiddleware);

// const composeEnhancers =
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const reducers = combineReducers({
//     labelAdminList: LabelAdminReducer,
// });

// export const store = configureStore({reducer: reducers}, composeEnhancers(middleware));
// middleware.run(watchLabelAdminList);   


import {
    applyMiddleware,
    combineReducers,
    createStore,
    compose
} from 'redux';
import thunk from 'redux-thunk';
import { LabelListReducer } from './reducer';

const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    labelAdminList: LabelListReducer,

});

export const store = createStore(reducers, composeEnhancers(middleware));