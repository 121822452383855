import { apiDashBoard, apiOrderList, apiLabelList } from '../../../services/Gateway/RestAPIHandler';
import Utils from '../../../utils/Utils';

export const ORDER_LIST_GET = 'Get Order List';
export const ORDER_LIST_SUCCESS = 'Order List Success';
export const ORDER_LIST_FAILURE = 'Order List Failure';

export function dashboardAction() {
    return (dispatch) => {
        return apiDashBoard()
            .then(([response, json]) => {
                if(response.status === 200){
                    let res = json.results
                    dispatch(getOrderListSuccess(res));
                }
                else {
                    dispatch(getOrderListFailure(`Unable to fetch order data; Error code - ${Utils.isEmpty(json.errorCode) ? '0' : json.errorCode}`));
                }
            })
            .catch(err => {
                dispatch(getOrderListFailure("Unable to fetch the resource; Error message - " + err.message));
            })
    }
}

export function getOrderListAction(params) {
    return (dispatch) => {
        dispatch(getOrderList());
        return apiOrderList(params)
            .then(([response, json]) => {
                if(response.status === 200){
                    dispatch(getOrderListSuccess(json.results,params))
                }
                else {
                    dispatch(getOrderListFailure("Unable to fetch order data; Error code - " + json.errorCode));
                }
            })
    }
}

export function getOrderList() {
    return {
        type: ORDER_LIST_GET
    }
}

export function getOrderListSuccess(res,params) {
    
    return {
        type: ORDER_LIST_SUCCESS,
        payload: {res:res,request:params}
    }
}

export function getOrderListFailure(message) {
    return {
        type: ORDER_LIST_FAILURE,
        payload: message
    }
}

export function getLabelListAction(params) {
    return (dispatch) => {
        dispatch(getLabelList());
        return apiLabelList(params)
            .then(([response, json]) => {
                if(response.status === 200){
                    dispatch(getLabelListSuccess(json.results));
                }
                else {
                    dispatch(getLabelListFailure("Unable to fetch order data; Error code - " + json.errorCode));
                }
            })
    }
}

export const LABEL_LIST_GET = 'Get Label List';
export const LABEL_LIST_SUCCESS = 'Label List Success';
export const LABEL_LIST_FAILURE = 'Label List Failure';

export function getLabelList() {
    return {
        type: LABEL_LIST_GET
    }
}

export function getLabelListSuccess(data) {
    return {
        type: LABEL_LIST_SUCCESS,
        payload: data
    }
}

export function getLabelListFailure(message) {
    return {
        type: LABEL_LIST_FAILURE,
        payload: message
    }
}