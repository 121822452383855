import React from 'react'
import { Box } from '@mui/system';
import {styles,OrderButton} from './styles';
function Buttons({status,poNum,handlePrepare,poItemIdsList,handleSent,convertJsonToCSV,handleLock,handleUnlock,handleBulkNavigate,handleCancel,handlePrepareSend}) {


  return (
    <Box style={styles.buttons}>
      <OrderButton onClick={handlePrepare} className={poItemIdsList.length>1||poNum===0||(status==='SHIPPED'||status==='LOCKED'||status==='CANCELLED'||status==="PROCESSING"||status==="SENT"||status==="PRINTED")?'disabled':''}>Prepare</OrderButton>
      <OrderButton onClick={handleSent} className={poItemIdsList.length===1&&(poNum!==0&&status!=="SHIPPED"&& status!=="CANCELLED"&& status!=="LOCKED"&&status!=='ERROR'&&status!=='NEW'&&status!=="PROCESSING"&&status!=="SENT"&&status!=="PRINTED")?'':'disabled'}>Send</OrderButton>
      <OrderButton onClick={handlePrepareSend} className={poItemIdsList.length>=1&&(poNum!==0&&status!=="SENT"&&status!=="PROCESSING"&&status!=="SHIPPED"&& status!=='CANCELLED'&& status!=="LOCKED"&&status!=="PRINTED")?'':'disabled'} >Prepare & Send</OrderButton>
      <OrderButton onClick={handleLock} className={poItemIdsList.length===1&&(poNum!==0&&status!=="SHIPPED"&& status!=='LOCKED'&&status!=='CANCELLED'&&status!=="PROCESSING"&&status!=="SENT"&&status!=="PRINTED")?'':'disabled'}>Lock</OrderButton>
      <OrderButton onClick={handleUnlock}  className={poItemIdsList.length===1&&(poNum!==0&&status==='LOCKED')?'':'disabled'}>Unlock</OrderButton>
      <OrderButton onClick={handleCancel} className={poItemIdsList.length===1&&(poNum!==0&&status!=="SHIPPED"&& status!=='CANCELLED'&& status!=="LOCKED"&&status!=="PRINTED"&&status!=="SENT")?'':'disabled'}>Cancel</OrderButton>
      {/* <OrderButton onClick={convertJsonToCSV}>Download Bulk</OrderButton> */}
    </Box>
  )
}
export default Buttons
