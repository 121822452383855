import {PRODUCT_TYPE_GET , PRODUCT_TYPE_SUCCESS,PRODUCT_TYPE_FAILURE } from './actions';

const initialState = {
    order:{}
}

export function ProductTypeReducer(state = initialState, action) {
    
    if(action.type === PRODUCT_TYPE_GET) {
        return state;
    }
    if(action.type === PRODUCT_TYPE_SUCCESS) {
        return {
            ...state,
            order: {
                isLoaded: true,
                data: action.payload,
                message: 'Success'
            }
        }
    }
    if(action.type === PRODUCT_TYPE_FAILURE) {
        return {
            ...state,
            order: {
                data: [],
                message: action.payload
            }
        }
    }
    return state;
}

