import React from 'react'
import Buttons from '../../Orders/OrderList/Buttons';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Box} from '@mui/material';
import { styles,StyledAgGridReact } from './styles';
import { getLabelUsageTypeAction } from './Store/actions';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {useDispatch, useSelector  } from 'react-redux';

function LabelUsageType(){

    const buttonlist =['Add','Save','Delete'];
    const checkboxSelection = function (params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    };
    const headerCheckboxSelection = function (params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    };
    const columnDef = [
      { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 20 },
    
        { field: 'labelUsageCode', headerName: 'Label Usage Type ID',editable: true, width: 385, headerClass: 'grid-header' },
        { field: 'name', headerName: 'Name', width: 415,editable: true, headerClass: 'grid-header' },
        // { field: 'lblCategory', headerName: 'Label Category',editable: true, width: 400, headerClass: 'grid-header' },
 
    ];
    const currentPage = 1;
    const totalGridPages = 1;
    const gridRef = React.useRef();
    const gridPage = [];

    for(let pageCnt=0; pageCnt < totalGridPages && pageCnt < 4; pageCnt++ ){
      gridPage.push(
      <Box style={(pageCnt === 0) ? styles.gridPagination.selectedButton : styles.gridPagination.buttons}>
        <Box style={styles.gridPagination.buttons.pageButton}>{(pageCnt + 1)}</Box>
      </Box>)
    }
   
  
    const dispatch = useDispatch();
    React.useEffect(() =>{
      dispatch(getLabelUsageTypeAction({page: currentPage}))
    },[dispatch, currentPage])
    const list = useSelector(state => state.labelUsageTypeList);

return(
    <Box style={styles.gridContainer}>
    <Box className={"ag-theme-alpine"}
    sx={{height:'400px',width: '100%'}}>
    <StyledAgGridReact
      rowSelection='single'
      rowData={Object.keys(list.order).length > 0 ? list.order.data[0].content : []}
      columnDefs={columnDef}
      ref={gridRef}
      pagination={true}
      paginationPageSize={8}
      suppressPaginationPanel={true}
    />
      <Box id="grid-pagination" style={styles.gridPagination}>
      <Box id="grid-first-page" style={styles.gridPagination.buttons}>
        <ArrowLeftIcon style={styles.gridPagination.buttons.icons} />
      </Box>
      {gridPage}
      <Box id="grid-last-page" style={styles.gridPagination.buttons}>
        <ArrowRightIcon style={styles.gridPagination.buttons.icons} />
      </Box>
    </Box>
  </Box>
            <Buttons buttonlist={buttonlist}/>

    </Box>
)
}
export default LabelUsageType;