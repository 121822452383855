import React from 'react'
import {Box, Typography} from '@mui/material'
const labelDetails = [
  {
    labelColor:'#3D4144',
    labelText:'Completed Orders'
  },
  {
    labelColor:'#0262F9',
    labelText:'New Orders'
  },
  {
    labelColor:'#FCF75E',
    labelText:'In Progress'
  },
  {
    labelColor:'#A52A2A',
    labelText:'Orders with Error'
  },
]
function Legend() {
  return (
    
      labelDetails.map((item,index) =>
          <Box data-testid={`Legend${index}`} key={index} sx= {{display:'flex',alignItems:'center',marginBottom:'3px'}}>
          <Box sx ={{height:'13px',width:'13px',backgroundColor:`${item.labelColor}`,marginRight:'7px',borderRadius:'50%'}}></Box>
          <Typography sx={{fontSize:'10px',fontFamily:'Helvetica Neue',fontWeight:'600'}}>{item.labelText}</Typography>
          </Box>
         )
    
    
  )
}

export default Legend