export const styles = {
    gridPagination: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px',
        pageInfo: {
            marginLeft: '10px',
            fontSize: '13px'
        },
        pageButton: {
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            columnGap: '7px',
            marginRight: '10px'
        },
        muiButton: {
            color: 'var(--GridPageNumberColor)',
            fontWeight: 'bold',
            fontSize: '12px',
            lineHeight: '21px',
            fontFamily: 'Poppins',
            '.MuiPaginationItem-page': {
                color: 'var(--GridPageNumberColor)',
                border: '1px solid var(--GridPageButtonBorder)',
                backgroundColor: `var(--ButtonBGColor)`,
            },
            '.MuiPaginationItem-page.Mui-selected': {
                color: 'var(--ButtonBGColor)',
                backgroundColor: `var(--ButtonBorderColor)`,
                '&:hover':{
                    backgroundColor: `var(--ButtonBorderColor)`,
                }
            }
        },
    },
    Goto: {
        padding: '0 5px',
        fontSize: '14px',
        lineHeight: '21px',
        fontFamily: 'Poppins',

        text: {
            width: '40px',
            marginLeft: '5px',
            fontFamily: 'Poppins',
            padding: '2px 5px',
            textAlign: 'center'
        }
    }
}