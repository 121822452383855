import React from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Box, Typography,IconButton } from '@mui/material';
import { styles, StyledAgGridReact,RefreshButton } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import {useSelector,useDispatch } from 'react-redux';
import {apiAddLabel} from '../../../services/Gateway/RestAPIHandler'
import { searchPOInformation } from '../Store/actions';
import { useSearchParams } from 'react-router-dom';
import Loader from '../../../customWidgets/Loader';
import AccessLevelContext from '../../../../accessLevel-context';
import Modal from "@mui/material/Modal";

function AddLabel({close,setAlertMessage,setAlertType,setOpen,setHideAddLabel}) {
  const accessLevel= React.useContext(AccessLevelContext)
  const dispatch= useDispatch()
  const [comment,setComment] = React.useState('')
  const [data,setData] = React.useState([])
  const [searchLabel,setSearchLabel] = React.useState('')
  const [loading,setLoading] = React.useState(true)
  const [protectedLabel,setProtectedLabel] = React.useState('N')
  const searchParams = useSearchParams()[0];
  const [openAddComment, setOpenAddComment] = React.useState(false);
  const handleOpenAddComment = () => {
    setOpenAddComment(true);
    setHideAddLabel(true)
  };
  const handleCloseAddComment = () => {
    setOpenAddComment(false);
    setHideAddLabel(false)
  };
  const checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };

  const columnDef = [
    { field: 'chkBox', headerName: '',sortable:'false', resizable: false, checkboxSelection: checkboxSelection, width: 50,filter:false},
    { field: 'labelCode', headerName: 'Label Code', width: 400,sortable:'true', }
  
  ];
  
  const [addLabel, setAddLabel] = React.useState('')
  const gridRef = React.useRef();
  const GRID_FETCH_SIZE = 7;
  const list = useSelector(state => state.orderList);
 React.useEffect(() => {
  const labels = Object.keys(list.order).length > 0? list.order.data[0].labels :[] 
  // const newLabels = labels.map((ele) => { return {"labelname":ele.labelCode}})
  setData(labels)
  setLoading(false)
 },[])//eslint-disable-line react-hooks/exhaustive-deps
 const rowClassRules = React.useMemo(() => {
  return {

    'unapproved': (params) => {
    const approved = params.data.brandProtection
    return approved.includes("Y")
    },
    
  };
}, []);
 const onSelectionChange = (event) => {
  const selectedRows = event.api.getSelectedRows();
  if (selectedRows.length > 0){
    setAddLabel(selectedRows[0].labelCode)
    setProtectedLabel(selectedRows[0].brandProtection)
  }
  else{
    setAddLabel('')
  }
}
const handleChange = (e) => {
  const newLabels = list.order.data[0].labels.map((ele) => { return {"labelname":ele}})
  setSearchLabel(e.target.value)
  const newdata = newLabels.filter((ele) => ele.labelname.toLowerCase().startsWith(e.target.value.toLowerCase()))
  setData(newdata)
}
const handleAddLabel = () => {
  setLoading(true)
  let data = list.order.data[0].poItem
  data.poItemSizes=[]
   apiAddLabel({
     'comment':`${comment}`,
     'labelCode':`${addLabel}`,
     'poItemId':data.poItemId
   }).then(([response, json]) => {
     if(json.status!=="ERROR")
     {
       dispatch(searchPOInformation({ 
           "POItem":`${searchParams.get("PONo")}`,
          }))
          setAlertType('success')
          setAlertMessage("Label added successfully")
          setAddLabel('')
     } else{
      dispatch(searchPOInformation({ 
        "POItem":`${searchParams.get("PONo")}`,
       })) 
      setAlertType('error')
      setAlertMessage(json.errorMessage)
    }
    
    setOpen(true)
    close()
    setLoading(false)
 })
 }
 




  return (
    <Box style={styles.gridContainer}>
    <Loader open={loading}/>
      <Typography component='div' style={styles.title}>
        <Typography component='span' style={styles.label1}>Add Label</Typography>
        <IconButton id="close-modal-popup" data-testid="close-modal-popup" onClick={close} style={styles.closeButton}>
          <CloseIcon />
      </IconButton>
      </Typography>
      
      <input value={searchLabel} style={{height:'30px',marginBottom:'20px'}} type={'text'} placeholder={'Search Label'}  onChange={handleChange}/>
      <Box className={"ag-theme-alpine"} sx={{ height: '300px', width: '100%' }}>
        <StyledAgGridReact
          rowSelection='single'
          rowData={data}
          columnDefs={columnDef}
          ref={gridRef}
          rowClassRules={rowClassRules}
          cacheBlockSize={GRID_FETCH_SIZE}
          suppressPaginationPanel={true}
          onSelectionChanged={onSelectionChange}
        />
      </Box>
      <Box style={styles.alignBottom}>
        <RefreshButton onClick={(protectedLabel==='Y' && accessLevel?.accessLevel!=='A01')?handleOpenAddComment:handleAddLabel} className={addLabel===''?'disabled':''}>SAVE</RefreshButton>
      </Box>
      <Modal
         open={openAddComment}
         onClose={handleCloseAddComment}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description">
          <Box style={styles.popupStyle2}>
            <Box>
            <p>{addLabel} needs Admin approval to be added </p>
            <textarea value={comment} onChange={(e) => {setComment(e.target.value)}}  placeholder={"Add your Comments here"} type={'text'} maxLength={200} rows={4} cols={50} style={{height:'100px',width:'100%'}}/>
            </Box>
            <Box style={{marginTop:'30px'}}>
              <RefreshButton onClick={handleCloseAddComment}>CANCEL</RefreshButton>
              <RefreshButton onClick={() => {handleCloseAddComment();handleAddLabel();}} className={comment.length>0?'':'disabled'}>SAVE</RefreshButton>
            </Box>
          </Box>
      </Modal>
    </Box>
  )
}
export default AddLabel;