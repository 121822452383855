import { apisearchCountrySize, apisearchCustomer } from '../../../../services/Gateway/RestAPIHandler';
export const COUNTRY_SIZE_LIST_GET = "Get Country Size List";
export const COUNTRY_SIZE_LIST_SUCCESS = "Country Size List Sucess";
export const COUNTRY_SIZE_LIST_FAILURE = "Country Size List Failure";

const searchDefault = {
    "ascDesc": "",
    "fetchSize": 50,
    "filterSearch": "",
    "pageNumber": 1,
    "sortBy": ""
}
export function getCountrySizeListAction(params) {
    return (dispatch) => {
        dispatch(getCountrySizeList());
        return apisearchCountrySize(params)
            .then(([response, json]) => {
                if (response.status === 200) {
                    dispatch(getCountrySizeListSuccess(json.results));
                }
                else {
                    dispatch(getCountrySizeListFailure("Unable to fetch Customer list data; Error code - " + json.errorCode));
                }
            })
    }
}
export function searchCountrySizeAction(params = searchDefault) {
    return (dispatch) => {
        dispatch(getCountrySizeList());
        return apisearchCountrySize(params)
            .then(([response, json]) => {
                if (response.status === 200) {

                    dispatch(getCountrySizeListSuccess(json.results, params));
                }
                else {
                    dispatch(getCountrySizeListFailure("Unable to fetch Customer search data; Error code - " + json.errorCode, params));
                }
            })
    }
}

export function getCountrySizeList() {
    return {
        type: COUNTRY_SIZE_LIST_GET
    }
}

export function getCountrySizeListSuccess(res,params) {
    return {
        type: COUNTRY_SIZE_LIST_SUCCESS,
        payload: {res:res,request:params}

    }

}

export function getCountrySizeListFailure(message,params) {
    return {
        type: COUNTRY_SIZE_LIST_FAILURE,
        payload: {message:message,request:params}
    }
}