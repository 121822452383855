import SearchIcon from '@mui/icons-material/Search';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Loader from '../Loader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {useLocation} from 'react-router-dom'
import React, { useState } from 'react';
import SearchContext from '../../../search-context';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import {Box } from '@mui/system';
import SimpleSnackbar from '../Snackbar/index'
import SearchChild from './SearchChild';
import {StyledPopperDiv,styles,Searchbtn,SearchInput} from './styles'
import { useDispatch,useSelector } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import searchHelper from './searchHelper';
import Utils from '../../utils/Utils';

export default function Search({storeList}) {
  /* Variable Declartion */
  const store = useSelector(state => state[storeList])
  // const [searchColumns,setSearchColumns]= useState(store.searchColumns)
  const dispatch = useDispatch()
  let location = useLocation();
  const [columnDef,setColumnDef] = useState([]);
  const [openAlert,setOpenAlert] = useState(false);
  const [alertType,setAlertType] = useState("success");
  const [alertMessage,setAlertMessage] = useState("");
  const [sortColumn,setSortColumn] = useState('')
  const [searchChildren,setSearchChildren] = useState([{column:'All columns',searchterm:'',inputtype:'',value:'',type:'Regular',toValue:'',fromValue:''}])
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortOrder,setSortOrder] = useState('asc')
  const [searchlist,setSearchList] = useState(columnDef)
  const [res,setRes] = useState([])
  const [loading,setLoading] = useState(true)
  const list = columnDef;
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  
  React.useEffect(() =>{
    let res = searchHelper(location.pathname)
    const [data] = res;
    if(!Utils.isEmpty(store?.searchColumns)){
      setSearchChildren(store.searchColumns)
      setLoading(false)
    }
    if(!data?.display){
      setLoading(false)
    }

  },[store])
  React.useEffect(() => {
    let res = searchHelper(location.pathname)
    const [data] = res;
    setRes(data);
    if(data?.display && data?.columnDef){
      setSortColumn(data?.columnDef[0]?.field)
      setColumnDef(data.columnDef)
    }
    setSearchChildren([{column:'All columns',searchterm:'',inputtype:'',value:'',type:'Regular',toValue:'',fromValue:''}])
  },[location.pathname])

  /* Component methods */
  const handleClickAway = () => {
    setAnchorEl(null);
  };
  
  const handleAddQuery = () => {
    if((searchChildren[0].column==='All columns' && searchChildren.length>=4) || (searchChildren.length>=columnDef.length && searchChildren.length>=4) ){
      return
    }
    setSearchChildren([...searchChildren,{column:'All columns',searchterm:'',inputtype:'',value:'',type:'Regular',toValue:'',fromValue:''}])
  }

  const handleClearAll = (e) =>{
    e.stopPropagation();
    setSearchChildren([{column:'All columns',searchterm:'',inputtype:'',value:'',type:'Regular',toValue:'',fromValue:''}]);
    let [data] = list;
    setSortColumn(data?.field);
    setSortOrder('asc');
    dispatch(res.fetchFunc())
  }

  const onClickAwayCallBack =(event) => {
    if(event.target.localName==='body'){
      return;
    }
    setAnchorEl(null);
  }
  const handleClickSearch = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const submitForm  = (event) => {
    let flag =true;
    let searchString =''
    let fetchSize=7;
    if(res.url==="/Order_List"){
      fetchSize = 15
    }
    else if (res.url==="/Country_Size"){
      fetchSize = 50
    }
    else if (res.url==="/Inventory_List"){
      fetchSize = 15
    }
    else if (res.url==="/Approval_List"){
      fetchSize = 15
    }
    else{
      fetchSize = 7
    }
    searchChildren.forEach((item) => {
      if(item.column==='All columns' || item.column==='undefined'|| (item.value===''&& item.toValue==='' && item.fromValue==='')|| (item.toValue!=='' && item.fromValue!==''&& parseInt(item.fromValue)>parseInt(item.toValue))){
        flag = false;
      }
      if(item.column.includes('date')||item.column.includes('Date')){
        const d1 = new Date(item.fromValue)
        const d2 = new Date(item.toValue)
        if(d2<d1){
          flag=false
        }
      }

      if(item.value.includes('*')){
        searchString = searchString + item.searchterm +'%' + item.value + ','
      }
      else if(item.value!==''){
        let searchValue = item.value;
        
        //Condition to check Division column in Order List page
    

        searchString = searchString + item.searchterm +':' + searchValue.replace(/,/g,"|")+ ','
      }
      else{
        searchString = searchString + item.searchterm +'^' + item.fromValue + '#' + item.toValue + ','
      }
    })
    
    if(flag){
      dispatch(res.searchFunc(
        {
          "ascDesc": sortOrder,
          "fetchSize": fetchSize,
          "filterSearch": searchString,
          "pageNumber": 1,
          "sortBy": sortColumn
        },
        searchChildren
      ))
      handleClickSearch(event)
    }
    else{
      setAlertType('error')
      setAlertMessage('Please enter correct search terms')
      setOpenAlert(true)
    }
  }

  

  return (
    <SearchContext.Provider value={searchChildren}>
    <ClickAwayListener onClickAway={onClickAwayCallBack}>
        <Box data-testid="search-widget" sx = { res?.display ? {display:'flex'} : {display:'none'}}>
        <Loader open={loading}/>
        <SimpleSnackbar setOpen={setOpenAlert} open={openAlert}  message={alertMessage} messagetype={alertType} />
        <Box  style={styles.Search} onClick={handleClickSearch}>
          {searchChildren[0].column!=='All columns' || searchChildren.length>1 ?
          <>
            <FilterAltIcon data-testid="filter-icon" sx={{color:'black'}}/>
            <p style={{color:'red',marginRight:'30px'}}>{searchChildren.length}</p>
            <button data-testid="filter-clear-all" style={styles.ClearAll_btn1} onClick={(e) => {handleClearAll(e);handleClickAway();}}>clear All</button>
          </> :
          <SearchInput type={'text'} placeholder='Search and/or sort' className='Search_input' disabled />
          }
          <Searchbtn data-testid="searchContent" onClick={handleClickSearch}><SearchIcon/></Searchbtn>
        </Box>
        <PopperUnstyled id={id} data-testid="search-popup" open={open}  anchorEl={anchorEl} modifiers ={[{name:"offset", options :{enabled:true,offset:'0,30'}}]} sx={{position:'fixed',top:'60px',right:'100px'}} placement={'bottom'}>
          <StyledPopperDiv  style={styles.Search_div}>
            {searchChildren.map((item,index) => <SearchChild key={index} submitForm={submitForm} setSearchChildren={setSearchChildren} index={index} searchChildren={searchChildren} searchlist ={searchlist} setSearchList={setSearchList} />)}
              <Box style={styles.Search_sort}>
                <Box style={{marginLeft:'29px'}}>
                  <p style={styles.Sort_Text}>Sort By </p>
                  <Select value={sortColumn} sx={{width: '145px',height: '38px',fontSize:'14px'}}>
                    { list.map((item,index) => <MenuItem sx={{fontSize:'14px'}} key={index} onClick={(e) => {setSortColumn(item.field)}} value={item.field}>{item.headerName}</MenuItem>)} 
                  </Select>
                </Box>
                <Box style={{marginLeft:'40px'}}>
                  <p style={styles.Sort_Text}>Sort Direction </p>
                  <FormControl>
                    <RadioGroup
                      sx={{marginLeft:'0px'}}
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={sortOrder}
                      onChange={(e) => setSortOrder(e.target.value)}>
                      <FormControlLabel value="asc" control={<Radio />} label="Ascending" />
                      <FormControlLabel value="desc" control={<Radio />} label="Descending" />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            <Box sx ={{display:'flex',justifyContent:'end',width:'100%',marginBottom:'10px',marginRight:'10px'}}>
              <button data-testid="search-add-query" style={styles.Search_drawer_btn} onClick={handleAddQuery}>+ Add query</button>
              {searchChildren[0].column!=='All columns' || searchChildren.length>1?<><button data-testid="query-clear-all" style={styles.Search_drawer_btn2} onClick={handleClearAll}>clear All</button></>:''}
              <button data-testid="search-form" style={styles.Search_drawer_btn} onClick={()=>{setTimeout(submitForm(),500)}}>Search</button>
            </Box>
          </StyledPopperDiv>
        </PopperUnstyled>
      </Box>
    </ClickAwayListener>
    </SearchContext.Provider>
  );
}
