export const qaConfig = () => {
    return {
        environment: 'qa',
        domain: 'https://rblabeling.qa.aws.sparcgroup.cloud/',
        hostname: 'rblabeling.qa.aws.sparcgroup.cloud',
        albURL: 'http://reebok-labeling-alb-562534693.us-east-2.elb.amazonaws.com',
        gateway: 'https://dqtfj43b8j.execute-api.us-east-2.amazonaws.com',
        gatewayPath: '/test/labelling',
        oneLogin: {
            authority: "https://sparcbrands.onelogin.com/oidc/2",
            loginURL: "https://sparcbrands.onelogin.com/",
            logout: "https://sparcbrands.onelogin.com/oidc/2/logout",
            clientId: "b06c3dd0-27c1-013b-c8ce-0aa45e943bf9203226",
            redirectURI: "https://rblabeling.qa.aws.sparcgroup.cloud/redirect"
        }
    }
}