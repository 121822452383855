export const prodConfig = () => {
    return {
        environment: 'prod',
        domain: 'https://rblabeling.prod.aws.sparcgroup.cloud/',
        hostname: 'rblabeling.prod.aws.sparcgroup.cloud',
        albURL: 'http://reebok-labeling-be-alb-prod-2058855898.us-east-2.elb.amazonaws.com',
        gateway: 'https://7ka3yp7i2b.execute-api.us-east-2.amazonaws.com',
        gatewayPath: '/prod/labelling',
        oneLogin: {
            authority: "https://sparcbrands.onelogin.com/oidc/2",
            loginURL: "https://sparcbrands.onelogin.com/",
            logout: "https://sparcbrands.onelogin.com/oidc/2/logout",
            clientId: "e21de9a0-33a1-013b-aab2-06673b8ea5b3203226",
            redirectURI: "https://rblabeling.prod.aws.sparcgroup.cloud/redirect"
        }
    }
}