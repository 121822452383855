import React, { useRef } from "react";
import Modal from "@mui/material/Modal";
import {store as historyStore } from '../Orders/ViewHistory/Store/store'
import {
    Box,
    Typography,
    Tooltip,
    Card, OutlinedInput, InputAdornment
} from "@mui/material/";
import Loader from '../../customWidgets/Loader/'
import {apipreparePOItem,apiunlockPOItem,apilockPOItem,apiCancelPoItem,apiSendPoItem,apiResetPoItem} from '../../services/Gateway/RestAPIHandler'
import HistoryIcon from '@mui/icons-material/History';
import { OrderButton, styles, StyledCard } from "./styles";
import { POCard } from "./constant";
import { Provider, useSelector, useDispatch } from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {searchPOInformation} from '../Orders/Store/actions'
import ViewHistory from '../Orders/ViewHistory';
import Utils from "../../utils/Utils";
import { updateOveragePercent } from '../../services/Gateway/RestAPIHandler';
import SimpleSnackbar from '../../customWidgets/Snackbar';
import AccessLevelContext from '../../../accessLevel-context';


function PoCardTable({setAlertType,setAlertMessage,openPOAlert}) {
    const [loading,setLoading] = React.useState(true)
    const [poDetails,setPODetails] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [errorInfo, setErrorInfo] = React.useState({alertMessage: '', alertType: ''});
    const [openOverageModal, setOpenOverageModal] = React.useState(false);
    const overagePercent = useRef();

  /* Variable declaration */
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const orderlist = useSelector(state => state.orderList);

  const saveOveragePercent = () => {
    setLoading(true)
    if(!Utils.isEmpty(overagePercent.current.value) && overagePercent.current.value > 0 && !Utils.isEmpty(searchParams.get("PONo"))){
      updateOveragePercent({
        orderNumber: searchParams.get("PONo"),
        overagePercentage: overagePercent.current.value
      })
      .then(([response, json]) => {
        if(json.successCode === 200) {
          setLoading(false)
          setShowError(true);
          setErrorInfo({
            alertMessage: 'Overage percentage added',
            alertType: 'success'
          })

          dispatch(searchPOInformation({ 
            "POItem":`${searchParams.get("PONo")}`,
          }))

          setTimeout(() => {
            setOpenOverageModal(false);
          }, 4000);
        }
        else {
          setLoading(false)
          setShowError(true);
          setErrorInfo({
            alertMessage: json.errorMessage,
            alertType: 'error'
          })
        }
      })
    }
    else {
      setLoading(false)
      setShowError(true);
      setErrorInfo({
        alertMessage: 'Invalid overage percentage',
        alertType: 'error'
      })
    }
  }

  const handleKeydown = (e) => {
    const regexNumber = new RegExp('([0-9])');
    if(regexNumber.test(e.key) || e.keyCode === 13 || e.keyCode === 8
      || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 46 || e.keyCode === 190) {
      return true;
    }
    else {
      e.preventDefault();
      return false;
    }
  }

  /* useEffect to load order list */
  React.useEffect(() => {
    if(!Utils.isEmpty(orderlist.order)) {
      setPODetails(orderlist.order?.data[0]?.poItem)
      setLoading(false)
    }
  },[orderlist])
    
  /* Method declaration */
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOverageOpen = () => setOpenOverageModal(true);
  const handleOverageClose = () => {
    //Reset value
    setShowError(false);
    setErrorInfo({
      alertMessage: '',
      alertType: ''
    })
    setOpenOverageModal(false);
  }
    
  
    const handleLock = async() => {
        setLoading(true)
        apilockPOItem(poDetails.poItemId)
        .then(([response, json]) => {
          if(response.status===200 && json.status!=='ERROR')
          {
            dispatch(searchPOInformation({ 
                "POItem":`${searchParams.get("PONo")}`,
               }))
            setAlertMessage(`PO ${searchParams.get("PONo")} has been locked successfully`)
            setAlertType('success')
            setLoading(false)
            openPOAlert(true)
          }
          else{
            setAlertMessage(json.responseMessage ?? json.errorMessage)
            setAlertType('error')
            setLoading(false)
            openPOAlert(true)
          }
        })
      }
      const handleUnlock = async() => {
        setLoading(true)
        apiunlockPOItem(poDetails.poItemId)
        .then(([response, json]) => {
          if(response.status===200 && json.status!=='ERROR')
          {
            dispatch(searchPOInformation({ 
                "POItem":`${searchParams.get("PONo")}`,
               }))
               setAlertMessage(`PO ${searchParams.get("PONo")} has been unlocked successfully`)
               setAlertType('success')
               setLoading(false)
               openPOAlert(true)
             }
             else{
               setAlertMessage(json.responseMessage ?? json.errorMessage)
               setAlertType('error')
               setLoading(false)
               openPOAlert(true)
             }
        })
      }
    const handleCancel = async() => {
      setLoading(true)
        apiCancelPoItem(poDetails.poItemId)
        .then(([response, json]) => {
          if(response.status===200 && json.status!=='ERROR')
          {
            dispatch(searchPOInformation({ 
                "POItem":`${searchParams.get("PONo")}`,
               }))
               setAlertMessage(`PO ${searchParams.get("PONo")} has been canceled successfully`)
               setAlertType('success')
               setLoading(false)
               openPOAlert(true)
             }
             else{
               setAlertMessage(json.responseMessage ?? json.errorMessage)
               setAlertType('error')
               setLoading(false)
               openPOAlert(true)
             }
        })
    }

    const handleReset = async() => {
      setLoading(true)
        apiResetPoItem(poDetails.poItemId)
        .then(([response, json]) => {
          if(response.status===200 && json.status!=='ERROR')
          {
            dispatch(searchPOInformation({ 
                "POItem":`${searchParams.get("PONo")}`,
               }))
               setAlertMessage(`PO ${searchParams.get("PONo")} has been reset successfully`)
               setAlertType('success')
               setLoading(false)
               openPOAlert(true)
             }
             else{
               setAlertMessage(json.responseMessage ?? json.errorMessage)
               setAlertType('error')
               setLoading(false)
               openPOAlert(true)
             }
        })
    }

const handleSent = async() => {
  setLoading(true)
    apiSendPoItem(poDetails.poItemId)
    .then(([response, json]) => {
      if(response.status===200 && json.status!=='ERROR')
      {
        dispatch(searchPOInformation({ 
            "POItem":`${searchParams.get("PONo")}`,
           }))
           setAlertMessage(`PO ${searchParams.get("PONo")} has been Sent successfully`)
           setAlertType('success')
           setLoading(false)
           openPOAlert(true)
         }
         else{
           setAlertMessage(json.responseMessage ?? json.errorMessage)
           setAlertType('error')
           setLoading(false)
           openPOAlert(true)
         }

})
}   
    const handlePrepare = async() => {
      setLoading(true)
        apipreparePOItem(poDetails.poItemId)
        .then(([response, json]) => {
          if(response.status===200 && json.status!=='ERROR'){
            
            dispatch(searchPOInformation({ 
                "POItem":`${searchParams.get("PONo")}`,
               }))
               setAlertMessage(`PO ${searchParams.get("PONo")} has been prepared successfully`)
               setAlertType('success')
               openPOAlert(true)
               setLoading(false)
             }
             else{
               setAlertMessage(json.responseMessage ?? json.errorMessage)
               setAlertType('error')
               openPOAlert(true)
               setLoading(false)
             }
    
      })
    }
    /*const handlePrepareSend = async() => {
      setLoading(true)
      apiPrepareSendOrder(poDetails.poItemId)
        .then(([response, json]) => {
          if(response.status===200 && json.status!=='ERROR'){
            
            dispatch(searchPOInformation({ 
                "POItem":`${searchParams.get("PONo")}`,
               }))
               setAlertMessage(`PO ${searchParams.get("PONo")} has been prepared and Sent successfully`)
               setAlertType('success')
               openPOAlert(true)
               setLoading(false)
             }
             else{
               setAlertMessage(json.errorMessage)
               setAlertType('error')
               openPOAlert(true)
               setLoading(false)
             }
    
      })
    }*/

    const accessLevel= React.useContext(AccessLevelContext);
    
    return (
        <Box>
          <Loader open={loading}/>
            <StyledCard>
                <Typography style={styles.col1title} component="span">
                  {POCard.PONumber}
                </Typography>
                <Typography style={styles.col1field} component="span">
                  { Utils.isEmpty(poDetails?.orderNumber) ? '' : poDetails?.orderNumber }
                </Typography>
                <Typography  style={styles.col2title} component="span">
                    {POCard.CustCode}
                </Typography>
                <Tooltip title={Utils.isEmpty(poDetails?.customerDTO) ?"": poDetails.customerDTO.name} placement="top">
                <Typography style={styles.col2field} component="span">
                  { Utils.isEmpty(poDetails?.customerCode) ? '' : poDetails?.customerCode}
                </Typography>
                </Tooltip>
                <Typography  style={styles.col2title} component="span">
                    {POCard.Division}
                </Typography>
                <Typography style={styles.col2field} component="span">
                  { Utils.isEmpty(poDetails?.divisionCode) ? '' : (poDetails?.divisionCode === '1' ? 'Footwear' :  poDetails?.divisionCode === '2' ? 'Apparel' : 'Hardware') }
                </Typography>
                <Typography style={styles.btn_prepare} component="span">
                    <OrderButton onClick={handlePrepare} className={(poDetails?.status==='LOCKED'||poDetails?.status==='CANCELLED'||poDetails?.status==='SHIPPED'||poDetails?.status==="PROCESSING"||poDetails?.status==="SENT"||poDetails?.status==='PRINTED')?'disabled':''}>
                        PREPARE
                    </OrderButton>
                </Typography>
                <Typography style={styles.btn_lock} display="inline" component="span">
                    <OrderButton onClick={handleLock}className={poDetails?.status!=='LOCKED'&&poDetails?.status!=='CANCELLED'&&poDetails?.status!=='SHIPPED'&&poDetails?.status!=="PROCESSING"&&poDetails?.status!=="SENT"&&poDetails?.status!=='PRINTED'?'':'disabled'} >
                        LOCK
                    </OrderButton>
                </Typography>
            </StyledCard>
            <StyledCard style={styles.card2}>
                <Typography style={styles.col1title} component="span">
                    {POCard.CustOrderNo}
                </Typography>
                <Typography style={styles.col1field} component="span">
                { Utils.isEmpty(poDetails?.customerOrderNum) ? '' : poDetails?.customerOrderNum}
                </Typography>
                <Typography  style={styles.col2title} component="span">
                    {POCard.OrderQty}
                </Typography>
                <Typography  style={styles.col2field} component="span">
                { Utils.isEmpty(poDetails?.orderQuantity) ? 0 : poDetails?.orderQuantity}
                </Typography>
                <Typography  style={styles.col2title} component="span">
                    {POCard.prodGroup}
                </Typography>
                <Typography  style={styles.col2field} component="span">
                  {Utils.isEmpty(poDetails?.productGroupDescription) ? 0 : poDetails?.productGroupDescription}
                </Typography>
                <Typography style={styles.btn_send} component="span">
                    <OrderButton onClick={handleSent} className={poDetails?.status!=="PROCESSING"&&poDetails?.status!=="SENT"&&poDetails?.status!=='SHIPPED'&&poDetails?.status!=='PRINTED'&&poDetails?.status!=="CANCELLED"&& poDetails?.status!=="LOCKED"&&poDetails?.status!=='ERROR'&&poDetails?.status!=='NEW'?'':'disabled'}>
                        SEND
                    </OrderButton>
                </Typography>
                <Typography style={styles.btn_lock} component="span">
                    <OrderButton onClick={handleCancel} className={poDetails?.status!=='SHIPPED'&&poDetails?.status!=='CANCELLED'&&poDetails?.status!=='PRINTED'&&poDetails?.status!=="LOCKED"&&poDetails?.status!=="SENT"?'':'disabled'}>
                        CANCEL
                    </OrderButton>
                </Typography>
            </StyledCard>

            <StyledCard style={styles.card3} >
                <Typography style ={styles.col1title} component="span">
                    {POCard.CountryOrigin}
                </Typography>
                <Typography style={styles.col1field} component="span">
                  { Utils.isEmpty(poDetails?.countryOfOrigin) ? '' : poDetails?.countryOfOrigin }
                </Typography>
                <Typography  style={styles.col2title} component="span">
                    {POCard.SkipCountry}
                </Typography>
                <Typography  style={styles.col2field} component="span">
                    { Utils.isEmpty(poDetails?.shipToCountry) ? '' : poDetails?.shipToCountry}
                </Typography>
                <Typography  style={styles.col2title} component="span">
                    View History
                </Typography>
                <Typography  style={styles.col2field} component="span">
                  <Typography  style={styles.btn_history} component="span">
                    <Tooltip title="View History">
                      <HistoryIcon onClick={handleOpen} />
                    </Tooltip>
                    <Modal
                      open={open}
                      onClose={handleClose}>
                        <Box sx={styles.popupStyle}>
                          <Provider store={historyStore}><ViewHistory setAlertType={setAlertType} setAlertMessage={setAlertMessage} setOpen={openPOAlert} close={handleClose}/></Provider> 
                        </Box>
                    </Modal>
                  </Typography>
                </Typography>
                
                <Typography  style={styles.btn_send} component="span">
                    <SimpleSnackbar setOpen={setShowError} open={showError} message={errorInfo.alertMessage} messagetype={errorInfo.alertType} />
                    <OrderButton onClick={handleOverageOpen} className={poDetails?.status==='PREPARED' ? '' : 'disabled'} >
                      OVERAGE %
                    </OrderButton>
                    <Modal
                      open={openOverageModal}
                      onClose={handleOverageClose}
                      sx={{ display: 'inline-flex', alignItems: 'center', width: 'fit-content', height: 'fit-content', left: '36%', top: '30%' }}>
                      <Card sx={{ width: '100%', padding: '12px', paddingLeft: '15px', marginTop: '10px', marginBottom: '10px' }}>
                        <Typography component='span' sx={{ marginRight: '10px' }}>
                          Overage Percentage
                        </Typography>
                        <OutlinedInput
                          id="overage-percentage"
                          inputRef={overagePercent}
                          endAdornment={<InputAdornment position="end">%</InputAdornment>}
                          sx={{ height: '36px', width: '80px' }}
                          inputProps={{ inputMode: 'numeric' , maxLength: 5}}
                          onKeyDown={handleKeydown}
                        />
                        <OrderButton sx={{ marginLeft: '15px' }} onClick={saveOveragePercent}>Save</OrderButton>
                      </Card>
                    </Modal>
                </Typography>
                <Typography  style={styles.btn_lock} component="span">
                    <OrderButton onClick={handleUnlock}  className={poDetails?.status==='LOCKED' ? '' : 'disabled'}>
                        UNLOCK
                    </OrderButton>
                </Typography>
            </StyledCard>
            <StyledCard style={styles.cardreset}>
                <Typography  sx={styles.btn_lock}  style={{display:poDetails?.status!=='SHIPPED'?'none':'block'}} component="span">
                    <OrderButton onClick={handleReset} className={poDetails?.status=='SHIPPED'&&poDetails?.status!=='CANCELLED'?'':'disabled'}>
                        RESET
                    </OrderButton>
                </Typography>
                <Typography  sx={styles.btn_lock}  style={{display:(poDetails?.status!=='CANCELLED' || poDetails?.orderQuantity <= 0)?'none':'block'}} component="span">
                    <OrderButton onClick={handleReset}  style={{display:accessLevel?.accessLevel!=='A01'?'none':'block'}} className={poDetails?.status!=='SHIPPED'&&poDetails?.status==='CANCELLED'?'':'disabled'}>
                        CANCEL RESET
                    </OrderButton>
                </Typography>
            </StyledCard>
        </Box>

    )

}
export default PoCardTable;

