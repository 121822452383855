import {apiLabelList } from '../../../services/Gateway/RestAPIHandler';

export const LABEL_LIST_GET = 'Get Label List';
export const LABEL_LIST_SUCCESS = 'Label List Success';
export const LABEL_LIST_FAILURE = 'Label List Failure';

export function getLabelListAction(params) {
    return (dispatch) => {
        dispatch(getLabelList());
        return apiLabelList(params)
            .then(([response, json]) => {
                if(response.status === 200){
                    dispatch(getLabelListSuccess(json));
                }
                else {
                    dispatch(getLabelListFailure("Unable to fetch order data; Error code - " + json.errorCode));
                }
            })
    }
}

export function getLabelList() {
    return {
        type: LABEL_LIST_GET
    }
}

export function getLabelListSuccess(data) {
    return {
        type: LABEL_LIST_SUCCESS,
        payload: data
    }
}

export function getLabelListFailure(message) {
    return {
        type: LABEL_LIST_FAILURE,
        payload: message
    }
}