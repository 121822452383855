import {BULK_ACTION_LIST_GET , BULK_ACTION_LIST_SUCCESS,BULK_ACTION_LIST_FAILURE} from './actions';

const initialState = {
    order: {},
    request:{},
}

export function BulkActionListReducer(state = initialState, action) {
    
    if(action.type === BULK_ACTION_LIST_GET) {
        return state;
    }
    if(action.type === BULK_ACTION_LIST_SUCCESS) {
        return {
            ...state,
            order: {
                isLoaded: true,
                data: action.payload.res,
                message: 'Success'
            },
            request:action.payload.request
            
        }
    }
    if(action.type === BULK_ACTION_LIST_FAILURE) {
        return {
            ...state,
            order: {
                data: [],
                message: action.payload.message
            },
            request:action.payload.request
        }
    }
    
    return state;
    
}



