import React from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import logo from '../../../assets/excelicon.png';
import * as XLSX from 'xlsx'
import SimpleSnackbar from '../../../customWidgets/Snackbar';
// import Buttons from '../../Orders/OrderList/Buttons';
import { Box, IconButton ,Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// import logo from '../../../assets/nonAdmin.PNG';
// import {Lbl } from './styles';
import {LabelContainer, LabelFont, Lbl } from './styles';
import { styles, StyledAgGridReact } from './styles';
//  import { getLabelListGridAction } from '../LabelLayout/LabelLayoutGrid/Store/actions';
// import { useDispatch,useSelector} from 'react-redux';

function LabelNonAdmin({ label, close }) {
//    const dispatch = useDispatch()
const [open1,setOpen1] = React.useState(false)
const [alertType,setAlertType] = React.useState("success")
const [alertMessage,setAlertMessage] = React.useState("")
const [imgData,setImgData] = React.useState('');
const columnDef = [
        { field: 'fieldSequence', headerName: 'Field Sequence', width: 280,resizable:true  },
        { field: 'fieldCode', headerName: 'Field Name', width: 230,resizable:true  },
        { field: 'fieldType', headerName: 'Field Type', width: 235,resizable:true  },
        { field: 'fieldLength', headerName: 'Field Length', width: 235,resizable:true  },
        { field: 'mandatory', headerName: 'Mandatory', width: 235,resizable:true  },    
        { field: 'fieldFormat', headerName: 'Field Format ', width: 225,resizable:true  },
       
    ];
    
    // const dispatch = useDispatch();
    // React.useEffect(() => {
    //     dispatch(getLabelListGridAction(label[0].id))
    // }, [])//eslint-disable-line react-hooks/exhaustive-deps
    // const list = useSelector(state => state.labelLayoutGridList);
    const exportToData = (data) => {
        const newData = data.map(ele => {
          return {
            "Field Sequence":ele?.fieldSequence,
            "Field Name": ele?.fieldCode,
            "Field Type":ele?.fieldType,
            "Field Length":ele?.fieldLength,
            "Mandatory":ele?.mandatory,
            "Field Format":ele?.fieldFormat
          }
        })
        const worksheet = XLSX.utils.json_to_sheet(newData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
        XLSX.writeFile(workbook, `${label[0].labelCode}_Label_Layout.xlsx`)
       
      }
      // React.useEffect(() => {
      //   const fetchData = async () => {
      //     const data = await fetch('/labelling/viewImage?filePath=label.png',{
      //       headers: {
      //         'Content-Type': 'application/json',
      //         'Accept': 'application/json',
      //         'Authorization': `Bearer ${window.localStorage.getItem("access_token")}`
      //       },
      //     })
      //     const res = await data.blob()
      //     setImgData({src:URL.createObjectURL(res)})
      //   }
      //   fetchData()
      // },[])
      const exportToExcel = () => {
        const data = label[0].labelLayoutDTOs;
        if(data===null){
          setAlertType('error')
          setAlertMessage("No Data to export")
          setOpen1(true)
        }
        else{
          exportToData(data)
      }
      }
    const closeButtonFunction = (event) => {
        close();
    }

    return (
        <Box style={styles.gridContainer}>
               <SimpleSnackbar setOpen={setOpen1} open={open1}  message={alertMessage} messagetype={alertType} />
            <IconButton id="close-modal-popup" data-testid="close-modal-popup" onClick={closeButtonFunction} style={styles.closeButton}>
                <CloseIcon />
            </IconButton>
            <Typography component="span" >
                <Lbl>LABEL INFORMATION</Lbl>
            </Typography>
            <Box style={styles.card2}>
                <LabelContainer>Label Code</LabelContainer>
                <LabelContainer>Label name</LabelContainer>
                <LabelContainer >Label height</LabelContainer>
                <LabelContainer >Formula</LabelContainer>

            </Box>
            <Box style={styles.card2}>
                <LabelFont>{label[0].labelCode}</LabelFont>
                <LabelFont>{label[0].name}</LabelFont>
                <LabelFont>{label[0].height}</LabelFont>
                <LabelFont >{label[0].formula}</LabelFont>

            </Box>
            <Box style={styles.card2}>
                <LabelContainer>Label width</LabelContainer>
                <LabelContainer >Usage type</LabelContainer>
                <LabelContainer >Version</LabelContainer>
                <LabelContainer>Material</LabelContainer>

            </Box>
            <Box style={styles.card2}>
                <LabelFont>{label[0].width}</LabelFont>
                <LabelFont>{label[0].labelUsageType}</LabelFont>
                <LabelFont>{label[0].version}</LabelFont>
                <LabelFont >{label[0].material} </LabelFont>

            </Box>
            {/* <Box style={styles.imageBox}> 
                <img src={'https://labelrefimages.s3.us-east-2.amazonaws.com/label2.jpg'} style={{height:'500px',width:'250px', border:'1px solid black',padding:'20px',borderRadius:'10px'}}/>
            </Box> */}
            <Box style={styles.card3}>
                <Lbl>LAYOUT DETAILS <img onClick={() => { exportToExcel() }} src={logo} style={{ marginLeft: '18px', cursor: 'pointer', width: '19px', height: '18px', opacity: 1 }} alt={"Export Excel"} />
               </Lbl>
                 <Box className={"ag-theme-alpine"}
                    sx={{ height: '300px', width: '100%' }}>
                    <StyledAgGridReact
                        rowData={label[0].labelLayoutDTOs}
                        pagination={false}
                        columnDefs={columnDef}
                        rowSelection='single'
                        suppressPaginationPanel={true}
                    />

                </Box>
            </Box>

        </Box>

    );
}
export default LabelNonAdmin
