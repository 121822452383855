import devConfig from './environment_dev.js';
import { qaConfig } from './environment_qa.js';
import { prodConfig } from './environment_prod.js';

const host = window.location.hostname;
const dev = devConfig();
const qa = qaConfig();
const prod = prodConfig();

let env = {};
if(host === "localhost" || host === dev.hostname) {
    env = dev;
}
else if(host === qa.hostname) {
    env = qa;
}
else if(host === prod.hostname) {
    env = prod;
}
else {
    env = dev;
}

const envConfig = {
    environment: env.environment,
    albURL: env.albURL,
    domain: env.domain,
    hostname: env.hostname,
    gateway: env.gateway,
    gatewayPath: env.gatewayPath,
    oneLogin: env.oneLogin
}

export default envConfig;
