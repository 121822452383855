import {searchOrderListAction} from '../../modules/Orders/OrderList/Store/actions'
import {searchLabelAssignmentAction } from '../../modules/Label/LabelAssignment/Store/actions';
import { searchCustomerAction } from '../../modules/Label/LabelCustomerList/Store/actions';
import { searchFactoryAction } from '../../modules/Label/LabelFactoryList/Store/actions';
import {searchBulkActionAction} from '../../modules/Orders/VIewBulkAction/Store/actions';
import {getLabelListAdminAction } from '../../modules/Label/LabelAdmin/Store/actions';
import {getLabelArticleListAction} from '../../modules/ReferenceData/ArticleMaster/Store/actions'
import { searchCountrySizeAction } from '../../modules/ReferenceData/CountrySizeLookup/Store/actions';
import {searchOrderLabelAction} from '../../modules/Orders/InventoryList/Store/actions'
import {searchApprovalOrderListAction} from '../../modules/Orders/ApprovalList/Store/actions'

  const BulkActionColumnDef =[
    { field: 'poBatchId', headerName: 'Batch ID', width: 85,resizable:true,type:'number' },
    {type:'text', field: 'status', headerName: 'Status', width: 110,resizable:true },
    {type:'text', field: 'action', headerName: 'Action', width: 110,resizable:true },
    {type:'text', field: 'createdBy', headerName: 'Created By', width: 110,resizable:true },
      ];

  const orderColumnDef = [
    { field: 'orderNumber', headerName: 'PO No', width: 85,resizable:true,type:'number' },
    // { field: 'poItemId', headerName: 'PO Item No', width: 110,resizable:true,type:'number' },
    {type:'text', field: 'status', headerName: 'Status', width: 110,resizable:true },
    {type:'text', field: 'orderQuantity', headerName: 'Order Quantity', width: 110,resizable:true },
    // {type:'text', field: 'clientCode', headerName: 'Client Code', width: 110,resizable:true },
    { type:'text',field: 'articleNumber', headerName: 'Article Number', width: 150,resizable:true },
    // { type:'text',field: 'articleDescription', headerName: 'Article Description', width: 400 ,resizable:true },
    { field: 'modelDescription', headerName: 'Model Description', width: 400 ,resizable:true,searchType:['Regular'] },
    { type:'text',field: 'countryOfOrigin', headerName: 'Origin Country Code', width: 150,resizable:true },
    { type:'text',field: 'customerCode', headerName: 'Customer Code', width: 150,resizable:true },
    { type:'number',field: 'customerOrderNum', headerName: 'Customer Order No', width: 150,resizable:true },
    {type:'text', field: 'divisionCode', headerName: 'Division Code', width: 110,resizable:true },
    {type:'text', field: 'factoryCode', headerName: 'Factory Code', width: 110,resizable:true },
    {type:'text', field: 'factoryName', headerName: 'Factory Name', width: 110,resizable:true },
    { type:'date',field: 'lastProductionDate', headerName: 'Last Production Date', width: 110,resizable:true },
    { type:'text',field: 'shippingAddressingLine1', headerName: 'Shipping Addressing Line 1', width: 110,resizable:true },
    { type:'text',field: 'shippingAddressingLine2', headerName: 'Shipping Addressing Line 2', width: 110,resizable:true },
    {type:'text', field: 'shippingAddressingLine3', headerName: 'Shipping Addressing Line 3', width: 110,resizable:true },
    { type:'text',field: 'shipToCountry', headerName: 'Shipping Country Code', width: 110,resizable:true },
    // { type:'text',field: 'sportsCategoryCode', headerName: 'Sports Category Code', width: 110,resizable:true },
    // {type:'text', field: 'workingNumber', headerName: 'Working Number', width: 110,resizable:true },
    { type:'text',field: 'currency', headerName: 'Currency' , width: 200,resizable:true },
    { type:'text', field: 'retailPrice', headerName: 'Retail Price' , width: 200,resizable:true },
    // { type:'text',field: 'liaisonOfficeCode', headerName: 'LO' , width: 200,resizable:true },  
    
  ];
  const LabelListColumnDef = [
    {field: 'labelCode', headerName: 'Label Code', width: 140, headerClass: 'grid-header',cellStyle:{color:'#044BD9',textDecoration:'underline',cursor:'pointer'}},
    {field: 'name', headerName: 'Label Name', width: 200, headerClass: 'grid-header'},
    {field: 'labelType', headerName: 'Label Type ', width: 170, headerClass: 'grid-header'},
    {field: 'formula', headerName: 'Formula ', width: 170, headerClass: 'grid-header'},    
    {field: 'overagePercentage', headerName: 'Overage Percentage ', width: 200, headerClass: 'grid-header'},
    {field: 'height', headerName: 'Label Height', width: 200, headerClass: 'grid-header' },
    {field: 'width', headerName: 'Label Width', width: 200, headerClass: 'grid-header'},
   ];

  const ArticleMasterColumnDef =[
    { field: 'articleNumber', headerName: 'Article Number', width: 175, editable: true, headerClass: 'grid-header',resizable:true },
    { field: 'articleDescription', headerName: 'Article Description', width: 350, headerClass: 'grid-header',resizable:true },
    { field: 'oldArticleNumber', headerName: 'Old Article Number', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'divisionDescription', headerName: 'Division ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'brandCode', headerName: 'Brand Code ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'sizeRange', headerName: 'Size Range ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'modelNum', headerName: 'Model Number ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'modelDescription', headerName: 'Model Description ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'ageGroup', headerName: 'Age Group ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'gender', headerName: 'Gender', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'saleslineCode', headerName: 'Salesline', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'upperMaterial', headerName: 'Upper Material', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'liningMaterial', headerName: 'Lining Material', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'insoleMaterial', headerName: 'Insole Material', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'outsoleMaterial', headerName: 'Outsole Material', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'productTypeDescription', headerName: 'Product Type', width: 350, headerClass: 'grid-header',resizable:true },
    { field: 'productGroupDescription', headerName: 'Product Group', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'sportsCategoryDescription', headerName: 'Sports Category', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'productFit', headerName: 'Product Fit', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'colourCombinationCode', headerName: 'Colour Combination Code ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'creationSeason', headerName: 'Creation Season ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'currentSeason', headerName: 'Current Season ', width: 140, headerClass: 'grid-header',resizable:true },
    { field: 'colorCombinationShort', headerName: 'Colour Combination Short ', width: 240, headerClass: 'grid-header',resizable:true },
    { field: 'colorCombinationLong', headerName: 'Colour Combination Long ', width: 240, headerClass: 'grid-header',resizable:true },
    { field: 'workingNumber', headerName: 'Working Num', width: 240, headerClass: 'grid-header',resizable:true },

  ]
  const LabelListAssignmentColumnDef = [
    { field: 'artLabAssignmentId', headerName: 'Label Assignment ID', editable: true, width: 200, headerClass: 'grid-header',resizable:true,searchType:['Regular']  },
    // { field: 'divisionDesc', headerName: 'Division', editable: true, width: 200, headerClass: 'grid-header',resizable:true },
    { field: 'ageGroup', headerName: 'Age Group', editable: true, width: 200, headerClass: 'grid-header',resizable:true },
    { field: 'countryDesc', headerName: 'Shipping Country', editable: true, width: 200, headerClass: 'grid-header',resizable:true },
    { field: 'productGroup', headerName: 'Product Group', width: 200, editable: true, headerClass: 'grid-header',resizable:true },
    { field: 'customerNumber', headerName: 'Customer Number', editable: true, width: 200, headerClass: 'grid-header',resizable:true },
    { field: 'labelCode', headerName: 'Label Code', width: 200, editable: true, headerClass: 'grid-header',resizable:true },
];
  const customerColumnDef = [
    { field: 'customerCode', headerName: 'Customer Code', width: 180, headerClass: 'grid-header',resizable:true },
    { field: 'customerType', headerName: 'Customer Type', width: 180, headerClass: 'grid-header',resizable:true },
    { field: 'name', headerName: 'Customer Short Name', width: 300, editable: true, headerClass: 'grid-header',resizable:true },
    { field: 'shortDescription', headerName: 'Description', width: 300, headerClass: 'grid-header',resizable:true },
    { field: 'shippingAddress1', headerName: 'Shipping Address 1', width: 240, headerClass: 'grid-header',resizable:true },
    { field: 'shippingAddressCity', headerName: 'Shipping Address 2', width: 180, headerClass: 'grid-header',resizable:true },
    { field: 'shippingAddressCountry', headerName: 'Shipping Address 3', width: 180, headerClass: 'grid-header',resizable:true },
    { field: 'shippingAddressPostcode', headerName: 'Shipping Address Post code', width: 250, headerClass: 'grid-header',resizable:true },
    { field: 'shippingCountryCode', headerName: 'Shipping Address Country Code', width: 280, headerClass: 'grid-header',resizable:true },
    { field: 'greenPoint', headerName: 'Green Point', width: 280, headerClass: 'grid-header',resizable:true },
    { field: 'pictogram', headerName: 'Pictogram', width: 280, headerClass: 'grid-header',resizable:true },
    { field: 'brandCode', headerName: 'Barcode Type', width: 280, headerClass: 'grid-header',resizable:true },
 ];
 const countrySizeColumnDef = [
    { field: 'division', headerName: 'Division', width: 250, headerClass: 'grid-header',resizable:true },
    { field: 'technicalSize', headerName: 'Technical Size', width: 250, headerClass: 'grid-header',resizable:true },
    
];
  const factoryColumnDef = [
    { field: 'factoryCode', headerName: 'Factory Code', width: 125, headerClass: 'grid-header'},
    { field: 'shortDescription', headerName: 'Factory Short Name', width: 175, editable: true, headerClass: 'grid-header',resizable:true },
    { field: 'description', headerName: 'Description', width: 400, headerClass: 'grid-header',resizable:true },
    { field: 'factoryCountryCode', headerName: 'Country Code', width: 160, headerClass: 'grid-header',resizable:true },
    { field: 'manufacturerCode', headerName: 'Manufacturer Code', width: 180, headerClass: 'grid-header',resizable:true },
    { field: 'liaisonOfficeCode', headerName: 'Liaison Office Code', width: 200, headerClass: 'grid-header',resizable:true  },
    { field: 'factoryAddress1', headerName: 'Address 1', width: 400, headerClass: 'grid-header',resizable:true },
    { field: 'factoryAddress2', headerName: 'Address 2', width: 300, headerClass: 'grid-header',resizable:true  },
    { field: 'factoryAddress3', headerName: 'Address 3', width: 300, headerClass: 'grid-header',resizable:true  },
    { field: 'factoryPostcode', headerName: 'Post Code', width: 150, headerClass: 'grid-header' ,resizable:true },
    { field: 'factoryTown', headerName: 'Town', width: 250, headerClass: 'grid-header',resizable:true  },
    { field: 'factorySupplierCode', headerName: 'Supplier Code', width: 150, headerClass: 'grid-header',resizable:true  },
    { field: 'factoryCurrencyCode', headerName: 'Currency Code', width: 150, headerClass: 'grid-header',resizable:true  },
    { field: 'divisionCode', headerName: 'Division', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'productionDepartmentCode', headerName: 'Production Department Code', width: 180, headerClass: 'grid-header',resizable:true },
    { field: 'factoryLocked', headerName: 'Locked', width: 150, headerClass: 'grid-header',resizable:true  },
    { field: 'factoryLockedReason', headerName: 'Locked Reason', width: 150, headerClass: 'grid-header',resizable:true  },
   ];

   const inventoryColumnDef = [
    { field: 'orderNum', headerName: 'PO No', width: 85,resizable:true,type:'number' },
    {type:'text', field: 'orderLabelStatus', headerName: 'Status', width: 110,resizable:true },
    {type:'text', field: 'orderQuantity', headerName: 'Order Quantity', width: 110,resizable:true },
    {type:'text', field: 'labelQuantity', headerName: 'Label Quantity', width: 110,resizable:true },
    {type:'text', field: 'labelCode', headerName: 'Label Code', width: 110,resizable:true },
    { type:'text',field: 'articleNumber', headerName: 'Article Number', width: 150,resizable:true },
    { type:'text',field: 'customerCode', headerName: 'Customer Code', width: 150,resizable:true },
    {type:'text', field: 'divisionCode', headerName: 'Division Code', width: 110,resizable:true },
    {type:'text', field: 'factoryCode', headerName: 'Factory Code', width: 110,resizable:true },
    {type:'text', field: 'factoryName', headerName: 'Factory Name', width: 110,resizable:true }
  ];

  const approvalColumnDef = [
    { field: 'orderNumber', headerName: 'PO No', width: 85,resizable:true,type:'number' },
    {type:'text', field: 'status', headerName: 'Status', width: 110,resizable:true },
    {type:'text', field: 'orderQuantity', headerName: 'Order Quantity', width: 110,resizable:true },
    { type:'text',field: 'articleNumber', headerName: 'Article Number', width: 150,resizable:true },
    { field: 'modelDescription', headerName: 'Model Description', width: 400 ,resizable:true,searchType:['Regular'] },
    { type:'text',field: 'countryOfOrigin', headerName: 'Origin Country Code', width: 150,resizable:true },
    { type:'text',field: 'customerCode', headerName: 'Customer Code', width: 150,resizable:true },
    { type:'number',field: 'customerOrderNum', headerName: 'Customer Order No', width: 150,resizable:true },
    {type:'text', field: 'divisionCode', headerName: 'Division Code', width: 110,resizable:true },
    {type:'text', field: 'factoryCode', headerName: 'Factory Code', width: 110,resizable:true },
    {type:'text', field: 'factoryName', headerName: 'Factory Name', width: 110,resizable:true },
    { type:'date',field: 'lastProductionDate', headerName: 'Last Production Date', width: 110,resizable:true },
    { type:'text',field: 'shippingAddressingLine1', headerName: 'Shipping Addressing Line 1', width: 110,resizable:true },
    { type:'text',field: 'shippingAddressingLine2', headerName: 'Shipping Addressing Line 2', width: 110,resizable:true },
    {type:'text', field: 'shippingAddressingLine3', headerName: 'Shipping Addressing Line 3', width: 110,resizable:true },
    { type:'text',field: 'shipToCountry', headerName: 'Shipping Country Code', width: 110,resizable:true },
    { type:'text',field: 'currency', headerName: 'Currency' , width: 200,resizable:true },
    { type:'text', field: 'retailPrice', headerName: 'Retail Price' , width: 200,resizable:true }, 
  ];

  const searchlist = [
    {
        url:'/',
        display:false
    },
    {
        url:'/orders/Order_Label_Use',
        display: false,
        
    },
    {
      url:'/orders/View_Bulk_Actions',
      display:true,
      columnDef:BulkActionColumnDef,
      fetchFunc:searchBulkActionAction,
      searchFunc:searchBulkActionAction
    },
    {
        url:'/Order_List',
        display:true,
        columnDef:orderColumnDef,
        fetchFunc:searchOrderListAction,
        searchFunc:searchOrderListAction

    },
    {
      url:'/Inventory_List',
      display:true,
      columnDef:inventoryColumnDef,
      fetchFunc:searchOrderLabelAction,
      searchFunc:searchOrderLabelAction
     },
     {
      url:'/Approval_List',
      display:true,
      columnDef:approvalColumnDef,
      fetchFunc:searchApprovalOrderListAction,
      searchFunc:searchApprovalOrderListAction
     },
    {
      url:'/Country_Size',
        display:true,
        columnDef:countrySizeColumnDef,
        fetchFunc:searchCountrySizeAction,
        searchFunc:searchCountrySizeAction
    },
    {
        url:'/Label/label_Main_Type',
        display:true
    },
    {
        url:'/referenceData/Label_Receiver',
        display:true
    },
    {
      url:'/referenceData/productReference/articleMaster',
      display:false
  },
    {
      url:'/Label/LabelAdmin',
      display:false
  },
  {
    url:'/Article_master',
    display:true,
    columnDef:ArticleMasterColumnDef,
    fetchFunc:getLabelArticleListAction,
    searchFunc:getLabelArticleListAction
},
    {
        url:'/Label_List',
        display:true,
        columnDef:LabelListColumnDef,
        fetchFunc:getLabelListAdminAction,
        searchFunc:getLabelListAdminAction
    },
    {
        url:'/Customer_List',
        display:true,
        columnDef : customerColumnDef,
        fetchFunc:searchCustomerAction,
        searchFunc:searchCustomerAction


    },
    {
      url:'/Factory_List',
      display:true,
      columnDef : factoryColumnDef,
      fetchFunc:searchFactoryAction,
      searchFunc:searchFactoryAction
  },
    
    {
        url:'/Label_Assignment',
        display:true,
        columnDef : LabelListAssignmentColumnDef,
        fetchFunc:searchLabelAssignmentAction,
        searchFunc:searchLabelAssignmentAction

    },
    {
        url:'/label/Label_Usage_Type',
        display:false
    },
    {
        url:'/label/Factory_List',
        display:false
    },
    {
      url:'/users/UserList',
      display:false
    },
    {
      url:'/users/UserGroup',
      display:false
    },
    {
      url:'/users/UserOrder',
      display:false
    }

  ]
  
export default function searchhelper (text) {
   return searchlist.filter(item => item.url===text )
}
