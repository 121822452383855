import React, { useState, useEffect, useMemo,useRef } from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Box } from '@mui/material';
import { styles, StyledAgGridReact } from './styles';
import {searchFactoryAction } from './Store/actions';
import {useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import GridPagination from '../../../customWidgets/GridPagination/index';
import Utils from '../../../utils/Utils';
import Loader from '../../../customWidgets/Loader'
import AccessLevelContext from '../../../../accessLevel-context';
import logo from '../../../assets/excelicon.png';
import * as XLSX from 'xlsx';
import { apisearchFactory } from '../../../services/Gateway/RestAPIHandler';
import SimpleSnackbar from '../../../customWidgets/Snackbar';

function LabelFactory() {
  const accessLevel= React.useContext(AccessLevelContext)
  const navigate = useNavigate()
  const checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const columnDef = [
    { field: 'chkBox', headerName: '', checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 20 },
    { field: 'factoryCode', resizable: true, headerName: 'Factory Code', width: 150, headerClass: 'grid-header' },
    { field: 'shortDescription', headerName: 'Factory Short Name', width: 175, editable: true, headerClass: 'grid-header',resizable:true },
    { field: 'description', headerName: 'Description', width: 400, headerClass: 'grid-header',resizable:true },
    { field: 'factoryCountryCode', headerName: 'Country Code', width: 160, headerClass: 'grid-header',resizable:true },
    { field: 'manufacturerCode', headerName: 'Manufacturer Code', width: 180, headerClass: 'grid-header',resizable:true },
    { field: 'liaisonOfficeCode', headerName: 'Liaison Office Code', width: 200, headerClass: 'grid-header',resizable:true  },
    { field: 'factoryAddress1', headerName: 'Address 1', width: 400, headerClass: 'grid-header',resizable:true },
    { field: 'factoryAddress2', headerName: 'Address 2', width: 300, headerClass: 'grid-header',resizable:true  },
    { field: 'factoryAddress3', headerName: 'Address 3', width: 300, headerClass: 'grid-header',resizable:true  },
    { field: 'factoryPostcode', headerName: 'Post Code', width: 150, headerClass: 'grid-header' ,resizable:true },
    { field: 'factoryTown', headerName: 'Town', width: 250, headerClass: 'grid-header',resizable:true  },
    { field: 'factorySupplierCode', headerName: 'Supplier Code', width: 150, headerClass: 'grid-header',resizable:true  },
    { field: 'factoryCurrencyCode', headerName: 'Currency Code', width: 150, headerClass: 'grid-header',resizable:true  },
    { field: 'divisionCode', headerName: 'Division', width: 150, headerClass: 'grid-header',resizable:true },
    { field: 'productionDepartmentCode', headerName: 'Production Department Code', width: 180, headerClass: 'grid-header',resizable:true },
    { field: 'factoryLocked', headerName: 'Locked', width: 150, headerClass: 'grid-header',resizable:true  },
    { field: 'factoryLockedReason', headerName: 'Locked Reason', width: 150, headerClass: 'grid-header',resizable:true  },
   ];

  const [currentPage, setCurrentPage] = React.useState(1);
  const GRID_FETCH_SIZE = 7;
  const gridRef = useRef();
  const [loading,setLoading] = useState(true)
  const [openAlert, setOpenAlert] = useState(false);
  const list = useSelector(state => state.labelFactoryList);
  const [req, setReq] = useState(list.request)
  const dispatch = useDispatch();
  const [alertType,setAlertType] = useState("success");
  const [alertMessage,setAlertMessage] = useState("");
  const [labelRows,setLabelRows] = React.useState([])

  useEffect(() => {
    if(accessLevel?.accessLevel!=='A01' && accessLevel?.accessLevel!=='A03'){
        navigate('/')
      }
      else{
    if (req.filterSearch !== '' && req.hasOwnProperty('filterSearch')) {
      dispatch(searchFactoryAction(req))
    }
    else {
      dispatch(searchFactoryAction({
        "ascDesc": "",
        "fetchSize": GRID_FETCH_SIZE,
        "filterSearch": "",
        "pageNumber": currentPage,
        "sortBy": ""
      }))
    }}
  }, [])//eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!Utils.isEmpty(list.order)) {
      setLoading(false)
    }
  }, [currentPage])//eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setCurrentPage(req.pageNumber)
  }, [req]);
  const onSelectionChange = (event) => {
    const selectedRows = event.api.getSelectedRows();
    if (!Utils.isEmpty(selectedRows)){
      setLabelRows(selectedRows)
    }
    else{
      setLabelRows([])
    }
  }
  const showGridLoader = () => {
    gridRef.current.api.showLoadingOverlay();
  }
  const getTotalPages = useMemo(() => {
    setReq(list.request)
    const orderData = !Utils.isEmpty(list.order) ? list.order?.data[0] : [];
    if (!Utils.isEmpty(list.order)) {
      return Utils.isEmpty(list.order?.data) ?
        {
          totalPages: 1,
          totalElements: 1
        } : {
          totalPages: orderData?.totalPages,
          totalElements: orderData?.totalElements
        };
    }
    else
      return {
        totalPages: 1,
        totalElements: 1
      };
  }, [list.order]);
  const dataToExcel = (data) => {
    const newData = data.map(ele => {
      return {
        "Factory Code" : ele?.factoryCode,
        "Factory Short Name ": ele?.shortDescription,
        "Description":ele?.description,
        "Country Code":ele?.factoryCountryCode,
        "Manufacturer Code":ele?.manufacturerCode,
        "Liaison Office Code":ele?.liaisonOfficeCode,
        "Address 1":ele?.factoryAddress1,
        "Address 2":ele?.factoryAddress2,
        "Address 3":ele?.factoryAddress3,
        "Post Code":ele?.factoryPostcode,
        "Town":ele?.factoryTown,
        "Supplier Code":ele?.factorySupplierCode,
        "Currency Code":ele?.factoryCurrencyCode,
        "Division":ele?.divisionCode,
        "Production Department Code":ele?.productionDepartmentCode,
        "Locked":ele?.factoryLocked,
        "Locked Reason":ele?.factoryLockedReason,
      
        
              }
    })
    
    const worksheet = XLSX.utils.json_to_sheet(newData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    XLSX.writeFile(workbook, "DownloadFactoryList.xlsx")
    setLoading(false)
  }
  const exportToExcel = () => {
    setLoading(true)

          if(labelRows.length===0){
            apisearchFactory(
              {...req,fetchSize:500,pageNumber: 1}
            ).then(([response, json])=> {
            if(response.status===200 && !Utils.isEmpty(json.results)){
              const data = json.results[0].content;
              dataToExcel(data)
              }
            else{
              setOpenAlert(true)
              setAlertType('error')
              setAlertMessage("No data to export")
              setLoading(false)
            }
          })
          }      
          else{
            dataToExcel(labelRows)
          }  
       
  }

  const handleGridPagination = (event, thisPage) => {
    if(thisPage !== currentPage) {
      showGridLoader();
      setLabelRows([])
      setCurrentPage(thisPage);

      if (req.filterSearch !== '' && req.hasOwnProperty('filterSearch')) {
        dispatch(searchFactoryAction({ ...req, fetchSize: GRID_FETCH_SIZE, pageNumber: thisPage }));
      }
      else {
        dispatch(searchFactoryAction({
          "ascDesc": "",
          "fetchSize": GRID_FETCH_SIZE,
          "filterSearch": "",
          "pageNumber": thisPage,
          "sortBy": ""
        }))
      }
    }
  }
  const gotoKeyDown = (event, value) => {
    if (event.keyCode === 13 && parseInt(value) >= 1 && parseInt(value) <= getTotalPages.totalPages && parseInt(value)!==currentPage ) {
      showGridLoader();
      setLabelRows([])
      setCurrentPage(parseInt(value));
      if (req.filterSearch !== '' && req.hasOwnProperty('filterSearch')) {
        dispatch(searchFactoryAction({ ...req, fetchSize: GRID_FETCH_SIZE, pageNumber: parseInt(value) }));
      }
      else {
        dispatch(searchFactoryAction({
          "ascDesc": "",
          "fetchSize": GRID_FETCH_SIZE,
          "filterSearch": "",
          "pageNumber": parseInt(value),
          "sortBy": ""
        }))
      }
    }
  }
  return (
    <Box style={styles.gridContainer}>
       <Loader open={loading}/>
       <img onClick={() => { exportToExcel() }} src={logo} style={styles.excelicon} alt={"Export Excel"} />
       <SimpleSnackbar setOpen={setOpenAlert} open={openAlert}  message={alertMessage} messagetype={alertType} />

      <Box className={"ag-theme-alpine"} sx={{ height: '365px', width: '100%' }}>
        <StyledAgGridReact
        ref={gridRef}
          rowSelection='multiple'
          rowData={Object.keys(list.order).length > 0 ? list.order.data[0].content : []}
          columnDefs={columnDef}
          pagination={true}
          paginationPageSize={GRID_FETCH_SIZE}
          onSelectionChanged={onSelectionChange}
          suppressRowClickSelection={true}
          suppressPaginationPanel={true}
          overlayLoadingTemplate={`<div class="loader"></div>`}
        />

      </Box>
      <Box style={styles.alignBottom}>
        <GridPagination
          pageClick={handleGridPagination}
          gridTotal={getTotalPages}
          currentPage={currentPage}
          pageSize={GRID_FETCH_SIZE}
          onSelectionChanged={onSelectionChange}
          gotoKeyDown={gotoKeyDown} />
      </Box>

    </Box>
  )
}
export default LabelFactory;