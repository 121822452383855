import { LABEL_LIST_GET, LABEL_LIST_SUCCESS, LABEL_LIST_FAILURE } from './actions';

const initialState = {
    order: {}
}

export function LabelReducer(state = initialState, action) {
    if(action.type === LABEL_LIST_GET) {
        return state;
    }
    if(action.type === LABEL_LIST_SUCCESS) {
        return {
            ...state,
            order: {
                isLoaded: true,
                data: action.payload,
                message: 'Success'
            }
        }
    }
    if(action.type === LABEL_LIST_FAILURE) {
        return {
            ...state,
            order: {
                data: [],
                message: action.payload
            }
        }
    }
    return state;
}