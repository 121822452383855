import { styled } from "@mui/system";
import { Accordion, AccordionSummary, Typography } from '@mui/material';

export const POAccordion = styled(Accordion)({
    maxHeight: "fit-content",
    overflow: "hidden"
})

export const POAccordionSummary = styled(AccordionSummary)({
    height: "fit-content",
    margin: 0,
    padding: 0,
    '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0'
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        padding: '0 8px'
    }
})

export const POTypography = styled(Typography) ({
    fontSize: '22px',
    color: `var(--POTextColor)`,
    marginLeft: '14px'
})

export const styles = {
    POSummaryContent: {
        '& .Mui-expanded': {
            backgroundColor: "black"
        }
    }
}