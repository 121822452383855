import React from 'react'
import { OrderButton,styles } from './styles'
import {Box} from '@mui/material/'
import AccessLevelContext from '../../../accessLevel-context';
function Buttons({handleApprove,ApprovedStatus,ApprovalPendingStatus,poStatus,shippedStatus,printedStatus,handlePrepareSend,labelIds,handleOpen4,cancelStatus,handleOpenAddLabel,sentStatus,handlePrepare,processingStatus,lockStatus,preparedStatus,handlelock,handleunlock,handleCancel,handleSent,handleSendApproval}) {

  const accessLevel= React.useContext(AccessLevelContext);
  return (
    <Box style={styles.buttons}>
      <OrderButton onClick={handlePrepare} className={labelIds===""||!ApprovedStatus||cancelStatus||sentStatus||lockStatus||processingStatus||shippedStatus||printedStatus?'disabled':''}>Prepare</OrderButton>
      <OrderButton onClick={handleSent} className={labelIds===""||!ApprovedStatus||cancelStatus===true||shippedStatus||printedStatus||sentStatus?'disabled':preparedStatus?'':'disabled'} >Send</OrderButton>
      <OrderButton onClick={handlePrepareSend} className={labelIds===""||!ApprovedStatus||cancelStatus||lockStatus||processingStatus||sentStatus||shippedStatus||printedStatus?'disabled':''} >Prepare & Send</OrderButton>
      <OrderButton  onClick={handlelock} className={(labelIds===""||!ApprovedStatus||cancelStatus||processingStatus||sentStatus||lockStatus||shippedStatus||printedStatus)?'disabled':''} >Lock</OrderButton>
      <OrderButton  onClick={handleunlock} className={labelIds!==""&&lockStatus?'':'disabled'}>Unlock</OrderButton>
      <OrderButton onClick={handleCancel} className={labelIds===""||cancelStatus||shippedStatus||lockStatus||sentStatus||processingStatus||printedStatus?'disabled':''} >Cancel</OrderButton>
      {/* <OrderButton onClick={handleCancel} className={labelIds===""||cancelStatus||shippedStatus||lockStatus?'disabled':''} >Cancel</OrderButton>
      <OrderButton onClick={handleOpen4} className={labelIds===""||shippedStatus?'disabled':''} >Delete</OrderButton> */}
      <OrderButton onClick={handleOpen4} className={labelIds===""||cancelStatus||lockStatus||sentStatus||processingStatus||printedStatus||shippedStatus?'disabled':''} >Delete</OrderButton>
      <OrderButton onClick={handleOpenAddLabel} >Add Label</OrderButton>
      <OrderButton onClick={handleSendApproval} className={labelIds===""||!ApprovalPendingStatus?'disabled':''}>Send For Approval</OrderButton>
      <OrderButton onClick={handleApprove} style={{display:accessLevel?.accessLevel!=='A01'?'none':'block'}} className={labelIds===""||ApprovedStatus?'disabled':''}>Approve</OrderButton>
    </Box>
  )
  }
export default Buttons
